import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getAuction,
	setAuctionsLoading,
	setAuctionsNotLoading,
	startAuction,
} from "../../../../actions/auctionActions";
import { useParams, Link } from "react-router-dom";
import { Redirect } from "react-router";
import { confirm } from "react-confirm-box";
import util from "../../../../util/RENO";

class StartRENOAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			duration: 0,
			durationMinutes: 0,
			durationSeconds: 0,
			minimumContractPrice: 0,
			minimumDecrement: 0,
			bidUpperLimit: 0,
			timeExtensionWindow: 0,
			timeExtensionWindowMinutes: 0,
			timeExtensionWindowSeconds: 0,
			timeExtensionDuration: 0,
			timeExtensionDurationMinutes: 0,
			timeExtensionDurationSeconds: 0,
			postBreakDuration: 0,
			postBreakDurationMinutes: 0,
			postBreakDurationSeconds: 0,
			minimumDecrementToStrikePrice: 0,
			tiesOnlyForLeadingBids: 0,
			showRanksToBidders: 0,
			onlyLeadingBidsCanExtend: 0,
		};
	}

	async componentDidMount() {
		await this.props.setAuctionsLoading();
		await this.props.getAuction(this.props.params.auctionId);
		await this.props.setAuctionsNotLoading();

		const { auction } = this.props.auction;

		const durationMinutes = parseInt(auction.startingDuration / 60);
		const durationSeconds = parseInt(auction.startingDuration % 60);
		const timeExtensionWindowMinutes = parseInt(auction.timeExtensionWindow / 60);
		const timeExtensionWindowSeconds = parseInt(auction.timeExtensionWindow % 60);
		const timeExtensionDurationMinutes = parseInt(auction.timeExtensionDuration / 60);
		const timeExtensionDurationSeconds = parseInt(auction.timeExtensionDuration % 60);
		const postBreakDurationMinutes = parseInt(auction.postBreakDuration / 60);
		const postBreakDurationSeconds = parseInt(auction.postBreakDuration % 60);

		this.setState({
			duration: auction.startingDuration,
			durationMinutes: durationMinutes,
			durationSeconds: durationSeconds,
			minimumContractPrice: auction.minimumContractPrice,
			minimumDecrement: auction.minimumDecrement,
			bidUpperLimit: auction.bidUpperLimit,
			timeExtensionWindow: auction.timeExtensionWindow,
			timeExtensionWindowMinutes: timeExtensionWindowMinutes,
			timeExtensionWindowSeconds: timeExtensionWindowSeconds,
			timeExtensionDuration: auction.timeExtensionDuration,
			timeExtensionDurationMinutes: timeExtensionDurationMinutes,
			timeExtensionDurationSeconds: timeExtensionDurationSeconds,
			postBreakDuration: auction.postBreakDuration,
			postBreakDurationMinutes: postBreakDurationMinutes,
			postBreakDurationSeconds: postBreakDurationSeconds,
			minimumDecrementToStrikePrice: auction.minimumDecrementToStrikePrice,
			tiesOnlyForLeadingBids: auction.tiesOnlyForLeadingBids,
			showRanksToBidders: auction.showRanksToBidders,
			onlyLeadingBidsCanExtend: auction.onlyLeadingBidsCanExtend,
		});
	}

	async auctionStartAction() {
		const { auction } = this.props.auction;

		const estimatedStartTime = new Date().getTime() / 1000.0;
		const estimatedEndTime = estimatedStartTime + this.state.duration;

		const result = await confirm("Auction Start Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "35vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Would you like to start this auction with the following configuration parameters?
								<table className="table table-bordered table-sm">
									<thead>
										<tr>
											<th>Parameter</th>
											<th>Value</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Auction Duration</td>
											<td>{`${this.state.duration / 60} min(s) ${
												this.state.duration % 60
											} sec(s)`}</td>
										</tr>
										<tr>
											<td>Approx Start Time</td>
											<td>{util.formatTime(estimatedStartTime)}</td>
										</tr>
										<tr>
											<td>Approx End Time</td>
											<td>{util.formatTime(estimatedEndTime)}</td>
										</tr>
										<tr>
											<td>Minimum Contract Price</td>
											<td>{util.formatCurrencyWithText(this.state.minimumContractPrice)}</td>
										</tr>
										<tr>
											<td>Minimum Decrement</td>
											<td>{util.formatCurrencyWithText(this.state.minimumDecrement)}</td>
										</tr>
										<tr>
											<td>Bid Upper Limit</td>
											<td>{util.formatCurrencyWithText(this.state.bidUpperLimit)}</td>
										</tr>
										<tr>
											<td>Time Extension Window</td>
											<td>{util.formatDurationWithText(this.state.timeExtensionWindow)}</td>
										</tr>
										<tr>
											<td>Time Extension Duration</td>
											<td>{util.formatDurationWithText(this.state.timeExtensionDuration)}</td>
										</tr>
										<tr>
											<td>Post Break Duration</td>
											<td>{util.formatDurationWithText(this.state.postBreakDuration)}</td>
										</tr>
									</tbody>
								</table>
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			const data = {
				duration: this.state.duration,
				minimumContractPrice: this.state.minimumContractPrice,
				minimumDecrement: this.state.minimumDecrement,
				bidUpperLimit: this.state.bidUpperLimit,
				timeExtensionWindow: this.state.timeExtensionWindow,
				timeExtensionDuration: this.state.timeExtensionDuration,
				postBreakDuration: this.state.postBreakDuration,
				minimumDecrementToStrikePrice: this.state.minimumDecrementToStrikePrice,
				tiesOnlyForLeadingBids: this.state.tiesOnlyForLeadingBids,
				showRanksToBidders: this.state.showRanksToBidders,
				onlyLeadingBidsCanExtend: this.state.onlyLeadingBidsCanExtend,
			};

			await this.props.startAuction(this.props.params.auctionId, data);
			return;
		}
	}

	onFloatChange(event) {
		this.setState({
			...this.state,
			[event.target.title]: parseFloat(event.target.value) > 0 ? parseFloat(event.target.value) : 0,
		});
	}

	onTimeChange(event) {
		const newValue = parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;

		if (event.target.title === "dur-min")
			this.setState({
				...this.state,
				durationMinutes: newValue,
				duration: newValue * 60 + this.state.durationSeconds,
			});
		else if (event.target.title === "dur-sec")
			this.setState({
				...this.state,
				durationSeconds: newValue,
				duration: this.state.durationMinutes * 60 + newValue,
			});
		else if (event.target.title === "tew-min")
			this.setState({
				...this.state,
				timeExtensionWindowMinutes: newValue,
				timeExtensionWindow: newValue * 60 + this.state.timeExtensionWindowSeconds,
			});
		else if (event.target.title === "tew-sec")
			this.setState({
				...this.state,
				timeExtensionWindowSeconds: newValue,
				timeExtensionWindow: this.state.timeExtensionWindowMinutes * 60 + newValue,
			});
		else if (event.target.title === "ted-min")
			this.setState({
				...this.state,
				timeExtensionDurationMinutes: newValue,
				timeExtensionDuration: newValue * 60 + this.state.timeExtensionDurationSeconds,
			});
		else if (event.target.title === "ted-sec")
			this.setState({
				...this.state,
				timeExtensionDurationSeconds: newValue,
				timeExtensionDuration: this.state.timeExtensionDurationMinutes * 60 + newValue,
			});
		else if (event.target.title === "pbd-min")
			this.setState({
				...this.state,
				postBreakDurationMinutes: newValue,
				postBreakDuration: newValue * 60 + this.state.postBreakDurationSeconds,
			});
		else if (event.target.title === "pbd-sec")
			this.setState({
				...this.state,
				postBreakDurationSeconds: newValue,
				postBreakDuration: this.state.postBreakDurationMinutes * 60 + newValue,
			});
	}

	onMinimumDecrementToStrikePriceChange() {
		this.setState({
			...this.state,
			minimumDecrementToStrikePrice: this.state.minimumDecrementToStrikePrice === 0 ? 1 : 0,
		});
	}

	onTiesOnlyForLeadingBidsChange() {
		this.setState({
			...this.state,
			tiesOnlyForLeadingBids: this.state.tiesOnlyForLeadingBids === 0 ? 1 : 0,
		});
	}

	onShowRanksToBiddersChange() {
		this.setState({
			...this.state,
			showRanksToBidders: this.state.showRanksToBidders === 0 ? 1 : 0,
		});
	}

	onOnlyLeadingBidsCanExtendChange() {
		this.setState({
			...this.state,
			onlyLeadingBidsCanExtend: this.state.onlyLeadingBidsCanExtend === 0 ? 1 : 0,
		});
	}

	render() {
		const { auction, auction_loading, started } = this.props.auction;
		const { user, user_loading, signedIn } = this.props.user;

		if (!signedIn || user.type !== "admin" || started) {
			return <Redirect to="/auctions" />;
		}

		if (auction_loading || user_loading) {
			return (
				<div className="container">
					<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/auctions">Auctions</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Start Auction
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Start Auction
						</li>
					</ol>
				</nav>

				<hr />

				<div>
					<h4>Auction Configuration Parameters</h4>
					<br />

					<div className="row">
						<div className="col-4">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Minimum Contract Price: </span>
								<span className="input-group-text">NOK</span>
								<input
									type="number"
									className="form-control"
									title="minimumContractPrice"
									value={this.state.minimumContractPrice}
									onChange={(e) => this.onFloatChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">øre/kWh</span>
							</div>
						</div>
						<div className="col-4">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Minimum Decrement: </span>
								<span className="input-group-text">NOK</span>
								<input
									type="number"
									className="form-control"
									title="minimumDecrement"
									value={this.state.minimumDecrement}
									onChange={(e) => this.onFloatChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">øre/kWh</span>
							</div>
						</div>
						<div className="col-4">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Bid Upper Limit: </span>
								<span className="input-group-text">NOK</span>
								<input
									type="number"
									className="form-control"
									title="bidUpperLimit"
									value={this.state.bidUpperLimit}
									onChange={(e) => this.onFloatChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">øre/kWh</span>
							</div>
						</div>
					</div>

					<br />

					<div className="row">
						<div className="col-6">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Auction Duration:</span>
								<input
									type="number"
									className="form-control"
									title="dur-min"
									value={this.state.durationMinutes}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">min(s)</span>
								<input
									type="number"
									className="form-control"
									title="dur-sec"
									value={this.state.durationSeconds}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">sec(s)</span>
							</div>
						</div>
						<div className="col-6">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Post Break Duration:</span>
								<input
									type="number"
									className="form-control"
									title="pbd-min"
									value={this.state.postBreakDurationMinutes}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">min(s)</span>
								<input
									type="number"
									className="form-control"
									title="pbd-sec"
									value={this.state.postBreakDurationSeconds}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">sec(s)</span>
							</div>
						</div>
					</div>
					<br />

					<div className="row">
						<div className="col-6">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Time Extension Window:</span>
								<input
									type="number"
									className="form-control"
									title="tew-min"
									value={this.state.timeExtensionWindowMinutes}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">min(s)</span>
								<input
									type="number"
									className="form-control"
									title="tew-sec"
									value={this.state.timeExtensionWindowSeconds}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">sec(s)</span>
							</div>
						</div>
						<div className="col-6">
							<div className="input-group input-group-sm has-validation">
								<span className="input-group-text">Time Extension Duration:</span>
								<input
									type="number"
									className="form-control"
									title="ted-min"
									value={this.state.timeExtensionDurationMinutes}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">min(s)</span>
								<input
									type="number"
									className="form-control"
									title="ted-sec"
									value={this.state.timeExtensionDurationSeconds}
									onChange={(e) => this.onTimeChange(e)}
									style={{ textAlign: "center" }}
								/>
								<span className="input-group-text">sec(s)</span>
							</div>
						</div>
					</div>
					<br />

					<div className="row">
						<div className="col-6">
							<div className="input-group input-group-sm">
								<div className="input-group-text" style={{ width: "100%" }}>
									<div
										className="form-check form-switch"
										style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
									>
										<input
											className="form-check-input"
											type="checkbox"
											checked={this.state.minimumDecrementToStrikePrice}
											onChange={(e) => this.onMinimumDecrementToStrikePriceChange(e)}
										/>
										<label className="form-check-label">
											Apply Minimum Decrement to Strike Bid
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="input-group input-group-sm">
								<div className="input-group-text" style={{ width: "100%" }}>
									<div
										className="form-check form-switch"
										style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
									>
										<input
											className="form-check-input"
											type="checkbox"
											checked={this.state.tiesOnlyForLeadingBids}
											onChange={(e) => this.onTiesOnlyForLeadingBidsChange(e)}
										/>
										<label className="form-check-label">Ties only for Leading Bid Prices</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />

					<div className="row">
						<div className="col-6">
							<div className="input-group input-group-sm">
								<div className="input-group-text" style={{ width: "100%" }}>
									<div
										className="form-check form-switch"
										style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
									>
										<input
											className="form-check-input"
											type="checkbox"
											checked={this.state.showRanksToBidders}
											onChange={(e) => this.onShowRanksToBiddersChange(e)}
										/>
										<label className="form-check-label">Show Ranks to Bidders</label>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="input-group input-group-sm">
								<div className="input-group-text" style={{ width: "100%" }}>
									<div
										className="form-check form-switch"
										style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
									>
										<input
											className="form-check-input"
											type="checkbox"
											checked={this.state.onlyLeadingBidsCanExtend}
											onChange={(e) => this.onOnlyLeadingBidsCanExtendChange(e)}
										/>
										<label className="form-check-label">Only Leading Bids can Extend</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />

					<div className="row">
						<button
							className="btn btn-outline-success"
							style={{ width: "20%", display: "block", marginRight: "0", marginLeft: "auto" }}
							onClick={() => this.auctionStartAction()}
						>
							Start Auction
						</button>
					</div>
				</div>
			</div>
		);
	}
}

function WithParams(props) {
	let params = useParams();
	return <StartRENOAuction {...props} params={params} />;
}

StartRENOAuction.propTypes = {
	getAuction: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	startAuction: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, { getAuction, setAuctionsLoading, setAuctionsNotLoading, startAuction })(
	WithParams
);
