import { CONFIG_LOADING, STOP_CONFIG_LOADING, UPDATE_CONFIG } from "../actions/types";

const initialState = {
	allowUserLogin: true,
	presentationMode: false,
	presentationModeAuctionId: 1,
	configLoading: false,
	auctions: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_CONFIG:
			return {
				...state,
				allowUserLogin: action.allowUserLogin,
				presentationMode: action.presentationMode,
				presentationModeAuctionId: action.presentationModeAuctionId,
				auctions: action.auctions,
			};
		case CONFIG_LOADING:
			return {
				...state,
				configLoading: true,
			};
		case STOP_CONFIG_LOADING:
			return {
				...state,
				configLoading: false,
			};
		default:
			return state;
	}
}
