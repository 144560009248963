import {
	GET_AUCTIONS,
	GET_AUCTION,
	CREATE_AUCTION,
	GET_RESULT,
	GET_HISTORY,
	GET_PRICE_DEMAND_HISTORY,
	GET_PRICES,
	GET_MPSB_ALL_BIDS,
	GET_MPSB_ALL_BIDS_FOR_USERS,
	GET_MPSB_USER_BIDS,
	GET_ROUND,
	AUCTION_LOADING,
	START_AUCTION,
	END_ROUND,
	END_AUCTION,
	USER_BIDS_REMOVAL_MPSB,
	NEXT_ROUND,
	MAKE_BID,
	SIMULATE_MPSB_BIDS,
	STOP_AUCTION_LOADING,
	RESET_AUCTION,
	DETERMINE_WINNERS,
	GET_WINNING_BIDS,
	GET_BIDDER_PROGRESS,
	SIMULATE_ROUND,
	GET_SIMULATION_DATA,
	GET_BID_CREDITS,
	GET_STATE,
	RESTORE_STATE,
	UNDO_USER_ROUND_BIDS,
	UPDATE_AUCTION,
	GET_RENO_USER_BIDS,
	AUCTION_NO_CHANGE,
} from "../actions/types";

const initialState = {
	auctions: [],
	auction: {},
	round: {},
	results: {},
	winning_bids: {},
	history: [],
	price_demand_history: [],
	prices: [],
	allBids: [],
	allBidsUser: [],
	userBids: [],
	bid_credits: [],
	bidder_progress: [],
	simulation_data: [],
	auction_loading: false,
	success: {},
	simulated: false,
	created: false,
	started: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_AUCTIONS:
			return {
				...state,
				auctions: action.payload,
				created: false,
				started: false,
			};
		case STOP_AUCTION_LOADING:
			return {
				...state,
				auction_loading: false,
			};
		case GET_AUCTION:
			return {
				...state,
				auction: action.payload,
			};
		case CREATE_AUCTION:
			return {
				...state,
				auction_loading: false,
				created: true,
			};
		case UPDATE_AUCTION:
			return {
				...state,
			};
		case START_AUCTION:
			return {
				...state,
				success: action.payload,
				started: true,
			};
		case GET_ROUND:
			return {
				...state,
				round: action.payload,
			};
		case END_ROUND:
			return {
				...state,
				success: action.payload,
			};
		case END_AUCTION:
			return {
				...state,
				success: action.payload,
			};
		case USER_BIDS_REMOVAL_MPSB:
			return {
				...state,
			};
		case MAKE_BID:
			return {
				...state,
			};
		case SIMULATE_MPSB_BIDS:
			return {
				...state,
				simulated: true,
			};
		case SIMULATE_ROUND:
			return {
				...state,
			};
		case GET_SIMULATION_DATA:
			return {
				...state,
				simulation_data: action.payload,
			};
		case NEXT_ROUND:
			return {
				...state,
				success: action.payload,
			};
		case DETERMINE_WINNERS:
			return {
				...state,
			};
		case AUCTION_NO_CHANGE:
			return {
				...state,
			};
		case GET_WINNING_BIDS:
			return {
				...state,
				winning_bids: action.payload,
			};
		case AUCTION_LOADING:
			return {
				...state,
				auction_loading: true,
			};
		case GET_RESULT:
			return {
				...state,
				results: action.payload,
			};
		case GET_HISTORY:
			return {
				...state,
				history: action.payload,
			};
		case GET_PRICE_DEMAND_HISTORY:
			return {
				...state,
				price_demand_history: action.payload,
			};
		case GET_PRICES:
			return {
				...state,
				prices: action.payload,
			};
		case GET_MPSB_ALL_BIDS:
			return {
				...state,
				allBids: action.payload,
			};
		case GET_MPSB_ALL_BIDS_FOR_USERS:
			return {
				...state,
				allBidsUser: action.payload,
			};
		case GET_MPSB_USER_BIDS:
			return {
				...state,
				userBids: action.payload,
			};
		case GET_BIDDER_PROGRESS:
			return {
				...state,
				bidder_progress: action.payload,
			};
		case GET_BID_CREDITS:
			return {
				...state,
				bid_credits: action.payload,
			};
		case GET_STATE:
			return {
				...state,
				success: action.payload,
			};
		case RESTORE_STATE:
			return {
				...state,
			};
		case UNDO_USER_ROUND_BIDS:
			return {
				...state,
			};
		case GET_RENO_USER_BIDS:
			return {
				...state,
				userBids: action.payload.bids,
			};
		case RESET_AUCTION:
			return {
				...state,
				auction: {},
				round: {},
				results: {},
				winning_bids: {},
				history: [],
				prices: [],
				bid_credits: [],
				bidder_progress: [],
				simulation_data: [],
				userBids: [],
				allBids: [],
				allBidsUser: [],
				simulated: false,
			};
		default:
			return state;
	}
}
