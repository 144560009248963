import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { useHistory } from "react-router-dom";
import { emitSendReminder, emitMakeAnnouncement, emitTriggerRefresh } from "../../../../helpers/emit/auction";
import {
	getSaveState,
	restoreState,
	simulateRound,
	endRound,
	nextRound,
	determineWinners,
	randomizeWinners,
	setAuctionsLoading,
	setAuctionsNotLoading,
	getAuction,
	getRound,
	getResults,
	getBidCredits,
	getPriceDemandHistory,
	getPrices,
	getWinningBids,
	getBidderProgress,
	getSimulationData,
	getHistory,
} from "../../../../actions/auctionActions";
import { getPoints, getBids, getAllUsers, setUserLoading, setUserNotLoading } from "../../../../actions/userActions";

export const SMRAAdminPanel = (props) => {
	const {
		mainFilterValue,
		incrementValues,
		nominalIncrementValues,
		priceTargetValues,
		fetchDataAction,
		refreshAction,
	} = props;
	const [announcementText, setAnnouncementText] = useState("");
	const [autoSave, setAutoSave] = useState(false);
	const [timerValueMins, setTimerValueMins] = useState("03");
	const [timerValueSecs, setTimerValueSecs] = useState("00");
	const [jsonUploadEnabled, setJsonUploadEnabled] = useState(false);
	const [jsonUploadFile, setJsonUploadFile] = useState({});

	let history = useHistory();
	const params = useParams();
	const auction = useSelector((state) => state.auction);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const startingPoints = auction.bidder_progress.reduce((total, prog) => prog.starting_points + total, 0);
	const currentPoints = auction.bidder_progress.reduce((total, prog) => prog.points + total, 0);
	const eligibleUsers = auction.bidder_progress.filter((prog) => prog.starting_points > 0).length;
	const submittedUsers = auction.bidder_progress.filter(
		(prog) => prog.starting_points > 0 && prog.points < prog.starting_points
	).length;

	const pointsPerc = ((startingPoints - currentPoints) * 100) / startingPoints;
	const usersPerc = (submittedUsers * 100) / eligibleUsers;

	let pointsClass =
		"progress-bar progress-bar-striped " +
		(pointsPerc >= 50 ? (pointsPerc === 100 ? "bg-success" : "bg-warning") : "bg-danger");
	let usersClass =
		"progress-bar progress-bar-striped " +
		(usersPerc >= 50 ? (usersPerc === 100 ? "bg-success" : "bg-warning") : "bg-danger");

	if (auction.round.round_status === "Ended") {
		pointsClass = "progress-bar progress-bar-striped bg-secondary";
		usersClass = "progress-bar progress-bar-striped bg-secondary";
	}

	const startAuctionEnabled = auction.auction.round === 0;
	const roundEndEnabled =
		auction.auction.round !== 0 &&
		auction.round.round_status === "In Progress" &&
		auction.auction.status === "In Progress";
	const nextRoundEnabled = auction.round.round_status === "Ended" && auction.auction.status === "In Progress";
	const determineWinnersEnabled =
		auction.round.round_status === "Ended" && auction.auction.status === "Ended" && !auction.winning_bids.bids;
	const randomizeWinnersEnabled =
		auction.round.round_status === "Ended" && auction.auction.status === "Ended" && auction.winning_bids.bids;

	const saveStateEnabled = auction.auction.round > 1;
	const loadStateEnabled = jsonUploadEnabled;

	const noExcessDemand =
		auction.round.leases && auction.round.leases.filter((l) => l.current_demand >= 2).length === 0;
	const endButtonText = noExcessDemand ? "End Auction" : "End Round";
	const endButtonStyle = noExcessDemand ? "btn btn-outline-danger  btn-sm" : "btn btn-outline-primary  btn-sm";

	const simulateRoundEnabled =
		auction.auction.round !== 0 &&
		auction.round.round_status === "In Progress" &&
		auction.auction.status === "In Progress" &&
		submittedUsers < eligibleUsers;

	const makeAnnouncementEnabled = announcementText.length > 0;

	/*
	const refresh = async () => {
		// Fetch Auction Data
		await dispatch(setAuctionsLoading());
		await dispatch(setUserLoading());

		await fetchData();

		await dispatch(setAuctionsNotLoading());
		await dispatch(setUserNotLoading());
	};
	const fetchData = async () => {
		await dispatch(getAuction(params.auctionId));

		if (auction.auction.status !== "Awaiting Start") {
			await dispatch(getRound(params.auctionId));
			await dispatch(getResults(params.auctionId));
			await dispatch(getBidCredits(params.auctionId));
			await dispatch(getPriceDemandHistory(params.auctionId));
			if (user.user.type === "admin") {
				await dispatch(getPrices(params.auctionId));
				await dispatch(getHistory(params.auctionId));
				await dispatch(getBidderProgress(params.auctionId));
				await dispatch(getSimulationData(params.auctionId));
			}
		}
		if (auction.auction.status === "Ended") {
			await dispatch(getWinningBids(params.auctionId));
		}

		if (auction.auction.status !== "Awaiting Start") {
			await dispatch(getPoints(params.auctionId));
			await dispatch(getBids(params.auctionId));
		}

		if (user.user.type === "admin") {
			await dispatch(getAllUsers());
		}
		this.setState({ ...this.state, showTableData: false });

		let temp = {};
		let nominalTemp = {};
		let priceTargetTemp = {};
		if (auction.round.leases && auction.round.leases.length > 0) {
			auction.round.leases.forEach((lease) => {
				temp[lease.id] = 100;
				nominalTemp[lease.id] = 10000000;
				priceTargetTemp[lease.id] = Math.round(lease.current_bid / 10000000) * 10000000 + 10000000;
			});
		}

		this.setState({
			...this.state,
			secondsRemaining: auction.round.end_time - Math.floor(new Date().getTime() / 1000.0),
			selectedLeaseIds: [],
			submittedExitBid: user.bids
				? user.bids.current_bids.filter((cur_bid) => cur_bid.exit === 1 && cur_bid.carried === 0).length > 0
					? true
					: false
				: false,
			incrementValues: temp,
			nominalIncrementValues: nominalTemp,
			priceTargetValues: priceTargetTemp,
			showTableData: true,
			currentRoundBidSubmitted: user.bids ? (user.bids.current_bids.length > 0 ? true : false) : false,
			useBidCredits: auction.auction.use_bid_credits === 1 ? true : false,
		});
	};
	*/

	const startAuction = () => {
		history.push(`/auctions/start/${auction.auction.id}`);
	};

	const determineWinnersAction = async () => {
		const result = await confirm("Winner Determination Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to run winner determination for the auction?</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(determineWinners(auction.auction.id));
			await props.fetchDataAction(); //used to be refresh
		}
	};

	const randomizeWinnersAction = async () => {
		await dispatch(randomizeWinners(auction.auction.id));
		await props.fetchDataAction(); //refresh();
	};

	const endRoundAction = async () => {
		const result = await confirm("Admin Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>
									Would you like to{" "}
									{auction.round.leases &&
									auction.round.leases.filter((l) => l.current_demand >= 2).length === 0
										? "end the auction"
										: "end the round"}
									?
								</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(endRound(auction.auction.id, auction.auction.round));
			await props.fetchDataAction();
			if (autoSave) {
				await saveState();
			}
			return;
		}
	};

	const saveState = async () => {
		let jsondata = await dispatch(getSaveState(auction.auction.id));

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		today = dd + "-" + mm + "-" + yyyy;

		const element = document.createElement("a");
		const textFile = new Blob([JSON.stringify(jsondata.payload)], { type: "application/json" }); //pass data from localStorage API to blob
		element.href = URL.createObjectURL(textFile);
		var auctionName = jsondata.payload.auction.name;
		var roundNum = jsondata.payload.auction.round;
		var auctionStatus = jsondata.payload.auction.status === "Ended" ? "E" : "IP";
		var roundStatus = jsondata.payload.rounds[roundNum - 1].status === "Ended" ? "E" : "IP";
		element.download =
			auctionName + " Auction-" + auctionStatus + " R" + roundNum + "-" + roundStatus + " " + today;
		document.body.appendChild(element);
		element.click();
	};

	const nextRoundAction = async () => {
		var timer =
			parseInt(timerValueMins[timerValueMins.length - 2] ? timerValueMins[timerValueMins.length - 2] : 0) * 600 +
			parseInt(timerValueMins[timerValueMins.length - 1] ? timerValueMins[timerValueMins.length - 1] : 0) * 60 +
			parseInt(timerValueSecs[timerValueSecs.length - 2] ? timerValueSecs[timerValueSecs.length - 2] : 0) * 10 +
			parseInt(timerValueSecs[timerValueSecs.length - 1] ? timerValueSecs[timerValueSecs.length - 1] : 0);

		if (timer == 0) {
			timer = 180;
		}

		let increments = [];
		let incData = [];
		var nominalInc;
		auction.round.leases.forEach((lease) => {
			var inc;
			var newPrice;
			if (mainFilterValue === "nominalIncrements") {
				inc = nominalIncrementValues[lease.id];
				newPrice = lease.current_bid + inc;
				nominalInc = true;
			}
			if (mainFilterValue === "priceTarget") {
				inc = priceTargetValues[lease.id] - lease.current_bid;
				newPrice = lease.current_bid + inc;
				nominalInc = true;
			}
			if (mainFilterValue === "percentageIncrements") {
				inc = incrementValues[lease.id];
				newPrice = lease.current_bid * ((100 + inc) / 100);
				nominalInc = false;
			}
			increments.push({
				lease_id: lease.id,
				increment: inc,
			});
			incData.push({
				lease_id: lease.id,
				demand: lease.current_demand,
				name: lease.name,
				price: lease.current_bid,
				newPrice: newPrice,
				increment: inc,
			});
		});

		const req_json = {
			duration: timer,
			nominal: nominalInc,
			increments: increments,
		};

		//increment negative check
		var negativeIncrement = false;
		incData.forEach((e) => {
			if (e.increment < 0 && e.demand > 1) {
				negativeIncrement = true;
				return;
			}
		});

		const result = await confirm("Next Round Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "35vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								{negativeIncrement ? (
									<p>
										Next round cannot be started as a round with excess demand has a negative
										increment
									</p>
								) : (
									<p>Would you like to start the next round with the following information?</p>
								)}

								<p>
									Duration: <strong>{formatTime(timer)}</strong>
								</p>

								{incData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<thead>
											<tr>
												<th colSpan={3}>
													{mainFilterValue === "nominalIncrements"
														? "Nominal Increments"
														: mainFilterValue === "priceTarget"
														? "Price Target"
														: "Percentage Increments"}
												</th>
											</tr>
										</thead>
										<tbody>
											{incData.map(({ lease_id, name, increment, demand, price, newPrice }) => (
												<tr key={lease_id}>
													<td>{name}</td>
													<td>
														{demand > 1
															? mainFilterValue === "nominalIncrements"
																? currencyFormat(increment)
																: mainFilterValue === "priceTarget"
																? currencyFormat(increment)
																: `${increment} %`
															: "-"}
													</td>
													<td>
														{demand > 1 ? (
															<>
																{currencyFormat(price)}{" "}
																<i className="fas fa-long-arrow-alt-right"></i>{" "}
																{currencyFormat(newPrice)}
															</>
														) : (
															"-"
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No increment information</p>
								)}

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
										disabled={negativeIncrement}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(nextRound(auction.auction.id, req_json, { round: auction.auction.round + 1 }, "SMRA"));
			await props.fetchDataAction();
			return;
		}
	};

	const onTimerChangeMins = (event) => {
		if (!isNaN(event.target.value)) {
			setTimerValueMins(event.target.value);
		}
	};

	const onTimerChangeSecs = (event) => {
		if (!isNaN(event.target.value)) {
			setTimerValueSecs(event.target.value);
		}
	};

	const loadState = async () => {
		await dispatch(restoreState(auction.auction.id, jsonUploadFile));
		setJsonUploadEnabled(false);
		await props.fetchDataAction(); //this.refresh();
	};

	const handleJsonFileUpload = (event) => {
		//check if file is present and button can be enabled
		setJsonUploadEnabled(event.target.value.length !== 0);

		//send payload to back end
		const fileReader = new FileReader();
		fileReader.readAsText(event.target.files[0]);
		fileReader.onload = (e) => {
			const jsonData = JSON.parse(e.target.result);
			setJsonUploadFile(jsonData);
		};
	};

	const triggerRefresh = async () => {
		await emitTriggerRefresh({ auction_id: auction.auction.id });
	};

	const simulateRoundBids = async () => {
		const user_ids = [...new Set(auction.simulation_data.map((item) => item.user_id))];

		let userData = [];
		user.users.forEach((user) => {
			if (user_ids.includes(user.id)) {
				userData.push({ username: user.username, display_name: user.display_name });
			}
		});

		const result = await confirm("Simulate Round Bids Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to simulate the bids (where possible) for the following users?</p>
								{userData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<tbody>
											{userData.map(({ username, display_name }) => (
												<tr>
													<td>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No simulated users</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(simulateRound(auction.auction.id, user_ids));
			await props.fetchDataAction(); //this.fetchData();
		}
	};

	const sendReminder = async () => {
		let user_ids = [];
		auction.bidder_progress.forEach((prog) => {
			if (prog.starting_points > 0 && prog.points > 0) {
				user_ids.push(prog.user_id);
			}
		});

		let userData = [];
		user.users.forEach((user) => {
			if (user_ids.includes(user.id)) {
				userData.push({ username: user.username, display_name: user.display_name });
			}
		});

		const result = await confirm("Send Reminder Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to send a reminder to the following users?</p>
								{userData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<tbody>
											{userData.map(({ username, display_name }) => (
												<tr>
													<td>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No users to remind</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await emitSendReminder({ auction_id: auction.auction.id, user_ids, type: "SMRA" });
		}
	};

	const makeAnnouncement = async () => {
		if (announcementText.length > 0) {
			const result = await confirm("Send Announcement Confirmation", {
				render: (message, onConfirm, onCancel) => {
					return (
						<>
							<div
								className="card"
								style={{
									width: "25vw",
									margin: "auto",
									alignItems: "center",
								}}
							>
								<div
									className="card-body"
									style={{
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<h6 style={{ padding: "10px" }}> {message} </h6>
									<hr />
									<p>Would you like to send the following announcement to all the users?</p>

									<p>
										<strong>
											<em>{announcementText}</em>
										</strong>
									</p>
									<br />
									<div>
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={onConfirm}
											style={{ margin: "10px" }}
										>
											{" "}
											Yes{" "}
										</button>
										<button
											type="button"
											className="btn btn-outline-danger"
											onClick={onCancel}
											style={{ margin: "10px" }}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</>
					);
				},
				closeOnOverlayClick: true,
			});

			if (result) {
				await emitMakeAnnouncement({ auction_id: auction.auction.id, message: announcementText });
				setAnnouncementText("");
			}
		}
	};

	const onAnnouncementChange = (event) => {
		setAnnouncementText(event.target.value);
	};

	return (
		<div>
			<br />
			<div
				className="card"
				style={{
					width: "80%",
					margin: "auto",
					alignItems: "center",
				}}
			>
				<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
					<h6>Admin Panel</h6>
					<div className="row">
						<div className="input-group flex-nowrap input-group-sm" role="group">
							<button
								type="button"
								disabled={!startAuctionEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => startAuction()}
							>
								Start Auction
							</button>
							<button
								type="button"
								disabled={!determineWinnersEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => determineWinnersAction()}
							>
								Determine Winners
							</button>
							<button
								type="button"
								disabled={!randomizeWinnersEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => randomizeWinnersAction()}
							>
								Randomize Winners
							</button>
							<button
								type="button"
								disabled={!roundEndEnabled}
								className={endButtonStyle}
								onClick={() => endRoundAction()}
							>
								{endButtonText}
							</button>
							<button
								type="button"
								disabled={!nextRoundEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => nextRoundAction()}
							>
								Next Round
							</button>
							<input
								type="string"
								disabled={!nextRoundEnabled}
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(timerValueMins)}
								onChange={onTimerChangeMins}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Mins</span>
							<input
								type="string"
								disabled={!nextRoundEnabled}
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(timerValueSecs)}
								onChange={onTimerChangeSecs}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Secs</span>
						</div>
					</div>

					<hr />

					<div className="row">
						<div className="input-group input-group-sm">
							<button
								className="btn btn-outline-primary"
								type="button"
								disabled={!saveStateEnabled}
								onClick={() => saveState()}
							>
								Save State
							</button>
							<button
								className="btn btn-outline-primary"
								type="button"
								disabled={!loadStateEnabled}
								onClick={() => loadState()}
							>
								Load State
							</button>
							<input
								className="form-control"
								type="file"
								accept=".json"
								id="formFile"
								onChange={handleJsonFileUpload}
							/>
							<div className="input-group-text">
								<div
									className="form-check form-switch"
									style={{ minHeight: "inherit", marginBottom: "0rem" }}
								>
									<input
										className="form-check-input"
										type="checkbox"
										checked={autoSave}
										onChange={() => setAutoSave(!autoSave)}
									/>
									<label className="form-check-label">Autosave</label>
								</div>
							</div>
						</div>
					</div>

					<hr />
					<div className="row">
						<div className="btn-group" role="group">
							<button
								type="button"
								className="btn btn-outline-primary btn-sm"
								onClick={() => triggerRefresh()}
							>
								Trigger Refresh
							</button>
							<button
								type="button"
								disabled={!simulateRoundEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => simulateRoundBids()}
							>
								Simulate Round Bids
							</button>
							<button
								type="button"
								disabled={!simulateRoundEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => sendReminder()}
							>
								Send Reminder
							</button>
							<button
								type="button"
								disabled={!makeAnnouncementEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => makeAnnouncement()}
							>
								Make Announcement
							</button>
							<input
								type="string"
								className="form-control form-control-sm"
								name="announcement"
								value={announcementText}
								onChange={onAnnouncementChange}
								placeholder="Announcement..."
								style={{ width: "250px" }}
							/>
						</div>
					</div>

					{auction.status === "In Progress" ? (
						<>
							<hr />

							<div className="row">
								<div className="col-2" style={{ textAlign: "right" }}>
									<p className="text-muted" style={{ fontSize: "14px" }}>
										Points [{startingPoints - currentPoints}/{startingPoints}]
									</p>
								</div>
								<div className="col-4">
									<div className="progress" style={{ height: "18px" }}>
										<div
											className={pointsClass}
											role="progressbar"
											style={{
												width: `${pointsPerc}%`,
											}}
											aria-valuenow={startingPoints - currentPoints}
											aria-valuemin="0"
											aria-valuemax={startingPoints}
										></div>
									</div>
								</div>

								<div className="col-2" style={{ textAlign: "right" }}>
									<p className="text-muted" style={{ fontSize: "14px" }}>
										Users [{submittedUsers}/{eligibleUsers}]
									</p>
								</div>
								<div className="col-4">
									<div className="progress" style={{ height: "18px" }}>
										<div
											className={usersClass}
											role="progressbar"
											style={{
												width: `${usersPerc}%`,
											}}
											aria-valuenow={submittedUsers}
											aria-valuemin="0"
											aria-valuemax={eligibleUsers}
										></div>
									</div>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
			<br />
		</div>
	);
};

function timerFormat(secs) {
	var z = secs[secs.length - 1] ? secs[secs.length - 1] : "0";
	var y = secs[secs.length - 2] ? secs[secs.length - 2] : "0";
	var result = y.concat(z);
	return result;
}

function formatTime(secs) {
	if (secs > 0) {
		let hours = Math.floor(secs / 3600);
		let minutes = Math.floor(secs / 60) % 60;
		let seconds = secs % 60;
		return [hours, minutes, seconds]
			.map((v) => ("" + v).padStart(2, "0"))
			.filter((v, i) => v !== "00" || i > 0)
			.join(":");
	} else {
		return "Time Up";
	}
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}
