import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import SMRADashboard from "../../components/dashboards/SMRADashboard";
import MPSBDashboard from "../../components/dashboards/MPSBDashboard";
import RENODashboard from "./RENO";
import ErrorPage from "../../ErrorPage";
import { auctionTypes } from "../common/constants";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auctionType: null,
		};
	}

	async componentDidMount() {
		const typeId = this.props.auction.auctions.filter((x) => {
			return parseInt(x.id) === parseInt(this.props.params.auctionId);
		});
		if (typeId.length > 0) {
			this.setState({
				...this.state,
				auctionType: parseInt(typeId[0].type),
			});
		} else {
			this.setState({
				...this.state,
				auctionType: null,
			});
		}
	}

	render() {
		if (this.state.auctionType === auctionTypes.SMRA) {
			return <SMRADashboard />;
		} else if (this.state.auctionType === auctionTypes.MPSB) {
			return <MPSBDashboard />;
		} else if (this.state.auctionType === auctionTypes.RENO) {
			return <RENODashboard />;
		} else {
			return <ErrorPage />;
		}
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <Dashboard {...props} params={params} />;
}

Dashboard.propTypes = {
	auction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
});

export default connect(mapStateToProps, {})(WithParamsAndNavigate);
