import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getAuction,
	startAuction,
	setAuctionsLoading,
	setAuctionsNotLoading,
} from "../../../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { confirm } from "react-confirm-box";

class StartENOOAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startingPrice: 0,
			roundDuration: 0,
			timerValueMins: "0",
			timerValueSecs: "0",
			description: "",
		};
	}

	componentDidMount() {
		this.componentRefresh();
	}

	async componentRefresh() {
		await this.props.setAuctionsLoading();
		await this.props.getAuction(this.props.params.auctionId);

		const { auction } = this.props.auction;

		const minutes = Math.floor(auction.round_duration / 60);
		const seconds = Math.floor(auction.round_duration % 60);

		this.setState({
			...this.state,
			roundDuration: auction.round_duration,
			startingPrice: auction.starting_price,
			timerValueMins: minutes.toString(),
			timerValueSecs: seconds.toString(),
			description: auction.description,
		});

		await this.props.setAuctionsNotLoading();
	}

	onTimerChangeMins(event) {
		const input = parseInt(event.target.value);
		if (!isNaN(event.target.value)) {
			if (input >= 0) {
				this.setState({ ...this.state, timerValueMins: event.target.value });
			}
		}
	}

	onTimerChangeSecs(event) {
		const input = parseInt(event.target.value);

		if (!isNaN(event.target.value)) {
			if (input < 0) {
				const curMinutes = parseInt(this.state.timerValueMins);
				if (curMinutes > 0) {
					const newMins = curMinutes - 1;
					this.setState({
						...this.state,
						timerValueSecs: (59).toString(),
						timerValueMins: newMins.toString(),
					});
				}
			} else if (input == 60) {
				const newMins = parseInt(this.state.timerValueMins) + 1;
				this.setState({ ...this.state, timerValueSecs: (0).toString(), timerValueMins: newMins.toString() });
			} else {
				this.setState({ ...this.state, timerValueSecs: input.toString() });
			}
		}
	}

	onDescriptionChange(event) {
		this.setState({ ...this.state, description: event.target.value });
	}

	onPriceChange(event) {
		const newPrice = parseFloat(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		this.setState({ ...this.state, startingPrice: newPrice });
	}

	async startAuction() {
		const result = await confirm("Start Auction Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "30vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Are you sure you would like to start the auction with the following values?</p>

								<p>
									<em>Description:</em> {this.state.description}
								</p>

								<table className="table table-bordered table-sm">
									<tbody>
										<tr>
											<th>Round Duration</th>
											<td>{`${this.state.timerValueMins} mins ${this.state.timerValueSecs} sec`}</td>
										</tr>
										<tr>
											<th>Starting Price</th>
											<td>{currencyFormat(this.state.startingPrice)}</td>
										</tr>
									</tbody>
								</table>

								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		const roundDuration = parseInt(this.state.timerValueMins) * 60 + parseInt(this.state.timerValueSecs);

		let payload = {
			duration: roundDuration,
			starting_price: this.state.startingPrice,
			description: this.state.description,
		};

		if (result) {
			await this.props.startAuction(this.props.params.auctionId, payload, "ENOO");
		}
	}

	render() {
		const { auction, auction_loading, started } = this.props.auction;
		const { user, user_loading, signedIn } = this.props.user;

		if (!signedIn || user.type !== "admin" || started) {
			return <Redirect to="/auctions" />;
		}

		if (auction_loading || user_loading) {
			return (
				<div className="container">
					<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/auctions">Auctions</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Start Auction
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container" style={{ paddingBottom: "100px" }}>
				<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Start Auction
						</li>
					</ol>
				</nav>

				<hr />
				<br />
				<button
					className="btn btn-outline-success"
					style={{ height: "100%", float: "right" }}
					onClick={this.startAuction.bind(this)}
				>
					Start Auction
				</button>

				<div className="row">
					<div className="col-4">
						<label>Round Duration</label>
						<div className="input-group flex-nowrap mb-3" role="group">
							<input
								type="number"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueMins)}
								onChange={this.onTimerChangeMins.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Mins</span>
							<input
								type="number"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueSecs)}
								onChange={this.onTimerChangeSecs.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Secs</span>
						</div>
					</div>

					<div className="col-4">
						<label>Starting Price</label>
						<div className="input-group flex-nowrap mb-3" role="group">
							<input
								type="string"
								className="form-control form-control-sm"
								id="startingPrice"
								value={currencyFormat(this.state.startingPrice)}
								onChange={this.onPriceChange.bind(this)}
								style={{ width: "80px", height: "38px" }}
							/>
						</div>
					</div>
					<div className="col-4"></div>
				</div>

				<div className="row">
					<div className="col-8">
						<label>Auction Description</label>
						<div className="input-group flex-nowrap mb-3" role="group">
							<input
								type="string"
								className="form-control form-control-sm"
								id="description"
								value={this.state.description}
								onChange={this.onDescriptionChange.bind(this)}
								style={{ width: "80px", height: "38px" }}
							/>
						</div>
					</div>
					<div className="col-4"></div>
				</div>
			</div>
		);
	}
}

function timerFormat(secs) {
	var z = secs[secs.length - 1] ? secs[secs.length - 1] : "0";
	var y = secs[secs.length - 2] ? secs[secs.length - 2] : "0";
	var result = y.concat(z);
	return result;
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

function WithParams(props) {
	let params = useParams();
	return <StartENOOAuction {...props} params={params} />;
}

StartENOOAuction.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	getAuction: PropTypes.func.isRequired,
	startAuction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, { setAuctionsLoading, getAuction, setAuctionsNotLoading, startAuction })(
	WithParams
);
