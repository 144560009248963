import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuctions, setAuctionsLoading, setAuctionsNotLoading, resetAuction } from "../../actions/auctionActions";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { leaveAllRooms } from "../../helpers/emit/auction";
import { leaveASTRooms } from "../../helpers/emit/auctionSimulationTool";
import socket from "../../helpers/socket";
import { auctionTypes } from "../common/constants";

class Dashboards extends Component {
	componentDidMount() {
		this.componentRefresh();
	}

	async componentRefresh() {
		await leaveAllRooms();
		await leaveASTRooms();
		await this.props.setAuctionsLoading();
		await this.props.getAuctions();
		await this.props.resetAuction();
		await this.props.setAuctionsNotLoading();
	}

	viewDashboard(id, type) {
		this.props.history.push(`/dashboard/${id}`);
	}

	render() {
		const { signedIn, user } = this.props.user;
		const { auctions, auction_loading } = this.props.auction;

		if (!signedIn || user.type !== "admin") {
			return <Redirect to="/" />;
		}

		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.componentRefresh();
		});

		if (auction_loading) {
			return (
				<div className="container">
					<h3>Dashboards</h3>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active" aria-current="page">
								Dashboards
							</li>
						</ol>
					</nav>
					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Dashboards</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							Dashboards
						</li>
					</ol>
				</nav>
				<hr />
				<div className="text-right" style={{ alignItems: "right", marginRight: 0 }}>
					<button
						type="button"
						className="btn btn-outline-primary btn-sm"
						onClick={() => this.componentRefresh()}
					>
						<i className="fas fa-sync"></i> Data Reload
					</button>
				</div>
				<br />

				<div className="container">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Auctioneer</th>
								<th>Status</th>
								<th>Type</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{auctions ? (
								auctions.map(({ id, name, auctioneer, status, type }) => (
									<tr key={id}>
										<td>{name}</td>
										<td>{auctioneer}</td>
										<td>{status}</td>
										{user.type === "admin" ? <td>{determineType(type)}</td> : <></>}
										<td>
											<button
												type="button"
												className="btn btn-outline-primary btn-sm"
												onClick={() => this.viewDashboard(id, type)}
												disabled={status === "Awaiting Start"}
											>
												<i className="fas fa-external-link-alt"></i>
											</button>
										</td>
									</tr>
								))
							) : (
								<></>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

function determineType(type) {
	if (type === auctionTypes.SMRA) {
		return "SMRA";
	} else if (type === auctionTypes.MPSB) {
		return "MPSB";
	} else if (type === auctionTypes.ENOO) {
		return "ENOO";
	} else if (type === auctionTypes.RENO) {
		return "RENO";
	}
	return "";
}

Dashboards.propTypes = {
	getAuctions: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	resetAuction: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, {
	setAuctionsLoading,
	getAuctions,
	setAuctionsNotLoading,
	resetAuction,
})(Dashboards);
