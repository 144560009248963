import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllUsers, setUserNotLoading, setUserLoading } from "../../../../actions/userActions";
import {
	getAuction,
	startAuction,
	setAuctionsLoading,
	setAuctionsNotLoading,
} from "../../../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { confirm } from "react-confirm-box";

class StartMPSBAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userPointsString: "",
			userPoints: [],
			simulationData: {},
			timerValueMins: "10",
			timerValueSecs: "00",
			minTEA: "",
			maxTEA: "",
			minASQ: "",
			maxASQ: "",
			fcr: 1.37,
			customTEA: false,
			asq_mw: "",
			asq_gwh: "",
		};
	}

	async componentDidMount() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getAllUsers();

		await this.props.setAuctionsNotLoading();
		await this.props.setUserNotLoading();

		const { auction } = this.props.auction;
		const { users } = this.props.user;

		let simData = {};
		const prepopulatedMinPrice = [0, 75.9, 83.4, 76.1, 76.2, 69.4, 75.9];
		const prepopulatedMaxPrice = [0, 110.5, 123.6, 110.9, 111.6, 101.2, 99.7];
		const prepopulatedMinQuantity = [0, 414, 375, 414, 810, 1440, 798];
		const prepopulatedMaxQuantity = [0, 450, 380, 504, 882, 1449, 810];
		const prepopulatedMinSplits = [0, 1, 1, 1, 1, 1, 1];
		const prepopulatedMaxSplits = [0, 1, 1, 2, 2, 3, 2];

		const prepopulatedUserValues = {
			2: { minPrice: 75.9, maxPrice: 110.5, minQuantity: 414, maxQuantity: 450, minSplits: 1, maxSplits: 1 },
			3: { minPrice: 83.4, maxPrice: 123.6, minQuantity: 375, maxQuantity: 380, minSplits: 1, maxSplits: 1 },
			4: { minPrice: 76.1, maxPrice: 110.9, minQuantity: 414, maxQuantity: 504, minSplits: 1, maxSplits: 2 },
			5: { minPrice: 76.2, maxPrice: 111.6, minQuantity: 810, maxQuantity: 882, minSplits: 1, maxSplits: 2 },
			6: { minPrice: 69.4, maxPrice: 101.2, minQuantity: 1440, maxQuantity: 1449, minSplits: 1, maxSplits: 3 },
			7: { minPrice: 75.9, maxPrice: 99.7, minQuantity: 798, maxQuantity: 810, minSplits: 1, maxSplits: 2 },
		};

		users.forEach((user) => {
			if (user.id in prepopulatedUserValues) {
				// simData[`user_${user.id}`] = {
				// 	isSimulated: false,
				// 	minPrice: prepopulatedMinPrice[user.id - 1],
				// 	maxPrice: prepopulatedMaxPrice[user.id - 1],
				// 	minQuantity: prepopulatedMinQuantity[user.id - 1],
				// 	maxQuantity: prepopulatedMaxQuantity[user.id - 1],
				// 	minSplits: prepopulatedMinSplits[user.id - 1],
				// 	maxSplits: prepopulatedMaxSplits[user.id - 1],
				// };
				simData[`user_${user.id}`] = {
					isSimulated: false,
					minPrice: prepopulatedUserValues[user.id].minPrice,
					maxPrice: prepopulatedUserValues[user.id].maxPrice,
					minQuantity: prepopulatedUserValues[user.id].minQuantity,
					maxQuantity: prepopulatedUserValues[user.id].maxQuantity,
					minSplits: prepopulatedUserValues[user.id].minSplits,
					maxSplits: prepopulatedUserValues[user.id].maxSplits,
				};
			} else {
				simData[`user_${user.id}`] = {
					isSimulated: false,
					minPrice: 0,
					maxPrice: 0,
					minQuantity: 0,
					maxQuantity: 0,
					minSplits: 0,
					maxSplits: 0,
				};
			}
		});

		this.setState({ ...this.state, simulationData: simData });

		var minTEA = prepopulatedMinQuantity.reduce((prev, curr) => prev + curr, 0);
		var maxTEA = prepopulatedMaxQuantity.reduce((prev, curr) => prev + curr, 0);

		this.setState({
			...this.state,
			minTEA: minTEA,
			maxTEA: maxTEA,
			minASQ: Math.round(Math.max(Math.min(minTEA / this.state.fcr, 4566.21), 253.68)),
			maxASQ: Math.round(Math.max(Math.min(maxTEA / this.state.fcr, 4566.21), 253.68)),
			asq_mw: auction.asq,
			asq_gwh: ((auction.asq * 8760 * 0.45) / 1000).toFixed(2),
		});
	}

	onChange(event) {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		});
	}

	onChangeCheckbox(event) {
		this.setState({
			...this.state,
			[event.target.name]: event.target.checked,
		});
	}

	onASQmwChange(event) {
		const asq_gwh = ((event.target.value * 8760 * 0.45) / 1000).toFixed(2);
		if (asq_gwh >= 0 && asq_gwh <= 18000) {
			this.setState({
				...this.state,
				asq_mw: event.target.value,
				asq_gwh: asq_gwh,
			});
		}
	}

	onASQgwhChange(event) {
		const asq_gwh = event.target.value;
		if (asq_gwh >= 0 && asq_gwh <= 18000) {
			this.setState({
				...this.state,
				asq_mw: ((event.target.value * 1000) / (8760 * 0.45)).toFixed(2),
				asq_gwh: asq_gwh,
			});
		}
	}

	onFCRChange(event) {
		this.setState({
			...this.state,
			fcr: event.target.value,
			minASQ: Math.round(Math.max(Math.min(this.state.minTEA / event.target.value, 4566.21), 253.68)),
			maxASQ: Math.round(Math.max(Math.min(this.state.maxTEA / event.target.value, 4566.21), 253.68)),
		});
	}

	onRangeChange(event) {
		if (event.target.name === "minTEA") {
			this.setState({
				...this.state,
				minTEA: event.target.value,
				minASQ: Math.round(Math.max(Math.min(event.target.value / this.state.fcr, 4566.21), 253.68)),
			});
		}
		if (event.target.name === "minASQ") {
			this.setState({
				...this.state,
				minASQ: event.target.value,
				minTEA: Math.round(event.target.value * this.state.fcr),
			});
		}
		if (event.target.name === "maxTEA") {
			this.setState({
				...this.state,
				maxTEA: event.target.value,
				maxASQ: Math.round(Math.max(Math.min(event.target.value / this.state.fcr, 4566.21), 253.68)),
			});
		}
		if (event.target.name === "maxASQ") {
			this.setState({
				...this.state,
				maxASQ: event.target.value,
				maxTEA: Math.round(event.target.value * this.state.fcr),
			});
		}
	}

	onIsSimulatedChange(event) {
		let simData = this.state.simulationData;
		simData[`user_${event.target.value}`].isSimulated = !simData[`user_${event.target.value}`].isSimulated;
		this.setState({ ...this.state, simulationData: simData });
	}

	onSimDataChange(event) {
		let simData = this.state.simulationData;
		if (event.target.getAttribute("simdatatype") === "minSplits") {
			let value = parseInt(event.target.value);
			if (isNaN(value) || value < 0) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].minSplits = value;
		}
		if (event.target.getAttribute("simdatatype") === "maxSplits") {
			let value = parseInt(event.target.value);
			if (isNaN(value) || value < 0) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].maxSplits = value;
		}
		if (event.target.getAttribute("simdatatype") === "minQuantity") {
			let value = parseInt(event.target.value.replace(/,/g, ""), 10);
			if (isNaN(value) || value < 0) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].minQuantity = value;
		}
		if (event.target.getAttribute("simdatatype") === "maxQuantity") {
			let value = parseInt(event.target.value.replace(/,/g, ""), 10);
			if (isNaN(value) || value < 0) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].maxQuantity = value;
		}
		if (event.target.getAttribute("simdatatype") === "minPrice") {
			let value = event.target.value;
			if (isNaN(value)) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].minPrice = value;
		}
		if (event.target.getAttribute("simdatatype") === "maxPrice") {
			let value = event.target.value;
			if (isNaN(value)) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].maxPrice = value;
		}

		if (!this.state.customTEA) {
			let minTEA = 0;
			let maxTEA = 0;
			Object.keys(simData).forEach((user) => {
				minTEA += simData[user].minQuantity;
				maxTEA += simData[user].maxQuantity;
			});

			this.setState({
				...this.state,
				simulationData: simData,
				minTEA: minTEA,
				maxTEA: maxTEA,
				minASQ: Math.round(Math.max(Math.min(minTEA / this.state.fcr, 18000), 1000)),
				maxASQ: Math.round(Math.max(Math.min(maxTEA / this.state.fcr, 18000), 1000)),
			});
		} else {
			this.setState({ ...this.state, simulationData: simData });
		}
	}

	onBidCreditsDataChange(event) {
		let bidCreditsData = this.state.bidCreditsData;
		if (event.target.getAttribute("bidCreditsDataType") === "percentage") {
			let value = parseFloat(event.target.value);
			if (isNaN(value) || value < 0) value = 0;
			bidCreditsData[`user_${event.target.getAttribute("user")}`][event.target.getAttribute("name")] = value;
		}

		this.setState({ ...this.state, bidCreditsData: bidCreditsData });
	}

	increasePoints(event) {
		const id = event.target.name;
		let points = this.state.userPoints.filter((point) => {
			return point.user_id != id;
		});

		points.push({
			user_id: id,
			points:
				this.state.userPoints.filter((point) => {
					return point.user_id == id;
				}).length == 0
					? 2
					: this.state.userPoints.filter((point) => {
							return point.user_id == id;
					  })[0].points + 1,
		});
		this.setState({ ...this.state, userPoints: points });
	}

	decreasePoints(event) {
		const id = event.target.name;
		let points = this.state.userPoints.filter((point) => {
			return point.user_id != id;
		});
		points.push({
			user_id: id,
			points:
				this.state.userPoints.filter((point) => {
					return point.user_id == id;
				}).length == 0
					? 2
					: this.state.userPoints.filter((point) => {
							return point.user_id == id;
					  })[0].points - 1,
		});
		this.setState({ ...this.state, userPoints: points });
	}

	increaseAllPoints() {
		let points = [];
		this.props.user.users.forEach((s) => {
			points.push({
				user_id: s.id,
				points:
					this.state.userPoints.filter((point) => {
						return point.user_id == s.id;
					}).length == 0
						? 2
						: this.state.userPoints.filter((point) => {
								return point.user_id == s.id;
						  })[0].points + 1,
			});
		});
		this.setState({ ...this.state, userPoints: points });
	}

	decreaseAllPoints() {
		let points = [];
		this.props.user.users.forEach((s) => {
			points.push({
				user_id: s.id,
				points:
					this.state.userPoints.filter((point) => {
						return point.user_id == s.id;
					}).length == 0
						? 2
						: this.state.userPoints.filter((point) => {
								return point.user_id == s.id;
						  })[0].points - 1,
			});
		});
		this.setState({ ...this.state, userPoints: points });
	}

	setPoints(event) {
		event.preventDefault();
		let points = [];
		this.state.userPointsString.split(",").forEach((s) => {
			if (s.split(":").length == 2) {
				points.push({
					user_id: parseInt(s.split(":")[0]),
					points: parseInt(s.split(":")[1]),
				});
			}
		});

		this.setState({ ...this.state, userPoints: points });
	}

	onTimerChangeMins(event) {
		if (!isNaN(event.target.value)) {
			this.setState({ ...this.state, timerValueMins: event.target.value });
		}
	}

	onTimerChangeSecs(event) {
		if (!isNaN(event.target.value)) {
			this.setState({ ...this.state, timerValueSecs: event.target.value });
		}
	}

	async startAuction() {
		const result = await confirm("Start Auction Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>You are about to start the Auction</p>
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		var timer =
			parseInt(
				this.state.timerValueMins[this.state.timerValueMins.length - 2]
					? this.state.timerValueMins[this.state.timerValueMins.length - 2]
					: 0
			) *
				600 +
			parseInt(
				this.state.timerValueMins[this.state.timerValueMins.length - 1]
					? this.state.timerValueMins[this.state.timerValueMins.length - 1]
					: 0
			) *
				60 +
			parseInt(
				this.state.timerValueSecs[this.state.timerValueSecs.length - 2]
					? this.state.timerValueSecs[this.state.timerValueSecs.length - 2]
					: 0
			) *
				10 +
			parseInt(
				this.state.timerValueSecs[this.state.timerValueSecs.length - 1]
					? this.state.timerValueSecs[this.state.timerValueSecs.length - 1]
					: 0
			);

		let payload = {
			duration: timer,
			simulation_config: this.state.simulationData,
			simulation_parameters: {
				minTea: parseFloat(this.state.minTEA) ? this.state.minTEA : null,
				maxTea: parseFloat(this.state.maxTEA) ? this.state.maxTEA : null,
				fcr: parseFloat(this.state.fcr) ? this.state.fcr : null,
				asq: this.state.asq_mw,
			},
		};

		if (result) {
			await this.props.startAuction(this.props.params.auctionId, payload, "MPSB");
		}
	}

	render() {
		const { auction, auction_loading, started } = this.props.auction;
		const { users, user, user_loading, signedIn } = this.props.user;

		if (!signedIn || user.type !== "admin" || started) {
			return <Redirect to="/auctions" />;
		}

		if (auction_loading || user_loading) {
			return (
				<div className="container">
					<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/auctions">Auctions</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Start Auction
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container" style={{ paddingBottom: "100px" }}>
				<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Start Auction
						</li>
					</ol>
				</nav>

				<hr />
				<br />

				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<div className="col-3">
						<label>Auction Duration</label>
						<div className="input-group flex-nowrap mb-3" role="group">
							<input
								type="string"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueMins)}
								onChange={this.onTimerChangeMins.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Mins</span>
							<input
								type="string"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueSecs)}
								onChange={this.onTimerChangeSecs.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Secs</span>
						</div>
					</div>

					<br />

					<div className="col-6"></div>
					<div className="col-3">
						<button
							className="btn btn-outline-success"
							style={{ height: "100%", width: "100%" }}
							onClick={this.startAuction.bind(this)}
						>
							Start Auction
						</button>
					</div>
				</div>

				<br />

				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<h4>Auction Parameters</h4>
					<hr />
				</div>

				<div className="row mb-3" style={{ width: "90%", margin: "auto" }}>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="fcr"
								value={this.state.fcr}
								onChange={this.onFCRChange.bind(this)}
							/>
							<label>FCR</label>
						</div>
					</div>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="asq_mw"
								value={this.state.asq_mw}
								onChange={this.onASQmwChange.bind(this)}
							/>
							<label>ASQ (MW)</label>
						</div>
					</div>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="asq_gwh"
								value={this.state.asq_gwh}
								onChange={this.onASQgwhChange.bind(this)}
							/>
							<label>ASQ (GWh/yr)</label>
						</div>
					</div>
				</div>
				<div className="row mb-3" style={{ width: "90%", margin: "auto" }}>
					<div className="ml-3">
						<div className="form-check">
							<input
								type="checkbox"
								className="form-check-input"
								value={this.state.customTEA}
								onChange={this.onChangeCheckbox.bind(this)}
								name="customTEA"
							/>
							<label className="form-check-label">Custom TEA/ASQ range</label>
						</div>
					</div>
				</div>
				<div className="row mb-3" style={{ width: "90%", margin: "auto" }}>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="minTEA"
								value={this.state.minTEA}
								onChange={this.onRangeChange.bind(this)}
								disabled={!this.state.customTEA}
							/>
							<label>Min TEA (MW)</label>
						</div>
					</div>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="maxTEA"
								value={this.state.maxTEA}
								onChange={this.onRangeChange.bind(this)}
								disabled={!this.state.customTEA}
							/>
							<label>Max TEA (MW)</label>
						</div>
					</div>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="minASQ"
								value={this.state.minASQ}
								onChange={this.onRangeChange.bind(this)}
								disabled={!this.state.customTEA}
							/>
							<label>Min ASQ (MW)</label>
						</div>
					</div>
					<div className="col-3">
						<div className="form-floating">
							<input
								type="number"
								className="form-control"
								name="maxASQ"
								value={this.state.maxASQ}
								onChange={this.onRangeChange.bind(this)}
								disabled={!this.state.customTEA}
							/>
							<label>Max ASQ (MW)</label>
						</div>
					</div>
				</div>

				{/* Simulation Data Table */}
				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<h4>Bid Bot Data</h4>
					<hr />

					<div className="table-responsive">
						<table className="table table-sm table-bordered mx-auto w-auto" style={{ textAlign: "center" }}>
							<thead>
								<tr>
									<th
										style={{
											minWidth: "160px",
											margin: `auto`,
											padding: `15px`,
										}}
									></th>
									{users ? (
										users.map(({ id, username, display_name }) => (
											<th
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
												}}
											>
												{`${display_name} (${username})`}
											</th>
										))
									) : (
										<></>
									)}
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Is Simulated ?</th>
									{users ? (
										users.map(({ id, username, display_name }) => (
											<td
												key={id}
												style={{
													minWidth: "300px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														id={`checkbox_${username}`}
														value={id}
														onChange={this.onIsSimulatedChange.bind(this)}
														checked={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].isSimulated
																: false
														}
													/>
													<label
														className="form-check-label"
														htmlFor={`checkbox_${username}`}
													>
														Simulate {display_name}
													</label>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>

								<tr>
									<th>Price (€/MWh)</th>
									{users ? (
										users.map(({ id }) => (
											<td
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "160px" }}>
														Min Price (€/MWh)
													</span>
													<input
														type="number"
														aria-label="Min Price"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].minPrice
																: 0
														}
														simdatatype="minPrice"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "160px" }}>
														Max Price (€/MWh)
													</span>
													<input
														type="number"
														aria-label="Max Price"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].maxPrice
																: 0
														}
														simdatatype="maxPrice"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>

								<tr>
									<th>Quantity (MW)</th>
									{users ? (
										users.map(({ id }) => (
											<td
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "160px" }}>
														Min Quantity (MW)
													</span>
													<input
														type="string"
														aria-label="Min Quantity"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={commaFormat(
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].minQuantity
																: 0
														)}
														simdatatype="minQuantity"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "160px" }}>
														Max Quantity (MW)
													</span>
													<input
														type="string"
														aria-label="Max Quantity"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={commaFormat(
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].maxQuantity
																: 0
														)}
														simdatatype="maxQuantity"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>
								<tr>
									<th>Splits</th>
									{users ? (
										users.map(({ id }) => (
											<td
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "120px" }}>
														Min Splits
													</span>
													<input
														type="string"
														aria-label="Min Splits"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].minSplits
																: 0
														}
														simdatatype="minSplits"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
												<div className="input-group">
													<span className="input-group-text" style={{ width: "120px" }}>
														Max Splits
													</span>
													<input
														type="string"
														aria-label="Max Quantity"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].maxSplits
																: 0
														}
														simdatatype="maxSplits"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
													/>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "€0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 0,
	}).format(num);
}

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat().format(num);
}

function timerFormat(secs) {
	var z = secs[secs.length - 1] ? secs[secs.length - 1] : "0";
	var y = secs[secs.length - 2] ? secs[secs.length - 2] : "0";
	var result = y.concat(z);
	return result;
}

function WithParams(props) {
	let params = useParams();
	return <StartMPSBAuction {...props} params={params} />;
}

StartMPSBAuction.propTypes = {
	getAllUsers: PropTypes.func.isRequired,
	getAuction: PropTypes.func.isRequired,
	startAuction: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	auction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	auction: state.auction,
});

export default connect(mapStateToProps, {
	setUserLoading,
	setAuctionsLoading,
	getAllUsers,
	getAuction,
	startAuction,
	setAuctionsNotLoading,
	setUserNotLoading,
})(WithParams);
