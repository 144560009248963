import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAuction, getHistory, setAuctionsLoading, setAuctionsNotLoading } from "../../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { COLORS, auctionStatus, eventTypes } from "../../common/constants";
import { getAllUsers, setUserLoading, setUserNotLoading } from "../../../actions/userActions";
import AuctionDashboard from "./AuctionDashboard";
import UserDashboard from "./UserDashboard";
import { joinAuctionRoom } from "../../../helpers/emit/auction";
import socket from "../../../helpers/socket";
import { rejoinedSocketRoom } from "../../../actions/socketActions";
import { addNotification } from "../../common/NotificationManager";

class RENODashboard extends Component {
	constructor(props) {
		super(props);

		this.FILTER = {
			DEFAULT: "default",
			AUCTION: "auction",
			USER: "user",
			WINNER: "winner",
			COMMAND: "command",
		};

		this.state = {
			mainFilterValue: this.FILTER.DEFAULT,
			userFilterValue: this.FILTER.DEFAULT,
			colors: {},
		};
	}

	componentDidMount() {
		this.refresh();
	}

	componentDidUpdate() {
		if (this.props.socket) {
			if (this.props.socket.needToRejoinRoom) {
				this.joinSocketRoom();
				this.props.rejoinedSocketRoom();
			}
		}
	}

	async refresh() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.fetchData();
		this.joinSocketRoom();

		this.props.setAuctionsNotLoading();
		this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getHistory(this.props.params.auctionId);
		await this.props.getAllUsers();

		const { users } = this.props.user;
		var colors = {};
		for (var i = 0; i < users.length; i++) {
			colors[users[i].id] = COLORS[i];
		}

		this.setState({
			...this.state,
			colors: colors,
		});
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			mainFilterValue: event.target.value,
			userFilterValue: this.FILTER.DEFAULT,
		});
	}

	onUserDropDownChange(event) {
		this.setState({
			...this.state,
			userFilterValue: event.target.value,
		});
	}

	returnSelectedDashboard() {
		if (this.state.mainFilterValue === this.FILTER.AUCTION)
			return <AuctionDashboard auction={this.props.auction} user={this.props.user} colors={this.state.colors} />;
		else if (this.state.mainFilterValue === this.FILTER.USER) {
			if (this.state.userFilterValue == this.FILTER.DEFAULT) return <></>;
			else {
				const { history, auction } = this.props.auction;
				const { users } = this.props.user;

				const selectedUserId = parseInt(this.state.userFilterValue);
				const selectedUser = users.filter((u) => u.id === selectedUserId)[0];

				return (
					<UserDashboard history={history} user={selectedUser} auction={auction} colors={this.state.colors} />
				);
			}
		}
		return <></>;
	}

	returnSpinner() {
		return (
			<div className="container">
				<h3></h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/dashboards">Dashboards</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getUsersDropdown() {
		if (this.state.mainFilterValue === this.FILTER.USER) {
			const { users } = this.props.user;

			return (
				<select
					value={this.state.userFilterValue}
					onChange={(e) => this.onUserDropDownChange(e)}
					style={{
						width: "250px",
						marginLeft: "10px",
						padding: "6px",
						borderRadius: "4px",
						fontSize: "14px",
						borderColor: "#0275d8",
						color: "#0275d8",
					}}
				>
					<option value={this.FILTER.DEFAULT} disabled={true}>
						Select User
					</option>
					<hr />
					{users.map(({ id, display_name }) => (
						<option key={id} value={id}>
							{display_name}
						</option>
					))}
				</select>
			);
		}
	}

	render() {
		const { auction, auction_loading } = this.props.auction;
		const { user, signedIn, user_loading } = this.props.user;

		if (!signedIn || user.type !== "admin") {
			return <Redirect to="/" />;
		}
		if (auction_loading || user_loading) {
			return this.returnSpinner;
		}

		// Sockets
		socket.off(eventTypes.AUCTION_INFORMATION).on(eventTypes.AUCTION_INFORMATION, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "info",
				duration: 10000,
			});
		});
		socket.off(eventTypes.AUCTION_SUCCESS).on(eventTypes.AUCTION_SUCCESS, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "success",
				duration: 10000,
			});
		});
		socket.off(eventTypes.AUCTION_ERROR).on(eventTypes.AUCTION_ERROR, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 20000,
			});
		});
		socket.off(eventTypes.AUCTION_WARNING).on(eventTypes.AUCTION_WARNING, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "warning",
				showIcon: true,
				duration: 30000,
			});
		});
		socket.off(eventTypes.AUCTION_REFRESH).on(eventTypes.AUCTION_REFRESH, (data) => {
			this.fetchData();
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		return (
			<div className="container">
				<h3>{auction.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/dashboards">Dashboards</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name}
							{auction.status === auctionStatus.IN_PROGRESS ? (
								<span className="badge bg-success" style={{ marginLeft: "10px" }}>
									In Progress
								</span>
							) : auction.status === auctionStatus.ENDED ? (
								<span className="badge bg-danger" style={{ marginLeft: "10px" }}>
									Ended
								</span>
							) : (
								<></>
							)}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="text-right">
					<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
						<i className="fas fa-sync"></i> Data Reload
					</button>
				</div>
				<br />

				<div className="card" style={{ width: "80%", margin: "auto" }}>
					<div className="card-body" style={{ padding: "2%" }}>
						<div className="row">
							<div className="col-9">
								<select
									value={this.state.mainFilterValue}
									onChange={(e) => this.onMainDropDownChange(e)}
									style={{
										width: "200px",
										marginLeft: "5px",
										padding: "6px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
									}}
								>
									<option value={this.FILTER.DEFAULT} disabled={true}>
										Select Dashboard
									</option>
									<hr />
									<option value={this.FILTER.AUCTION}>Auction Summary</option>
									<option value={this.FILTER.USER}>Users Dashboard</option>
									<hr />
									<option value={this.FILTER.COMMAND} disabled={true}>
										Command Centre
									</option>
								</select>

								{this.getUsersDropdown()}
							</div>
						</div>
					</div>
				</div>

				<hr />
				<br />
				{this.returnSelectedDashboard()}
				<br />
			</div>
		);
	}
}

function WithParams(props) {
	let params = useParams(); // React Hooks
	return <RENODashboard {...props} params={params} />;
}

RENODashboard.propTypes = {
	getAuction: PropTypes.func.isRequired,
	getHistory: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	getAllUsers: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	rejoinedSocketRoom: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	socket: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
	socket: state.socket,
});

export default connect(mapStateToProps, {
	getAuction,
	getHistory,
	setAuctionsLoading,
	setAuctionsNotLoading,
	getAllUsers,
	setUserLoading,
	setUserNotLoading,
	rejoinedSocketRoom,
})(WithParams);
