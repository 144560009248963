import axiosConn from "../helpers/axios";
import authHeaders from "../helpers/authHeaders";
import {
	GET_TRACKERS,
	GET_TRACKER,
	ADD_ROUND,
	TRACKER_LOADING,
	STOP_TRACKER_LOADING,
	START_TRACKER,
	ADD_BID,
	DELETE_ROUND,
	DELETE_BID,
	ERROR,
	RESET_TRACKER,
	UPDATE_PREFERENCES,
	UPDATE_DATA,
	UPDATE_BTT_AUCTION_DATA,
} from "./types";
import {
	emitAddBid,
	emitAddRound,
	emitResetTracker,
	emitUndoBid,
	emitUndoRound,
	emitUpdateData,
	emitUpdatePreferences,
	emitUpdateDataFromAuction,
} from "../helpers/emit/tracker";

export const getTrackers = () => async (dispatch) => {
	dispatch(setTrackersLoading());
	try {
		const resReady = await axiosConn.get("/tracker", {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_TRACKERS, payload: res.data.trackers });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getTracker = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/tracker/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_TRACKER, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const startTracker =
	(id, values = []) =>
	async (dispatch) => {
		dispatch(setTrackersLoading());
		try {
			const resReady = await axiosConn.post(`tracker/${id}/start`, values, { headers: authHeaders() });
			const res = await resReady;
			dispatch({
				type: START_TRACKER,
				payload: res.data,
			});
		} catch (err) {
			dispatch(errorHandler(err));
		}
	};

export const updateData =
	(id, values = []) =>
	async (dispatch) => {
		try {
			const resReady = await axiosConn.post(`tracker/${id}/update/data`, values, { headers: authHeaders() });
			const res = await resReady;
			await emitUpdateData({ tracker_id: id });
			dispatch({
				type: UPDATE_DATA,
				payload: res.data,
			});
		} catch (err) {
			dispatch(errorHandler(err));
		}
	};

export const updatePreferences =
	(id, values = []) =>
	async (dispatch) => {
		try {
			const resReady = await axiosConn.post(`tracker/${id}/update/preferences`, values, {
				headers: authHeaders(),
			});
			const res = await resReady;
			await emitUpdatePreferences({ tracker_id: id });
			dispatch({
				type: UPDATE_PREFERENCES,
				payload: res.data,
			});
		} catch (err) {
			dispatch(errorHandler(err));
		}
	};

export const addRound =
	(id, values = []) =>
	async (dispatch) => {
		try {
			const resReady = await axiosConn.post(`tracker/${id}/add/round`, values, { headers: authHeaders() });
			const res = await resReady;
			await emitAddRound({ tracker_id: id });
			dispatch({
				type: ADD_ROUND,
				payload: res.data,
			});
		} catch (err) {
			dispatch(errorHandler(err));
		}
	};

export const addBid = (id, value) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`tracker/${id}/add/bid`, value, {
			headers: authHeaders(),
		});
		const res = await resReady;
		await emitAddBid({ tracker_id: id });
		dispatch({
			type: ADD_BID,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const deleteRound = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/tracker/${id}/undo/round`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitUndoRound({ tracker_id: id });
		dispatch({
			type: DELETE_ROUND,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const deleteBid = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/tracker/${id}/undo/bid`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitUndoBid({ tracker_id: id });
		dispatch({
			type: DELETE_BID,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const resetTracker = (id) => async (dispatch) => {
	dispatch(setTrackersLoading());
	try {
		const resReady = await axiosConn.post(`/tracker/${id}/reset`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitResetTracker({ tracker_id: id });
		dispatch({ type: RESET_TRACKER });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const updateBTTAuctionData = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/tracker/${id}/update/state`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitUpdateDataFromAuction({ tracker_id: id });
		dispatch({ type: UPDATE_BTT_AUCTION_DATA, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const setTrackersLoading = () => {
	return {
		type: TRACKER_LOADING,
	};
};

export const setTrackersNotLoading = () => {
	return {
		type: STOP_TRACKER_LOADING,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err,
	};
};
