import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTracker, startTracker, setTrackersLoading, setTrackersNotLoading } from "../../actions/trackerActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

class StartTracker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			siteValue: {},
			priority: {},
			budget: {},
		};
	}

	componentDidMount() {
		this.refresh();
	}

	async refresh() {
		await this.props.setTrackersLoading();
		await this.props.getTracker(this.props.params.bidTrackerId);
		await this.props.setTrackersNotLoading();
	}

	onChangeSiteValue(event) {
		let value = parseInt(event.target.value.replace(/,/g, "").replace(`$`, ``).replace(`$`, ``), 10);
		this.setState({
			...this.state,
			siteValue: { ...this.state.siteValue, [event.target.name]: value },
		});
	}

	onChangeBudget(event) {
		let value = parseInt(event.target.value.replace(/,/g, "").replace(`$`, ``).replace(`$`, ``), 10);
		this.setState({
			...this.state,
			budget: { ...this.state.budget, [event.target.name]: value },
		});
	}

	onChangePriority(event) {
		this.setState({
			...this.state,
			priority: {
				...this.state.priority,
				[event.target.name]: parseInt(event.target.value),
			},
		});
	}

	async startTracker() {
		let preferences = [];
		for (const [key, value] of Object.entries(this.state.siteValue)) {
			for (const [key1, value1] of Object.entries(this.state.priority)) {
				for (const [key2, value2] of Object.entries(this.state.budget)) {
					if (key1 == key && key1 == key2) {
						preferences.push({
							lease_id: parseInt(key),
							site_value: parseInt(value),
							priority: parseInt(value1),
							budget: parseInt(value2),
						});
					}
				}
			}
		}
		const payload = { preferences };

		await this.props.startTracker(this.props.params.bidTrackerId, payload);
	}

	render() {
		const { user, user_loading, signedIn } = this.props.user;
		const { tracker, tracker_loading, started } = this.props.tracker;

		if (!signedIn || !(user.type == "admin" || user.user_id == tracker.user_id) || started) {
			return <Redirect to="/bidtrackers" />;
		}

		if (tracker_loading || user_loading) {
			return (
				<div className="container">
					<h3>Start Bid Tracker {tracker.name ? `(${tracker.name})` : ""}</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/bidtrackers">Bid Trackers</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Start Bid Tracker
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Start Bid Tracker {tracker.name ? `(${tracker.name})` : ""}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/bidtrackers">Bid Trackers</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Start Bid Tracker
						</li>
					</ol>
				</nav>

				<hr />

				<br />

				<div className="card" style={{ width: "80%", margin: "auto" }}>
					<div className="card-body" style={{ alignItems: "center" }}>
						<div className="table-responsive">
							<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
								<thead>
									<tr>
										<th>Lease Area</th>
										<th>Reserve Price</th>
										<th>Site Value</th>
										<th>Budget</th>
										<th>Priority Group</th>
									</tr>
								</thead>
								<tbody>
									{tracker.leases ? (
										tracker.leases.map(({ id, name, starting_bid }) => (
											<tr key={id}>
												<td>{name}</td>
												<td>{currencyFormat(starting_bid)}</td>
												<td>
													<div className="input-group input-group-sm mb-3">
														<input
															type="string"
															className="form-control"
															name={id}
															value={currencyFormat(this.state.siteValue[id])}
															onChange={this.onChangeSiteValue.bind(this)}
														/>
													</div>
												</td>
												<td>
													<div className="input-group input-group-sm mb-3">
														<input
															type="string"
															className="form-control"
															name={id}
															value={currencyFormat(this.state.budget[id])}
															onChange={this.onChangeBudget.bind(this)}
														/>
													</div>
												</td>
												<td>
													<div className="input-group input-group-sm mb-3">
														<input
															type="number"
															className="form-control"
															name={id}
															onChange={this.onChangePriority.bind(this)}
														/>
													</div>
												</td>
											</tr>
										))
									) : (
										<></>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br />
				<button className="btn btn-primary pull-right" onClick={this.startTracker.bind(this)}>
					Start Bid Tracker
				</button>
			</div>
		);
	}
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function WithParams(props) {
	let params = useParams();
	return <StartTracker {...props} params={params} />;
}

StartTracker.propTypes = {
	user: PropTypes.object.isRequired,
	tracker: PropTypes.object.isRequired,
	getTracker: PropTypes.func.isRequired,
	startTracker: PropTypes.func.isRequired,
	setTrackersNotLoading: PropTypes.func.isRequired,
	setTrackersLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	tracker: state.tracker,
});

export default connect(mapStateToProps, {
	setTrackersNotLoading,
	setTrackersLoading,
	getTracker,
	startTracker,
})(WithParams);
