import axiosConn from "../helpers/axios";
import authHeaders from "../helpers/authHeaders";
import { DATABASE_RESET, DATABASE_LOADING, ERROR } from "./types";
import { emitConfigUpdate } from "../helpers/emit/config";

export const databaseReset = () => async (dispatch) => {
	dispatch(setDatabaseLoading());

	try {
		await axiosConn.post("/db/drop", {}, { headers: authHeaders() });
		await axiosConn.post("/db/create", {}, { headers: authHeaders() });
		await axiosConn.post("/db/populate", {}, { headers: authHeaders() });

		await emitConfigUpdate({});
		dispatch({
			type: DATABASE_RESET,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const setDatabaseLoading = () => {
	return {
		type: DATABASE_LOADING,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err,
	};
};
