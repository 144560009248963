import { SOCKET_CONNECT, SOCKET_DISCONNECT, SOCKET_REJOINED_ROOM, SOCKET_REJOIN_ROOM } from "../actions/types";

const initialState = {
	connected: false,
	needToRejoinRoom: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SOCKET_CONNECT:
			return { ...state, connected: true, needToRejoinRoom: true };
		case SOCKET_DISCONNECT:
			return { ...state, connected: false, needToRejoinRoom: true };
		case SOCKET_REJOIN_ROOM:
			return { ...state, needToRejoinRoom: true };
		case SOCKET_REJOINED_ROOM:
			return { ...state, needToRejoinRoom: false };
		default:
			return state;
	}
}
