import socket from "../socket";

export const joinAuctionRoom = async (id, user) => {
	await socket.emit("join_auction", {
		auction_id: id,
		user: user,
	});
};

export const leaveAllRooms = async () => {
	await socket.emit("leave_auction");
};

export const emitStartAuction = async (data) => {
	await socket.emit("auction_start", data);
};

export const emitEndRound = async (data) => {
	await socket.emit("round_end", data);
};

export const emitEndAuction = async (data) => {
	await socket.emit("auction_end", data);
};

export const emitNextRound = async (data) => {
	await socket.emit("round_next", data);
};

export const emitDetermineWinners = async (data) => {
	await socket.emit("auction_winner_determination", data);
};

export const emitMakeBids = async (data) => {
	await socket.emit("auction_bid", data);
};

export const emitMakeProxyBid = async (data) => {
	await socket.emit("auction_proxy_bid", data);
};

export const emitSimulateAuction = async (data) => {
	await socket.emit("auction_simulate", data);
};

export const emitDeleteBids = async (data) => {
	await socket.emit("auction_delete_bids", data);
};

export const emitTriggerRefresh = async (data) => {
	await socket.emit("auction_trigger_refresh", data);
};

export const emitMakeAnnouncement = async (data) => {
	await socket.emit("auction_announcement", data);
};

export const emitSendReminder = async (data) => {
	await socket.emit("send_reminder", data);
};

export const emitRequestAssistance = async (id, user) => {
	await socket.emit("request_assistance", {
		auction_id: id,
		user: user,
	});
};
