import React, { PureComponent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { COLORS } from "../../common/constants";

export default class PercentageLineChart extends PureComponent {
	render() {
		let leases_data = [];
		let idx = 0;
		this.props.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		const domain = [0, "dataMax"];

		return (
			<ResponsiveContainer width="100%" height={250}>
				<LineChart
					width={400}
					height={250}
					data={this.props.data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="round" />
					<YAxis
						tickCount={this.props.tickCount}
						domain={domain}
						tickFormatter={(value) =>
							new Intl.NumberFormat("en-US", {
								style: "percent",
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(value)
						}
					/>
					<Tooltip
						allowEscapeViewBox={{ y: true }}
						formatter={(value) =>
							new Intl.NumberFormat("en-US", {
								style: "percent",
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(value)
						}
					/>
					<Legend />
					<Line
						name={"Trend"}
						dataKey="avg"
						stroke="#999999"
						strokeWidth={1}
						strokeOpacity={0.7}
						strokeDashArray="1 1"
					/>
					{leases_data.map(({ id, name, stroke }) => (
						<Line
							key={id}
							name={name}
							type="monotone"
							dataKey={id.toString()}
							stroke={stroke}
							strokeWidth={2}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		);
	}
}
