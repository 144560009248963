import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import {
	getAuction,
	getResults,
	getHistory,
	getRound,
	setAuctionsLoading,
	setAuctionsNotLoading,
	getPrices,
	getWinningBids,
	getBidCredits,
	postUndoUserRoundBids,
} from "../../actions/auctionActions";
import { getAllUsers, setUserNotLoading, setUserLoading } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import WinnersDashboard from "./components/WinnersDashboard";
import UserSummaryDashboard from "./components/UserSummaryDashboard";
import UserDashboard from "./components/UserDashboard";
import LeaseSummaryDashboard from "./components/LeaseSummaryDashboard";
import LeaseDashboard from "./components/LeaseDashboard";
import AuctionDataDashboard from "./components/AuctionDataDashboard";
import BidsSummaryDashboard from "./components/BidsSummaryDashboard";
import AuctionDashboard from "./components/AuctionDashboard";
import socket from "../../helpers/socket";
import { addNotification } from "../common/NotificationManager";
import { joinAuctionRoom } from "../../helpers/emit/auction";
import CreditsDashboard from "./components/CreditsDashboard";
import CommandCentre from "./components/CommandCentre";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mainFilterValue: "select",
			userFilterValue: "all",
			leaseFilterValue: "all",
			roundFilterValue: 0,
			roundOptionsMin: 0,
			roundOptionsMax: 0,
			minCustomRoundGap: 4,
			rounds: [],
		};

		this.onMainDropDownChange = this.onMainDropDownChange.bind(this);
		this.onUserDropDownChange = this.onUserDropDownChange.bind(this);
		this.onLeaseDropDownChange = this.onLeaseDropDownChange.bind(this);
		this.onRoundDropDownChange = this.onRoundDropDownChange.bind(this);
		this.onCustomRoundDropDownChange = this.onCustomRoundDropDownChange.bind(this);
	}

	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	async refresh() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.fetchData();

		await this.props.setAuctionsNotLoading();
		await this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getRound(this.props.params.auctionId);
		await this.props.getHistory(this.props.params.auctionId);
		await this.props.getResults(this.props.params.auctionId);
		await this.props.getPrices(this.props.params.auctionId);
		await this.props.getBidCredits(this.props.params.auctionId);

		if (this.props.auction.auction.status == "Ended") {
			await this.props.getWinningBids(this.props.params.auctionId);
		}
		await this.props.getAllUsers();

		if (this.state.roundFilterValue !== "custom") {
			let _rounds = Array.from({ length: this.props.auction.auction.round }, (_, i) => i + 1);
			if (this.state.roundFilterValue > 0) {
				_rounds = _rounds.slice(0, this.state.roundFilterValue);
			} else if (this.state.roundFilterValue < 0) {
				_rounds = _rounds.slice(Math.max(_rounds.length + parseInt(this.state.roundFilterValue), 0));
			}
			this.setState({
				...this.state,
				rounds: _rounds,
			});
		}
	}

	onMainDropDownChange(event) {
		const _rounds = Array.from({ length: this.props.auction.auction.round }, (_, i) => i + 1);
		this.setState({
			...this.state,
			mainFilterValue: event.target.value,
			userFilterValue: "summary",
			leaseFilterValue: "summary",
			roundFilterValue: 0,
			roundOptionsMin: 1,
			roundOptionsMax: this.props.auction.auction.round,
			rounds: _rounds,
		});
	}

	onUserDropDownChange(event) {
		this.setState({ ...this.state, userFilterValue: event.target.value });
	}

	onLeaseDropDownChange(event) {
		this.setState({ ...this.state, leaseFilterValue: event.target.value });
	}

	onRoundDropDownChange(event) {
		const { auction } = this.props.auction;
		const newValue = event.target.value;
		let _rounds = Array.from({ length: auction.round }, (_, i) => i + 1);

		if (newValue === "custom") {
			this.setState({
				...this.state,
				roundFilterValue: event.target.value,
				rounds: _rounds,
			});
			return;
		}

		if (newValue > 0) {
			_rounds = _rounds.slice(0, newValue);
		} else if (newValue < 0) {
			_rounds = _rounds.slice(Math.max(_rounds.length + parseInt(newValue), 0));
		}
		this.setState({
			...this.state,
			roundFilterValue: event.target.value,
			rounds: _rounds,
			roundOptionsMin: 1,
			roundOptionsMax: this.props.auction.auction.round,
		});
	}

	onCustomRoundDropDownChange(event) {
		if (event.target.name === "min") {
			const newMin = event.target.value;
			const _rounds = Array(this.state.roundOptionsMax - newMin + 1)
				.fill()
				.map((_, idx) => parseInt(newMin) + idx);
			this.setState({
				...this.state,
				roundOptionsMin: newMin,
				rounds: _rounds,
			});
		}
		if (event.target.name === "max") {
			const newMax = event.target.value;
			const _rounds = Array(newMax - this.state.roundOptionsMin + 1)
				.fill()
				.map((_, idx) => parseInt(this.state.roundOptionsMin) + idx);
			this.setState({
				...this.state,
				roundOptionsMax: newMax,
				rounds: _rounds,
			});
		}
	}

	getCustomRoundDropDowns() {
		if (this.state.roundFilterValue == "custom") {
			const minRounds = Array(this.state.roundOptionsMax - 1 + 1 - parseInt(this.state.minCustomRoundGap))
				.fill()
				.map((_, idx) => 1 + idx);
			const maxRounds = Array(
				this.props.auction.auction.round -
					parseInt(this.state.roundOptionsMin) +
					1 -
					parseInt(this.state.minCustomRoundGap)
			)
				.fill()
				.map((_, idx) => parseInt(this.state.roundOptionsMin) + idx + parseInt(this.state.minCustomRoundGap));

			return (
				<>
					<select
						name={"min"}
						value={this.state.roundOptionsMin}
						onChange={(e) => this.onCustomRoundDropDownChange(e)}
						style={{
							width: "100px",
							marginRight: "5px",
							padding: "6px",
							borderRadius: "4px",
							fontSize: "14px",
							borderColor: "#0275d8",
							color: "#0275d8",
						}}
					>
						{minRounds.map((round) => (
							<option key={round} value={round}>
								Round {round}
							</option>
						))}
					</select>
					-
					<select
						name={"max"}
						value={this.state.roundOptionsMax}
						onChange={(e) => this.onCustomRoundDropDownChange(e)}
						style={{
							width: "100px",
							marginLeft: "5px",
							marginRight: "10px",
							padding: "6px",
							borderRadius: "4px",
							fontSize: "14px",
							borderColor: "#0275d8",
							color: "#0275d8",
						}}
					>
						{maxRounds.map((round) => (
							<option key={round} value={round}>
								Round {round}
							</option>
						))}
					</select>
				</>
			);
		}

		return <></>;
	}

	getSecondaryDropDown() {
		if (this.state.mainFilterValue === "users") {
			const { users } = this.props.user;
			return (
				<select
					value={this.state.userFilterValue}
					onChange={(e) => this.onUserDropDownChange(e)}
					style={{
						width: "250px",
						marginLeft: "10px",
						padding: "6px",
						borderRadius: "4px",
						fontSize: "14px",
						borderColor: "#0275d8",
						color: "#0275d8",
					}}
				>
					<option value="summary">Users Summary</option>
					<option value="all">All Users</option>
					<hr />
					{users.map(({ id, display_name, username }) => (
						<option key={id} value={id}>
							{display_name} ({username})
						</option>
					))}
				</select>
			);
		}
		if (this.state.mainFilterValue === "leases") {
			const { auction } = this.props.auction;
			return (
				<select
					value={this.state.leaseFilterValue}
					onChange={(e) => this.onLeaseDropDownChange(e)}
					style={{
						width: "250px",
						marginLeft: "10px",
						padding: "6px",
						borderRadius: "4px",
						fontSize: "14px",
						borderColor: "#0275d8",
						color: "#0275d8",
					}}
				>
					<option value="summary">Lease Area Summary</option>
					<option value="all">All Lease Areas</option>
					<hr />
					{auction.leases.map(({ id, name }) => (
						<option key={id} value={id}>
							Site {name}
						</option>
					))}
				</select>
			);
		}
	}

	getWinnersDashboard() {
		return <WinnersDashboard auction={this.props.auction} user={this.props.user} />;
	}

	getUsersDashboard() {
		// Get User Summary Dashbord
		if (this.state.userFilterValue === "summary")
			return (
				<UserSummaryDashboard auction={this.props.auction} user={this.props.user} rounds={this.state.rounds} />
			);

		// Get List of All Lease Dashboards
		if (this.state.userFilterValue == "all") {
			return (
				<>
					{this.props.user.users.map((user) => (
						<div key={user.id}>
							<UserDashboard auction={this.props.auction} user={user} rounds={this.state.rounds} />
							<br />
							<hr />
							<br />
						</div>
					))}
				</>
			);
		}

		// Get User Dashboard
		const user = this.props.user.users.filter((u) => u.id == parseInt(this.state.userFilterValue));
		if (user.length == 1)
			return <UserDashboard auction={this.props.auction} user={user[0]} rounds={this.state.rounds} />;
		return <></>;
	}

	getLeasesDashboard() {
		// Get Lease Summary Dashboard
		if (this.state.leaseFilterValue == "summary")
			return <LeaseSummaryDashboard auction={this.props.auction} rounds={this.state.rounds} />;

		// Get List of All Lease Dashboards
		if (this.state.leaseFilterValue == "all") {
			return (
				<>
					{this.props.auction.auction.leases.map((lease) => (
						<div key={lease.id}>
							<LeaseDashboard auction={this.props.auction} lease={lease} rounds={this.state.rounds} />
							<br />
							<hr />
							<br />
						</div>
					))}
				</>
			);
		}

		// Get Lease Dashboard
		const lease = this.props.auction.auction.leases.filter((l) => l.id == parseInt(this.state.leaseFilterValue));
		if (lease.length == 1)
			return <LeaseDashboard auction={this.props.auction} lease={lease[0]} rounds={this.state.rounds} />;
		return <></>;
	}

	getDataDashboard() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Auction Data</h4>
				</div>
				<AuctionDataDashboard auction={this.props.auction} user={this.props.user} />
			</>
		);
	}

	getBidsDashboard() {
		return <BidsSummaryDashboard auction={this.props.auction} user={this.props.user} rounds={this.state.rounds} />;
	}

	getAuctionDashboard() {
		return <AuctionDashboard auction={this.props.auction} user={this.props.user} rounds={this.state.rounds} />;
	}

	getCreditsDashboard() {
		return <CreditsDashboard auction={this.props.auction} user={this.props.user} />;
	}

	getCommandCentre() {
		return (
			<CommandCentre
				auction={this.props.auction}
				user={this.props.user}
				postUndoUserRoundBids={this.props.postUndoUserRoundBids}
				refresh={this.refresh.bind(this)}
			/>
		);
	}

	getDashboard() {
		if (this.state.mainFilterValue === "auction") return this.getAuctionDashboard();
		if (this.state.mainFilterValue === "users") return this.getUsersDashboard();
		if (this.state.mainFilterValue === "leases") return this.getLeasesDashboard();
		if (this.state.mainFilterValue === "bids") return this.getBidsDashboard();
		if (this.state.mainFilterValue === "winners") return this.getWinnersDashboard();
		if (this.state.mainFilterValue === "data") return this.getDataDashboard();
		if (this.state.mainFilterValue === "credits") return this.getCreditsDashboard();
		if (this.state.mainFilterValue === "command") return this.getCommandCentre();
		return <></>;
	}

	returnSpinner() {
		return (
			<div className="container">
				<h3></h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/dashboards">Dashboards</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { auction, auction_loading } = this.props.auction;
		const { user, signedIn, user_loading } = this.props.user;

		if (!signedIn || user.type !== "admin") {
			return <Redirect to="/" />;
		}
		if (auction_loading || user_loading) {
			return this.returnSpinner;
		}

		// Sockets
		socket.off("auction_refresh").on("auction_refresh", (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "info",
				duration: 7000,
			});
		});
		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});
		socket.off("auction_assistance").on("auction_assistance", (msg) => {
			addNotification({
				title: "Assistance Requested",
				message: msg,
				type: "warning",
			});
		});
		socket.off("auction_reminder").on("auction_reminder", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 10000,
			});
		});
		socket.off("auction_announcement").on("auction_announcement", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "default",
				duration: 10000,
			});
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		return (
			<div className="container">
				<h3>{auction.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/dashboards">Dashboards</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name} MPSB DASHBOARD
							{auction.status === "In Progress" ? (
								<span className="badge bg-success" style={{ marginLeft: "10px" }}>
									In Progress
								</span>
							) : auction.status === "Ended" ? (
								<span className="badge bg-danger" style={{ marginLeft: "10px" }}>
									Ended
								</span>
							) : (
								<></>
							)}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="text-right">
					<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
						<i className="fas fa-sync"></i> Data Reload
					</button>
				</div>
				<br />

				<div className="card" style={{ width: "80%", margin: "auto" }}>
					<div className="card-body" style={{ padding: "2%" }}>
						<div className="row">
							<div className="col-6">
								<select
									value={this.state.mainFilterValue}
									onChange={(e) => this.onMainDropDownChange(e)}
									style={{
										width: "200px",
										marginLeft: "5px",
										padding: "6px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
									}}
								>
									<option value="select" disabled={true}>
										Select Dashboard
									</option>
									<hr />
									<option value="auction">Auction Dashboard</option>
									<option value="users">Users Dashboard</option>
									<option value="leases">Lease Areas Dashboard</option>
									<option value="bids">Bids Dashboard</option>
									<hr />
									<option value="winners" disabled={auction.status !== "Ended"}>
										Auction Winners
									</option>
									<option value="data">Auction Data</option>
									<option value="credits">Bid Credits</option>
									<hr />
									<option value="command">Command Centre</option>
								</select>

								{this.getSecondaryDropDown()}
							</div>
							<div
								className="col-6"
								style={{
									textAlign: "right",
									alignItems: "right",
								}}
							>
								{this.state.mainFilterValue == "users" ||
								this.state.mainFilterValue == "leases" ||
								this.state.mainFilterValue == "bids" ||
								this.state.mainFilterValue == "auction" ? (
									<>
										{this.getCustomRoundDropDowns()}
										<select
											value={this.state.roundFilterValue}
											onChange={(e) => this.onRoundDropDownChange(e)}
											style={{
												width: "200px",
												marginLeft: "5px",
												padding: "6px",
												borderRadius: "4px",
												fontSize: "14px",
												borderColor: "#0275d8",
												color: "#0275d8",
											}}
										>
											<option value={0}>All {auction.round} Rounds</option>
											<option
												value="custom"
												disabled={auction.round <= this.state.minCustomRoundGap}
											>
												Custom
											</option>
											<hr />
											<option value={-5} disabled={auction.round <= 5}>
												Last 5 Rounds
											</option>
											<option value={-10} disabled={auction.round <= 10}>
												Last 10 Rounds
											</option>
											<option value={-15} disabled={auction.round <= 15}>
												Last 15 Rounds
											</option>
											<hr />
											<option value={5} disabled={auction.round <= 5}>
												First 5 Rounds
											</option>
											<option value={10} disabled={auction.round <= 10}>
												First 10 Rounds
											</option>
											<option value={15} disabled={auction.round <= 15}>
												First 15 Rounds
											</option>
										</select>
									</>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</div>

				<hr />

				<br />

				{this.getDashboard()}

				<br />
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <Dashboard {...props} params={params} />;
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

Dashboard.propTypes = {
	getAuction: PropTypes.func.isRequired,
	getWinningBids: PropTypes.func.isRequired,
	getResults: PropTypes.func.isRequired,
	getBidCredits: PropTypes.func.isRequired,
	getHistory: PropTypes.func.isRequired,
	getPrices: PropTypes.func.isRequired,
	getRound: PropTypes.func.isRequired,
	getAllUsers: PropTypes.func.isRequired,
	postUndoUserRoundBids: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, {
	setUserLoading,
	setAuctionsLoading,
	getAuction,
	getWinningBids,
	getResults,
	getRound,
	getHistory,
	getAllUsers,
	getPrices,
	setAuctionsNotLoading,
	setUserNotLoading,
	getBidCredits,
	postUndoUserRoundBids,
})(WithParamsAndNavigate);
