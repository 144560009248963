import socket from "../socket";

export const joinASTRoom = async (data) => {
	await socket.emit("ast_join", data);
};

export const leaveASTRooms = async () => {
	await socket.emit("ast_leave");
};

export const emitASTSimulate = async (data) => {
	await socket.emit("ast_simulate", data);
};

export const emitASTConfigUpdate = async (data) => {
	await socket.emit("ast_config_update", data);
};
