import axiosConn from "../helpers/axios";
import authHeaders from "../helpers/authHeaders";
import { SIGN_IN, SIGN_OUT, GET_POINTS, GET_BIDS, ERROR, GET_USERS, USER_LOADING, STOP_USER_LOADING } from "./types";

export const signIn = (username, password) => async (dispatch) => {
	dispatch(setUserLoading());

	try {
		const payload = { username, password };
		const resReady = await axiosConn.post("/user/login", payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		if (res.data) {
			await window.sessionStorage.setItem("user", JSON.stringify(res.data));
		}
		dispatch({ type: SIGN_IN, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const signOut = () => (dispatch) => {
	dispatch(setUserLoading());
	window.sessionStorage.removeItem("user");
	dispatch({
		type: SIGN_OUT,
	});
};

export const getAllUsers = () => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`user/all`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_USERS, payload: res.data.users });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getPoints = (auctionId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/user/${auctionId}/points`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_POINTS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler);
	}
};

export const getBids = (auctionId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${auctionId}/user`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const setUserLoading = () => {
	return {
		type: USER_LOADING,
	};
};

export const setUserNotLoading = () => {
	return {
		type: STOP_USER_LOADING,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err.response.data.error,
	};
};
