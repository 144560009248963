import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Area,
	AreaChart,
} from "recharts";
import { COLORS } from "../../common/constants";

class LeaseSummaryDashboard extends Component {
	constructor(props) {
		super(props);
	}

	renderLeasePriceGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;

		let pricesChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = prices.filter((p) => p.lease_id === lease.id && p.round === roundNum);
				data[lease.id] = d.length > 0 ? d[0].price : 0;
			});
			pricesChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Clock Prices
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={300}>
						<LineChart
							data={pricesChartData}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	renderLeasePricesTable() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;

		let pricesData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = prices.filter((p) => p.lease_id === lease.id && p.round === roundNum);
				data[lease.id] = d.length > 0 ? d[0].price : 0;
			});
			pricesData.push({
				roundNum,
				leaseData: data,
			});
		});

		let leaseStrokes = {};
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leaseStrokes[lease.id] = COLORS[idx];
				idx += 1;
			});

		return (
			<div className="card">
				<div className="card-header" style={{ textAlign: "center" }}>
					Lease Clock Prices
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<table
							className="table table-sm table-bordered"
							id="table-lease-history"
							style={{
								textAlign: "center",
								verticalAlign: "center",
							}}
						>
							<thead>
								<tr>
									<th>Round</th>
									{auction.leases ? (
										auction.leases.map(({ id, name }) => (
											<th
												key={id}
												style={{
													minWidth: "125px",
													backgroundColor: leaseStrokes[id] + "60",
												}}
											>
												{name}
											</th>
										))
									) : (
										<></>
									)}
								</tr>
							</thead>
							<tbody>
								{pricesData.map(({ roundNum, leaseData }) => (
									<tr key={roundNum}>
										<th>{roundNum}</th>
										{auction.leases ? (
											auction.leases.map(({ id }) => (
												<td key={id}>{currencyFormat(leaseData[id])}</td>
											))
										) : (
											<></>
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	renderDemandGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = history.filter((his) => his.lease_id == lease.id && his.round == roundNum && his.exit == 0);
				data[lease.id] = d.length;
			});
			demandChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Demand (Live Bids)
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={200}>
						<LineChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis tickCount={3} />
							<Tooltip />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	getDemandStyle(demand, color) {
		if (demand) return { backgroundColor: color + "60" };
		return {};
	}

	getExitStyle(exitBids, color) {
		if (exitBids) return { color: color };
		return {};
	}

	renderDemandTable() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let leaseStrokes = {};
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leaseStrokes[lease.id] = COLORS[idx];
				idx += 1;
			});

		let tableData = [];
		rounds.forEach((roundNum) => {
			const demand = {};
			const exitBids = {};
			auction.leases.forEach((lease) => {
				const d = history.filter(
					(his) => his.lease_id == lease.id && his.round == roundNum && his.exit == 0
				).length;
				demand[lease.id] = d > 0 ? d : null;
				const e = history.filter(
					(his) => his.lease_id == lease.id && his.round == roundNum && his.exit == 1 && his.carried == 0
				).length;
				exitBids[lease.id] = e > 0 ? e : null;
			});
			tableData.push({ roundNum, demand, exitBids });
		});

		return (
			<div className="card">
				<div className="card-header" style={{ textAlign: "center" }}>
					Demand and Exit Bids
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<table
							className="table table-sm table-bordered"
							id="table-lease-history"
							style={{
								textAlign: "center",
								verticalAlign: "center",
							}}
						>
							<thead>
								<tr>
									<th rowSpan={2}>Round</th>
									{auction.leases ? (
										auction.leases.map(({ id, name }) => (
											<th
												key={id}
												colSpan={2}
												style={{
													minWidth: "125px",
													backgroundColor: leaseStrokes[id] + "60",
												}}
											>
												{name}
											</th>
										))
									) : (
										<></>
									)}
								</tr>
								<tr>
									{auction.leases ? (
										auction.leases.map(({ id, name }) => (
											<>
												<th
													style={{
														backgroundColor: leaseStrokes[id] + "60",
													}}
												>
													Demand
												</th>
												<th
													style={{
														backgroundColor: leaseStrokes[id] + "60",
													}}
												>
													Exit Bids
												</th>
											</>
										))
									) : (
										<></>
									)}
								</tr>
							</thead>
							<tbody>
								{tableData.map(({ roundNum, demand, exitBids }) => (
									<tr key={roundNum}>
										<th>{roundNum}</th>
										{auction.leases ? (
											auction.leases.map(({ id }) => (
												<>
													<td style={this.getDemandStyle(demand[id], leaseStrokes[id])}>
														{" "}
														{demand[id]}{" "}
													</td>
													<td style={this.getExitStyle(exitBids[id], leaseStrokes[id])}>
														{" "}
														{exitBids[id]}{" "}
													</td>
												</>
											))
										) : (
											<></>
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	renderExitBidsGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			demandChartData.push({
				round: `Round ${roundNum}`,
				exitBids: history.filter((his) => his.round == roundNum && his.exit == 1 && his.carried == 0).length,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Exit Bids
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={200}>
						<AreaChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} allowDecimals={false} tickCount={4} />
							<Tooltip />
							<Area type="monotone" dataKey="exitBids" name="Exit Bids" stroke="#999999" fill="#999999" />
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderPercentageIncreaseGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;

		let pricePercentageChangeData = [];
		rounds.forEach((roundNum) => {
			if (roundNum != 1) {
				let roundData = {};
				let total = 0;
				let num = 0;

				auction.leases.forEach((lease) => {
					const d = prices.filter((data) => {
						return data.lease_id == lease.id && data.round == roundNum;
					});
					const e = prices.filter((data) => {
						return data.lease_id == lease.id && data.round == roundNum - 1;
					});
					roundData[lease.id] = null;

					if (d.length > 0 && e.length > 0) {
						roundData[lease.id] =
							(d[0].price - e[0].price) / e[0].price > 0 ? (d[0].price - e[0].price) / e[0].price : null;
						if ((d[0].price - e[0].price) / e[0].price > 0) {
							total += (d[0].price - e[0].price) / e[0].price;
							num += 1;
						}
					}
				});
				pricePercentageChangeData.push({
					round: `Round ${roundNum}`,
					...roundData,
					avg: num > 0 ? total / num : 0,
				});
			}
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Percentage Increments
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={250}>
						<LineChart
							data={pricePercentageChangeData}
							margin={{
								top: 5,
								right: 40,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(value)
								}
							/>
							<Tooltip
								allowEscapeViewBox={{ y: true }}
								formatter={(value) =>
									new Intl.NumberFormat("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(value)
								}
							/>
							<Legend />
							<Line
								name={"Average"}
								dataKey="avg"
								stroke="#999999"
								strokeWidth={1}
								strokeOpacity={0.7}
								strokeDashArray="1 1"
							/>
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderNominalIncreaseGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;

		let priceNominalChangeData = [];
		rounds.forEach((roundNum) => {
			if (roundNum != 1) {
				let roundData = {};
				let total = 0;
				let num = 0;

				auction.leases.forEach((lease) => {
					const d = prices.filter((data) => {
						return data.lease_id == lease.id && data.round == roundNum;
					});
					const e = prices.filter((data) => {
						return data.lease_id == lease.id && data.round == roundNum - 1;
					});
					roundData[lease.id] = null;
					if (d.length > 0 && e.length > 0) {
						roundData[lease.id] = d[0].price - e[0].price > 0 ? d[0].price - e[0].price : null;
						if (d[0].price - e[0].price > 0) {
							total += d[0].price - e[0].price;
							num += 1;
						}
					}
				});
				priceNominalChangeData.push({
					round: `Round ${roundNum}`,
					...roundData,
					avg: num > 0 ? total / num : 0,
				});
			}
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Nominal Increments
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={250}>
						<LineChart
							data={priceNominalChangeData}
							margin={{
								top: 5,
								right: 40,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								allowEscapeViewBox={{ y: true }}
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend />
							<Line
								name={"Average"}
								dataKey="avg"
								stroke="#999999"
								strokeWidth={1}
								strokeOpacity={0.7}
								strokeDashArray="1 1"
							/>
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Lease Areas Summary</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row">{this.renderLeasePriceGraph()}</div>
				<br />
				<div className="row">
					<div className="col-6">{this.renderDemandGraph()}</div>
					<div className="col-6">{this.renderExitBidsGraph()}</div>
				</div>
				<br />
				<div className="row">
					<div className="col-6">{this.renderPercentageIncreaseGraph()}</div>
					<div className="col-6">{this.renderNominalIncreaseGraph()}</div>
				</div>
				<br />
				<hr />
				<br />
				<div className="row">{this.renderLeasePricesTable()}</div>
				<br />
				<div className="row">{this.renderDemandTable()}</div>
				<br />
			</>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

LeaseSummaryDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
};

export default LeaseSummaryDashboard;
