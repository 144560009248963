import {
	GET_TRACKERS,
	GET_TRACKER,
	ADD_ROUND,
	TRACKER_LOADING,
	STOP_TRACKER_LOADING,
	START_TRACKER,
	ADD_BID,
	DELETE_ROUND,
	DELETE_BID,
	RESET_TRACKER,
	UPDATE_PREFERENCES,
	UPDATE_DATA,
	UPDATE_BTT_AUCTION_DATA,
	ERROR,
} from "../actions/types";

const initialState = {
	trackers: [],
	tracker: {},
	tracker_loading: false,
	started: false,
	success: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_TRACKERS:
			return {
				...state,
				trackers: action.payload,
				tracker_loading: false,
				started: false,
			};
		case GET_TRACKER:
			return {
				...state,
				tracker: action.payload,
			};
		case START_TRACKER:
			return {
				...state,
				success: action.payload,
				started: true,
			};
		case UPDATE_PREFERENCES:
			return {
				...state,
				success: action.payload,
			};
		case UPDATE_DATA:
			return {
				...state,
				success: action.payload,
			};
		case ADD_ROUND:
			return {
				...state,
				success: action.payload,
			};
		case ADD_BID:
			return {
				...state,
				success: action.payload,
			};
		case DELETE_ROUND:
			return {
				...state,
				success: action.payload,
			};
		case DELETE_BID:
			return {
				...state,
				success: action.payload,
			};
		case TRACKER_LOADING:
			return {
				...state,
				tracker_loading: true,
			};
		case STOP_TRACKER_LOADING:
			return {
				...state,
				tracker_loading: false,
			};
		case RESET_TRACKER:
			return {
				...state,
				tracker_loading: false,
			};
		case UPDATE_BTT_AUCTION_DATA:
			return {
				...state,
				success: action.payload,
			};
		default:
			return state;
	}
}
