export const AUCTION_NO_CHANGE = "AUCTION_NO_CHANGE";

export const GET_AUCTIONS = "GET_AUCTIONS";
export const GET_AUCTION = "GET_AUCTION";
export const CREATE_AUCTION = "CREATE_AUCTION";
export const UPDATE_AUCTION = "UPDATE_AUCTION";
export const GET_ROUND = "GET_ROUND";
export const GET_RESULT = "GET_RESULT";
export const MAKE_BID = "MAKE_BID";
export const GET_HISTORY = "GET_HISTORY";
export const GET_PRICE_DEMAND_HISTORY = "GET_PRICE_DEMAND_HISTORY";
export const GET_PRICES = "GET_PRICES";
export const END_AUCTION = "END_AUCTION";

export const GET_MPSB_ALL_BIDS = "GET_MPSB_ALL_BIDS";
export const GET_MPSB_ALL_BIDS_FOR_USERS = "GET_MPSB_ALL_BIDS_FOR_USERS";
export const GET_MPSB_USER_BIDS = "GET_MPSB_USER_BIDS";
export const SIMULATE_MPSB_BIDS = "SIMULATE_MPSB_BIDS";

export const WINNER_DETERMINATION_MPSB = "WINNER_DETERMINATION_MPSB";
export const USER_BIDS_REMOVAL_MPSB = "USER_BIDS_REMOVAL_MPSB";

export const GET_RENO_USER_BIDS = "GET_RENO_USER_BIDS";

export const GET_SIMULATIONS = "GET_SIMULATIONS";
export const GET_SIMULATION = "GET_SIMULATION";
export const CREATE_SIMULATION = "CREATE_SIMULATION";
export const UPDATE_SIMULATION = "UPDATE_SIMULATION";
export const RUN_SIMULATION = "RUN_SIMULATION";
export const GET_SIMULATION_RUN = "GET_SIMULATION_RUN";
export const GET_SIMULATION_BIDS = "GET_SIMULATION_BIDS";
export const GET_MARGINAL_BIDS = "GET_MARGINAL_BIDS";
export const GET_STATISTICAL_ANALYSIS = "GET_STATISTICAL_ANALYSIS";

export const RESET_SIMULATION = "RESET_SIMULATION";
export const SIMULATION_LOADING = "SIMULATION_LOADING";
export const SIMULATION_NOT_LOADING = "SIMULATION_NOT_LOADING";

export const DETERMINE_WINNERS = "DETERMINE_WINNERS";
export const GET_WINNING_BIDS = "GET_WINNING_BIDS";
export const GET_BIDDER_PROGRESS = "GET_BIDDER_PROGRESS";
export const SIMULATE_ROUND = "SIMULATE_ROUND";
export const GET_SIMULATION_DATA = "GET_SIMULATION_DATA";
export const GET_BID_CREDITS = "GET_BID_CREDITS";
export const GET_STATE = "GET_STATE";
export const RESTORE_STATE = "RESTORE_STATE";
export const UNDO_USER_ROUND_BIDS = "UNDO_USER_ROUND_BIDS";

export const START_AUCTION = "START_AUCTION";
export const END_ROUND = "END_ROUND";
export const NEXT_ROUND = "NEXT_ROUND";

export const RESET_AUCTION = "RESET_AUCTION";
export const AUCTION_LOADING = "AUCTION_LOADING";
export const STOP_AUCTION_LOADING = "STOP_AUCTION_LOADING";

export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_POINTS = "GET_POINTS";
export const GET_BIDS = "GET_BIDS";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const USER_LOADING = "USER_LOADING";
export const STOP_USER_LOADING = "STOP_USER_LOADING";

export const DATABASE_RESET = "DATABASE_RESET";
export const DATABASE_LOADING = "DATABASE_LOADING";

export const GET_TRACKERS = "GET_TRACKERS";
export const GET_TRACKER = "GET_TRACKER";
export const ADD_ROUND = "ADD_ROUND";
export const ADD_BID = "ADD_BID";
export const DELETE_ROUND = "DELETE_ROUND";
export const DELETE_BID = "DELETE_BID";
export const TRACKER_LOADING = "TRACKER_LOADING";
export const STOP_TRACKER_LOADING = "STOP_TRACKER_LOADING";
export const START_TRACKER = "START_TRACKER";
export const RESET_TRACKER = "RESET_TRACKER";
export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_BTT_AUCTION_DATA = "UPDATE_BTT_AUCTION_DATA";

export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const CONFIG_LOADING = "TRACKER_LOADING";
export const STOP_CONFIG_LOADING = "STOP_TRACKER_LOADING";

export const ERROR = "ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";
export const SOCKET_REJOIN_ROOM = "SOCKET_REJOIN_ROOM";
export const SOCKET_REJOINED_ROOM = "SOCKET_REJOINED_ROOM";
