import authHeaders from "../helpers/authHeaders";
import axiosConn from "../helpers/axios";
import {
	GET_SIMULATIONS,
	GET_SIMULATION,
	CREATE_SIMULATION,
	RUN_SIMULATION,
	GET_SIMULATION_RUN,
	GET_SIMULATION_BIDS,
	GET_MARGINAL_BIDS,
	GET_STATISTICAL_ANALYSIS,
	SIMULATION_LOADING,
	SIMULATION_NOT_LOADING,
	RESET_SIMULATION,
	ERROR,
	UPDATE_SIMULATION,
} from "./types";

export const getSimulations = () => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/simulation`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_SIMULATIONS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getSimulation = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/simulation/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_SIMULATION, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const createSimulation = (payload) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/simulation/create`, payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: CREATE_SIMULATION, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const updateSimulation = (id, payload) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/simulation/${id}/update`, payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: UPDATE_SIMULATION, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const runSimulation = (id, payload) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/simulation/${id}/run`, payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: RUN_SIMULATION, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getSimulationRun = (id, run_id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/simulation/${id}/run/${run_id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_SIMULATION_RUN, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getSimulationBids = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/simulation/${id}/bids`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_SIMULATION_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getMarginalBids = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/simulation/${id}/marginal-bids`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_MARGINAL_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const runStatisticalAnalysis = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(
			`/simulation/${id}/statistical-analysis`,
			{},
			{
				headers: authHeaders(),
			}
		);
		const res = await resReady;
		dispatch({ type: GET_STATISTICAL_ANALYSIS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const setSimulationLoading = () => {
	return {
		type: SIMULATION_LOADING,
	};
};

export const setSimulationNotLoading = () => {
	return {
		type: SIMULATION_NOT_LOADING,
	};
};

export const resetSimulation = () => {
	return {
		type: RESET_SIMULATION,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err,
	};
};
