import { SOCKET_REJOINED_ROOM, SOCKET_REJOIN_ROOM } from "./types";

export const rejoinSocketRoom = () => async (dispatch) => {
	dispatch({
		type: SOCKET_REJOIN_ROOM,
	});
};

export const rejoinedSocketRoom = () => async (dispatch) => {
	dispatch({
		type: SOCKET_REJOINED_ROOM,
	});
};
