import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { useHistory } from "react-router-dom";
import { emitSendReminder, emitMakeAnnouncement, emitTriggerRefresh } from "../../../../helpers/emit/auction";
import {
	updateAuction,
	simulateMpsbBids,
	endAuction,
	determineWinners,
	getAuction,
	getAllMPSBBids,
	getMPSBSimulationData,
	getAllMPSBBidsForUsers,
	getUserMPSBBid,
} from "../../../../actions/auctionActions";

import { getAllUsers } from "../../../../actions/userActions";

export const MPSBAdminPanel = (props) => {
	const [announcementText, setAnnouncementText] = useState("");
	const [asqMw, setAsqMw] = useState("");
	const [asqGwh, setAsqGwh] = useState("");

	let history = useHistory();
	const params = useParams();
	const auction = useSelector((state) => state.auction);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();

	let usersSubmitted = [];
	let submittedUsers = 0;
	let eligibleUsers = user.users.length;
	if (auction.allBids.bids) {
		auction.allBids.bids.forEach((bid) => {
			if (!usersSubmitted.includes(bid.user_id)) {
				usersSubmitted.push(bid.user_id);
				submittedUsers = submittedUsers + 1;
			}
		});
	}
	const usersPerc = (submittedUsers * 100) / eligibleUsers;

	let usersClass =
		"progress-bar progress-bar-striped " +
		(usersPerc >= 50 ? (usersPerc === 100 ? "bg-success" : "bg-warning") : "bg-danger");

	const startAuctionEnabled = auction.auction.status === "Awaiting Start";
	const endAuctionEnabled =
		auction.auction.status === "In Progress" && auction.allBids.bids && auction.allBids.bids.length > 0;
	const determineWinnersEnabled =
		auction.auction.status === "Ended" && auction.allBids.bids && auction.allBids.bids.length > 0;

	const makeAnnouncementEnabled = announcementText.length > 0;

	let user_ids = [];
	user.users.forEach((user) => {
		user_ids.push(user.id);
	});
	auction.allBids.bids ? (
		auction.allBids.bids.forEach((bids) => {
			if (user_ids.includes(bids.user_id)) {
				let new_user_ids = [];
				user_ids.forEach((value) => {
					if (value !== bids.user_id) {
						new_user_ids.push(value);
					}
				});
				user_ids = new_user_ids;
			}
		})
	) : (
		<></>
	);
	let userData = [];
	user.users.forEach((user) => {
		if (user_ids.includes(user.id)) {
			userData.push({ username: user.username, display_name: user.display_name });
		}
	});

	const sendReminderEnabled = auction.auction.status === "In Progress" && userData.length > 0;

	const fetchData = async () => {
		await dispatch(getAuction(params.auctionId));
		if (user.user.type === "admin") {
			await dispatch(getAllMPSBBids(params.auctionId));
			await dispatch(getAllUsers());
			await dispatch(getMPSBSimulationData(params.auctionId));
		}
		if (user.user.type == "user") {
			await dispatch(getAllMPSBBidsForUsers(params.auctionId));
		}
		await dispatch(getUserMPSBBid(params.auctionId));
	};

	const startAuction = () => {
		history.push(`/auctions/start/${params.auctionId}`);
	};

	const endAuctionAction = async () => {
		const result = await confirm("End Auction Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "35vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<div>Are you sure you want to end the auction?</div>
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(endAuction(params.auctionId));
			await fetchData();
			return;
		}
	};

	const determineWinnersAction = async () => {
		await dispatch(determineWinners(params.auctionId));
		await fetchData();
	};

	const updateAuctionAction = async () => {
		await dispatch(
			updateAuction(params.auctionId, {
				asq: Math.max(Math.min(asqMw, 4566.21), 253.68),
			})
		);
		setAsqMw("");
		setAsqGwh("");
		await fetchData();
	};

	const onChangeMW = (event) => {
		let gwhValue = ((event.target.value * 8760 * 0.45) / 1000).toFixed(2);
		setAsqMw(event.target.value);
		setAsqGwh(gwhValue);
	};

	const onChangeGWH = (event) => {
		let mwValue = ((event.target.value * 1000) / (8760 * 0.45)).toFixed(2);
		setAsqMw(mwValue);
		setAsqGwh(event.target.value);
	};

	const triggerRefreshAction = async () => {
		await emitTriggerRefresh({ auction_id: auction.auction.id });
	};

	const simulateBids = async () => {
		const user_ids = [...new Set(auction.simulation_data.map((item) => item.user_id))];
		auction.allBids.bids ? (
			auction.allBids.bids.forEach((bid) => {
				if (user_ids.includes(bid.user_id)) {
					const index = user_ids.indexOf(bid.user_id);
					if (index > -1) {
						user_ids.splice(index, 1);
					}
				}
			})
		) : (
			<></>
		);

		let userData = [];
		user.users.forEach((user) => {
			if (user_ids.includes(user.id)) {
				userData.push({ username: user.username, display_name: user.display_name });
			}
		});

		const result = await confirm("Simulate Round Bids Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to simulate the bids (where possible) for the following users?</p>
								{userData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<tbody>
											{userData.map(({ id, username, display_name }) => (
												<tr>
													<td key={id}>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No simulated users</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await dispatch(simulateMpsbBids(auction.auction.id, user_ids));
			await fetchData();
		}
	};

	const sendReminder = async () => {
		let user_ids = [];
		user.users.forEach((user) => {
			user_ids.push(user.id);
		});

		auction.allBids.bids ? (
			auction.allBids.bids.forEach((bids) => {
				if (user_ids.includes(bids.user_id)) {
					//remove value
					let new_user_ids = [];
					user_ids.forEach((value) => {
						if (value !== bids.user_id) {
							new_user_ids.push(value);
						}
					});
					user_ids = new_user_ids;
				}
			})
		) : (
			<></>
		);

		let userData = [];
		user.users.forEach((user) => {
			if (user_ids.includes(user.id)) {
				userData.push({ username: user.username, display_name: user.display_name });
			}
		});

		const result = await confirm("Send Reminder Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to send a reminder to the following users?</p>
								{userData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<tbody>
											{userData.map(({ id, username, display_name }) => (
												<tr>
													<td key={id}>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No users to remind</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await emitSendReminder({ auction_id: auction.auction.id, user_ids, type: "MPSB" });
		}
	};

	const makeAnnouncement = async () => {
		if (announcementText.length > 0) {
			const result = await confirm("Send Announcement Confirmation", {
				render: (message, onConfirm, onCancel) => {
					return (
						<>
							<div
								className="card"
								style={{
									width: "25vw",
									margin: "auto",
									alignItems: "center",
								}}
							>
								<div
									className="card-body"
									style={{
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<h6 style={{ padding: "10px" }}> {message} </h6>
									<hr />
									<p>Would you like to send the following announcement to all the users?</p>

									<p>
										<strong>
											<em>{announcementText}</em>
										</strong>
									</p>
									<br />
									<div>
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={onConfirm}
											style={{ margin: "10px" }}
										>
											{" "}
											Yes{" "}
										</button>
										<button
											type="button"
											className="btn btn-outline-danger"
											onClick={onCancel}
											style={{ margin: "10px" }}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</>
					);
				},
				closeOnOverlayClick: true,
			});

			if (result) {
				await emitMakeAnnouncement({ auction_id: auction.auction.id, message: announcementText });
				setAnnouncementText("");
			}
		}
	};

	const onAnnouncementChange = (event) => {
		setAnnouncementText(event.target.value);
	};

	return (
		<div>
			<br />
			<div
				className="card"
				style={{
					width: "80%",
					margin: "auto",
					alignItems: "center",
				}}
			>
				<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
					<h6>Admin Panel</h6>
					<div className="row">
						<div className="btn-group flex-nowrap input-group-sm" role="group">
							<button
								type="button"
								disabled={!startAuctionEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => startAuction()}
							>
								Start Auction
							</button>
							<button
								type="button"
								disabled={!endAuctionEnabled}
								className="btn btn-outline-danger btn-sm"
								onClick={() => endAuctionAction()}
							>
								End Auction
							</button>
							<button
								type="button"
								disabled={!determineWinnersEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => determineWinnersAction()}
							>
								Determine Winners
							</button>
						</div>
					</div>
					<hr />
					<div className="input-group flex-nowrap input-group-sm" role="group">
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => updateAuctionAction()}
							style={{ width: "170px" }}
							disabled={auction.status === "Awaiting Start" || !asqMw}
						>
							Update ASQ
						</button>
						<input
							type="number"
							className="form-control form-control-sm"
							name="asq_mw"
							placeholder="New ASQ (MW)"
							value={asqMw}
							onChange={onChangeMW}
						/>
						<input
							type="number"
							className="form-control form-control-sm"
							name="asq_gwh"
							placeholder="New ASQ (GWh/yr)"
							value={asqGwh}
							onChange={onChangeGWH}
						/>
						<span className="input-group-text input-group-sm">
							ASQ: {commaFormat(auction.auction.asq)} MW,{" "}
							{commaFormat(Math.round((auction.auction.asq * 8760 * 0.45) / 1000))} GWh/yr
						</span>
					</div>

					<hr />
					<div className="row">
						<div className="input-group input-group-sm" role="group">
							<button
								type="button"
								className="btn btn-outline-primary btn-sm"
								onClick={() => triggerRefreshAction()}
							>
								Trigger Refresh
							</button>
							<button
								type="button"
								//disabled={this.props.auction.simulated}
								className="btn btn-outline-primary btn-sm"
								onClick={() => simulateBids()}
							>
								Simulate Bids
							</button>
							<button
								type="button"
								disabled={!sendReminderEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => sendReminder()}
							>
								Send Reminder
							</button>
							<button
								type="button"
								disabled={!makeAnnouncementEnabled}
								className="btn btn-outline-primary btn-sm"
								onClick={() => makeAnnouncement()}
							>
								Make Announcement
							</button>
							<input
								type="string"
								className="form-control form-control-sm"
								name="announcement"
								value={announcementText}
								onChange={onAnnouncementChange}
								placeholder="Announcement..."
								style={{ width: "250px" }}
							/>
						</div>
					</div>

					{auction.status === "In Progress" ? (
						<>
							<hr />

							<div className="row">
								<div className="col-2" style={{ textAlign: "right" }}>
									<p className="text-muted" style={{ fontSize: "14px" }}>
										Users [{submittedUsers}/{eligibleUsers}]
									</p>
								</div>
								<div className="col-10">
									<div className="progress" style={{ height: "18px" }}>
										<div
											className={usersClass}
											role="progressbar"
											style={{
												width: `${usersPerc}%`,
											}}
											aria-valuenow={submittedUsers}
											aria-valuemin="0"
											aria-valuemax={eligibleUsers}
										></div>
									</div>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
			<br />
		</div>
	);
};

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat().format(num);
}
