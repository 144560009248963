import React, { Component } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

class ResultsTable extends Component {

	getBidType(bid) {
		if (bid.exit === 0 && bid.combined === 0 && bid.carried === 0 && bid.no_bid === 0) {
			return "Live Bid";
		} else if (bid.exit === 0 && bid.combined === 0 && bid.carried === 1 && bid.no_bid === 0) {
			return "Frozen Live Bid";
		} else if (bid.exit === 0 && bid.combined === 1 && bid.carried === 0 && bid.no_bid === 0) {
			return "Combined Live Bid";
		} else if (bid.exit === 0 && bid.combined === 1 && bid.carried === 1 && bid.no_bid === 0) {
			return "Combined Frozen Live Bid";
		} else if (bid.exit === 1 && bid.combined === 0 && bid.carried === 0 && bid.no_bid === 0) {
			return "Exit Bid";
		} else if (bid.exit === 1 && bid.combined === 0 && bid.carried === 1 && bid.no_bid === 0) {
			return "Frozen Exit Bid";
		} else if (bid.exit === 1 && bid.combined === 1 && bid.carried === 0 && bid.no_bid === 0) {
			return "Combined Exit Bid";
		} else if (bid.exit === 1 && bid.combined === 1 && bid.carried === 1 && bid.no_bid === 0) {
			return "Frozen Combined Exit Bid";
		} else if (bid.exit === 1 && bid.combined === 0 && bid.carried === 0 && bid.no_bid === 1) {
			return "(No Bid) Exit Bid";
		} else if (bid.exit === 1 && bid.combined === 0 && bid.carried === 1 && bid.no_bid === 1) {
			return "Frozen (No Bid) Exit Bid";
		} else {
			return "";
		}
	}

	s2ab(s) {
		let buf = new ArrayBuffer(s.length);
		let view = new Uint8Array(buf);
		for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	}

	exportResultsTable() {
		const wb = XLSX.utils.table_to_book(document.getElementById("results-table"), { sheet: "Results" });
		const wbout = XLSX.write(wb, {
			bookType: "xlsx",
			bookSST: true,
			type: "binary",
		});

		saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Auction Results.xlsx");
	}

	renderWinningBidsTable() {
		const { auction, winning_bids, history } = this.props.auction;
		const { user } = this.props.user;

		if (!winning_bids.bids) {
			return (
				<div>
					<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
						Auction Results
					</div>
					<p>The auction results will be announced soon.</p>
				</div>
			);
		}

		let bid_total = 0;
		if (winning_bids.bids) {
			winning_bids.bids.forEach((bid) => {
				bid_total = bid_total + (bid.amount > 0 ? bid.amount : 0);
			});
		}

		let data = [];
		auction.leases.forEach((lease) => {
			if (winning_bids.bids.filter((bid) => bid.lease_id === lease.id).length > 0) {
				const lease_bid = winning_bids.bids.filter((bid) => bid.lease_id === lease.id)[0];
				const stage = lease_bid.stage_one_bid === 1 ? "Stage 1" : "Stage 2";
				data.push({
					lease_id: lease.id,
					lease_name: lease.name,
					starting_bid: lease.starting_bid,
					bid_username: lease_bid.username,
					bid_user: `${lease_bid.display_name} (${lease_bid.username})`,
					bid_amount: lease_bid.amount,
					bid_financial_exposure: lease_bid.financial_exposure,
					bid_type: this.getBidType(lease_bid),
					stage: stage,
					original_round: lease_bid.original_round,
					acres: lease.acres,
				});
			} else {
				data.push({
					lease_id: lease.id,
					lease_name: lease.name,
					starting_bid: lease.starting_bid,
					bid_username: "-",
					bid_user: "-",
					bid_amount: "",
					bid_financial_exposure: "",
					bid_type: "-",
					stage: "-",
					round: "-",
					acres: "-",
				});
			}
		});

		return (
			<div>
				<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
					Auction Results
				</div>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="table-responsive">
							<table className="table table-sm table-bordered" id="results-table">
								<thead>
									<tr style={{ textAlign: "center" }}>
										<th>Lease Name</th>
										<th>Starting Bid Price</th>
										<th>Bid User</th>
										<th>Imputed Price</th>
										<th>Cash Bid</th>
										<th>$ / Acre</th>
										<th>Bid Type</th>
										{user.type === "admin" ? (
											<>
												<th>Round</th>
												<th>Stage</th>
											</>
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{winning_bids.bids && data ? (
										data.map(
											({
												lease_id,
												lease_name,
												starting_bid,
												bid_username,
												bid_user,
												bid_amount,
												bid_financial_exposure,
												bid_type,
												stage,
												original_round,
												acres,
											}) => (
												<tr
													key={lease_id}
													className={bid_username === user.username ? "table-success" : ""}
												>
													<td>{lease_name}</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{currencyFormat(starting_bid)}
													</td>
													<td>{bid_user}</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_amount ? currencyFormat(bid_amount) : "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_financial_exposure
															? currencyFormat(bid_financial_exposure)
															: "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_financial_exposure && acres
															? currencyFormat(bid_financial_exposure / acres)
															: "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_type}
													</td>
													{user.type === "admin" ? (
														<>
															<td
																style={{
																	textAlign: "center",
																}}
															>
																{original_round}
															</td>
															<td
																style={{
																	textAlign: "center",
																}}
															>
																{stage}
															</td>
														</>
													) : (
														<></>
													)}
												</tr>
											)
										)
									) : (
										<></>
									)}
								</tbody>
								{user.type === "admin" ? (
									<tfoot>
										<tr>
											<th style={{ textAlign: "right" }} colSpan={3}>
												Auction Total
											</th>
											<th style={{ textAlign: "center" }}>
												{currencyFormat(parseInt(bid_total))}
											</th>
										</tr>
									</tfoot>
								) : (
									<></>
								)}
							</table>
						</div>

						{user.type === "admin" ? (
							<div>
								<hr />

								<div className="row">
									<div className="col-8">
										<div className="card">
											<div className="card-body">
												<ul>
													<li>
														<p>
															<strong>Optimal Solution(s): </strong>{" "}
															{winning_bids.optimal_solutions
																? winning_bids.optimal_solutions.length
																: "-"}{" "}
														</p>
													</li>
													<li>
														<p>
															<strong>Time Taken: </strong>{" "}
															{winning_bids.time_taken
																? `${winning_bids.time_taken} seconds`
																: "-"}{" "}
														</p>
													</li>
													{history ? (
														<li>
															<strong>Number of Bids: </strong>
															{history.length}
														</li>
													) : (
														<></>
													)}
												</ul>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div
											className="card"
											style={{
												height: "100%",
												margin: "auto",
												alignItems: "center",
											}}
										>
											<div
												className="card-body"
												style={{
													height: "100%",
													margin: "auto",
													alignItems: "center",
												}}
											>
												<button
													type="button"
													className="btn btn-outline-primary btn-sm"
													onClick={() => this.exportResultsTable()}
												>
													Download Results
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</li>
				</ul>
			</div>
		);
	}

	render() {
		const { auction } = this.props.auction;

		if (auction.round === 0 || auction.status !== "Ended") return <></>;

		return (
			<div className="card" style={{ width: "90%", margin: "auto" }}>
				<div className="card-body">{this.renderWinningBidsTable()}</div>
			</div>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

ResultsTable.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default ResultsTable;
