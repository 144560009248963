import React, { Component } from "react";
import { Redirect } from "react-router";
import { getConfig, updateConfig, setConfigNotLoading } from "../../actions/configActions";
import { leaveAllRooms } from "../../helpers/emit/auction";
import { leaveASTRooms } from "../../helpers/emit/auctionSimulationTool.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirm } from "react-confirm-box";
import { databaseReset } from "../../actions/databaseActions";
import { auctionTypes } from "../common/constants";
import { addNotification } from "../common/NotificationManager";

class Config extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allowUserLogin: false,
			presentationMode: false,
			presentationModeAuctionId: 1,
			configChanged: false,
			resettingDatabase: false,
		};
	}

	componentDidMount() {
		this.componentRefresh();
		this.refresh();
	}

	async componentRefresh() {
		await leaveAllRooms();
		await leaveASTRooms();
	}

	async refresh() {
		await this.props.getConfig();
		await this.props.setConfigNotLoading();

		this.setState({
			...this.state,
			configChanged: false,
			allowUserLogin: this.props.config.allowUserLogin,
			presentationMode: this.props.config.presentationMode,
			presentationModeAuctionId: this.props.config.presentationModeAuctionId,
			auctions: structuredClone(this.props.config.auctions),
		});
	}

	objectsEqual(o1, o2) {
		return typeof o1 === "object" && Object.keys(o1).length > 0
			? Object.keys(o1).length === Object.keys(o2).length &&
					Object.keys(o1).every((p) => (p === "lease_areas" ? true : this.objectsEqual(o1[p], o2[p])))
			: o1 === o2;
	}

	onChange(event) {
		const newState = this.state;

		// Update the corresponding state variables
		if (event.target.name == "allowUserLogin") {
			newState.allowUserLogin = !this.state.allowUserLogin;
		} else if (event.target.name == "presentationMode") {
			newState.presentationMode = !this.state.presentationMode;
		} else if (event.target.name == "presentationModeAuctionId") {
			newState.presentationModeAuctionId = event.target.value;
		} else if (event.target.name == "auctionsHidden") {
			let newAuctions = [];
			this.state.auctions.forEach((auction) => {
				if (auction.id == event.target.value) {
					let newAuction = auction;
					newAuction.hidden = newAuction.hidden == 1 ? 0 : 1;
					newAuctions.push(newAuction);
				} else {
					newAuctions.push(auction);
				}
			});
			newState.auctions = newAuctions;
		} else if (event.target.name == "useBidCredits") {
			let newAuctions = [];
			this.state.auctions.forEach((auction) => {
				if (auction.id == event.target.value) {
					let newAuction = auction;
					newAuction.use_bid_credits = newAuction.use_bid_credits == 1 ? 0 : 1;
					newAuctions.push(newAuction);
				} else {
					newAuctions.push(auction);
				}
			});
			newState.auctions = newAuctions;
		}

		// Check if the config has changed (compared to the config in the DB)
		const config = this.props.config;

		if (
			newState.allowUserLogin == config.allowUserLogin &&
			newState.presentationMode == config.presentationMode &&
			newState.presentationModeAuctionId == config.presentationModeAuctionId &&
			newState.auctions.length === config.auctions.length &&
			newState.auctions.every((o, idx) => this.objectsEqual(o, config.auctions[idx]))
		) {
			newState.configChanged = false;
		} else {
			newState.configChanged = true;
		}

		this.setState({ ...newState });
	}

	async updateConfigValues() {
		const result = await confirm("Admin Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to update the config variables?</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			let auctions = [];
			this.state.auctions.forEach((auction) => {
				auctions.push({
					id: auction.id,
					hidden: auction.hidden,
					use_bid_credits: auction.use_bid_credits,
				});
			});

			const data = {
				allow_user_login: this.state.allowUserLogin ? 1 : 0,
				presentation_mode: this.state.presentationMode ? 1 : 0,
				presentation_mode_auction_id: this.state.presentationModeAuctionId,
				auctions,
			};

			await this.props.updateConfig(data);
			await this.refresh();
			return;
		}
	}

	getAuctionType(type) {
		if (type === auctionTypes.SMRA) return "SMRA";
		else if (type === auctionTypes.MPSB) return "MPSB";
		else if (type === auctionTypes.ENOO) return "ENOO";
		else return "Unknown";
	}

	returnSpinner() {
		return (
			<div className="container">
				<h3>Config</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							Config
						</li>
					</ol>
				</nav>
				<hr />
				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async resetDatabase() {
		const result = await confirm("Admin Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like reset the database?</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			this.setState({ ...this.state, resettingDatabase: true });

			await this.props.databaseReset();
			await this.componentRefresh();
			await this.refresh();

			this.setState({ ...this.state, resettingDatabase: false });
			addNotification({
				title: "Successfully reset the database!",
				duration: 2000,
			});
			return;
		}
	}

	render() {
		const { signedIn, user } = this.props.user;
		if (!signedIn || user.type !== "admin") {
			return <Redirect to="/" />;
		}

		if (this.state.resettingDatabase) {
			return this.returnSpinner();
		}

		if (this.props.config.configLoading) {
			return (
				<div className="container">
					<h3>Config</h3>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active" aria-current="page">
								Config
							</li>
						</ol>
					</nav>
					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Config</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							Config
						</li>
					</ol>
				</nav>
				<hr />
				<br />

				<div>
					<br />
					<div
						className="card"
						style={{
							width: "80%",
							margin: "auto",
							alignItems: "center",
						}}
					>
						<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
							<h6>Admin Panel</h6>
							<div className="btn-group" role="group" aria-label="Basic example">
								<button
									type="button"
									className="btn btn-outline-danger btn-sm"
									onClick={() => this.resetDatabase()}
								>
									Reset Database
								</button>
							</div>
						</div>
					</div>
					<br />
				</div>
				<div className="card" style={{ width: "80%", margin: "auto" }}>
					<div className="card-body">
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								<div className="table-responsive-sm">
									<table className="table table-sm table-bordered">
										<thead
											style={{
												alignItems: "center",
												textAlign: "center",
											}}
										>
											<tr>
												<th colSpan={2}>General Config</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Allow Users to Log in</td>
												<td>
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															value={this.state.allowUserLogin}
															name="allowUserLogin"
															onChange={this.onChange.bind(this)}
															checked={this.state.allowUserLogin}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>Use Presentation Mode</td>
												<td>
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															value={this.state.presentationMode}
															name="presentationMode"
															onChange={this.onChange.bind(this)}
															checked={this.state.presentationMode}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Presentation Mode Auction ID{" "}
													<em>(will only be used if the presentation mode is in use)</em>
												</td>
												<td>
													<div className="input-group input-group-sm">
														<span
															className="input-group-text"
															style={{
																width: "100px",
															}}
														>
															Auction ID
														</span>
														<input
															type="number"
															aria-label="Presentation Mode Auction ID"
															className="form-control"
															name="presentationModeAuctionId"
															value={this.state.presentationModeAuctionId}
															disabled={!this.state.presentationMode}
															onChange={this.onChange.bind(this)}
															style={{
																width: "20px",
															}}
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<br />

								<div className="table-responseive-sm">
									<table className="table table-sm table-bordered">
										<thead
											style={{
												alignItems: "center",
												textAlign: "center",
											}}
										>
											<tr>
												<th colSpan={7}>Auction Config</th>
											</tr>
											<tr>
												<th>ID</th>
												<th>Name</th>
												<th>Type</th>
												<th>Status</th>
												<th>Lease Areas</th>

												<th>Active</th>
												<th>
													Use Bid <br />
													Credits?
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.auctions ? (
												this.state.auctions.map(
													({
														id,
														status,
														name,
														hidden,
														lease_areas,
														use_bid_credits,
														type,
													}) => (
														<tr key={id}>
															<td>{id}</td>
															<td>{name}</td>
															<td>{this.getAuctionType(type)}</td>
															<td>{status}</td>
															<td>{lease_areas}</td>

															<td>
																<div className="form-check">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		value={id}
																		name="auctionsHidden"
																		onChange={this.onChange.bind(this)}
																		checked={hidden == 0}
																	/>
																</div>
															</td>
															<td>
																<div className="form-check">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		value={id}
																		name="useBidCredits"
																		onChange={this.onChange.bind(this)}
																		checked={use_bid_credits == 1}
																	/>
																</div>
															</td>
														</tr>
													)
												)
											) : (
												<></>
											)}
										</tbody>
									</table>
								</div>
								<br />

								<div className="row">
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.updateConfigValues()}
										disabled={!this.state.configChanged}
									>
										Update Config
									</button>
								</div>
								<br />
							</li>

							<li className="list-group-item" style={{ textAlign: "center", fontSize: "15px" }}>
								<p>
									<i>Version: {process.env.GITHUB_SHA}</i>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

Config.propTypes = {
	config: PropTypes.object.isRequired,
	databaseReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	getConfig: PropTypes.func.isRequired,
	updateConfig: PropTypes.func.isRequired,
	setConfigNotLoading: PropTypes.func.isRequired,
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, { getConfig, updateConfig, setConfigNotLoading, databaseReset })(Config);
