import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import socket from "../../../helpers/socket";
import { addNotification } from "../../common/NotificationManager";
import {
	getSimulation,
	updateSimulation,
	runSimulation,
	getSimulationRun,
	getSimulationBids,
	getMarginalBids,
	runStatisticalAnalysis,
	setSimulationLoading,
	setSimulationNotLoading,
} from "../../../actions/simulationActions";
import { setUserLoading, setUserNotLoading } from "../../../actions/userActions";
import { joinASTRoom, emitASTSimulate, emitASTConfigUpdate } from "../../../helpers/emit/auctionSimulationTool";
import util from "../../../util/RENO";
import { confirm } from "react-confirm-box";
import Overview from "./Components/Overview";
import Configuration from "./Components/Configuration";

class RENOSimulation extends Component {
	constructor(props) {
		super(props);
		this.mainFilterValues = {
			DEFAULT: "DEFAULT",
			OVERVIEW: "OVERVIEW",
			CONFIG: "CONFIG",
		};
		this.binSizes = {
			TEN: "0.1",
			TWE: "0.25",
			FIF: "0.5",
			ONE: "1.0",
		};
		this.state = {
			runCount: null,
			mainFilterValue: this.mainFilterValues.OVERVIEW,
			binFilterValue: this.binSizes.ONE,
			summaryScenario: 0,
			bid_config_changed: false,
		};
	}

	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
	}

	async joinSocketRoom() {
		const data = {
			astId: this.props.params.simulationId,
			user: this.props.user.user,
		};
		await joinASTRoom(data);
	}

	async refresh() {
		this.setState({ ...this.state, viewSimulationID: 1 });

		await this.props.setSimulationLoading();
		await this.props.setUserLoading();

		await this.fetchData();

		await this.props.setSimulationNotLoading();
		await this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getSimulation(this.props.params.simulationId);

		const { simulation } = this.props.simulation;
		if (simulation) {
			this.setState({
				...this.state,
				runCount: simulation.runs,
				mainFilterValue: this.mainFilterValues.OVERVIEW,
			});
		}
	}

	async runSimulations() {
		const result = await confirm(`Confirmation`, {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Do you want to run {util.commaFormat(this.state.runCount)} new simulations?</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.setSimulationLoading();
			await this.props.runSimulation(this.props.params.simulationId, { runs: this.state.runCount });
			await this.props.setSimulationNotLoading();

			await this.refresh();
			const data = {
				astId: this.props.params.simulationId,
				simNum: this.state.runCount,
			};
			await emitASTSimulate(data);
			return;
		}
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			mainFilterValue: event.target.value,
		});
	}

	onBinRangeDropDownChange(event) {
		this.setState({
			...this.state,
			binFilterValue: event.target.value,
		});
	}

	onChange(event) {
		let value = event.target.value;
		if (event.target.name === "runCount") {
			if (value > 5000000) value = 5000000;
			value = parseInt(value);
		}
		this.setState({ ...this.state, [event.target.name]: value || null });
	}

	getDashboard() {
		const { simulation } = this.props.simulation;
		const { user } = this.props.user;
		if (this.state.mainFilterValue === this.mainFilterValues.OVERVIEW)
			return (
				<Overview
					simulation={simulation}
					user={user}
					binSizes={this.binSizes}
					binSize={this.state.binFilterValue}
				/>
			);
		else if (this.state.mainFilterValue === this.mainFilterValues.CONFIG) {
			return <Configuration simulation={simulation} user={user} refresh={() => this.fetchData()} />;
		}
		return <></>;
	}

	returnSpinner() {
		const { simulation } = this.props.simulation;

		return (
			<div className="container">
				{simulation && simulation.name ? <h3>{simulation.name}</h3> : <></>}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{simulation && simulation.name ? simulation.name : ""}
						</li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { simulation, simulation_loading } = this.props.simulation;
		const { user, signedIn, user_loading } = this.props.user;

		if (!signedIn) {
			return <Redirect to="/" />;
		}

		if (simulation_loading || user_loading || Object.keys(simulation).length === 0) {
			return this.returnSpinner();
		}

		const userConfig = simulation.simConfig ? simulation.simConfig.filter((config) => config.owner === 1)[0] : null;

		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});

		socket.off("ast_refresh").on("ast_refresh", (data) => {
			this.refresh();
			if (data.message) {
				addNotification({
					title: data.title,
					message: data.message,
					type: "info",
					duration: 7000,
				});
			}
		});

		return (
			<div className="container">
				<h3>{simulation.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && user.type === "user" ? (
								<>Simulations</>
							) : (
								<Link to="/simulations">Simulations</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{simulation.name}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
				</div>
				<br />

				<div className="card" style={{ margin: "auto" }}>
					<div className="card-body" style={{ padding: "2%" }}>
						<div className="row">
							<div className="col-6">
								<select
									value={this.state.mainFilterValue}
									onChange={(e) => this.onMainDropDownChange(e)}
									style={{
										width: "150px",
										padding: "6px",
										marginLeft: "5px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
									}}
								>
									<option value="default" disabled={true}>
										Select Dashboard
									</option>
									<hr />
									<option value={this.mainFilterValues.OVERVIEW}>Overview</option>
									{/* <hr />
										<option value="summaryScenarios">Auction Outcome</option> */}
									<hr />
									<option value={this.mainFilterValues.CONFIG}>Configuration</option>
								</select>
								{this.state.mainFilterValue === this.mainFilterValues.OVERVIEW ? (
									<select
										value={this.state.binFilterValue}
										onChange={(e) => this.onBinRangeDropDownChange(e)}
										style={{
											width: "100px",
											padding: "6px",
											marginLeft: "5px",
											borderRadius: "4px",
											fontSize: "14px",
											borderColor: "#0275d8",
											color: "#0275d8",
										}}
									>
										<option value="default" disabled={true}>
											Bin Range
										</option>
										<hr />
										<option value={this.binSizes.TEN}>0.1</option>
										{/* <option value={this.binSizes.TWE}>0.25</option> */}
										<option value={this.binSizes.FIF}>0.5</option>
										<option value={this.binSizes.ONE}>1.0</option>
									</select>
								) : (
									<></>
								)}
							</div>
							<div
								className="col-6"
								style={{
									textAlign: "right",
									alignItems: "right",
								}}
							>
								<div
									className="input-group input-group-sm"
									role="group"
									style={{ justifyContent: "flex-end" }}
								>
									{/* <button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.runSimulations()}
										style={{ width: "170px" }}
										disabled={
											!this.state.runCount ||
											this.state.mainFilterValue === this.mainFilterValues.CONFIG
										}
									>
										Run New Simulations
									</button>
									<input
										type="number"
										className="form-control form-control-sm"
										name="runCount"
										placeholder="New Run Count"
										value={this.state.runCount}
										onChange={this.onChange.bind(this)}
										style={{ maxWidth: "200px" }}
										disabled={this.state.mainFilterValue === this.mainFilterValues.CONFIG}
									/> */}
									<span className="input-group-text input-group-sm">
										Current Run Count: {util.commaFormat(simulation.runs) || 0}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				{this.getDashboard()}

				<br />
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <RENOSimulation {...props} params={params} />;
}

RENOSimulation.propTypes = {
	getSimulation: PropTypes.func.isRequired,
	setSimulationLoading: PropTypes.func.isRequired,
	setSimulationNotLoading: PropTypes.func.isRequired,
	updateSimulation: PropTypes.func.isRequired,
	runSimulation: PropTypes.func.isRequired,
	getSimulationRun: PropTypes.func.isRequired,
	getSimulationBids: PropTypes.func.isRequired,
	getMarginalBids: PropTypes.func.isRequired,
	getStatisticalAnalysis: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	simulation: state.simulation,
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, {
	getSimulation,
	updateSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
	runSimulation,
	getSimulationRun,
	getSimulationBids,
	getMarginalBids,
	getStatisticalAnalysis: runStatisticalAnalysis,
	setUserLoading,
	setUserNotLoading,
})(WithParamsAndNavigate);
