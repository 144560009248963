import Plot from "react-plotly.js";

export const MPSBResultsChart = (props) => {
	const { asq, bids, title, users, user, unit, toggleUnit } = props;

	let winnerTrace = { x: [], y: [], width: [], name: [], deq: [], deqX: [] };
	let loserTrace = { x: [], y: [], width: [], name: [], deq: [], deqX: [] };
	let ownerWinnerTrace = { x: [], y: [], width: [], name: [], deq: [], deqX: [] };
	let ownerLoserTrace = { x: [], y: [], width: [], name: [], deq: [], deqX: [] };
	let quantity_sum = 0;
	let deq_sum = 0;

	bids.forEach((bid) => {
		let username = users
			? users.find((user) => user.id === bid.user_id).display_name
			: bid.username
			? bid.username
			: bid.user_id === user.user_id
			? "Your Bid"
			: "Other Bidder";
		let owner = user ? bid.user_id === user.user_id : bid.owner;
		let deq = (bid.quantity * 8760 * 0.45) / 1000;
		if (bid.winner) {
			if (owner) {
				ownerWinnerTrace.x.push(quantity_sum + bid.quantity / 2);
				ownerWinnerTrace.deq.push(deq);
				ownerWinnerTrace.deqX.push(deq_sum + deq / 2);
				ownerWinnerTrace.y.push(bid.price);
				ownerWinnerTrace.width.push(bid.quantity);
				ownerWinnerTrace.name.push(username);
			} else {
				winnerTrace.x.push(quantity_sum + bid.quantity / 2);
				winnerTrace.deq.push(deq);
				winnerTrace.deqX.push(deq_sum + deq / 2);
				winnerTrace.y.push(bid.price);
				winnerTrace.width.push(bid.quantity);
				winnerTrace.name.push(username);
			}
		} else {
			if (owner) {
				ownerLoserTrace.x.push(quantity_sum + bid.quantity / 2);
				ownerLoserTrace.deq.push(deq);
				ownerLoserTrace.deqX.push(deq_sum + deq / 2);
				ownerLoserTrace.y.push(bid.price);
				ownerLoserTrace.width.push(bid.quantity);
				ownerLoserTrace.name.push(username);
			} else {
				loserTrace.x.push(quantity_sum + bid.quantity / 2);
				loserTrace.deq.push(deq);
				loserTrace.deqX.push(deq_sum + deq / 2);
				loserTrace.y.push(bid.price);
				loserTrace.width.push(bid.quantity);
				loserTrace.name.push(username);
			}
		}
		quantity_sum += bid.quantity;
		deq_sum += deq;
	});

	var switchIcon = {
		width: 500,
		height: 600,
		path: "M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z",
	};

	let data = [
		{
			name: "Your Winning Bids",
			type: "bar",
			x: unit === "MW" ? ownerWinnerTrace.x : ownerWinnerTrace.deqX,
			y: ownerWinnerTrace.y,
			width: unit === "MW" ? ownerWinnerTrace.width : ownerWinnerTrace.deq,
			text: ownerWinnerTrace.name,
			hovertext: ownerWinnerTrace.deq,
			hovertemplate:
				"<b>%{text}</b><br>" +
				"Price: %{y:.2f} €/MWh<br>" +
				"Offer Quantity: %{width:.2f} MW<br>" +
				"Deemed Energy Quantity: %{hovertext:.2f} GWh/yr<br>" +
				"<extra></extra>",
			marker: {
				color: "white",
				line: {
					color: "rgb(0,0,0)",
					width: 0.1,
				},
				pattern: {
					shape: "/",
					bgcolor: "rgb(158,202,225)",
				},
			},
		},
		{
			name: "Your Losing Bids",
			type: "bar",
			x: unit === "MW" ? ownerLoserTrace.x : ownerLoserTrace.deqX,
			y: ownerLoserTrace.y,
			width: unit === "MW" ? ownerLoserTrace.width : ownerLoserTrace.deq,
			text: ownerLoserTrace.name,
			hovertext: ownerLoserTrace.deq,
			hovertemplate:
				"<b>%{text}</b><br>" +
				"Price: %{y:.2f} €/MWh<br>" +
				"Offer Quantity: %{width:.2f} MW<br>" +
				"Deemed Energy Quantity: %{hovertext:.2f} GWh/yr<br>" +
				"<extra></extra>",
			marker: {
				color: "white",
				line: {
					color: "rgb(0,0,0)",
					width: 0.1,
				},
				pattern: {
					shape: "/",
					bgcolor: "rgb(192,192,192)",
				},
			},
		},
		{
			name: "Winning Bids",
			type: "bar",
			x: unit === "MW" ? winnerTrace.x : winnerTrace.deqX,
			y: winnerTrace.y,
			width: unit === "MW" ? winnerTrace.width : winnerTrace.deq,
			text: winnerTrace.name,
			hovertext: winnerTrace.deq,
			hovertemplate:
				"<b>%{text}</b><br>" +
				"Price: %{y:.2f} €/MWh<br>" +
				"Offer Quantity: %{width:.2f} MW<br>" +
				"Deemed Energy Quantity: %{hovertext:.2f} GWh/yr<br>" +
				"<extra></extra>",
			marker: {
				color: "rgb(158,202,225)",
				opacity: 0.5,
				line: {
					color: "rgb(0,0,0)",
					width: 0.1,
				},
			},
		},
		{
			name: "Losing Bids",
			type: "bar",
			x: unit === "MW" ? loserTrace.x : loserTrace.deqX,
			y: loserTrace.y,
			width: unit === "MW" ? loserTrace.width : loserTrace.deq,
			text: loserTrace.name,
			hovertext: loserTrace.deq,
			hovertemplate:
				"<b>%{text}</b><br>" +
				"Price: %{y:.2f} €/MWh<br>" +
				"Offer Quantity: %{width:.2f} MW<br>" +
				"Deemed Energy Quantity: %{hovertext:.2f} GWh/yr<br>" +
				"<extra></extra>",
			marker: {
				color: "rgb(192,192,192)",
				opacity: 0.5,
				line: {
					color: "rgb(0,0,0)",
					width: 0.1,
				},
			},
		},
	];

	let shapes = [
		{
			type: "line",
			yref: "paper",
			y0: 0,
			y1: 0.95,
			x0: unit === "MW" ? asq : Math.round((asq * 8760 * 0.45) / 1000),
			x1: unit === "MW" ? asq : Math.round((asq * 8760 * 0.45) / 1000),
			line: {
				color: "red",
				width: 2,
				dash: "dot",
			},
		},
	];

	let annotations = [
		{
			x: unit === "MW" ? asq : Math.round((asq * 8760 * 0.45) / 1000),
			y: 0.975,
			xref: "x",
			yref: "paper",
			text:
				unit === "MW"
					? `ASQ = ${commaFormat(asq)} MW`
					: `ASQ = ${commaFormat(Math.round((asq * 8760 * 0.45) / 1000))} GWh/yr`,
			ax: 0,
			ay: 0,
		},
	];

	let layout = {
		title: title,
		legend: {
			x: 0.025,
			xanchor: "left",
			yanchor: "bottom",
			y: 1,
		},
		hoverlabel: { bgcolor: "#FFF" },
		autosize: true,
		yaxis: { fixedrange: true, title: "Offer Price (€/MWh)" },
		xaxis: {
			fixedrange: true,
			title: unit === "MW" ? "Offer Quantity" : "Deemed Energy Quantity",
			exponentformat: "none",
			ticksuffix: unit === "MW" ? " MW" : " GWh/yr",
		},
		shapes: shapes,
		annotations: annotations,
	};

	let config = {
		displaylogo: false,
		displayModeBar: true,
		modeBarButtonsToRemove: ["lasso2d", "select2d"],
		toImageButtonOptions: {
			format: "png", // one of png, svg, jpeg, webp
			filename: `Simulation Results Graph`,
			scale: 1,
		},
	};

	if (toggleUnit) {
		config = {
			...config,
			modeBarButtonsToAdd: [
				{
					name: `Switch between MW and GWh/yr`,
					icon: switchIcon,
					click: toggleUnit,
				},
			],
		};
	}

	return (
		<Plot data={data} layout={layout} config={config} useResizeHandler style={{ width: "100%", height: "100%" }} />
	);
};

export const MPSBResultsTable = (props) => {
	const { bids, users } = props;
	return (
		<div className="table-responsive">
			<table className="table table-sm table-bordered mx-auto w-auto" style={{ textAlign: "center" }}>
				<thead>
					<tr>
						<th
							style={{
								margin: `auto`,
								minWidth: `225px`,
							}}
						></th>
						<th
							className="table-success"
							style={{
								margin: `auto`,
								minWidth: `150px`,
							}}
						>
							Total Cleared
						</th>
						{bids.map(({ id, username, winner, user_id }) => (
							<th
								key={id}
								className={winner ? "table-primary" : "table-secondary"}
								style={{
									margin: `auto`,
									minWidth: `150px`,
								}}
							>
								{user_id ? users.find((user) => user.id === user_id).display_name : username}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						<th
							style={{
								margin: `auto`,
							}}
						>
							Offer Price
						</th>
						<td>-</td>
						{bids.map(({ id, price }) => (
							<td
								key={id}
								style={{
									margin: `auto`,
								}}
							>
								{price.toFixed(2)} €/MWh
							</td>
						))}
					</tr>
					<tr>
						<th
							style={{
								margin: `auto`,
							}}
						>
							Distance to Marginal
						</th>
						<td>-</td>
						{bids && bids.length > 0 ? (
							bids.find((bid) => bid.winner) ? (
								bids.map(({ id, price, winner }) => (
									<td
										key={id}
										style={{
											margin: `auto`,
										}}
									>
										{winner ? "+" : ""}
										{(
											(winner
												? bids.find((bid) => !bid.winner)?.price
												: bids.find((bid) => bid.marginal)?.price) - price
										).toFixed(2)}{" "}
										€/MWh
									</td>
								))
							) : (
								bids.map(({ id }) => <td key={id}>-</td>)
							)
						) : (
							<></>
						)}
					</tr>
					<tr>
						<th
							style={{
								margin: `auto`,
							}}
						>
							Offer Quantity
						</th>
						<td>
							{commaFormat(
								bids.filter((bid) => bid.winner).reduce((prev, curr) => prev + curr.quantity, 0)
							)}{" "}
							MW
						</td>
						{bids.map(({ id, quantity }) => (
							<td
								key={id}
								style={{
									margin: `auto`,
								}}
							>
								{commaFormat(quantity)} MW
							</td>
						))}
					</tr>
					<tr>
						<th
							style={{
								margin: `auto`,
							}}
						>
							Deemed Energy Quantity
						</th>
						<td>
							{commaFormat(
								bids
									.filter((bid) => bid.winner)
									.reduce((prev, curr) => prev + Math.round((curr.quantity * 8760 * 0.45) / 1000), 0)
							)}{" "}
							GWh/yr
						</td>
						{bids.map(({ id, quantity }) => (
							<td
								key={id}
								style={{
									margin: `auto`,
								}}
							>
								{commaFormat((quantity * 8760 * 0.45) / 1000)} GWh/yr
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(num);
}
