import React, { Component } from "react";
import { connect } from "react-redux";
import { getTrackers, resetTracker } from "../../actions/trackerActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { leaveAllRooms } from "../../helpers/emit/tracker";
import { leaveASTRooms } from "../../helpers/emit/auctionSimulationTool";
import { confirm } from "react-confirm-box";
import socket from "../../helpers/socket";

class BidTrackers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered_user_id: -1,
			filtered_status: "All",
		};

		this.onUserDropDownChange = this.onUserDropDownChange.bind(this);
		this.onStatusDropDownChange = this.onStatusDropDownChange.bind(this);
	}

	componentDidMount() {
		this.refresh();
	}

	async refresh() {
		await leaveAllRooms();
		await leaveASTRooms();
		await this.props.getTrackers();
		this.setState({ ...this.state, filtered_user_id: -1 });
	}

	async resetTracker(name, id) {
		const result = await confirm("Reset Tracker Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>
									Are you sure you would like to reset '<strong>{name}</strong>'
								</p>
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.resetTracker(id);
			await this.refresh();
		}
	}

	onUserDropDownChange(event) {
		this.setState({ ...this.state, filtered_user_id: event.target.value });
	}

	onStatusDropDownChange(event) {
		this.setState({ ...this.state, filtered_status: event.target.value });
	}

	viewBidTracker(id) {
		this.props.history.push(`/bidtrackers/${id}`);
	}

	goToStartTracker(id) {
		this.props.history.push(`/bidtrackers/${id}/start`);
	}

	getAdminConsole() {
		const { user } = this.props.user;
		const { trackers } = this.props.tracker;

		if (user.type !== "admin") {
			return <></>;
		}

		let users = [];
		trackers.forEach((tracker) => {
			const found = users.some((el) => "username" in el && el.username === tracker.username);
			if (!found)
				users.push({
					user_id: tracker.user_id,
					username: tracker.username,
					display_name: tracker.display_name,
				});
		});

		return (
			<div>
				<br />
				<div
					className="card"
					style={{
						width: "80%",
						margin: "auto",
						alignItems: "center",
					}}
				>
					<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
						<h6>Admin Panel</h6>

						<select
							value={this.state.filtered_user_id}
							onChange={(e) => this.onUserDropDownChange(e)}
							style={{
								width: "300px",
								padding: "6px",
								borderRadius: "4px",
								fontSize: "14px",
								borderColor: "#0275d8",
								color: "#0275d8",
							}}
						>
							<option value={-1}>All Users</option>
							{users && users.length > 0 ? (
								users.map(({ user_id, username, display_name }) => (
									<option key={user_id} value={user_id}>
										{display_name} ({username})
									</option>
								))
							) : (
								<></>
							)}
						</select>
						<select
							value={this.state.filtered_status}
							onChange={(e) => this.onStatusDropDownChange(e)}
							style={{
								width: "150px",
								marginLeft: "5px",
								padding: "6px",
								borderRadius: "4px",
								fontSize: "14px",
								borderColor: "#0275d8",
								color: "#0275d8",
							}}
						>
							<option value="All">All Statuses</option>
							<option value="In Progress">In Progress</option>
							<option value="Awaiting Start">Awaiting Start</option>
						</select>
					</div>
				</div>
				<br />
			</div>
		);
	}

	render() {
		const { signedIn, user } = this.props.user;
		const { trackers, tracker_loading } = this.props.tracker;

		let userTrackers = [];
		if (user.type == "admin") {
			if (this.state.filtered_user_id < 0) {
				userTrackers = trackers;
			} else {
				userTrackers = trackers.filter((tracker) => tracker.user_id == this.state.filtered_user_id);
			}
			if (this.state.filtered_status == "Awaiting Start" || this.state.filtered_status == "In Progress") {
				userTrackers = userTrackers.filter((tracker) => tracker.status == this.state.filtered_status);
			}
		} else {
			userTrackers = trackers.filter((tracker) => tracker.user_id == user.user_id);
		}

		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.refresh();
		});

		if (!signedIn) {
			return <Redirect to="/" />;
		}

		if (tracker_loading) {
			return (
				<div className="container">
					<h3>Bid Trackers</h3>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active" aria-current="page">
								Bid Trackers
							</li>
						</ol>
					</nav>
					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Bid Trackers</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							Bid Trackers
						</li>
					</ol>
				</nav>
				<hr />
				<div className="text-right" style={{ alignItems: "right", marginRight: 0 }}>
					<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
						<i className="fas fa-sync"></i> Data Reload
					</button>
				</div>
				<br />

				{this.getAdminConsole()}

				<div className="container">
					<table className="table">
						<thead>
							<tr>
								<th>Trackers {user.type == "admin" ? `(${userTrackers.length})` : ""} </th>
								{user.type == "admin" ? <th>User</th> : <></>}
								<th>Status</th>
								{user.type == "admin" ? <th>Active</th> : <></>}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{userTrackers && userTrackers.length > 0 ? (
								userTrackers.map(({ id, name, status, username, display_name, hidden }) => (
									<>
										{hidden == 1 && user.type == "user" ? (
											<></>
										) : (
											<tr key={id}>
												<td>{name}</td>
												{user.type == "admin" ? (
													<td>
														{display_name} ({username})
													</td>
												) : (
													<></>
												)}
												<td>{status}</td>
												{user.type == "admin" ? (
													<td>
														<div className="form-check">
															<input
																className="form-check-input"
																type="checkbox"
																checked={hidden == 0}
																disabled
															/>
														</div>
													</td>
												) : (
													<></>
												)}
												<td>
													<button
														type="button"
														className="btn btn-outline-primary btn-sm"
														disabled={status !== "Awaiting Start" ? false : true}
														onClick={() => this.viewBidTracker(id)}
													>
														<i className="fas fa-external-link-alt"></i>
													</button>

													<button
														type="button"
														className="btn btn-outline-success btn-sm"
														disabled={status == "Awaiting Start" ? false : true}
														onClick={() => this.goToStartTracker(id)}
														style={{
															marginLeft: "5px",
														}}
													>
														<i className="fas fa-play"></i>
													</button>

													<button
														type="button"
														className="btn btn-outline-danger btn-sm"
														disabled={status !== "Awaiting Start" ? false : true}
														onClick={() => this.resetTracker(name, id)}
														style={{
															marginLeft: "5px",
														}}
													>
														<i className="fas fa-redo-alt"></i>
													</button>
												</td>
											</tr>
										)}
									</>
								))
							) : (
								<tr>
									<td colSpan={3}>No trackers to display</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

BidTrackers.propTypes = {
	user: PropTypes.object.isRequired,
	tracker: PropTypes.object.isRequired,
	getTrackers: PropTypes.func.isRequired,
	resetTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	tracker: state.tracker,
});

export default withRouter(connect(mapStateToProps, { getTrackers, resetTracker })(BidTrackers));
