import React, { Component } from "react";
import PropTypes from "prop-types";
import util from "../../../../util/RENO";

class UserBidHistoryTable extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { userBids, auction } = this.props.auction;
		const { user } = this.props.user;

		const sortedUserBids = userBids.sort((a, b) => b.time - a.time);
		const showRank = auction.showRanksToBidders == 1;

		return (
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header" style={{ textAlign: "center", height: "2.5rem" }}>
							<h5>{user.display_name ? `${user.display_name}'s ` : ""} Bidding History</h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								{sortedUserBids.length == 0 ? (
									<p>Submit bids to see the history</p>
								) : (
									<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
										<thead>
											<th style={{ width: "20%" }}>Bid Time</th>
											<th style={{ width: "20%" }}>Bid Amount</th>
											<th style={{ width: "20%" }}>Bid Decrement</th>
											{showRank ? <th style={{ width: "15%" }}>Rank (On Submission)</th> : <></>}
											<th style={{ width: "15%" }}>Time Since Start</th>
											<th style={{ width: "10%" }}>Extended?</th>
										</thead>
										<tbody>
											{sortedUserBids.map(
												({
													id,
													time,
													amount,
													decrement,
													canUndo,
													winner,
													increasedDuration,
													rankOnSubmission,
												}) => (
													<tr
														key={id}
														className={
															winner ? "table-success" : canUndo ? "table-active" : ""
														}
													>
														<td>{util.formatTime(time)}</td>
														<td>
															NOK <strong>{util.formatCurrency(amount)}</strong> øre/kWh
														</td>
														<td>
															{decrement ? (
																<>
																	NOK{" "}
																	<strong>{util.formatCurrency(decrement)}</strong>{" "}
																	øre/kWh
																</>
															) : (
																<>-</>
															)}
														</td>
														{showRank ? <td>{rankOnSubmission}</td> : <></>}
														<td>{util.formatDuration(time - auction.startTime)}</td>
														<td>{increasedDuration == 0 ? "-" : "Yes"}</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								)}
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

UserBidHistoryTable.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default UserBidHistoryTable;
