import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	BarChart,
	Bar,
	LabelList,
	LineChart,
	Line,
	AreaChart,
	Area,
} from "recharts";
import { COLORS } from "../../common/constants";

class AuctionDashboard extends Component {
	constructor(props) {
		super(props);
	}

	renderCustomizedLabel(props) {
		const { x, y, width, value } = props;
		const radius = 10;

		return (
			<g>
				<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#999999" />
				<text
					x={x + width / 2}
					y={y - radius}
					fill="#fff"
					textAnchor="middle"
					dominantBaseline="middle"
					style={{ fontSize: "12px" }}
				>
					{value}
				</text>
			</g>
		);
	}

	renderUserProgressGraph() {
		const { history } = this.props.auction;
		const rounds = this.props.rounds;

		let progressChartData = [];
		rounds.forEach((roundNum) => {
			const clb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 0).length;
			const ceb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 0).length;
			const flb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 1).length;
			let t = 14;
			let r = 0;
			if (roundNum > 1) {
				t = history.filter((his) => his.round === roundNum - 1 && his.exit == 0).length;
				r = t - (clb + ceb + flb);
			}
			progressChartData.push({
				round: `Round ${roundNum}`,
				totalUsers: clb + ceb + flb > 0 ? clb + ceb + flb : 0,
				liveUsers: clb > 0 ? clb : null,
				exitUsers: ceb > 0 ? ceb : null,
				frozenUsers: flb > 0 ? flb : null,
				remaining: r > 0 ? r : null,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center", alignItems: "center" }}>
					User Progress
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={150}>
						<BarChart
							data={progressChartData}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} tickCount={4} />
							<Tooltip />
							<Bar
								type="monotone"
								dataKey="frozenUsers"
								name="Frozen Users"
								stackId="1"
								stroke="#0275d8"
								fill="#0275d8"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="liveUsers"
								name="Live Users"
								stackId="1"
								stroke="#5cb85c"
								fill="#5cb85c"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="exitUsers"
								name="Exit Users"
								stackId="1"
								stroke="#f0ad4e"
								fill="#f0ad4e"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="remaining"
								name="Remaining Bids"
								stackId="1"
								stroke="#999999"
								fill="#999999"
								fillOpacity={0.8}
							>
								<LabelList dataKey="totalUsers" content={this.renderCustomizedLabel}></LabelList>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderBidderProgressGraph() {
		const { history } = this.props.auction;
		const rounds = this.props.rounds;

		let progressChartData = [];
		rounds.forEach((roundNum) => {
			const clb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 0).length;
			const ceb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 0).length;
			const flb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 1).length;
			const feb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 1).length;
			let t = 14;
			let r = 0;
			if (roundNum > 1) {
				t = history.filter((his) => his.round === roundNum - 1 && his.exit == 0).length;
				r = t - (clb + ceb + flb + feb);
			}
			progressChartData.push({
				round: `Round ${roundNum}`,
				totalBids: clb + ceb + flb + feb > 0 ? clb + ceb + flb + feb : 0,
				liveBids: clb > 0 ? clb : null,
				exitBids: ceb > 0 ? ceb : null,
				frozenLiveBids: flb > 0 ? flb : null,
				frozenExitBids: feb > 0 ? feb : null,
				remaining: r > 0 ? r : null,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center", alignItems: "center" }}>
					Bids Progress
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={150}>
						<BarChart
							data={progressChartData}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} tickCount={4} />
							<Tooltip />
							<Bar
								type="monotone"
								dataKey="frozenLiveBids"
								name="Frozen Live Bids"
								stackId="1"
								stroke="#0275d8"
								fill="#0275d8"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="frozenExitBids"
								name="Frozen Exit Bids"
								stackId="1"
								stroke="#5bc0de"
								fill="#5bc0de"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="liveBids"
								name="Live Bids"
								stackId="1"
								stroke="#5cb85c"
								fill="#5cb85c"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="exitBids"
								name="Exit Bids"
								stackId="1"
								stroke="#f0ad4e"
								fill="#f0ad4e"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="remaining"
								name="Remaining Bids"
								stackId="1"
								stroke="#999999"
								fill="#999999"
								fillOpacity={0.8}
							>
								<LabelList dataKey="totalBids" content={this.renderCustomizedLabel}></LabelList>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderLeasePriceGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;

		let pricesChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = prices.filter((p) => p.lease_id === lease.id && p.round === roundNum);
				data[lease.id] = d.length > 0 ? d[0].price : 0;
			});
			pricesChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Lease Area Clock Prices
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={225}>
						<LineChart
							data={pricesChartData}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	renderDemandGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = history.filter((his) => his.lease_id == lease.id && his.round == roundNum && his.exit == 0);
				data[lease.id] = d.length;
			});
			demandChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Demand (Live Bids)
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={150}>
						<LineChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis tickCount={3} />
							<Tooltip />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderExitBidsGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			demandChartData.push({
				round: `Round ${roundNum}`,
				exitBids: history.filter((his) => his.round == roundNum && his.exit == 1 && his.carried == 0).length,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Exit Bids
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={150}>
						<AreaChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} allowDecimals={false} tickCount={4} />
							<Tooltip />
							<Area type="monotone" dataKey="exitBids" name="Exit Bids" stroke="#999999" fill="#999999" />
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Auction Summary</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row">
					<div className="col-6"> {this.renderUserProgressGraph()} </div>
					<div className="col-6"> {this.renderBidderProgressGraph()} </div>
				</div>
				<br />
				<div className="row">{this.renderLeasePriceGraph()}</div>
				<br />
				<div className="row">
					<div className="col-6">{this.renderDemandGraph()}</div>
					<div className="col-6">{this.renderExitBidsGraph()}</div>
				</div>
				<br />
			</>
		);
	}
}

AuctionDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default AuctionDashboard;
