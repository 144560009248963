import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../../../actions/userActions";
import { useParams, useHistory } from "react-router-dom";
import { confirm } from "react-confirm-box";
import {
	updateAuction,
	endAuction,
	determineWinners,
	nextRound,
	makeENOOProxyBid,
} from "../../../../actions/auctionActions";
import { emitSendReminder, emitMakeAnnouncement, emitTriggerRefresh } from "../../../../helpers/emit/auction";
import RAButton from "../../../common/RAButton/RAButton";

export const ENOOAdminPanel = (props) => {
	const { refresh } = props;

	const [newDuration, setNewDuration] = useState({ minutes: "", seconds: "" });
	const [nextRoundParams, setNextRoundParams] = useState({ increment: "", price: "" });
	const [proxyBidParams, setProxyBidParams] = useState({ increment: "", price: "", user_id: 0 });
	const [announcement, setAnnouncement] = useState("");

	const dispatch = useDispatch();
	const { auctionId } = useParams();
	const history = useHistory();

	const auction = useSelector((state) => state.auction.auction);
	const round = useSelector((state) => state.auction.round);
	const users = useSelector((state) => state.user.users);

	useEffect(() => {
		dispatch(getAllUsers());
	}, [dispatch]);

	const sendReminder = async () => {
		let userData = users.map((user) => ({ username: user.username, display_name: user.display_name }));

		const result = await confirm("Send Reminder Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to send a reminder to the following users?</p>
								{userData.length > 0 ? (
									<table className="table table-bordered table-sm">
										<tbody>
											{userData.map(({ username, display_name }) => (
												<tr>
													<td>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No users to remind</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await emitSendReminder({
				auction_id: auctionId,
				user_ids: users.map((user) => user.id),
				type: "ENOO",
			});
		}
	};

	const makeAnnouncement = async () => {
		if (announcement.length > 0) {
			const result = await confirm("Send Announcement Confirmation", {
				render: (message, onConfirm, onCancel) => {
					return (
						<>
							<div
								className="card"
								style={{
									width: "25vw",
									margin: "auto",
									alignItems: "center",
								}}
							>
								<div
									className="card-body"
									style={{
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<h6 style={{ padding: "10px" }}> {message} </h6>
									<hr />
									<p>Would you like to send the following announcement to all the users?</p>

									<p>
										<strong>
											<em>{announcement}</em>
										</strong>
									</p>
									<br />
									<div>
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={onConfirm}
											style={{ margin: "10px" }}
										>
											{" "}
											Yes{" "}
										</button>
										<button
											type="button"
											className="btn btn-outline-danger"
											onClick={onCancel}
											style={{ margin: "10px" }}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</>
					);
				},
				closeOnOverlayClick: true,
			});

			if (result) {
				await emitMakeAnnouncement({ auction_id: auctionId, message: announcement });
				setAnnouncement("");
			}
		}
	};

	const triggerRefresh = async () => {
		await emitTriggerRefresh({ auction_id: auctionId });
	};

	const onNextRoundIncrementChange = (value) => {
		const price = round.price || auction.starting_price;
		let newIncrement = parseFloat(value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newPrice = price + newIncrement;

		if (newIncrement <= 0 || !newIncrement) {
			newIncrement = 1;
			newPrice = price + 1;
		}

		setNextRoundParams({ increment: newIncrement, price: newPrice });
	};

	const onNextRoundPriceChange = (value) => {
		const price = round.price || auction.starting_price;
		let newPrice = parseFloat(value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newIncrement = newPrice - price;

		if (newPrice <= price || !newPrice) {
			newIncrement = 1;
			newPrice = price + 1;
		}

		setNextRoundParams({ increment: newIncrement, price: newPrice });
	};

	const onProxyBidIncrementChange = (value) => {
		const price = round.price || auction.starting_price;
		let newIncrement = parseFloat(value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newPrice = price + newIncrement;

		if (newIncrement <= 0 || !newIncrement) {
			newIncrement = 1;
			newPrice = price + 1;
		}

		setProxyBidParams((state) => ({ ...state, increment: newIncrement, price: newPrice }));
	};

	const onProxyBidPriceChange = (value) => {
		const price = round.price || auction.starting_price;
		let newPrice = parseFloat(value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newIncrement = newPrice - price;

		if (newPrice <= price || !newPrice) {
			newIncrement = 1;
			newPrice = price + 1;
		}

		setProxyBidParams((state) => ({ ...state, increment: newIncrement, price: newPrice }));
	};

	return (
		<div className="card" style={{ width: "80%", alignItems: "center", margin: "auto" }}>
			<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
				<h6>Admin Panel</h6>
				<div className="row mb-3">
					<div className="btn-group flex-nowrap input-group-sm" role="group">
						<RAButton
							label="Start Auction"
							disabled={auction.status !== "Awaiting Start"}
							onClick={() => history.push(`/auctions/start/${auctionId}`)}
						/>
						<RAButton
							label="End Auction"
							color="btn-outline-danger"
							disabled={auction.status !== "In Progress"}
							onClick={async () => {
								await dispatch(endAuction(auctionId));
								await refresh();
							}}
							confirmProps={{
								title: "End Auction",
								body: "Are you sure you want to end the auction?",
							}}
						/>
						<RAButton
							label="Determine Winners"
							color="btn-outline-success"
							disabled={auction.status !== "Ended"}
							onClick={async () => {
								await dispatch(determineWinners(auctionId));
								await refresh();
							}}
							confirmProps={{
								title: "Determine Winners",
								body: "Are you sure you want to determine winners?",
							}}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="input-group flex-nowrap input-group-sm" role="group">
						<span className="input-group-text input-group-sm">
							Round Duration: {Math.floor(auction.round_duration / 60)}m {auction.round_duration % 60}s
						</span>

						<input
							type="number"
							className="form-control form-control-sm"
							placeholder="Minutes"
							value={newDuration.minutes}
							onChange={(e) => setNewDuration((state) => ({ ...state, minutes: e.target.value }))}
						/>
						<input
							type="number"
							className="form-control form-control-sm"
							placeholder="Seconds"
							value={newDuration.seconds}
							onChange={(e) => setNewDuration((state) => ({ ...state, seconds: e.target.value }))}
						/>
						<RAButton
							label="Update Duration"
							style={{ width: 125 }}
							onClick={async () => {
								await dispatch(
									updateAuction(auctionId, {
										duration:
											parseInt(newDuration.minutes * 60) + parseInt(newDuration.seconds || 0),
									})
								);
								setNewDuration({ minutes: "", seconds: "" });
								await refresh();
							}}
							disabled={!(newDuration.minutes || newDuration.seconds)}
							confirmProps={{
								title: "Update Round Duration",
								body: `Would you like to change the round duration to ${newDuration.minutes || 0}m ${
									newDuration.seconds || 0
								}s?`,
							}}
						/>
					</div>
				</div>
				<div className="row">
					<div className="input-group flex-nowrap input-group-sm" role="group">
						<span className="input-group-text input-group-sm">
							Current Price: {currencyFormat(round.price || auction.starting_price)}
						</span>
						<span className="input-group-text input-group-sm">+</span>
						<input
							className="form-control form-control-sm"
							placeholder="Increment"
							value={currencyFormat(nextRoundParams.increment)}
							onChange={(e) => onNextRoundIncrementChange(e.target.value)}
						/>
						<span className="input-group-text input-group-sm">=</span>
						<input
							className="form-control form-control-sm"
							placeholder="Price"
							value={currencyFormat(nextRoundParams.price)}
							// onChange={(e) => onNextRoundPriceChange(e.target.value)}
							disabled
						/>
						<RAButton
							label="Next Round"
							style={{ width: 125 }}
							onClick={async () => {
								await dispatch(nextRound(auctionId, { price: nextRoundParams.price }, "ENOO"));
								await refresh();
								setNextRoundParams({
									increment: "",
									price: "",
								});
							}}
							disabled={nextRoundParams.price <= (round.price || auction.starting_price)}
							confirmProps={{
								title: "Next Round",
								body: `Would you like to set the next round price to ${currencyFormat(
									nextRoundParams.price
								)}?`,
							}}
						/>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="input-group flex-nowrap input-group-sm" role="group">
						<select
							style={{
								padding: "6px",
								fontSize: "14px",
								borderColor: "#0d6efd",
								color: "#0d6efd",
								borderTopLeftRadius: 4,
								borderBottomLeftRadius: 4,
								zIndex: 2,
							}}
							value={proxyBidParams.user_id}
							onChange={(e) => setProxyBidParams((state) => ({ ...state, user_id: e.target.value }))}
						>
							<option value={0} disabled={true}>
								User Dropdown
							</option>
							{users.map((user) => (
								<option value={user.id}>{user.display_name}</option>
							))}
						</select>
						<span style={{ zIndex: 1 }} className="input-group-text input-group-sm">
							{currencyFormat(round.price || auction.starting_price)}
						</span>
						<span className="input-group-text input-group-sm">+</span>
						<input
							className="form-control form-control-sm"
							placeholder="Increment"
							value={currencyFormat(proxyBidParams.increment)}
							onChange={(e) => onProxyBidIncrementChange(e.target.value)}
						/>
						<span className="input-group-text input-group-sm">=</span>
						<input
							className="form-control form-control-sm"
							placeholder="Price"
							value={currencyFormat(proxyBidParams.price)}
							disabled
							// onChange={(e) => onProxyBidPriceChange(e.target.value)}
						/>
						<RAButton
							label="Place Proxy Bid"
							style={{ width: 125 }}
							onClick={async () => {
								await dispatch(
									makeENOOProxyBid(
										auctionId,
										{ price: proxyBidParams.price, user_id: proxyBidParams.user_id },
										{
											display_name: users.find((user) => user.id == proxyBidParams.user_id)
												.display_name,
											amount: proxyBidParams.price,
											user_id: proxyBidParams.user_id,
										}
									)
								);
								await refresh();
								setProxyBidParams({
									user_id: 0,
									price: "",
									increment: "",
								});
							}}
							disabled={
								!proxyBidParams.user_id ||
								proxyBidParams.price <= (round.price || auction.starting_price)
							}
							confirmProps={{
								title: "Make Proxy Bid",
								body: `Would you like to make a proxy bid of ${currencyFormat(proxyBidParams.price)} 
								as ${users.find((user) => user.id == proxyBidParams.user_id)?.display_name}?`,
							}}
						/>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="input-group input-group-sm" role="group">
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => triggerRefresh()}
						>
							Trigger Refresh
						</button>
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => sendReminder()}>
							Send Reminder
						</button>
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => makeAnnouncement()}
							disabled={!announcement}
						>
							Make Announcement
						</button>
						<input
							type="string"
							className="form-control form-control-sm"
							name="announcement"
							placeholder="Announcement..."
							style={{ width: "250px" }}
							value={announcement}
							onChange={(e) => setAnnouncement(e.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}
