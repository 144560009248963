import React, { Component } from "react";
import { connect } from "react-redux";
import {
	getAuctions,
	setAuctionsLoading,
	setAuctionsNotLoading,
	resetAuction,
} from "../../../../actions/auctionActions";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { leaveAllRooms } from "../../../../helpers/emit/auction";
import { leaveASTRooms } from "../../../../helpers/emit/auctionSimulationTool";
import socket from "../../../../helpers/socket";
import { auctionTypes, userType } from "../../../common/constants";

class Auctions extends Component {
	componentDidMount() {
		this.componentRefresh();
	}

	async componentRefresh() {
		await leaveAllRooms();
		await leaveASTRooms();
		await this.props.setAuctionsLoading();
		await this.props.getAuctions();
		await this.props.resetAuction();
		await this.props.setAuctionsNotLoading();
	}

	viewAuction(id, type) {
		this.props.history.push(`/auction/${id}`);
	}

	goToStartAuction(id, type) {
		this.props.history.push(`/auctions/start/${id}`);
	}

	createNewAuction() {
		this.props.history.push(`/auctions/create/${0}`);
	}

	duplicateAuction(id) {
		this.props.history.push(`/auctions/create/${id}`);
	}

	render() {
		const { signedIn, user } = this.props.user;
		const { auctions, auction_loading } = this.props.auction;
		const { db_loading } = this.props.database;

		if (!signedIn) {
			return <Redirect to="/" />;
		}

		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.componentRefresh();
		});

		if (auction_loading || db_loading) {
			return (
				<div className="container">
					<h3>Auctions</h3>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active" aria-current="page">
								Auctions
							</li>
						</ol>
					</nav>
					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		const auctionsList =
			user.type === userType.USER ? auctions.filter((auction) => auction.hidden === 0) : auctions;

		return (
			<div className="container">
				<h3>Auctions</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							Auctions
						</li>
					</ol>
				</nav>
				<hr />
				<div className="text-right" style={{ alignItems: "right", marginRight: 0 }}>
					<button
						type="button"
						className="btn btn-outline-primary btn-sm"
						onClick={() => this.componentRefresh()}
					>
						<i className="fas fa-sync"></i> Data Reload
					</button>

					{user.type === "admin" && (
						<button
							style={{ float: "right" }}
							type="button"
							className="btn btn-outline-success btn-sm"
							onClick={() => this.createNewAuction()}
						>
							Create New Auction
						</button>
					)}
				</div>
				<br />

				<div className="container">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Auctioneer</th>
								<th>Status</th>
								{user.type === "admin" ? <th>Type</th> : <></>}
								{user.type === "admin" ? <th>Active</th> : <></>}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{auctionsList ? (
								auctionsList.map(({ id, name, auctioneer, status, hidden, type }) => (
									<tr key={id}>
										<td>{name}</td>
										<td>{auctioneer}</td>
										<td>{status}</td>
										{user.type === "admin" ? <td>{determineType(type)}</td> : <></>}
										{user.type === "admin" ? (
											<td>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														checked={hidden === 0}
														disabled
													/>
												</div>
											</td>
										) : (
											<></>
										)}
										<td>
											<button
												type="button"
												className="btn btn-outline-primary btn-sm"
												onClick={() => this.viewAuction(id, type)}
											>
												<i className="fas fa-external-link-alt"></i>
											</button>
											{user.type === "admin" ? (
												<button
													type="button"
													className="btn btn-outline-success btn-sm"
													disabled={status === "Awaiting Start" ? false : true}
													onClick={() => this.goToStartAuction(id, type)}
													style={{
														marginLeft: "5px",
													}}
												>
													<i className="fas fa-play"></i>
												</button>
											) : (
												<></>
											)}
											{user.type === "admin" ? (
												<button
													type="button"
													className="btn btn-outline-secondary btn-sm"
													onClick={() => this.duplicateAuction(id)}
													style={{
														marginLeft: "5px",
													}}
													data-toggle="tooltip"
													data-placement="top"
													title="Duplicate Auction"
												>
													<i className="far fa-copy"></i>
												</button>
											) : (
												<></>
											)}
										</td>
									</tr>
								))
							) : (
								<></>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

function determineType(type) {
	if (type === auctionTypes.SMRA) {
		return "SMRA";
	} else if (type === auctionTypes.MPSB) {
		return "MPSB";
	} else if (type === auctionTypes.ENOO) {
		return "ENOO";
	} else if (type === auctionTypes.RENO) {
		return "RENO";
	} else {
		return "";
	}
}

Auctions.propTypes = {
	getAuctions: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	resetAuction: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	database: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
	database: state.database,
});

export default connect(mapStateToProps, {
	setAuctionsLoading,
	getAuctions,
	setAuctionsNotLoading,
	resetAuction,
})(Auctions);
