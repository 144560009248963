import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllUsers, setUserNotLoading, setUserLoading } from "../../../../actions/userActions";
import {
	getAuction,
	startAuction,
	setAuctionsLoading,
	setAuctionsNotLoading,
} from "../../../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import XLSX from "xlsx";
import { confirm } from "react-confirm-box";

class StartSMRAAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userPointsString: "",
			userPoints: [],
			simulationData: {},
			bidCreditsData: {},
			timerValueMins: "03",
			timerValueSecs: "00",
		};
	}

	async componentDidMount() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getAllUsers();

		await this.props.setAuctionsNotLoading();
		await this.props.setUserNotLoading();

		const { auction } = this.props.auction;
		const { users } = this.props.user;

		let simData = {};
		let bidCreditsData = {};

		users.forEach((user) => {
			simData[`user_${user.id}`] = { isSimulated: false, totalBudget: 0 };
			bidCreditsData[`user_${user.id}`] = {};
			auction.leases.forEach((lease) => {
				simData[`user_${user.id}`][lease.name] = {
					priority: 0,
					siteBudget: 0,
					siteValue: 0,
				};
				bidCreditsData[`user_${user.id}`][lease.name] = 0;
			});
		});

		this.setState({ ...this.state, simulationData: simData, bidCreditsData: bidCreditsData });
	}

	handleSimulationExcelFileUpload(event) {
		const { auction } = this.props.auction;
		const { users } = this.props.user;
		let simData = this.state.simulationData;

		let lease_id_index = {};
		let idx = 0;
		auction.leases.forEach((lease) => {
			lease_id_index[lease.id] = idx;
			idx += 1;
		});

		const file = event.target.files[0];
		new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(file);
			fileReader.onload = (e) => {
				const bufferArray = e.target.result;
				const wb = XLSX.read(bufferArray, { type: "buffer" });
				const ws = wb.Sheets["_read"];
				const data = XLSX.utils.sheet_to_json(ws);

				users.forEach((user) => {
					auction.leases.forEach((lease) => {
						if (data[lease_id_index[lease.id]].hasOwnProperty(user.id)) {
							var array = data[lease_id_index[lease.id]][user.id].split(",");
							simData[`user_${user.id}`][lease.name] = {
								priority: parseInt(array[0]),
								siteBudget: parseInt(array[2]),
								siteValue: parseInt(array[1]),
							};
						}
					});
					this.setState({ ...this.state, simulationData: simData });
				});
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	}

	handleJsonFileUpload(event) {
		const { auction } = this.props.auction;
		const { users } = this.props.user;
		let creditsData = this.state.bidCreditsData;
		let simData = this.state.simulationData;

		const leaseNames = auction.leases.map((l) => l.name);

		const fileReader = new FileReader();
		fileReader.readAsText(event.target.files[0], "UTF-8");
		fileReader.onload = (e) => {
			const jsonData = JSON.parse(e.target.result);
			const uploadedBidCreditsData = jsonData.bidCreditsData;
			const uploadedSimData = jsonData.simulationData;

			users.forEach((user) => {
				if (uploadedSimData[String(user.id)]) {
					const userSimData = uploadedSimData[String(user.id)];
					simData[`user_${user.id}`].totalBudget = userSimData.totalBudget;
					userSimData.leaseData.forEach((userLeaseData) => {
						if (leaseNames.includes(userLeaseData.leaseName)) {
							simData[`user_${user.id}`][userLeaseData.leaseName] = {
								priority: parseInt(userLeaseData.priority),
								siteBudget: parseInt(userLeaseData.siteBudget),
								siteValue: parseInt(userLeaseData.siteValue),
							};
						}
					});
				}
				if (uploadedBidCreditsData[String(user.id)]) {
					const userBidCreditsData = uploadedBidCreditsData[String(user.id)];
					userBidCreditsData.leaseData.forEach((userLeaseData) => {
						if (leaseNames.includes(userLeaseData.leaseName)) {
							creditsData[`user_${user.id}`][userLeaseData.leaseName] = userLeaseData.bidCredits;
						}
					});
				}
			});

			this.setState({ ...this.state, bidCreditsData: creditsData, simulationData: simData });
		};
	}

	handleJsonFileDownload() {
		let formattedBidData = {};
		let bidCreditsData = this.state.bidCreditsData;
		for (let user in bidCreditsData) {
			let leaseData = [];
			for (let lease in bidCreditsData[user]) {
				leaseData.push({ leaseName: lease, bidCredits: bidCreditsData[user][lease] });
			}
			formattedBidData[user.replace("user_", "")] = { leaseData: leaseData };
		}

		let formattedSimData = {};
		let simulationData = this.state.simulationData;
		for (let user in simulationData) {
			let leaseData = [];
			for (let userData in simulationData[user]) {
				if (!(userData === "isSimulated" || userData === "totalBudget")) {
					leaseData.push({ leaseName: userData, ...simulationData[user][userData] });
				}
			}
			formattedSimData[user.replace("user_", "")] = {
				isSimulated: simulationData[user].isSimulated,
				totalBudget: simulationData[user].totalBudget,
				leaseData: leaseData,
			};
		}

		var data = { bidCreditsData: formattedBidData, simulationData: formattedSimData };
		var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 2));
		var downloadAnchorNode = document.createElement("a");
		downloadAnchorNode.setAttribute("href", dataStr);
		downloadAnchorNode.setAttribute("download", "data.json");
		document.body.appendChild(downloadAnchorNode);
		downloadAnchorNode.click();
		downloadAnchorNode.remove();
	}

	onChange(event) {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		});
	}

	onIsSimulatedChange(event) {
		let simData = this.state.simulationData;
		simData[`user_${event.target.value}`].isSimulated = !simData[`user_${event.target.value}`].isSimulated;
		this.setState({ ...this.state, simulationData: simData });
	}

	onSimDataChange(event) {
		let simData = this.state.simulationData;
		if (event.target.getAttribute("simdatatype") === "priority") {
			let value = parseInt(event.target.value);
			if (isNaN(value) || value < 0) value = 0;
			simData[`user_${event.target.getAttribute("user")}`][event.target.getAttribute("name")].priority = value;
		}
		if (event.target.getAttribute("simdatatype") === "siteValue") {
			let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) value = 0;
			simData[`user_${event.target.getAttribute("user")}`][event.target.getAttribute("name")].siteValue = value;
		}
		if (event.target.getAttribute("simdatatype") === "siteBudget") {
			let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) value = 0;
			simData[`user_${event.target.getAttribute("user")}`][event.target.getAttribute("name")].siteBudget = value;
		}
		if (event.target.getAttribute("simdatatype") === "totalBudget") {
			let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) value = 0;
			simData[`user_${event.target.getAttribute("user")}`].totalBudget = value;
		}
		this.setState({ ...this.state, simulationData: simData });
	}

	onBidCreditsDataChange(event) {
		let bidCreditsData = this.state.bidCreditsData;
		if (event.target.getAttribute("bidCreditsDataType") === "percentage") {
			let value = parseFloat(event.target.value);
			if (isNaN(value) || value < 0) value = 0;
			bidCreditsData[`user_${event.target.getAttribute("user")}`][event.target.getAttribute("name")] = value;
		}

		this.setState({ ...this.state, bidCreditsData: bidCreditsData });
	}

	increasePoints(event) {
		const id = event.target.name;
		let points = this.state.userPoints.filter((point) => {
			return point.user_id != id;
		});

		points.push({
			user_id: id,
			points:
				this.state.userPoints.filter((point) => {
					return point.user_id == id;
				}).length == 0
					? 2
					: this.state.userPoints.filter((point) => {
							return point.user_id == id;
					  })[0].points + 1,
		});
		this.setState({ ...this.state, userPoints: points });
	}

	decreasePoints(event) {
		const id = event.target.name;
		let points = this.state.userPoints.filter((point) => {
			return point.user_id != id;
		});
		points.push({
			user_id: id,
			points:
				this.state.userPoints.filter((point) => {
					return point.user_id == id;
				}).length == 0
					? 2
					: this.state.userPoints.filter((point) => {
							return point.user_id == id;
					  })[0].points - 1,
		});
		this.setState({ ...this.state, userPoints: points });
	}

	increaseAllPoints() {
		let points = [];
		this.props.user.users.forEach((s) => {
			points.push({
				user_id: s.id,
				points:
					this.state.userPoints.filter((point) => {
						return point.user_id == s.id;
					}).length == 0
						? 2
						: this.state.userPoints.filter((point) => {
								return point.user_id == s.id;
						  })[0].points + 1,
			});
		});
		this.setState({ ...this.state, userPoints: points });
	}

	decreaseAllPoints() {
		let points = [];
		this.props.user.users.forEach((s) => {
			points.push({
				user_id: s.id,
				points:
					this.state.userPoints.filter((point) => {
						return point.user_id == s.id;
					}).length == 0
						? 2
						: this.state.userPoints.filter((point) => {
								return point.user_id == s.id;
						  })[0].points - 1,
			});
		});
		this.setState({ ...this.state, userPoints: points });
	}

	setPoints(event) {
		event.preventDefault();
		let points = [];
		this.state.userPointsString.split(",").forEach((s) => {
			if (s.split(":").length == 2) {
				points.push({
					user_id: parseInt(s.split(":")[0]),
					points: parseInt(s.split(":")[1]),
				});
			}
		});

		this.setState({ ...this.state, userPoints: points });
	}

	onTimerChangeMins(event) {
		if (!isNaN(event.target.value)) {
			this.setState({ ...this.state, timerValueMins: event.target.value });
		}
	}

	onTimerChangeSecs(event) {
		if (!isNaN(event.target.value)) {
			this.setState({ ...this.state, timerValueSecs: event.target.value });
		}
	}

	async startAuctionWithPoints() {
		const { auction } = this.props.auction;
		const { users } = this.props.user;

		let simulatedUsers = [];
		let extraPointsUsers = [];
		users.forEach((user) => {
			if (this.state.simulationData[`user_${user.id}`].isSimulated) {
				simulatedUsers.push({ username: user.username, display_name: user.display_name });
			}
			const points = this.state.userPoints.filter((p) => p.user_id == user.id);
			if (points.length > 0 && points[0].points > 1) {
				extraPointsUsers.push({
					username: user.username,
					display_name: user.display_name,
					points: points[0].points,
				});
			}
		});

		const result = await confirm("Start Auction Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Would you like to start the auction with the following information?</p>

								{extraPointsUsers.length > 0 ? (
									<table className="table table-bordered table-sm">
										<thead>
											<tr>
												<th colSpan={2}>Extra Eligibility Points</th>
											</tr>
											<tr>
												<th>User</th>
												<th>Points</th>
											</tr>
										</thead>
										<tbody>
											{extraPointsUsers.map(({ username, display_name, points }) => (
												<tr>
													<td>
														{display_name} ({username})
													</td>
													<td>{points}</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>All users start with 1 eligibility point each</p>
								)}

								{simulatedUsers.length > 0 ? (
									<table className="table table-bordered table-sm">
										<thead>
											<tr>
												<th>Simulated Users</th>
											</tr>
										</thead>
										<tbody>
											{simulatedUsers.map(({ username, display_name }) => (
												<tr>
													<td>
														{display_name} ({username})
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p>No simulated users</p>
								)}
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			let simData = [];
			let simConfig = [];
			let creditsConfig = [];
			users.forEach((user) => {
				auction.leases.forEach((lease) => {
					creditsConfig.push({
						user_id: user.id,
						lease_id: lease.id,
						percentage: this.state.bidCreditsData[`user_${user.id}`][lease.name],
					});
				});
				if (this.state.simulationData[`user_${user.id}`].isSimulated) {
					auction.leases.forEach((lease) => {
						simData.push({
							user_id: user.id,
							lease_id: lease.id,
							priority: this.state.simulationData[`user_${user.id}`][lease.name].priority,
							site_value: this.state.simulationData[`user_${user.id}`][lease.name].siteValue,
							site_budget: this.state.simulationData[`user_${user.id}`][lease.name].siteBudget,
						});
					});
					simConfig.push({
						user_id: user.id,
						total_budget: this.state.simulationData[`user_${user.id}`].totalBudget,
					});
				}
			});

			var timer =
				parseInt(
					this.state.timerValueMins[this.state.timerValueMins.length - 2]
						? this.state.timerValueMins[this.state.timerValueMins.length - 2]
						: 0
				) *
					600 +
				parseInt(
					this.state.timerValueMins[this.state.timerValueMins.length - 1]
						? this.state.timerValueMins[this.state.timerValueMins.length - 1]
						: 0
				) *
					60 +
				parseInt(
					this.state.timerValueSecs[this.state.timerValueSecs.length - 2]
						? this.state.timerValueSecs[this.state.timerValueSecs.length - 2]
						: 0
				) *
					10 +
				parseInt(
					this.state.timerValueSecs[this.state.timerValueSecs.length - 1]
						? this.state.timerValueSecs[this.state.timerValueSecs.length - 1]
						: 0
				);

			let payload = {
				duration: timer,
				points: this.state.userPoints,
				simulation_data: simData,
				simulation_config: simConfig,
				bid_credits_data: creditsConfig,
			};

			await this.props.startAuction(this.props.params.auctionId, payload, "SMRA");
		}
	}

	render() {
		const { auction, auction_loading, started } = this.props.auction;
		const { users, user, user_loading, signedIn } = this.props.user;

		if (!signedIn || user.type !== "admin" || started) {
			return <Redirect to="/auctions" />;
		}

		if (auction_loading || user_loading) {
			return (
				<div className="container">
					<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/auctions">Auctions</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Start Auction
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container" style={{ paddingBottom: "100px" }}>
				<h3>Start Auction {auction.name ? `(${auction.name})` : ""}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Start Auction
						</li>
					</ol>
				</nav>

				<hr />

				<br />

				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<div className="col-3">Duration for first round:</div>
					<div className="col-3">
						<div className="input-group flex-nowrap mb-3" role="group">
							<input
								type="string"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueMins)}
								onChange={this.onTimerChangeMins.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Mins</span>
							<input
								type="string"
								className="form-control form-control-sm"
								id="nextRoundDuration"
								value={timerFormat(this.state.timerValueSecs)}
								onChange={this.onTimerChangeSecs.bind(this)}
								style={{ width: "40px" }}
							/>
							<span className="input-group-text">Secs</span>
						</div>
					</div>

					<div className="col-3"></div>
					<div className="col-3">
						<button
							className="btn btn-outline-success"
							style={{ height: "80%", width: "100%" }}
							onClick={this.startAuctionWithPoints.bind(this)}
						>
							Start Auction
						</button>
					</div>

					<label htmlFor="formFile" className="form-label">
						Upload JSON For Bid Credits & Bid Bot Data
					</label>
					<div className="input-group">
						<input
							className="form-control"
							type="file"
							accept=".json"
							id="formFile"
							onChange={this.handleJsonFileUpload.bind(this)}
						/>
						<button className="btn btn-outline-success" onClick={this.handleJsonFileDownload.bind(this)}>
							Download Data
						</button>
					</div>
				</div>

				<br />

				{/* Points Data */}
				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<h4>Points Data</h4>
					<hr />
					<div className="table-responsive">
						<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
							<thead>
								<tr>
									<th>User ID</th>
									<th>Username</th>
									<th>Account Type</th>
									<th>Display Name</th>
									<th>
										<div className="row">
											<div className="col-2">
												<button
													className="btn btn-outline-danger btn-sm"
													disabled={
														users.length != this.state.userPoints.length ||
														this.state.userPoints.filter((point) => {
															return point.points == 1;
														}).length >= 1
													}
													onClick={this.decreaseAllPoints.bind(this)}
												>
													<i
														style={{
															pointerEvents: "none",
														}}
														className="fas fa-minus"
													></i>
												</button>
											</div>
											<div className="col-8">Starting Eligibility Points</div>
											<div className="col-2">
												<button
													className="btn btn-outline-success btn-sm"
													onClick={this.increaseAllPoints.bind(this)}
												>
													<i
														style={{
															pointerEvents: "none",
														}}
														className="fas fa-plus"
													></i>
												</button>
											</div>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{users.length !== 0 ? (
									users.map(({ id, username, display_name, type }) => (
										<tr key={id}>
											<td>{id}</td>
											<td>{username}</td>
											<td>{type}</td>
											<th>{display_name}</th>
											<th>
												<div className="row">
													<div className="col-2">
														<button
															className="btn btn-outline-danger btn-sm"
															name={id}
															disabled={
																this.state.userPoints.filter((point) => {
																	return point.user_id == id;
																}).length == 0
																	? true
																	: this.state.userPoints.filter((point) => {
																			return point.user_id == id;
																	  })[0].points == 1
															}
															onClick={this.decreasePoints.bind(this)}
															style={{
																borderRadius: "15px",
															}}
														>
															<i
																style={{
																	pointerEvents: "none",
																}}
																className="fas fa-minus"
															></i>
														</button>
													</div>
													<div className="col-8">
														{this.state.userPoints.filter((point) => {
															return point.user_id == id;
														}).length == 0
															? 1
															: this.state.userPoints.filter((point) => {
																	return point.user_id == id;
															  })[0].points}
													</div>
													<div className="col-2">
														<button
															className="btn btn-outline-success btn-sm"
															name={id}
															onClick={this.increasePoints.bind(this)}
															style={{
																borderRadius: "15px",
															}}
														>
															<i
																style={{
																	pointerEvents: "none",
																}}
																className="fas fa-plus"
															></i>
														</button>
													</div>
												</div>
											</th>
										</tr>
									))
								) : (
									<></>
								)}
							</tbody>
						</table>
					</div>
				</div>

				<br />

				{/* Bid Credits Data Table */}
				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<h4>Bid Credits Data</h4>
					<hr />

					<div className="table-responsive">
						<table className="table table-sm table-bordered mx-auto w-auto" style={{ textAlign: "center" }}>
							<thead>
								<tr>
									<th
										style={{
											minWidth: "160px",
											margin: `auto`,
											padding: `15px`,
										}}
									></th>
									{users ? (
										users.map(({ id, username, display_name }) => (
											<th
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
												}}
											>
												{`${display_name} (${username})`}
											</th>
										))
									) : (
										<></>
									)}
								</tr>
							</thead>
							<tbody>
								{auction.leases ? (
									auction.leases.map((lease) => (
										<tr key={lease.id}>
											<th>
												{lease.name} <br /> {currencyFormat(lease.starting_bid)}
											</th>

											{users ? (
												users.map(({ id }) => (
													<td
														key={id}
														style={{
															minWidth: "175px",
															margin: `auto`,
															padding: `15px`,
														}}
													>
														<div className="input-group">
															<input
																type="number"
																step={0.1}
																aria-label="Bid Credits"
																className="form-control"
																value={
																	Object.keys(this.state.bidCreditsData).length > 0
																		? this.state.bidCreditsData[`user_${id}`][
																				lease.name
																		  ]
																		: 0.0
																}
																bidCreditsDataType="percentage"
																user={id}
																lease={lease.id}
																name={lease.name}
																onChange={this.onBidCreditsDataChange.bind(this)}
																style={{
																	width: "70px",
																}}
															/>
															<span
																className="input-group-text"
																style={{
																	width: "50px",
																}}
															>
																%
															</span>
														</div>
													</td>
												))
											) : (
												<></>
											)}
										</tr>
									))
								) : (
									<></>
								)}
							</tbody>
						</table>
					</div>
				</div>

				<br />
				<br />

				{/* Simulation Data Table */}
				<div className="row" style={{ width: "90%", margin: "auto" }}>
					<h4>Bid Bot Data</h4>
					<hr />
					<div className="card-body" style={{ alignItems: "center" }}>
						<div className="mb-3">
							<label htmlFor="formFile" className="form-label">
								Upload Excel For Simulation
							</label>
							<input
								className="form-control"
								type="file"
								accept=".xls,.xlsx"
								id="formFile"
								onChange={this.handleSimulationExcelFileUpload.bind(this)}
							/>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-sm table-bordered mx-auto w-auto" style={{ textAlign: "center" }}>
							<thead>
								<tr>
									<th
										style={{
											minWidth: "160px",
											margin: `auto`,
											padding: `15px`,
										}}
									></th>
									{users ? (
										users.map(({ id, username, display_name }) => (
											<th
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
												}}
											>
												{`${display_name} (${username})`}
											</th>
										))
									) : (
										<></>
									)}
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Is Simulated ?</th>
									{users ? (
										users.map(({ id, username, display_name }) => (
											<td
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														id={`checkbox_${username}`}
														value={id}
														onChange={this.onIsSimulatedChange.bind(this)}
														checked={
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].isSimulated
																: false
														}
													/>
													<label
														className="form-check-label"
														htmlFor={`checkbox_${username}`}
													>
														Simulate {display_name}
													</label>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>

								<tr>
									<th>Total Budget</th>
									{users ? (
										users.map(({ id }) => (
											<td
												key={id}
												style={{
													minWidth: "150px",
													margin: `auto`,
													padding: `15px`,
													textAlign: "left",
												}}
											>
												<div className="input-group">
													<span
														className="input-group-text"
														style={{
															width: "120px",
														}}
													>
														Total Budget
													</span>
													<input
														type="string"
														aria-label="Total Budget"
														className="form-control"
														disabled={
															Object.keys(this.state.simulationData).length > 0
																? !this.state.simulationData[`user_${id}`].isSimulated
																: true
														}
														value={currencyFormat(
															Object.keys(this.state.simulationData).length > 0
																? this.state.simulationData[`user_${id}`].totalBudget
																: 0
														)}
														simdatatype="totalBudget"
														user={id}
														onChange={this.onSimDataChange.bind(this)}
														style={{
															width: "150px",
														}}
													/>
												</div>
											</td>
										))
									) : (
										<></>
									)}
								</tr>

								{auction.leases ? (
									auction.leases.map((lease) => (
										<tr key={lease.id}>
											<th>
												{lease.name} <br /> {currencyFormat(lease.starting_bid)}
											</th>

											{users ? (
												users.map(({ id }) => (
													<td
														key={id}
														style={{
															minWidth: "300px",
															margin: `auto`,
															padding: `15px`,
														}}
													>
														<div className="input-group">
															<span
																className="input-group-text"
																style={{
																	width: "120px",
																}}
															>
																Priority
															</span>
															<input
																type="number"
																aria-label="Priority"
																className="form-control"
																disabled={
																	Object.keys(this.state.simulationData).length > 0
																		? !this.state.simulationData[`user_${id}`]
																				.isSimulated
																		: true
																}
																value={
																	Object.keys(this.state.simulationData).length > 0
																		? this.state.simulationData[`user_${id}`][
																				lease.name
																		  ].priority
																		: 0
																}
																simdatatype="priority"
																user={id}
																lease={lease.id}
																name={lease.name}
																onChange={this.onSimDataChange.bind(this)}
																style={{
																	width: "150px",
																}}
															/>
														</div>

														<div className="input-group">
															<span
																className="input-group-text"
																style={{
																	width: "120px",
																}}
															>
																Site Value
															</span>
															<input
																type="string"
																aria-label="Site Value"
																className="form-control"
																disabled={
																	Object.keys(this.state.simulationData).length > 0
																		? !this.state.simulationData[`user_${id}`]
																				.isSimulated
																		: true
																}
																value={currencyFormat(
																	Object.keys(this.state.simulationData).length > 0
																		? this.state.simulationData[`user_${id}`][
																				lease.name
																		  ].siteValue
																		: 0
																)}
																simdatatype="siteValue"
																user={id}
																lease={lease.id}
																name={lease.name}
																onChange={this.onSimDataChange.bind(this)}
																style={{
																	width: "150px",
																}}
															/>
														</div>

														<div className="input-group">
															<span
																className="input-group-text"
																style={{
																	width: "120px",
																}}
															>
																Site Budget
															</span>
															<input
																type="string"
																aria-label="Budget"
																className="form-control"
																disabled={
																	Object.keys(this.state.simulationData).length > 0
																		? !this.state.simulationData[`user_${id}`]
																				.isSimulated
																		: true
																}
																value={currencyFormat(
																	Object.keys(this.state.simulationData).length > 0
																		? this.state.simulationData[`user_${id}`][
																				lease.name
																		  ].siteBudget
																		: 0
																)}
																simdatatype="siteBudget"
																user={id}
																lease={lease.id}
																name={lease.name}
																onChange={this.onSimDataChange.bind(this)}
																style={{
																	width: "150px",
																}}
															/>
														</div>
													</td>
												))
											) : (
												<></>
											)}
										</tr>
									))
								) : (
									<></>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

function timerFormat(secs) {
	var z = secs[secs.length - 1] ? secs[secs.length - 1] : "0";
	var y = secs[secs.length - 2] ? secs[secs.length - 2] : "0";
	var result = y.concat(z);
	return result;
}

function WithParams(props) {
	let params = useParams();
	return <StartSMRAAuction {...props} params={params} />;
}

StartSMRAAuction.propTypes = {
	getAllUsers: PropTypes.func.isRequired,
	getAuction: PropTypes.func.isRequired,
	startAuction: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	auction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	auction: state.auction,
});

export default connect(mapStateToProps, {
	setUserLoading,
	setAuctionsLoading,
	getAllUsers,
	getAuction,
	startAuction,
	setAuctionsNotLoading,
	setUserNotLoading,
})(WithParams);
