import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ReferenceArea,
	ReferenceLine,
} from "recharts";
import { COLORS } from "../../common/constants";

class UserDashboard extends Component {
	constructor(props) {
		super(props);
	}

	getLeaseUserHistoryTableCellData(his) {
		let user_status = [];
		his.forEach((h) => {
			if (h.exit == 0 && h.carried == 0 && h.combined == 0) user_status.push(`Live Bid`);
			else if (h.exit == 0 && h.carried == 1 && h.combined == 0) user_status.push(`Frozen Live Bid`);
			if (h.exit == 0 && h.carried == 0 && h.combined == 1) user_status.push(`Combined Live Bid`);
			else if (h.exit == 0 && h.carried == 1 && h.combined == 1) user_status.push(`Combined Frozen Live Bid`);
			else if (h.exit == 1 && h.carried == 0 && h.combined == 0 && h.no_bid == 0) user_status.push(`Exit Bid`);
			else if (h.exit == 1 && h.carried == 1 && h.combined == 0 && h.no_bid == 0)
				user_status.push(`Frozen Exit Bid`);
			else if (h.exit == 1 && h.carried == 0 && h.combined == 1 && h.no_bid == 0)
				user_status.push(`Combined Exit Bid`);
			else if (h.exit == 1 && h.carried == 1 && h.combined == 1 && h.no_bid == 0)
				user_status.push(`Frozen Combined Exit Bid`);
			else if (h.exit == 1 && h.carried == 0 && h.combined == 0 && h.no_bid == 1)
				user_status.push(`(No Bid) Exit Bid`);
			else if (h.exit == 1 && h.carried == 1 && h.combined == 0 && h.no_bid == 1)
				user_status.push(`Frozen (No Bid) Exit Bid`);
		});

		return user_status.join(", ");
	}

	renderUserLeaseHistoryTable() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;

		let round_history = [];
		rounds.forEach((round_num) => {
			round_history.push({
				round_num: round_num,
				history: history.filter((his) => his.username === this.props.user.username && his.round === round_num),
			});
		});

		return (
			<div className="table-responsive">
				<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
					<thead style={{ textAlign: "center" }}>
						<tr>
							<th></th>
							{auction.leases ? (
								auction.leases.map(({ id, name }) => (
									<th colSpan={3} key={id} style={{ minWidth: "125px" }}>
										{name}
									</th>
								))
							) : (
								<></>
							)}
						</tr>
						<tr>
							<th style={{ minWidth: "25px" }}>Round</th>
							{auction.leases ? (
								auction.leases.map(({ id, name }) => (
									<>
										<th>Bid Type</th>
										<th>Imputed Price</th>
										<th>Cash Bid</th>
									</>
								))
							) : (
								<></>
							)}
						</tr>
					</thead>
					<tbody>
						{round_history.map(({ round_num, history }) => (
							<tr key={round_num}>
								<th
									className="align-middle"
									style={{
										textAlign: "center",
										verticalAlign: "center",
									}}
								>
									{round_num}
								</th>
								{auction.leases ? (
									auction.leases.map(({ id }) => (
										<>
											<td>
												{this.getLeaseUserHistoryTableCellData(
													history.filter(
														(his) =>
															his.username == this.props.user.username &&
															his.lease_id == id
													)
												)}
											</td>
											<td>
												{history
													.filter((bids) => bids.lease_id === id && bids.round === round_num)
													.map((bid) => currencyFormat(bid.amount))}
											</td>
											<td>
												{history
													.filter((bid) => bid.lease_id === id && bid.round === round_num)
													.map((bid) => currencyFormat(bid.financial_exposure))}
											</td>
										</>
									))
								) : (
									<></>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	renderBidHistoryGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;
		const userHistory = history.filter((his) => his.username === this.props.user.username);

		let bidHistoryChartData = [];
		let exitRounds = [];
		let exitLeaseId = 0;
		rounds.forEach((roundNum) => {
			let data = {};
			let exit = false;
			auction.leases.forEach((lease) => {
				const d = userHistory.filter((h) => h.lease_id === lease.id && h.round === roundNum);
				data[lease.id] = d.length > 0 ? d[0].amount : null;
				exit = d.length > 0 ? (d[0].exit == 1 ? true : false) : false;
				if (d.length > 0 && d[0].exit == 1) {
					exitRounds.push(roundNum);
					exitLeaseId = d[0].lease_id;
				}
			});
			bidHistoryChartData.push({
				round: `Round ${roundNum}`,
				...data,
				exit,
			});
		});

		let leases_data = [];
		let idx = 0;
		let exitStroke = "#000000";
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				if (exitRounds.length > 0 && lease.id === exitLeaseId) {
					exitStroke = COLORS[idx];
				}
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={300}>
						<LineChart
							height={200}
							data={bidHistoryChartData}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									id={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
							{exitRounds.length > 0 ? (
								<ReferenceLine
									x={`Round ${exitRounds[0]}`}
									label={{
										value: "Exit Bid",
										position: "insideTopLeft",
										opacity: "0.6",
									}}
									stroke={exitStroke}
								/>
							) : (
								<></>
							)}
							{exitRounds.length > 1 ? (
								<ReferenceArea
									x1={`Round ${Math.min(...exitRounds)}`}
									x2={`Round ${Math.max(...exitRounds)}`}
									fill={exitStroke}
									fillOpacity={0.1}
								/>
							) : (
								<></>
							)}
							{exitRounds.length > 0 ? (
								<ReferenceLine x={`Round ${exitRounds[exitRounds.length - 1]}`} stroke={exitStroke} />
							) : (
								<></>
							)}
						</LineChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	getBidType(bid) {
		if (bid.exit == 0 && bid.combined == 0 && bid.carried == 0 && bid.no_bid == 0) {
			return "Live Bid";
		} else if (bid.exit == 0 && bid.combined == 0 && bid.carried == 1 && bid.no_bid == 0) {
			return "Frozen Live Bid";
		} else if (bid.exit == 0 && bid.combined == 1 && bid.carried == 0 && bid.no_bid == 0) {
			return "Combined Live Bid";
		} else if (bid.exit == 1 && bid.combined == 0 && bid.carried == 0 && bid.no_bid == 0) {
			return "Exit Bid";
		} else if (bid.exit == 1 && bid.combined == 0 && bid.carried == 1 && bid.no_bid == 0) {
			return "Frozen Exit Bid";
		} else if (bid.exit == 1 && bid.combined == 1 && bid.carried == 0 && bid.no_bid == 0) {
			return "Combined Exit Bid";
		} else if (bid.exit == 1 && bid.combined == 1 && bid.carried == 1 && bid.no_bid == 0) {
			return "Frozen Combined Exit Bid";
		} else if (bid.exit == 1 && bid.combined == 0 && bid.carried == 0 && bid.no_bid == 1) {
			return "(No Bid) Exit Bid";
		} else if (bid.exit == 1 && bid.combined == 0 && bid.carried == 1 && bid.no_bid == 1) {
			return "Frozen (No Bid) Exit Bid";
		} else {
			return "";
		}
	}

	renderWinnerInfo() {
		const { auction, winning_bids } = this.props.auction;
		const user = this.props.user;
		let winning_lease_data = [];
		if (auction.status == "Ended") {
			if (winning_bids && winning_bids.bids) {
				const winnings = winning_bids.bids.filter((bid) => {
					return bid.username == user.username;
				});
				if (winnings.length > 0) {
					winnings.forEach((winning) => {
						winning_lease_data.push({
							lease_name: winning.name,
							username: `${user.display_name} (${user.username})`,
							imputed_price: winning.amount,
							cash_bid: winning.financial_exposure,
							bid_type: this.getBidType(winning),
							stage: winning.stage_one_bid === 1 ? "Stage 1" : "Stage 2",
							round: winning.round,
						});
					});
				}
			}
		}

		return (
			auction.status === "Ended" && (
				<div className="card" style={{ width: "95%", margin: "auto", padding: "0", textAlign: "center" }}>
					<h5 className="card-header">Auction Result</h5>
					<div className="card-body">
						{winning_bids && winning_bids.bids ? (
							winning_lease_data.length > 0 ? (
								<div className="table-responsive">
									<table className="table table-sm table-bordered">
										<thead>
											<tr>
												<th>Won Lease</th>
												<th>Imputed Price</th>
												<th>Cash Bid</th>
												<th>Round Won</th>
												<th>Bid Type</th>
												<th>Stage</th>
											</tr>
										</thead>
										<tbody>
											{winning_lease_data &&
												winning_lease_data.map((lease) => (
													<tr>
														<th>{lease.lease_name}</th>
														<td>{currencyFormat(lease.imputed_price)}</td>
														<td>{currencyFormat(lease.cash_bid)}</td>
														<td>{lease.round}</td>
														<td>{lease.bid_type}</td>
														<td>{lease.stage}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							) : (
								`${user.display_name} has not won any leases`
							)
						) : (
							"The auction has ended. The results will be announced soon."
						)}
					</div>
				</div>
			)
		);
	}

	render() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>{this.props.user.display_name} Bid History</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderWinnerInfo()}
				</div>
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderBidHistoryGraph()}
				</div>
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderUserLeaseHistoryTable()}
				</div>
			</>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

UserDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default UserDashboard;
