import Plot from "react-plotly.js";
import { COLORS } from "../../../common/constants";

export const BidPricesQuantitiesGraphs = (props) => {
	let { competitor_bid_config, user_bid_config } = props;

	let userPriceTraces = [];
	let userQuantityTraces = [];
	let competitorPriceTraces = [];
	let competitorQuantityTraces = [];
	let priceAnnotations = [];
	let quantityAnnotations = [];

	let user_index = 0;
	let total_index = 0;
	for (const [username, bids] of Object.entries(competitor_bid_config)) {
		bids.forEach(({ min_price, max_price, min_quantity, max_quantity }, bid_index) => {
			const label = `${username} ${bid_index > 0 ? bid_index + 1 : ""}`;
			min_price &&
				max_price &&
				competitorPriceTraces.push({
					type: "box",
					y: [min_price, max_price],
					x: label,
					name: label,
					hoverinfo: "none",
					marker: {
						color: COLORS[user_index],
					},
				});
			min_quantity &&
				max_quantity &&
				competitorQuantityTraces.push({
					type: "box",
					y: [min_quantity, max_quantity],
					x: label,
					name: label,
					hoverinfo: "none",
					marker: {
						color: COLORS[user_index],
					},
				});
			min_price &&
				max_price &&
				priceAnnotations.push({
					y: max_price,
					x: total_index,
					xref: "x",
					yref: "y",
					text: `${max_price}`,
					font: {
						color: COLORS[user_index],
					},
					ax: 0,
					ay: -10,
				});
			min_quantity &&
				max_quantity &&
				quantityAnnotations.push({
					y: max_quantity,
					x: total_index,
					xref: "x",
					yref: "y",
					text: `${max_quantity}`,
					font: {
						color: COLORS[user_index],
					},
					ax: 0,
					ay: -10,
				});
			if (parseFloat(max_price) !== parseFloat(min_price)) {
				let mid_price = ((parseFloat(min_price) + parseFloat(max_price)) / 2).toFixed(1);
				min_price &&
					max_price &&
					priceAnnotations.push(
						{
							y: min_price,
							x: total_index,
							xref: "x",
							yref: "y",
							text: `${min_price}`,
							font: {
								color: COLORS[user_index],
							},
							ax: 0,
							ay: 10,
						},
						{
							y: mid_price,
							x: total_index + 0.5,
							xref: "x",
							yref: "y",
							text: `${mid_price}`,
							font: {
								color: COLORS[user_index],
							},
							ax: 0,
							ay: 0,
						}
					);
			}
			if (parseFloat(max_quantity) !== parseFloat(min_quantity)) {
				let mid_quantity = ((parseFloat(min_quantity) + parseFloat(max_quantity)) / 2).toFixed(1);
				min_quantity &&
					max_quantity &&
					quantityAnnotations.push(
						{
							y: min_quantity,
							x: total_index,
							xref: "x",
							yref: "y",
							text: `${min_quantity}`,
							font: {
								color: COLORS[user_index],
							},
							ax: 0,
							ay: 10,
						},
						{
							y: mid_quantity,
							x: total_index + 0.5,
							xref: "x",
							yref: "y",
							text: `${mid_quantity}`,
							font: {
								color: COLORS[user_index],
							},
							ax: 0,
							ay: 0,
						}
					);
			}
			total_index++;
		});
		user_index++;
	}

	user_bid_config.forEach((bid) => {
		let price = bid.min_price || bid.price;
		let quantity = bid.min_quantity || bid.quantity;
		price &&
			userPriceTraces.push({
				type: "line",
				xref: "paper",
				x0: 0.025,
				y0: price,
				x1: 1,
				y1: price,
				line: {
					color: "grey",
					width: 2,
					dash: "dash",
				},
			});
		price &&
			priceAnnotations.push({
				y: price,
				x: 0,
				xref: "paper",
				yref: "y",
				text: `${price}`,
				font: {
					color: "grey",
				},
				ax: -5,
				ay: 0,
			});
		quantity &&
			userQuantityTraces.push({
				type: "line",
				xref: "paper",
				x0: 0.025,
				x1: 1,
				y0: quantity,
				y1: quantity,
				line: {
					color: "grey",
					width: 2,
					dash: "dash",
				},
			});
		quantity &&
			quantityAnnotations.push({
				y: quantity,
				x: 0,
				xref: "paper",
				yref: "y",
				text: `${quantity}`,
				font: {
					color: "grey",
				},
				ax: -5,
				ay: 0,
			});
	});

	return (
		<div className="card mb-3">
			<div className="row mb-3">
				<div className="col-12">
					<Plot
						data={competitorPriceTraces}
						layout={{
							yaxis: { showticklabels: false },
							title: "Bid Prices (€/MWh)",
							showlegend: false,
							annotations: priceAnnotations,
							shapes: userPriceTraces,
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
				<div className="col-12">
					<Plot
						data={competitorQuantityTraces}
						layout={{
							yaxis: { showticklabels: false },
							title: "Bid Quantities (MW)",
							showlegend: false,
							annotations: quantityAnnotations,
							shapes: userQuantityTraces,
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		</div>
	);
};
