const authHeaders = () => {
	var headers = {};
	// Add the API Key to the headers
	if (process.env.REACT_APP_BACKEND_API_KEY)
		headers = { ...headers, "x-api-key": process.env.REACT_APP_BACKEND_API_KEY };

	// Read the user object from the session storage and add JWT to authorization token
	const user = JSON.parse(window.sessionStorage.getItem("user"));
	if (user && user.token) headers = { ...headers, Authorization: "Bearer " + user.token };

	return headers;
};

export default authHeaders;
