import socket from "../socket";

export const joinTrackerRoom = async (id) => {
	await socket.emit("join_tracker", { tracker_id: id });
};

export const leaveAllRooms = async () => {
	await socket.emit("leave_tracker");
};

export const emitUpdateData = async (data) => {
	await socket.emit("tracker_update_data", data);
};

export const emitUpdatePreferences = async (data) => {
	await socket.emit("tracker_update_preferences", data);
};

export const emitAddRound = async (data) => {
	await socket.emit("tracker_add_data", data);
};

export const emitAddBid = async (data) => {
	await socket.emit("tracker_add_bid", data);
};

export const emitUpdateDataFromAuction = async (data) => {
	await socket.emit("tracker_fetch_auction_data", data);
};

export const emitUndoRound = async (data) => {
	await socket.emit("tracker_undo_data", data);
};

export const emitUndoBid = async (data) => {
	await socket.emit("tracker_undo_bid", data);
};

export const emitResetTracker = async (data) => {
	await socket.emit("tracker_reset", data);
};
