export const COLORS = [
	"#E69F00",
	"#56B4E9",
	"#009E73",
	"#0072B2",
	"#D55E00",
	"#CC79A7",
	"#6A00A3",
	"#964B00",
	"#FF8800",
	"#43db89",
];

export const auctionTypes = {
	SMRA: 1,
	MPSB: 2,
	ENOO: 3,
	RENO: 4,
};

export const userType = {
	ADMIN: "admin",
	USER: "user",
};

export const auctionStatus = {
	AWAITING_START: "Awaiting Start",
	IN_PROGRESS: "In Progress",
	IN_A_BREAK: "In a Break",
	ENDED: "Ended",
};

export const eventTypes = {
	AUCTION_REFRESH: "AUCTION_REFRESH",
	AUCTION_INFORMATION: "AUCTION_INFORMATION",
	AUCTION_SUCCESS: "AUCTION_SUCCESS",
	AUCTION_ERROR: "AUCTION_ERROR",
	AUCTION_WARNING: "AUCTION_WARNING",
};
