import moment from "moment";

function formatTime(seconds) {
	return moment.unix(seconds).format("HH:mm:ss Do MMM YYYY");
}

function formatDuration(seconds) {
	return moment.utc(seconds * 1000).format("mm:ss");
}

function formatDurationWithText(seconds) {
	// const t = moment.utc(seconds * 1000);
	// return `${t.format("mm")} min(s) ${t.format("ss")} sec(s)`

	return moment.utc(seconds * 1000).format("mm [min(s)] ss [sec(s)]");
}

function formatCurrency(stringValue) {
	const floatValue = parseFloat(stringValue);

	return Number(floatValue).toLocaleString("en", {
		minimumFractionDigits: 5,
		maximumFractionDigits: 5,
	});
}

function formatCurrencyWithText(stringValue) {
	return `NOK ${formatCurrency(stringValue)} øre/kWh`;
}

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(num);
}

function getPercentage(number, total, decimalPlaces) {
	if (!total)
		return Intl.NumberFormat("en", {
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(0);
	return Intl.NumberFormat("en", {
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	}).format((number * 100) / total);
}

export default {
	formatTime,
	formatCurrency,
	formatCurrencyWithText,
	formatDuration,
	formatDurationWithText,
	commaFormat,
	getPercentage,
};
