import {
	GET_SIMULATIONS,
	GET_SIMULATION,
	CREATE_SIMULATION,
	UPDATE_SIMULATION,
	RUN_SIMULATION,
	GET_SIMULATION_RUN,
	GET_SIMULATION_BIDS,
	GET_MARGINAL_BIDS,
	GET_STATISTICAL_ANALYSIS,
	SIMULATION_LOADING,
	SIMULATION_NOT_LOADING,
	RESET_SIMULATION,
} from "../actions/types";

const initialState = {
	simulations: [],
	simulation: {},
	simulationId: 0,
	statisticalAnalysis: [],
	run: {},
	bids: [],
	marginal_bids: [],
	simulation_loading: false,
	created: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_SIMULATIONS:
			return {
				...state,
				simulations: action.payload,
			};
		case GET_SIMULATION:
			return {
				...state,
				simulation: action.payload,
			};
		case CREATE_SIMULATION:
			return {
				...state,
				simulationId: action.payload.simulationId,
				created: true,
			};
		case UPDATE_SIMULATION:
			return {
				...state,
			};
		case RUN_SIMULATION:
			return {
				...state,
			};
		case GET_SIMULATION_RUN:
			return {
				...state,
				run: action.payload,
			};
		case GET_SIMULATION_BIDS:
			return {
				...state,
				bids: action.payload,
			};
		case GET_MARGINAL_BIDS:
			return {
				...state,
				marginal_bids: action.payload,
			};
		case GET_STATISTICAL_ANALYSIS:
			return {
				...state,
				statisticalAnalysis: action.payload,
			};
		case SIMULATION_LOADING:
			return {
				...state,
				simulation_loading: true,
			};
		case SIMULATION_NOT_LOADING:
			return {
				...state,
				simulation_loading: false,
			};
		case RESET_SIMULATION:
			return {
				...state,
				simulation: {},
				run: {},
				marginal_bids: [],
				statisticalAnalysis: [],
				simulationId: 0,
				simulation_loading: false,
				created: false,
			};
		default:
			return state;
	}
}
