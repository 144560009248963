import React, { Component } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import { COLORS } from "../../../common/constants";
import util from "../../../../util/RENO";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clearingPrice: {
				average: null,
				minimum: null,
				maximum: null,
			},
			graphRange: {
				min: null,
				max: null,
			},
		};
		this.orderOfBidders = ["Bidder 01", "Bidder 02", "Bidder 03", "Bidder 04"];
	}

	componentDidMount() {
		const simulation = this.props.simulation;

		let clearingPriceData = simulation.analysis.filter((data) => data.graphId === 0);
		this.setState({
			...this.state,
			clearingPrice: {
				average: clearingPriceData.filter((data) => data.xValue === "average")[0].yValue,
				minimum: clearingPriceData.filter((data) => data.xValue === "minimum")[0].yValue,
				maximum: clearingPriceData.filter((data) => data.xValue === "maximum")[0].yValue,
			},
			graphRange: {
				min: clearingPriceData.filter((data) => data.xValue === "graphMin")[0].yValue,
				max: clearingPriceData.filter((data) => data.xValue === "graphMax")[0].yValue,
			},
		});
	}

	__getBinMinFromRange(range) {
		return parseFloat(parseFloat(range.split(" - ")[0]).toFixed(3));
	}

	__getBinMaxFromRange(range) {
		return parseFloat(parseFloat(range.split(" - ")[1]).toFixed(3));
	}

	downloadDataAsXLSX() {
		const simulation = this.props.simulation;
		const user = this.props.user;

		// Simulation Config Worksheet
		const simulationConfigJSON = [
			{ ["Variable"]: "Number of Runs", ["Value"]: simulation.runs },
			{ ["Variable"]: "Minimum Contract Price", ["Value"]: simulation.minimumContractPrice },
			{ ["Variable"]: "Bid Upper Limit", ["Value"]: simulation.bidUpperLimit },
			{ ["Variable"]: "Minimum Bid Decrement", ["Value"]: simulation.minimumDecrement },
		];
		const simulationConfigWorksheet = XLSX.utils.json_to_sheet(simulationConfigJSON);

		// Bid Config Worksheet
		const bidConfigJSON = simulation.simConfig.map((config) => ({
			["Bidder Name"]: config.username,
			["Minimum Bid Amount"]: config.minAmount,
			["Maximum Bid Amount"]: config.maxAmount,
			["Minimum Bid Decrement"]: config.minDecrement,
			["Maximum Bid Decrement"]: config.maxDecrement,
			["Naive Bid Probability"]: config.naiveBidProbability,
		}));
		const bidConfigWorksheet = XLSX.utils.json_to_sheet(bidConfigJSON);

		// Win Percentage by Bidder Worksheet
		const winsByBidderGraphData = simulation.analysis.filter((data) => data.graphId === 1);
		const winsByBidderJSON = winsByBidderGraphData.map((data) => ({
			["Bidder Name"]: data.xValue,
			["Win Percentage"]: util.getPercentage(data.yValue, simulation.runs, 3),
		}));
		const winsByBidderWorksheet = XLSX.utils.json_to_sheet(winsByBidderJSON);

		// Clearing Prices Worksheet
		let selectedClearingPriceWinGraphId = 18;
		let selectedClearingPriceLossGraphId = 22;
		if (this.props.binSize === this.props.binSizes.TEN) {
			selectedClearingPriceWinGraphId = 18;
			selectedClearingPriceLossGraphId = 22;
		} else if (this.props.binSize === this.props.binSizes.TWE) {
			selectedClearingPriceWinGraphId = 19;
			selectedClearingPriceLossGraphId = 23;
		} else if (this.props.binSize === this.props.binSizes.FIF) {
			selectedClearingPriceWinGraphId = 20;
			selectedClearingPriceLossGraphId = 24;
		} else if (this.props.binSize === this.props.binSizes.ONE) {
			selectedClearingPriceWinGraphId = 21;
			selectedClearingPriceLossGraphId = 25;
		}
		let clearingPricesWinGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedClearingPriceWinGraphId)
			.sort((a, b) => (a.xValue > b.xValue ? 1 : b.xValue > a.xValue ? -1 : 0));
		let clearingPricesLossGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedClearingPriceLossGraphId)
			.sort((a, b) => (a.xValue > b.xValue ? 1 : b.xValue > a.xValue ? -1 : 0));
		let clearingPricesJSON = clearingPricesWinGraphData.map((winGraph, index) => ({
			["Range Min"]: this.__getBinMinFromRange(winGraph.xValue),
			["Range Max"]: this.__getBinMaxFromRange(winGraph.xValue),
			["Wins"]: winGraph.yValue,
			["Losses"]: clearingPricesLossGraphData[index].yValue,
		}));
		const clearingPricesWorksheet = XLSX.utils.json_to_sheet(clearingPricesJSON);

		// Win/Loss Probability and Distribution Worksheet
		let selectedWinProbGraphId = 10;
		let selectedLossProbGraphId = 14;
		let selectedWinMinAmountGraphId = 26;
		let selectedLossMinAmountGraphId = 30;
		if (this.props.binSize === this.props.binSizes.TEN) {
			selectedWinProbGraphId = 10;
			selectedLossProbGraphId = 14;
			selectedWinMinAmountGraphId = 26;
			selectedLossMinAmountGraphId = 30;
		} else if (this.props.binSize === this.props.binSizes.TWE) {
			selectedWinProbGraphId = 11;
			selectedLossProbGraphId = 15;
			selectedWinMinAmountGraphId = 27;
			selectedLossMinAmountGraphId = 31;
		} else if (this.props.binSize === this.props.binSizes.FIF) {
			selectedWinProbGraphId = 12;
			selectedLossProbGraphId = 16;
			selectedWinMinAmountGraphId = 28;
			selectedLossMinAmountGraphId = 32;
		} else if (this.props.binSize === this.props.binSizes.ONE) {
			selectedWinProbGraphId = 13;
			selectedLossProbGraphId = 17;
			selectedWinMinAmountGraphId = 29;
			selectedLossMinAmountGraphId = 33;
		}
		let winProbGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedWinProbGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let lossProbGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedLossProbGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let winMinAmountGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedWinMinAmountGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let lossMinAmountGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedLossMinAmountGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));

		let probabilityDistributionJSON = [];
		winProbGraphData.forEach((winGraph, index) => {
			let filteredWinMinAmountGraphData = winMinAmountGraphData.filter((data) => data.xValue === winGraph.xValue);
			let filteredLossMinAmountGraphData = lossMinAmountGraphData.filter(
				(data) => data.xValue === winGraph.xValue
			);

			let winPercentageJSONValue = 0;
			let lossPercentageJSONValue = 0;
			let winNumberJSONValue = 0;
			let lossNumberJSONValue = 0;
			if (filteredWinMinAmountGraphData.length > 0 && filteredLossMinAmountGraphData.length > 0) {
				let winMinAmountDataPoint = filteredWinMinAmountGraphData[0];
				let lossMinAmountDataPoint = filteredLossMinAmountGraphData[0];

				winNumberJSONValue = winMinAmountDataPoint.yValue;
				lossNumberJSONValue = lossMinAmountDataPoint.yValue;
				let rangeTotal = winNumberJSONValue + lossNumberJSONValue;
				winPercentageJSONValue = parseFloat(util.getPercentage(winMinAmountDataPoint.yValue, rangeTotal, 2));
				lossPercentageJSONValue = parseFloat(util.getPercentage(lossMinAmountDataPoint.yValue, rangeTotal, 2));
			}

			probabilityDistributionJSON.push({
				["Range Min"]: this.__getBinMinFromRange(winGraph.xValue),
				["Range Max"]: this.__getBinMaxFromRange(winGraph.xValue),
				["Calculated Win Probability"]: winGraph.yValue,
				["Calculated Loss Probability"]: lossProbGraphData[index].yValue,
				["Simulated Win Percentage"]: winPercentageJSONValue,
				["Simulated Loss Percentage"]: lossPercentageJSONValue,
				["Simulated Number of Win Bids"]: winNumberJSONValue,
				["Simulated Number of Loss Bids"]: lossNumberJSONValue,
			});
		});
		const probabilityDistributionWorksheet = XLSX.utils.json_to_sheet(probabilityDistributionJSON);

		// Create the workbook and add all the sheets to it
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, simulationConfigWorksheet, "Simulation Config");
		XLSX.utils.book_append_sheet(workbook, bidConfigWorksheet, "Bid Config");
		XLSX.utils.book_append_sheet(workbook, winsByBidderWorksheet, "Win Percentage by Bidder");
		XLSX.utils.book_append_sheet(workbook, clearingPricesWorksheet, "Clearing Price Distribution");
		XLSX.utils.book_append_sheet(workbook, probabilityDistributionWorksheet, "Probability Distribution");

		// Download the worksheet
		const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
		const blob = new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
		});
		saveAs(blob, `AST (${simulation.name.replace(/[^a-z0-9]/gi, "_")}) Data`);
	}

	renderWinsByBidderGraph() {
		const simulation = this.props.simulation;
		const user = this.props.user;

		let allUsernames = [];
		let chartData = [];

		let graphData = simulation.analysis.filter((data) => data.graphId === 1);
		let ownerName = user.display_name;
		let simulatedUsers = [
			...new Set(graphData.filter((a) => a.xValue != ownerName).map((data) => data.xValue)),
		].sort();

		// allUsernames = [ownerName, ...simulatedUsers];
		allUsernames = this.orderOfBidders;

		allUsernames.forEach((username) => {
			let userWins = graphData.filter((data) => data.xValue === username)[0].yValue;
			let perc = util.getPercentage(userWins, simulation.runs, 2);
			chartData.push({
				x: [username],
				y: [perc],
				text: `${perc}%`,
				customdata: [
					{
						wins: util.commaFormat(userWins),
						runs: util.commaFormat(simulation.runs),
					},
				],
				name: username,
				type: "bar",
				hovertemplate:
					"<b>%{x}</b> won<br><b>%{y}%</b> of the time<br>(%{customdata.wins} / %{customdata.runs})" +
					"<extra></extra>",
			});
		});

		return (
			<Plot
				data={chartData}
				layout={{
					title: "Percentage of Wins by Bidder",
					type: "bar",
					autosize: true,
					showlegend: false,
					yaxis: {
						showgrid: true,
						zeroline: true,
						showline: true,
						uirevision: "time",
						showticklabels: true,
						ticksuffix: "%",
					},
					xaxis: {
						title: "Bidder",
					},
					colorway: COLORS,
					hoverlabel: { bgcolor: "#FFF" },
				}}
				style={{ width: "100%", height: "100%", padding: "0", maring: "0" }}
				useResizeHandler
			/>
		);
	}

	renderClearingPricesGraph() {
		const simulation = this.props.simulation;

		let selectedClearingPriceWinGraphId = 18;
		let selectedClearingPriceLossGraphId = 22;
		if (this.props.binSize === this.props.binSizes.TEN) {
			selectedClearingPriceWinGraphId = 18;
			selectedClearingPriceLossGraphId = 22;
		} else if (this.props.binSize === this.props.binSizes.TWE) {
			selectedClearingPriceWinGraphId = 19;
			selectedClearingPriceLossGraphId = 23;
		} else if (this.props.binSize === this.props.binSizes.FIF) {
			selectedClearingPriceWinGraphId = 20;
			selectedClearingPriceLossGraphId = 24;
		} else if (this.props.binSize === this.props.binSizes.ONE) {
			selectedClearingPriceWinGraphId = 21;
			selectedClearingPriceLossGraphId = 25;
		}

		let chartData = [];
		let winGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedClearingPriceWinGraphId)
			.sort((a, b) => (a.xValue > b.xValue ? 1 : b.xValue > a.xValue ? -1 : 0));
		let lossGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedClearingPriceLossGraphId)
			.sort((a, b) => (a.xValue > b.xValue ? 1 : b.xValue > a.xValue ? -1 : 0));

		const totalWins = winGraphData.reduce((n, { yValue }) => n + yValue, 0);
		const totalLosses = simulation.runs - totalWins;

		let winGraphX = [];
		let winGraphY = [];
		let lossGraphX = [];
		let lossGraphY = [];
		let customData = [];

		winGraphData.forEach(({ xValue, yValue }, index) => {
			let lossGraphDataPoint = lossGraphData[index];

			let rangeTotal = yValue + lossGraphDataPoint.yValue;
			let rangeTotalPerc = util.getPercentage(rangeTotal, simulation.runs, 2);
			let rangeWinPerc = util.getPercentage(yValue, rangeTotal, 2);
			let rangeLossPerc = util.getPercentage(lossGraphDataPoint.yValue, rangeTotal, 2);
			let overallWinPerc = util.getPercentage(yValue, totalWins, 2);
			let overallLossPerc = util.getPercentage(lossGraphDataPoint.yValue, totalLosses, 2);

			winGraphX.push(this.__getBinMinFromRange(xValue));
			winGraphY.push(util.getPercentage(yValue, simulation.runs, 2));

			lossGraphX.push(this.__getBinMinFromRange(lossGraphDataPoint.xValue));
			lossGraphY.push(util.getPercentage(lossGraphDataPoint.yValue, simulation.runs, 2));

			customData.push({
				range: xValue,
				rangeTotalPerc: rangeTotalPerc,
				rangeWinPerc: rangeWinPerc,
				rangeLossPerc: rangeLossPerc,
				overallWinPerc: overallWinPerc,
				overallLossPerc: overallLossPerc,
			});
		});

		chartData.push({
			name: "Wins",
			x: winGraphX,
			y: winGraphY,
			type: "bar",
			customdata: customData,
			marker: {
				color: COLORS[2],
			},
			hovertemplate:
				"<b>%{customdata.rangeTotalPerc}%</b> of clearing prices were<br>" +
				"in the <b>%{customdata.range}</b> range<br><br>" +
				"You <b>won</b> <b>%{customdata.rangeWinPerc}%</b> of trials<br>" +
				"in this range. (%{customdata.overallWinPerc}% of all wins)<br><br>" +
				"You <b>lost</b> <b>%{customdata.rangeLossPerc}%</b> of trials<br>" +
				"in this range. (%{customdata.overallLossPerc}% of all losses)" +
				"<extra></extra>",
		});

		chartData.push({
			name: "Losses",
			x: lossGraphX,
			y: lossGraphY,
			type: "bar",
			customdata: customData,
			marker: {
				color: "rgb(220,20,60)",
			},
			hovertemplate:
				"<b>%{customdata.rangeTotalPerc}%</b> of clearing prices were<br>" +
				"in the <b>%{customdata.range}</b> range<br><br>" +
				"You <b>won</b> <b>%{customdata.rangeWinPerc}%</b> of trials<br>" +
				"in this range. (%{customdata.overallWinPerc}% of all wins)<br><br>" +
				"You <b>lost</b> <b>%{customdata.rangeLossPerc}%</b> of trials<br>" +
				"in this range. (%{customdata.overallLossPerc}% of all losses)" +
				"<extra></extra>",
		});

		return (
			<Plot
				data={chartData}
				layout={{
					title: `Clearing Price Distribution`,
					type: "bar",
					autosize: true,
					showlegend: true,
					barmode: "stack",
					yaxis: {
						showgrid: true,
						zeroline: true,
						showline: true,
						uirevision: "time",
						fixedrange: true,
						showticklabels: true,
						ticksuffix: "%",
					},
					xaxis: {
						range: [this.state.graphRange.min, this.state.graphRange.max],
						title: "Clearing Price Ranges (in NOK øre/kWh)",
						nticks: 20,
						tickformat: "0.3",
					},
					hoverlabel: { bgcolor: "#FFF" },
				}}
				style={{ width: "100%", height: "100%", padding: "0", maring: "0" }}
				useResizeHandler
				config={{
					responsive: true,
					modeBarButtonsToRemove: ["lasso2d", "select2d", "zoomIn", "zoomOut", "zoom", "pan", "autoscale"],
					displaylogo: false,
					displayModeBar: true,
				}}
			/>
		);
	}

	renderWinningProbabilityGraph() {
		const simulation = this.props.simulation;
		const user = this.props.user;

		let selectedWinProbGraphId = 10;
		let selectedLossProbGraphId = 14;
		let selectedWinAmountGraphId = 26;
		let selectedLossAmountGraphId = 30;
		if (this.props.binSize === this.props.binSizes.TEN) {
			selectedWinProbGraphId = 10;
			selectedLossProbGraphId = 14;
			selectedWinAmountGraphId = 26;
			selectedLossAmountGraphId = 30;
		} else if (this.props.binSize === this.props.binSizes.TWE) {
			selectedWinProbGraphId = 11;
			selectedLossProbGraphId = 15;
			selectedWinAmountGraphId = 27;
			selectedLossAmountGraphId = 31;
		} else if (this.props.binSize === this.props.binSizes.FIF) {
			selectedWinProbGraphId = 12;
			selectedLossProbGraphId = 16;
			selectedWinAmountGraphId = 28;
			selectedLossAmountGraphId = 32;
		} else if (this.props.binSize === this.props.binSizes.ONE) {
			selectedWinProbGraphId = 13;
			selectedLossProbGraphId = 17;
			selectedWinAmountGraphId = 29;
			selectedLossAmountGraphId = 33;
		}

		let chartData = [];
		let winProbGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedWinProbGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let lossProbGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedLossProbGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let winAmountGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedWinAmountGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));
		let lossAmountGraphData = simulation.analysis
			.filter((data) => data.graphId === selectedLossAmountGraphId)
			.sort((a, b) => this.__getBinMinFromRange(a.xValue) - this.__getBinMinFromRange(b.xValue));

		let winProbGraphX = [];
		let winProbGraphY = [];
		winProbGraphData.forEach(({ xValue, yValue }) => {
			winProbGraphX.push(this.__getBinMinFromRange(xValue));
			// winProbGraphY.push(0.05 + yValue * 0.9);
			winProbGraphY.push(yValue);
		});

		chartData.push({
			name: "Win Probability (with Core Ranges)",
			x: winProbGraphX,
			y: winProbGraphY,
			type: "lines",
			line: {
				color: "rgba(0, 158, 115, 0.4)",
				dash: "dot",
				width: 2,
			},
			hovertemplate:
				"There is a <b>%{y}%</b> probability of winning <br>with a bid envelope of NOK <b>%{x}</b> øre/kWh.<br><br>(Assuming core ranges for all competitors)" +
				"<extra></extra>",
		});

		let lossProbGraphX = [];
		let lossProbGraphY = [];
		lossProbGraphData.forEach(({ xValue, yValue }) => {
			lossProbGraphX.push(this.__getBinMinFromRange(xValue));
			// lossProbGraphY.push(0.05 + yValue * 0.9);
			lossProbGraphY.push(yValue);
		});

		chartData.push({
			name: "Loss Probability (with Core Ranges)",
			x: lossProbGraphX,
			y: lossProbGraphY,
			mode: "lines",
			line: {
				color: "rgba(220,20,60, 0.4)",
				dash: "dot",
				width: 2,
			},
			hovertemplate:
				"There is a <b>%{y}%</b> probability of losing <br> with a bid envelope of NOK <b>%{x}</b> øre/kWh.<br><br>(Assuming core ranges for all competitors)" +
				"<extra></extra>",
		});

		let winGraphX = [];
		let winGraphY = [];
		let lossGraphX = [];
		let lossGraphY = [];
		let customData = [];

		const totalWins = winAmountGraphData.reduce((n, { yValue }) => n + yValue, 0);
		const totalLosses = simulation.runs - totalWins;

		winAmountGraphData.forEach(({ xValue, yValue }, index) => {
			let lossGraphDataPoint = lossAmountGraphData[index];

			let rangeTotal = yValue + lossGraphDataPoint.yValue;
			let rangeTotalPerc = util.getPercentage(rangeTotal, simulation.runs, 2);
			let rangeWinPerc = util.getPercentage(yValue, rangeTotal, 2);
			let rangeLossPerc = util.getPercentage(lossGraphDataPoint.yValue, rangeTotal, 2);
			let overallWinPerc = util.getPercentage(yValue, totalWins, 2);
			let overallLossPerc = util.getPercentage(lossGraphDataPoint.yValue, totalLosses, 2);

			winGraphX.push(this.__getBinMinFromRange(xValue));
			winGraphY.push(util.getPercentage(yValue, rangeTotal, 2));

			lossGraphX.push(this.__getBinMinFromRange(lossGraphDataPoint.xValue));
			lossGraphY.push(util.getPercentage(lossGraphDataPoint.yValue, rangeTotal, 2));

			customData.push({
				range: xValue,
				rangeTotalPerc: rangeTotalPerc,
				rangeWinPerc: rangeWinPerc,
				rangeLossPerc: rangeLossPerc,
				overallWinPerc: overallWinPerc,
				overallLossPerc: overallLossPerc,
			});
		});

		chartData.push({
			name: "Simulated Wins",
			x: winGraphX,
			y: winGraphY,
			yaxis: "y2",
			type: "bar",
			customdata: customData,
			marker: {
				color: "rgba(0, 158, 115, 1)",
			},
			hovertemplate:
				"<b>%{customdata.rangeTotalPerc}%</b> of bid envelopes were<br>" +
				"in the <b>%{customdata.range}</b> range<br><br>" +
				"You <b>won %{customdata.rangeWinPerc}%</b> of trials<br>" +
				"with a bid envelope in this range.<br>(%{customdata.overallWinPerc}% of all wins)<br><br>" +
				"You <b>lost %{customdata.rangeLossPerc}%</b> of trials<br>" +
				"with a bid envelope in this range.<br>(%{customdata.overallLossPerc}% of all losses)" +
				"<extra></extra>",
		});

		chartData.push({
			name: "Simulated Losses",
			x: lossGraphX,
			y: lossGraphY,
			yaxis: "y2",
			type: "bar",
			customdata: customData,
			marker: {
				color: "rgba(220,20,60, 1)",
			},
			hovertemplate:
				"<b>%{customdata.rangeTotalPerc}%</b> of bid envelopes were<br>" +
				"in the <b>%{customdata.range}</b> range<br><br>" +
				"You <b>won %{customdata.rangeWinPerc}%</b> of trials<br>" +
				"with a bid envelope in this range.<br>(%{customdata.overallWinPerc}% of all wins)<br><br>" +
				"You <b>lost %{customdata.rangeLossPerc}%</b> of trials<br>" +
				"with a bid envelope in this range.<br>(%{customdata.overallLossPerc}% of all losses)" +
				"<extra></extra>",
		});

		return (
			<Plot
				data={chartData}
				layout={{
					title: `Win/Loss Probabilities (with Core Ranges) and Simulated Win/Loss Distribution vs Your Bid Amounts`,
					autosize: true,
					showlegend: true,
					legend: { orientation: "h", xanchor: "center", x: 0.5 },
					yaxis: {
						title: "Win/Loss Probabilities (with Core Ranges)",
						range: [0, 1],
						showgrid: false,
						zeroline: true,
						showline: true,
						uirevision: "time",
						showticklabels: true,
						tickformat: "0%",
						fixedrange: true,
					},
					xaxis: {
						// title: `${user.display_name}'s Minimum Bid Price (in NOK øre/kWh)`,
						nticks: 20,
						range: [this.state.graphRange.min, this.state.graphRange.max],
					},
					yaxis2: {
						title: "Simulated Win/Loss Distribution",
						range: [0, 100],
						overlaying: "y",
						side: "right",
						showline: true,
						showgrid: false,
						ticksuffix: "%",
						fixedrange: true,
					},
					hoverlabel: { bgcolor: "#FFF" },
				}}
				style={{ width: "100%", height: "100%", padding: "0", maring: "0" }}
				useResizeHandler
				config={{
					responsive: true,
					modeBarButtonsToRemove: ["lasso2d", "select2d", "zoomIn", "zoomOut", "zoom", "pan", "autoscale"],
					displaylogo: false,
					displayModeBar: true,
				}}
			/>
		);
	}

	render() {
		const simulation = this.props.simulation;
		const userConfig = simulation.simConfig.filter((config) => config.owner === 1)[0];
		const userWins = simulation.analysis
			.filter((data) => data.graphId === 1)
			.filter((data) => data.xValue === userConfig.username)[0].yValue;

		return (
			<div>
				<div className="row">
					<div className="col" style={{ textAlign: "right", alignItems: "right" }}>
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => this.downloadDataAsXLSX()}
						>
							<i class="fas fa-file-download"></i> Export Data to XLSX
						</button>
					</div>
				</div>
				<br />

				<div className="row">
					<div className="col-4">
						<div className="card">
							<ul className="list-group list-group-flush">
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Minimum Contract Price:{" "}
										<strong>{util.formatCurrencyWithText(simulation.minimumContractPrice)}</strong>
									</p>
								</li>
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Bid Upper Limit:{" "}
										<strong>{util.formatCurrencyWithText(simulation.bidUpperLimit)}</strong>
									</p>
								</li>
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Minimum Bid Decrement:{" "}
										<strong>{util.formatCurrencyWithText(simulation.minimumDecrement)}</strong>
									</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-4">
						<div className="card">
							<ul className="list-group list-group-flush">
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Your Bid Envelope:{" "}
										<strong>
											{userConfig ? util.formatCurrencyWithText(userConfig.minAmount) : ""}
										</strong>
									</p>
								</li>
								{/* <li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Your Maximum Bid:{" "}
										<strong>
											{userConfig ? util.formatCurrencyWithText(userConfig.maxAmount) : ""}
										</strong>
									</p>
								</li> */}
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Your Win Percentage:{" "}
										<strong>
											{userWins ? `${util.getPercentage(userWins, simulation.runs, 2)}%` : ""}
										</strong>
									</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-4">
						<div className="card">
							<ul className="list-group list-group-flush">
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Minimum Clearing Price:{" "}
										<strong>{util.formatCurrencyWithText(this.state.clearingPrice.minimum)}</strong>
									</p>
								</li>
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Average Clearing Price:{" "}
										<strong>{util.formatCurrencyWithText(this.state.clearingPrice.average)}</strong>
									</p>
								</li>
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Maximum Clearing Price:{" "}
										<strong>{util.formatCurrencyWithText(this.state.clearingPrice.maximum)}</strong>
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-12">
						<div className="card" style={{ margin: "auto", height: "50vh" }}>
							<div className="card-body">{this.renderWinsByBidderGraph()}</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-12">
						<div className="card" style={{ margin: "auto", height: "60vh" }}>
							<div className="card-body">{this.renderWinningProbabilityGraph()}</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-12">
						<div className="card" style={{ margin: "auto", height: "60vh" }}>
							<div className="card-body">{this.renderClearingPricesGraph()}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Overview.propTypes = {
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	binSizes: PropTypes.object.isRequired,
	binSize: PropTypes.string.isRequired,
};

export default Overview;
