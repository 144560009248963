import React, { Component } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import SMRAAuction from "../../SMRAAuction/Pages/SMRAAuction";
import MPSBAuction from "../../MPSBAuction/Pages/MPSBAuction";
import ENOOAuction from "../../ENOOAuction/Pages/ENOOAuction";
import ErrorPage from "../../../../ErrorPage";
import RENOAuction from "../../RENOAuction/Pages/RENOAuction";

class Auction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auctionType: null,
		};
	}

	async componentDidMount() {
		const typeId = this.props.auction.auctions.filter((x) => {
			return parseInt(x.id) === parseInt(this.props.params.auctionId);
		});
		if (typeId.length > 0) {
			this.setState({
				...this.state,
				auctionType: parseInt(typeId[0].type),
			});
		} else {
			this.setState({
				...this.state,
				auctionType: null,
			});
		}
	}

	render() {
		if (this.state.auctionType === 1) {
			return <SMRAAuction />;
		} else if (this.state.auctionType === 2) {
			return <MPSBAuction />;
		} else if (this.state.auctionType === 3) {
			return <ENOOAuction />;
		} else if (this.state.auctionType === 4) {
			return <RENOAuction />;
		} else {
			return <ErrorPage />;
		}
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <Auction {...props} params={params} />;
}

Auction.propTypes = {};

const mapStateToProps = (state) => ({
	auction: state.auction,
});

export default connect(mapStateToProps, {})(WithParamsAndNavigate);
