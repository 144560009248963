import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import backgroundImage from "../../assets/offshore-wind-energy-infrastructure.jpeg";
import { PopupButton } from "@typeform/embed-react";
import { Link } from "react-router-dom";

class Landing extends Component {
	signInRedirect() {
		this.props.history.push(`/login`);
	}

	render() {
		if (this.props.user.signedIn) {
			return <Redirect to="/auctions" />;
		} else {
			return (
				<div className="container">
					<br />
					<br />
					<div className="container">
						<div
							className="card"
							style={{
								width: "90%",
								margin: "auto",
								height: "70vh",
								backgroundColor: "#0275d8",
								borderRadius: "5px",
								boxShadow: "2px 2px 4px #000000",
							}}
						>
							<div className="card-body" style={{ padding: "0px" }}>
								<div className="row" style={{ height: "70vh" }}>
									<div className="col-7" style={{ padding: "10%" }}>
										<h2 style={{ color: "white" }}>
											Welcome to <em>RenewableAuctions.com</em>
										</h2>
										<button
											className="btn btn-light"
											style={{ marginTop: "5%", marginBottom: "5%" }}
											onClick={this.signInRedirect.bind(this)}
										>
											Get Started
										</button>
										<hr style={{ color: "white" }} />
										<br />
										<h6 style={{ color: "white" }}>For any queries, get in touch via</h6>

										<div className="btn-group" role="group" style={{ width: "60%" }}>
											<div className="btn btn-sm btn-outline-light">
												<Link
													to="#"
													onClick={(e) => {
														window.location.href = "mailto:gustav.gordon@paconsulting.com";
														e.preventDefault();
													}}
													style={{ textDecoration: "none", color: "white" }}
												>
													Email
												</Link>
											</div>
											<PopupButton
												id="PPEsic7H"
												size={50}
												className="btn btn-sm btn-outline-light"
											>
												Contact Form
											</PopupButton>
										</div>
									</div>
									<div className="col-5" style={{ overflow: "hidden" }}>
										<img
											src={backgroundImage}
											style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
				</div>
			);
		}
	}
}

Landing.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, {})(Landing);
