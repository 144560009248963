import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getTracker,
	addRound,
	addBid,
	deleteBid,
	deleteRound,
	updatePreferences,
	updateData,
	updateBTTAuctionData,
	setTrackersLoading,
	setTrackersNotLoading,
	resetTracker,
	startTracker,
} from "../../actions/trackerActions";
import { getAuction } from "../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import PriceLineChart from "./tracker-components/PriceLineChart";
import PercentageLineChart from "./tracker-components/PercentageLineChart";
import ExitBidAreaChart from "./tracker-components/ExitBidAreaChart";
import CustomLineChart from "./tracker-components/CustomLineChart";
import { confirm } from "react-confirm-box";
import socket from "../../helpers/socket";
import { addNotification } from "../common/NotificationManager";
import { joinTrackerRoom } from "../../helpers/emit/tracker";

class BidTracker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			demands: {},
			prices: {},
			selectedLeaseIds: [],
			editInfo: false,
			editRoundInfo: false,
			siteValues: {},
			priorityValues: {},
			budgetValues: {},
			dataValues: {},
			filterGraphValue: "All",
			filteredLeaseGraphValue: -1,
			bidStrategyFilterValue: "select",
		};

		this.onStatusDropDownChange = this.onStatusDropDownChange.bind(this);
		this.onLeaseDropDownChange = this.onLeaseDropDownChange.bind(this);
	}

	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			bidStrategyFilterValue: event.target.value,
		});
	}

	async joinSocketRoom() {
		await joinTrackerRoom(this.props.params.bidTrackerId);
	}

	async refresh() {
		await this.props.setTrackersLoading();
		await this.fetchData();
		await this.props.setTrackersNotLoading();
	}

	async fetchData() {
		await this.props.getTracker(this.props.params.bidTrackerId);
		await this.props.getAuction(this.props.tracker.tracker.auction_id);

		this.setState({
			...this.state,
			demands: {},
			prices: {},
			selectedLeaseIds: [],
			editInfo: false,
			editRoundInfo: false,
			siteValues: {},
			priorityValues: {},
			budgetValues: {},
			dataValues: {},
			filterGraphValue: "All",
			filteredLeaseGraphValue: -1,
		});

		const { tracker } = this.props.tracker;

		if (tracker.preferences && tracker.preferences.length > 0) {
			let temp = {};
			let temp2 = {};
			let temp3 = {};
			this.props.tracker.tracker.preferences.forEach((pref) => {
				temp[pref.lease_id] = pref.site_value;
				temp2[pref.lease_id] = pref.priority;
				temp3[pref.lease_id] = pref.budget;
			});
			this.setState({
				...this.state,
				siteValues: temp,
				priorityValues: temp2,
				budgetValues: temp3,
			});
		}

		if (tracker.leases && tracker.leases.length > 0) {
			let rounds = [];
			for (let i = parseInt(tracker.round); i > 0; i--) {
				rounds.push(i);
			}

			rounds.forEach((round) => {
				tracker.leases.forEach((lease) => {
					const data = tracker.data.filter((d) => d.lease_id === lease.id && d.round === round);
					if (!this.state.dataValues.hasOwnProperty(round)) this.state.dataValues[round] = {};
					this.state.dataValues[round][lease.id] = {
						demand: data.length > 0 ? data[0].demand : 0,
						price: data.length > 0 ? data[0].price : 0,
						placed_bid: data.length > 0 ? data[0].placed_bid : 0,
					};
				});
			});
		}
	}

	onSiteValueChange(event) {
		let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		if (isNaN(value)) {
			value = 0;
		}
		this.setState({
			...this.state,
			siteValues: {
				...this.state.siteValues,
				[event.target.name]: value,
			},
		});
	}

	onPriorityValueChange(event) {
		this.setState({
			...this.state,
			priorityValues: {
				...this.state.priorityValues,
				[event.target.name]: parseInt(event.target.value),
			},
		});
	}

	onBudgetValueChange(event) {
		let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		if (isNaN(value)) {
			value = 0;
		}
		this.setState({
			...this.state,
			budgetValues: {
				...this.state.budgetValues,
				[event.target.name]: value,
			},
		});
	}

	onDataValueChange(event) {
		let value;
		if (event.target.name === "price") {
			value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) {
				value = 0;
			}
		} else if (event.target.name === "demand") {
			value = parseInt(event.target.value);
		} else if (event.target.name === "placed_bid") {
			value = event.target.checked ? 1 : 0;
		}
		let dataValues = this.state.dataValues;
		dataValues[event.target.getAttribute("round")][event.target.getAttribute("lease_id")][event.target.name] =
			value;
		this.setState({ ...this.state, dataValues });
	}

	editInfo() {
		this.setState({ ...this.state, editInfo: true });
	}

	async setInfo() {
		const { tracker } = this.props.tracker;

		if (tracker.leases && tracker.leases.length > 0) {
			let preferences = [];
			tracker.leases.forEach((lease) => {
				preferences.push({
					lease_id: lease.id,
					site_value: this.state.siteValues[lease.id],
					priority: this.state.priorityValues[lease.id],
					budget: this.state.budgetValues[lease.id],
				});
			});
			const payload = { preferences };

			this.setState({ ...this.state, editInfo: false });
			await this.props.updatePreferences(this.props.params.bidTrackerId, payload);
			await this.fetchData();
		}
	}

	editRoundInfo() {
		this.setState({ ...this.state, editRoundInfo: true });
	}

	async setRoundInfo() {
		this.setState({ ...this.state, editRoundInfo: false });

		const data = [];
		this.props.tracker.tracker.data.forEach((d) => {
			data.push({
				lease_id: d.lease_id,
				round: d.round,
				price: this.state.dataValues[d.round][d.lease_id].price,
				demand: this.state.dataValues[d.round][d.lease_id].demand,
				placed_bid: this.state.dataValues[d.round][d.lease_id].placed_bid,
			});
		});

		const payload = { data };
		await this.props.updateData(this.props.params.bidTrackerId, payload);
		await this.fetchData();
	}

	async fetchAuctionState() {
		const result = await confirm("Fetch Auction State", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>
									Would you like to fetch the corresponding auction state and override the current bid
									tracking state?
								</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.updateBTTAuctionData(this.props.params.bidTrackerId);
			this.refresh();
		}
	}

	onDemandChange(event) {
		this.setState({
			...this.state,
			demands: {
				...this.state.demands,
				[event.target.name]: parseInt(event.target.value),
			},
		});
	}

	onPriceChange(event) {
		let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		if (!isNaN(value)) {
			this.setState({
				...this.state,
				prices: { ...this.state.prices, [event.target.name]: value },
			});
		}
	}

	onStatusDropDownChange(event) {
		this.setState({ ...this.state, filterGraphValue: event.target.value });
	}

	onLeaseDropDownChange(event) {
		this.setState({
			...this.state,
			filteredLeaseGraphValue: event.target.value,
		});
	}

	selectedLeaseCheckBoxChange(event) {
		const leaseId = parseInt(event.target.value);
		let updatedLeaseIds = this.state.selectedLeaseIds;

		if (updatedLeaseIds.includes(leaseId)) {
			updatedLeaseIds = updatedLeaseIds.filter((lid) => {
				return lid !== leaseId;
			});
		} else {
			updatedLeaseIds.push(leaseId);
		}

		this.setState({
			...this.state,
			selectedLeaseIds: updatedLeaseIds,
		});
	}

	async addRound() {
		let previous_demands = [];
		let current_prices = [];

		const { tracker } = this.props.tracker;

		let table_data = {};
		tracker.leases.forEach((lease) => {
			table_data[lease.id] = { demand: "0", price: "Same" };
		});

		for (const [lease_id, demand] of Object.entries(this.state.demands)) {
			previous_demands.push({
				lease_id: parseInt(lease_id),
				demand: parseInt(demand),
			});
			table_data[lease_id].demand = parseInt(demand);
		}

		for (const [lease_id, price] of Object.entries(this.state.prices)) {
			current_prices.push({
				lease_id: parseInt(lease_id),
				price: parseInt(price),
			});
			table_data[lease_id].price = currencyFormat(parseInt(price));
		}

		const payload = { previous_demands, current_prices };

		const result = await confirm("Round Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "20vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<table className="table table-bordered table-sm">
									<thead>
										<tr>
											<th style={{ padding: "10px" }}>Lease</th>
											<th style={{ padding: "10px" }}>
												Previous <br />
												Demand
											</th>
											<th style={{ padding: "10px" }}>
												Current <br />
												Price
											</th>
										</tr>
									</thead>
									<tbody>
										{tracker.leases.map(({ id, name }) => (
											<tr key={id}>
												<td>{name}</td>
												<td>{table_data[id].demand}</td>
												<td>{table_data[id].price}</td>
											</tr>
										))}
									</tbody>
								</table>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.addRound(this.props.params.bidTrackerId, payload);
			await this.fetchData();
			return;
		}
	}

	async addBidToTracker() {
		if (this.state.selectedLeaseIds.length > 0) {
			const { tracker } = this.props.tracker;
			const payload = { selected_leases: this.state.selectedLeaseIds };
			const leaseNames = tracker.leases
				.filter((l) => this.state.selectedLeaseIds.includes(l.id))
				.map((l) => l.name)
				.join(", ");

			const result = await confirm("Bid Confirmation", {
				render: (message, onConfirm, onCancel) => {
					return (
						<>
							<div
								className="card"
								style={{
									width: "15vw",
									margin: "auto",
									alignItems: "center",
								}}
							>
								<div
									className="card-body"
									style={{
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<h6 style={{ padding: "10px" }}> {message} </h6>
									<hr />
									<p>
										Would you like to place a <strong>Live Bid</strong>
									</p>
									<p>
										on <strong>{leaseNames}</strong>?
									</p>
									<br />
									<div>
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={onConfirm}
											style={{ margin: "10px" }}
										>
											{" "}
											Yes{" "}
										</button>
										<button
											type="button"
											className="btn btn-outline-danger"
											onClick={onCancel}
											style={{ margin: "10px" }}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</>
					);
				},
				closeOnOverlayClick: true,
			});

			if (result) {
				await this.props.addBid(this.props.params.bidTrackerId, payload);
				await this.fetchData();
				return;
			}
		}
	}

	async deleteRound() {
		await this.props.deleteRound(this.props.params.bidTrackerId);
		await this.fetchData();
	}

	async deleteBid() {
		await this.props.deleteBid(this.props.params.bidTrackerId);
		await this.fetchData();
	}

	conditionalFormatting(id) {
		const { tracker } = this.props.tracker;
		const bidStrategyFilterValue = this.state.bidStrategyFilterValue;
		if (
			tracker.preferences &&
			tracker.preferences.filter((p) => {
				return p.lease_id === id;
			}).length > 0
				? tracker.preferences.filter((p) => {
						return p.lease_id === id;
				  })[0].priority === 0
				: true
		) {
			return { verticalAlign: "middle" };
		}
		let currentPriorities = [];
		tracker.preferences.forEach((lease, index) => {
			let preferencesArray = tracker.preferences
				? tracker.preferences.filter((data) => {
						return data.priority === index + 1;
				  })
				: []; //creates an array where priority is equal
			if (currentPriorities.length === 0) {
				preferencesArray.forEach((area) => {
					let roundInfo =
						tracker.preferences &&
						tracker.data.filter((data) => {
							return data.round === tracker.round && data.lease_id === area.lease_id;
						}).length > 0
							? tracker.data.filter((data) => {
									return data.round === tracker.round && data.lease_id === area.lease_id;
							  })[0]
							: { price: 0 };
					if (roundInfo.price && area.site_value && area.site_value - roundInfo.price > 0) {
						currentPriorities.push({
							lease_id: area.lease_id,
							delta: area.site_value - roundInfo.price,
						});
					}
				});
			}
		});
		currentPriorities.sort(function (a, b) {
			if (bidStrategyFilterValue === "select") {
				return null; //not yet implemented, should show no bid strategy
			}
			if (bidStrategyFilterValue === "max") {
				return b.delta - a.delta;
			}
			if (bidStrategyFilterValue === "min") {
				return a.delta - b.delta;
			}
			if (bidStrategyFilterValue === "stick") {
				return 0; //not yet implemented
			}
		});

		let cellStyle = { verticalAlign: "middle" };
		let betaValue = 0;
		for (let i = 0; i < currentPriorities.length; i++) {
			if (currentPriorities[i].lease_id == id) {
				if (currentPriorities.length > 2) {
					betaValue = (currentPriorities.length - 1 - i + i * 0.35) / (currentPriorities.length - 1);
				} else if (currentPriorities.length == 2) {
					if (i == 0) {
						betaValue = 1;
					}
					if (i == 1) {
						betaValue = 0.35;
					}
				} else if (currentPriorities.length == 1) {
					betaValue = 1;
				}
				cellStyle = {
					backgroundColor: `rgba(106, 145, 255, ${betaValue})`,
					color: "#ffffff",
					verticalAlign: "middle",
				};
			}
		}
		if (
			tracker.preferences &&
			tracker.data &&
			tracker.preferences.filter((p) => {
				return p.lease_id == id;
			}).length > 0 &&
			tracker.data.filter((p) => {
				return p.lease_id == id && p.round == tracker.round;
			}).length > 0
				? tracker.preferences.filter((p) => {
						return p.lease_id == id;
				  })[0].site_value -
						tracker.data.filter((p) => {
							return p.lease_id == id && p.round == tracker.round;
						})[0].price <=
				  0
				: false
		) {
			cellStyle = {
				backgroundColor: `rgba(255, 0, 0, 0.6)`,
				color: "#ffffff",
				verticalAlign: "middle",
			};
		}
		if (
			tracker.preferences &&
			tracker.data &&
			tracker.preferences.filter((p) => {
				return p.lease_id == id;
			}).length > 0 &&
			tracker.data.filter((p) => {
				return p.lease_id == id && p.round == tracker.round;
			}).length > 0
				? tracker.preferences.filter((p) => {
						return p.lease_id == id;
				  })[0].budget -
						tracker.data.filter((p) => {
							return p.lease_id == id && p.round == tracker.round;
						})[0].price <=
				  0
				: false
		) {
			cellStyle = {
				backgroundColor: `rgba(100, 100, 100, 0.6)`,
				color: "#ffffff",
				verticalAlign: "middle",
			};
		}
		return cellStyle;
	}

	getCell(lease_id, round) {
		const { tracker } = this.props.tracker;

		const cell =
			tracker.data.filter((data) => {
				return data.round == round && data.lease_id == lease_id;
			}).length > 0
				? tracker.data.filter((data) => {
						return data.round == round && data.lease_id == lease_id;
				  })[0]
				: [];
		const previousCell =
			round > 1
				? tracker.data.filter((data) => {
						return data.round == round - 1 && data.lease_id == lease_id;
				  })[0]
				: null;
		const maxDemand = Math.max.apply(
			Math,
			tracker.data
				.filter((data) => {
					return data.round == round;
				})
				.map((data) => {
					return data.demand;
				})
		);

		let demandStyle = {};
		if (cell.demand ? cell.demand !== undefined : false) {
			if (cell.demand == 0) demandStyle = { backgroundColor: "#ffffff", color: "#999999" };
			else if (cell.demand == 1) demandStyle = { backgroundColor: "#999999", color: "#ffffff" };
			else if (cell.demand / maxDemand < 0.5)
				demandStyle = {
					backgroundColor: `rgba(223, 71, 89, ${cell.demand / maxDemand})`,
					color: "#ffffff",
				};
			else
				demandStyle = {
					backgroundColor: `rgba(223, 71, 89, ${cell.demand / maxDemand})`,
					color: "#ffffff",
				};
		}

		let arrowIcon = <></>;
		if (previousCell && cell.demand !== null) {
			if (cell.demand > previousCell.demand)
				arrowIcon = <i className="fas fa-arrow-up" style={{ marginLeft: "5px", color: "#000000" }}></i>;
			else if (cell.demand == previousCell.demand)
				arrowIcon = <i className="far fa-minus-square" style={{ marginLeft: "5px", color: "#999999" }}></i>;
			else if (cell.demand < previousCell.demand)
				arrowIcon = <i className="fas fa-arrow-down" style={{ marginLeft: "5px", color: "#000000" }}></i>;
		}

		const cellStyle = cell.placed_bid == 1 ? "table-primary" : "";

		return (
			<td key={`${cell.round}_${cell.lease_id}`} className={cellStyle} style={{ minWidth: "150px" }}>
				{!this.state.editRoundInfo ? (
					<div>
						{currencyFormat(cell.price)}
						<br />
						{cell.demand !== undefined || cell.demand >= 0 ? (
							<span className="badge" style={demandStyle}>
								{cell.demand}
							</span>
						) : (
							<></>
						)}
						{arrowIcon}
					</div>
				) : (
					<div>
						<div
							style={{ minWidth: "100px", margin: "0 auto" }}
							className="input-group input-group-sm mb-3"
						>
							<input
								style={{
									minWidth: "120px",
									margin: "0 auto",
									marginBottom: "0px",
								}}
								type="string"
								className="form-control"
								name="price"
								round={round}
								lease_id={lease_id}
								value={currencyFormat(this.state.dataValues[round][lease_id].price)}
								onChange={this.onDataValueChange.bind(this)}
							/>
						</div>
						{round != tracker.round ? (
							<>
								<div
									style={{
										margin: "0 auto",
										padding: "0",
										display: "flex",
										justifyContent: "center",
									}}
								>
									<input
										style={{ alignSelf: "center", margin: "0 10px" }}
										className="form-check-input"
										type="checkbox"
										round={round}
										lease_id={lease_id}
										name="placed_bid"
										checked={this.state.dataValues[round][lease_id].placed_bid}
										onChange={this.onDataValueChange.bind(this)}
									/>
									<input
										style={{
											width: "50px",
											marginBottom: "0px",
										}}
										type="number"
										className="form-control"
										name="demand"
										round={round}
										lease_id={lease_id}
										value={this.state.dataValues[round][lease_id].demand}
										onChange={this.onDataValueChange.bind(this)}
									/>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				)}
			</td>
		);
	}

	getTotalExitBids() {
		const { tracker } = this.props.tracker;

		if (!tracker.data) {
			return `-`;
		}

		let start_demand = 0;
		tracker.data.filter((d) => d.round === `1`).forEach((d) => (start_demand += d.demand));

		let current_demand = 0;
		tracker.data.filter((d) => d.round == tracker.round - 1).forEach((d) => (current_demand += d.demand));

		return start_demand - current_demand;
	}

	getTotalAuctionProceeds() {
		const { tracker } = this.props.tracker;

		if (!tracker.data) {
			return <></>;
		}

		let rounds = [];
		for (let i = parseInt(tracker.round); i > 0; i--) {
			rounds.push(i);
		}
		let auctionProceeds = [];
		rounds.forEach((round) => {
			let current_price = 0;
			tracker.data.filter((d) => d.round == round).forEach((d) => (current_price += d.price));
			auctionProceeds.push({
				round: round,
				auctionProceeds: current_price,
			});
		});

		return (
			<>
				{auctionProceeds ? (
					auctionProceeds.map(({ round, auctionProceeds }) => (
						<td key={round}>{currencyFormat(auctionProceeds)}</td>
					))
				) : (
					<></>
				)}
			</>
		);
	}

	getAggregateDemand() {
		const { tracker } = this.props.tracker;

		if (!tracker.data) {
			return <></>;
		}

		let rounds = [];
		for (let i = parseInt(tracker.round); i > 0; i--) {
			rounds.push(i);
		}
		let aggregateDemand = [];
		rounds.forEach((round) => {
			let demand = 0;
			tracker.data.filter((d) => d.round == round).forEach((d) => (demand += d.demand));
			if (round == tracker.round) {
				demand = `-`;
			}
			aggregateDemand.push({
				round: round,
				aggregateDemand: demand,
			});
		});

		return (
			<>
				{aggregateDemand ? (
					aggregateDemand.map(({ round, aggregateDemand }) => <td key={round}>{aggregateDemand}</td>)
				) : (
					<></>
				)}
			</>
		);
	}

	getExitBidsColumns() {
		const { tracker } = this.props.tracker;

		if (!tracker.data) {
			return <></>;
		}

		let rounds = [];
		for (let i = parseInt(tracker.round); i > 0; i--) {
			rounds.push(i);
		}

		let exit_bids = [];
		rounds.forEach((round) => {
			let current_demand = 0;
			tracker.data.filter((d) => d.round == round).forEach((d) => (current_demand += d.demand));
			let previous_demand = 0;
			tracker.data.filter((d) => d.round == round - 1).forEach((d) => (previous_demand += d.demand));

			exit_bids.push({
				round: round,
				exit_bids:
					previous_demand - current_demand >= 0 && tracker.round - round >= 1
						? previous_demand - current_demand
						: "-",
				cellStyle: previous_demand - current_demand >= 1 && tracker.round - round >= 1 ? "table-warning" : "",
			});
		});

		return (
			<>
				{exit_bids ? (
					exit_bids.map(({ round, exit_bids, cellStyle }) => (
						<td key={round} className={cellStyle}>
							{exit_bids}
						</td>
					))
				) : (
					<></>
				)}
			</>
		);
	}

	getCharts() {
		const { tracker } = this.props.tracker;

		if (!tracker || !tracker.round || tracker.round < 2) return <></>;

		let rounds = [];
		for (let i = 1; i <= parseInt(tracker.round); i++) rounds.push(i);

		let tracker_data = tracker.data;
		if (
			this.state.filterGraphValue == 5 ||
			this.state.filterGraphValue == 10 ||
			this.state.filterGraphValue == 15
		) {
			tracker_data = tracker.data.filter((d) => {
				return d.round > tracker.round - this.state.filterGraphValue;
			});
			rounds = [];
			for (
				let i =
					tracker.round - this.state.filterGraphValue > 0
						? tracker.round - this.state.filterGraphValue + 1
						: 1;
				i <= parseInt(tracker.round);
				i++
			)
				rounds.push(i);
		}

		let priceChartData = [];
		let bidHistoryChartData = [];
		let demandChartData = [];
		rounds.forEach((round_num) => {
			let round_data = {};
			let bid_data = {};
			let demand_data = {};
			let leases = tracker.leases;
			if (this.state.filteredLeaseGraphValue >= 0) {
				leases = tracker.leases.filter((l) => l.id == this.state.filteredLeaseGraphValue);
			}
			leases.forEach((lease) => {
				const d = tracker_data.filter((data) => {
					return data.lease_id == lease.id && data.round == round_num;
				});
				round_data[lease.id] = d.length > 0 ? d[0].price : null;
				demand_data[lease.id] = d.length > 0 ? d[0].demand : null;
			});
			tracker.leases.forEach((lease) => {
				const b = tracker_data.filter((data) => {
					return data.lease_id == lease.id && data.round == round_num && data.placed_bid == 1;
				});
				bid_data[lease.id] = b.length > 0 ? b[0].price : null;
			});
			priceChartData.push({ round: `${round_num}`, ...round_data });
			demandChartData.push({ round: `${round_num}`, ...demand_data });
			bidHistoryChartData.push({ round: `${round_num}`, ...bid_data });
		});

		let pricePercentageChangeData = [];
		rounds.forEach((round_num) => {
			if (round_num != 1) {
				let round_data = {};
				let total = 0;
				let num = 0;
				let leases = tracker.leases;
				if (this.state.filteredLeaseGraphValue >= 0) {
					leases = tracker.leases.filter((l) => l.id == this.state.filteredLeaseGraphValue);
				}
				leases.forEach((lease) => {
					const d = tracker_data.filter((data) => {
						return data.lease_id == lease.id && data.round == round_num;
					});
					const e = tracker_data.filter((data) => {
						return data.lease_id == lease.id && data.round == round_num - 1;
					});
					if (d.length > 0 && e.length > 0) {
						round_data[lease.id] = (d[0].price - e[0].price) / e[0].price;
						if ((d[0].price - e[0].price) / e[0].price > 0) {
							total += (d[0].price - e[0].price) / e[0].price;
							num += 1;
						}
					} else {
						round_data[lease.id] = null;
					}
				});
				pricePercentageChangeData.push({
					round: `${round_num}`,
					...round_data,
					avg: num > 0 ? total / num : 0,
				});
			}
		});

		let areaMapData = [];
		tracker.leases.forEach((lease) => {
			areaMapData.push({
				name: lease.name,
				children: [{ name: "Area (acres)", size: lease.acres }],
			});
		});

		let exitBidsData = [];
		rounds.forEach((round) => {
			let current_demand = 0;
			tracker_data.filter((d) => d.round == round).forEach((d) => (current_demand += d.demand));
			let previous_demand = 0;
			tracker_data.filter((d) => d.round == round - 1).forEach((d) => (previous_demand += d.demand));

			exitBidsData.push({
				name: `${round}`,
				exit_bids:
					previous_demand - current_demand >= 0 && tracker.round - round >= 1
						? previous_demand - current_demand
						: 0,
			});
		});

		return (
			<>
				<div className="row" style={{ zIndex: "1" }}>
					<div className="col-6">
						<div className="card">
							<div className="card-body" style={{ textAlign: "center" }}>
								<div className="card-header">Lease Prices / Round</div>
								<br />
								<PriceLineChart
									data={priceChartData}
									leases={tracker.leases}
									tickCount={5}
									height={250}
									dotWidth={2}
								/>
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="card">
							<div className="card-body" style={{ textAlign: "center" }}>
								<div className="card-header">Bid History</div>
								<br />
								<PriceLineChart
									data={bidHistoryChartData}
									leases={tracker.leases}
									tickCount={3}
									height={250}
									dotWidth={6}
								/>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row" style={{ zIndex: "2" }}>
					<div className="col-6" style={{ zIndex: "1" }}>
						<div className="card">
							<div className="card-body" style={{ textAlign: "center" }}>
								<div className="card-header">Lease Demand / Round</div>
								<br />
								<CustomLineChart data={demandChartData} leases={tracker.leases} />
							</div>
						</div>
					</div>
					<div className="col-6" style={{ zIndex: "1" }}>
						<div className="card">
							<div className="card-body" style={{ textAlign: "center" }}>
								<div className="card-header">Percentage Increase History</div>
								<br />
								<PercentageLineChart data={pricePercentageChangeData} leases={tracker.leases} />
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row" style={{ zIndex: "1", alignItems: "center" }}>
					<div className="col-12">
						<div className="card">
							<div className="card-body" style={{ textAlign: "center" }}>
								<div className="card-header">Estimated Exit Bids / Round</div>
								<br />
								<ExitBidAreaChart data={exitBidsData} />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		const { user, user_loading, signedIn } = this.props.user;
		const { tracker, tracker_loading } = this.props.tracker;

		var fetchDisabled = this.props.auction.auction.round < 2;

		let inputRoundInfo = tracker.data
			? tracker.data.filter((d) => {
					return d.round == tracker.round && d.placed_bid === "1";
			  }).length > 0
			: false;

		let netDemandChange = null;
		if (tracker.leases && tracker.leases.length > 0) {
			tracker.leases.forEach((lease) => {
				const currRoundData = tracker.data.filter((d) => {
					return d.lease_id == lease.id && d.round == tracker.round - 1;
				});
				const prevRoundData = tracker.data.filter((d) => {
					return d.lease_id == lease.id && d.round == tracker.round - 2;
				});
				if (currRoundData.length > 0 && prevRoundData.length > 0) {
					netDemandChange += currRoundData[0].demand - prevRoundData[0].demand;
				}
			});
		}

		if (!signedIn || !(user.type === "admin" || user.user_id == tracker.user_id)) {
			return <Redirect to="/bidtrackers" />;
		}

		if (tracker_loading || user_loading) {
			return (
				<div className="container">
					<h3>Bid Tracker</h3>

					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/bidtrackers">Bid Trackers</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Bid Tracker
							</li>
						</ol>
					</nav>

					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		let rounds = [];
		for (let x = parseInt(tracker.round); x > 0; x--) {
			rounds.push(x);
		}

		// Sockets
		socket.off("tracker_refresh").on("tracker_refresh", (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "info",
				duration: 7000,
			});
		});
		socket.off("tracker_reset").on("tracker_reset", (msg) => {
			this.props.history.push(`/bidtrackers`);
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		return (
			<div className="container">
				<h3>
					Bid Tracker {tracker.name ? `(${tracker.name})` : ""}{" "}
					{user.type === "admin" ? `[${tracker.display_name} (${tracker.username})]` : ""}
				</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/bidtrackers">Bid Trackers</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Bid Tracker
						</li>
					</ol>
				</nav>

				<hr />

				<div className="text-right" style={{ alignItems: "right", marginRight: 0 }}>
					<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
						<i className="fas fa-sync"></i> Data Reload
					</button>
				</div>

				<br />

				<div className="card">
					<div className="card-body">
						<h6>
							We Are Now In Round:{" "}
							{tracker.round && !inputRoundInfo ? `${tracker.round}` : `${tracker.round + 1}`}
						</h6>

						<select
							value={this.state.bidStrategyFilterValue}
							onChange={(e) => this.onMainDropDownChange(e)}
							style={{
								width: "200px",
								marginRight: "5px",
								padding: "6px",
								borderRadius: "4px",
								fontSize: "14px",
								borderColor: "#0275d8",
								color: "#0275d8",
								position: "absolute",
								top: "13px",
								right: "5px",
							}}
						>
							<option value="select" disabled={true}>
								Bidding Strategy
							</option>
							<option value="max">Maximise</option>
							<option value="min">Minimise</option>
							<option value="stick" disabled={true}>
								Stick
							</option>
						</select>
					</div>
				</div>

				<br />

				<div className="table-responsive">
					<table className="table table-sm table-bordered mx-auto w-auto" style={{ textAlign: "center" }}>
						<thead style={{ width: "100%" }}>
							<tr style={{ width: "100%" }}>
								<th
									style={{
										minWidth: "160px",
										margin: `auto`,
										padding: `15px`,
									}}
								>
									Lease Area
									<br />
								</th>
								<th
									style={{
										minWidth: "120px",
										margin: `auto`,
										padding: `15px`,
									}}
								>
									Site Value <br />
								</th>
								<th
									style={{
										minWidth: "120px",
										margin: `auto`,
										padding: `15px`,
									}}
								>
									Budget <br />
								</th>
								<th
									style={{
										minWidth: "90px",
										borderRight: "1px solid #000",
									}}
								>
									Demand Change
								</th>
								<th style={{ minWidth: "100px" }}>
									Round{" "}
									{tracker.round && !inputRoundInfo ? `${tracker.round}` : `${tracker.round + 1}`} Bid
								</th>
								<th style={{ minWidth: "120px" }}>
									Previous Round Demand (Round {tracker.round ? `${tracker.round}` : "Current"})
								</th>
								<th
									style={{
										minWidth: "150px",
										borderRight: "1px solid #000",
									}}
								>
									Current Round Price (Round {tracker.round ? `${tracker.round + 1}` : "Current"})
								</th>
								{rounds ? (
									rounds.map((x) => (
										<th
											key={x}
											style={{
												minWidth: "200px",
												margin: `auto`,
												padding: `15px`,
											}}
										>
											{" "}
											Round {x}
										</th>
									))
								) : (
									<></>
								)}
							</tr>
						</thead>
						<tbody>
							{tracker.leases ? (
								tracker.leases.map(({ id, name }) => (
									<tr key={id}>
										{/* Lease Name Column (also displays the site_value) */}
										<td style={this.conditionalFormatting(id)}>
											<strong>{name}</strong>
											<br />
											{!this.state.editInfo ? (
												<div>
													{tracker.preferences &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													}).length > 0 &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													})[0].priority != 0 ? (
														<span
															className="badge"
															style={{
																backgroundColor: `rgba(0, 0, 0, 0.5)`,
																color: "#ffffff",
															}}
														>
															P
															{tracker.preferences &&
															tracker.preferences.filter((p) => {
																return p.lease_id == id;
															}).length > 0
																? tracker.preferences.filter((p) => {
																		return p.lease_id == id;
																  })[0].priority
																: 0}
														</span>
													) : (
														<></>
													)}
												</div>
											) : (
												<div
													style={{
														width: "50px",
														margin: "0 auto",
													}}
													className="input-group input-group-sm mb-3"
												>
													<input
														style={{
															width: "50px",
															margin: "0 auto",
														}}
														type="number"
														className="form-control"
														name={id}
														value={this.state.priorityValues[id]}
														onChange={this.onPriorityValueChange.bind(this)}
													/>
												</div>
											)}
										</td>

										{/* Site Value */}
										<td style={this.conditionalFormatting(id)}>
											{!this.state.editInfo ? (
												<div>
													{tracker.preferences &&
													tracker.preferences.filter((p) => p.lease_id == id).length == 1 &&
													tracker.preferences.filter((p) => p.lease_id == id)[0].site_value
														? currencyFormat(
																tracker.preferences.filter((p) => p.lease_id == id)[0]
																	.site_value
														  )
														: ""}
													<br />
													{tracker.preferences &&
													tracker.data &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													}).length > 0 &&
													tracker.data.filter((p) => {
														return p.lease_id == id && p.round == tracker.round;
													}).length > 0 &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													})[0].priority != 0
														? currencyFormat(
																tracker.preferences.filter((p) => {
																	return p.lease_id == id;
																})[0].site_value -
																	tracker.data.filter((p) => {
																		return (
																			p.lease_id == id && p.round == tracker.round
																		);
																	})[0].price
														  )
														: null}
												</div>
											) : (
												<div
													style={{
														minWidth: "180px",
														margin: "0 auto",
													}}
													className="input-group input-group-sm mb-3"
												>
													<input
														style={{
															minWidth: "120px",
															margin: "0 auto",
														}}
														type="string"
														className="form-control"
														name={id}
														value={currencyFormat(this.state.siteValues[id])}
														onChange={this.onSiteValueChange.bind(this)}
													/>
												</div>
											)}
										</td>

										{/* Budget  */}
										<td style={this.conditionalFormatting(id)}>
											{!this.state.editInfo ? (
												<div>
													{tracker.preferences &&
													tracker.preferences.filter((p) => p.lease_id == id).length == 1 &&
													tracker.preferences.filter((p) => p.lease_id == id)[0].budget
														? currencyFormat(
																tracker.preferences.filter((p) => p.lease_id == id)[0]
																	.budget
														  )
														: ""}
													<br />
													{tracker.preferences &&
													tracker.data &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													}).length > 0 &&
													tracker.data.filter((p) => {
														return p.lease_id == id && p.round == tracker.round;
													}).length > 0 &&
													tracker.preferences.filter((p) => {
														return p.lease_id == id;
													})[0].priority != 0
														? currencyFormat(
																tracker.preferences.filter((p) => {
																	return p.lease_id == id;
																})[0].budget -
																	tracker.data.filter((p) => {
																		return (
																			p.lease_id == id && p.round == tracker.round
																		);
																	})[0].price
														  )
														: null}
												</div>
											) : (
												<div
													style={{
														minWidth: "180px",
														margin: "0 auto",
													}}
													className="input-group input-group-sm mb-3"
												>
													<input
														style={{
															minWidth: "120px",
															margin: "0 auto",
														}}
														type="string"
														className="form-control"
														name={id}
														value={currencyFormat(this.state.budgetValues[id])}
														onChange={this.onBudgetValueChange.bind(this)}
													/>
												</div>
											)}
										</td>

										{/* demand change from prevoius round  */}
										<td
											style={{
												borderRight: "1px solid #000",
											}}
										>
											{tracker.data &&
											tracker.data.filter((d) => {
												return d.lease_id == id && d.round == tracker.round - 1;
											}).length > 0 &&
											tracker.data.filter((d) => {
												return d.lease_id == id && d.round == tracker.round - 2;
											}).length > 0
												? tracker.data.filter((d) => {
														return d.lease_id == id && d.round == tracker.round - 1;
												  })[0].demand -
												  tracker.data.filter((d) => {
														return d.lease_id == id && d.round == tracker.round - 2;
												  })[0].demand
												: 0}
										</td>

										{/* Current Bid Checkbox Column */}
										<td>
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													id={id}
													value={id}
													onChange={this.selectedLeaseCheckBoxChange.bind(this)}
													checked={this.state.selectedLeaseIds.includes(id)}
													style={{ margin: "auto" }}
													disabled={inputRoundInfo || this.state.editInfo}
												/>
											</div>
										</td>

										{/* Previous Round Demand Input Column */}
										<td>
											<div className="input-group input-group-sm mb-3">
												<input
													style={{
														minWidth: "180px",
													}}
													type="number"
													className="form-control"
													name={id}
													onChange={this.onDemandChange.bind(this)}
													disabled={!inputRoundInfo || this.state.editInfo}
												/>
											</div>
										</td>

										{/* Current Round Price Input Column */}
										<td
											style={{
												borderRight: "1px solid #000",
											}}
										>
											<div
												style={{ minWidth: "180px" }}
												className="input-group input-group-sm mb-3"
											>
												<input
													style={{
														minWidth: "120px",
													}}
													type="string"
													className="form-control"
													name={id}
													value={currencyFormat(this.state.prices[id])}
													onChange={this.onPriceChange.bind(this)}
													disabled={!inputRoundInfo || this.state.editInfo}
												/>
											</div>
										</td>

										{/* Rounds Data Columns */}
										{rounds ? (
											rounds.map((round_num) =>
												tracker.data ? this.getCell(id, round_num) : <></>
											)
										) : (
											<></>
										)}
									</tr>
								))
							) : (
								<></>
							)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={4} style={{ borderRight: "1px solid #000" }}>
									Estimated Exit Bids Since Start: {this.getTotalExitBids()}
								</td>
								<th
									colSpan={3}
									style={{
										textAlign: "right",
										paddingRight: "2%",
										borderRight: "1px solid #000",
									}}
								>
									Exit Bids in Round (Max):
								</th>
								{this.getExitBidsColumns()}
							</tr>
							<tr>
								<th colSpan={4} style={{ borderRight: "1px solid #000" }}></th>
								<th
									colSpan={3}
									style={{
										textAlign: "right",
										paddingRight: "2%",
										borderRight: "1px solid #000",
									}}
								>
									Total Round Proceeds:
								</th>
								{this.getTotalAuctionProceeds()}
							</tr>
							<tr>
								<td colSpan={4} style={{ borderRight: "1px solid #000" }}></td>
								<th
									colSpan={3}
									style={{
										textAlign: "right",
										paddingRight: "2%",
										borderRight: "1px solid #000",
									}}
								>
									{" "}
									Aggregate Round Demand:
								</th>
								{this.getAggregateDemand()}
							</tr>
						</tfoot>

						<tfoot>
							<tr style={{ textAlign: "center" }}>
								<th colSpan={3}></th>
								<th colSpan={1} style={{ borderRight: "1px solid #000" }}>
									Sum: {netDemandChange ? netDemandChange : "0"}
								</th>

								<th colSpan={1} style={{ borderLeft: "1px solid #000" }}>
									{!inputRoundInfo ? (
										<button
											className="btn btn-outline-primary btn-sm"
											style={{
												width: "100%",
												height: "100%",
											}}
											disabled={
												this.state.selectedLeaseIds.length == 0 ||
												this.state.editInfo ||
												this.state.editRoundInfo
											}
											onClick={this.addBidToTracker.bind(this)}
										>
											Set Live
											<br /> Bid
										</button>
									) : (
										<button
											className="btn btn-outline-danger btn-sm"
											style={{
												width: "100%",
												height: "100%",
											}}
											onClick={this.deleteBid.bind(this)}
											disabled={this.state.editInfo || this.state.editRoundInfo}
										>
											Undo Live <br />
											Bid
										</button>
									)}
								</th>

								<th colSpan={2} style={{ borderRight: "1px solid #000" }}>
									{!inputRoundInfo ? (
										<button
											className="btn btn-outline-danger btn-sm"
											style={{
												width: "100%",
												height: "100%",
											}}
											disabled={
												(tracker.data
													? tracker.data.filter((d) => {
															return d.placed_bid === "1";
													  }).length == 0 && tracker.round === "1"
													: false) ||
												this.state.editInfo ||
												this.state.editRoundInfo
											}
											onClick={this.deleteRound.bind(this)}
										>
											Undo Price and <br />
											Demand Info
										</button>
									) : (
										<button
											className="btn btn-outline-primary btn-sm"
											style={{
												width: "100%",
												height: "100%",
											}}
											onClick={this.addRound.bind(this)}
											disabled={this.state.editInfo || this.state.editRoundInfo}
										>
											Add Price and <br />
											Demand Info
										</button>
									)}
								</th>
							</tr>
						</tfoot>
					</table>
				</div>

				<br />

				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-6">
								{!this.state.editInfo ? (
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.editInfo()}
										disabled={this.state.editRoundInfo}
									>
										<i className="fas"></i>Edit Preference Info
									</button>
								) : (
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.setInfo()}
										disabled={this.state.editRoundInfo}
									>
										<i className="fas"></i>Set Preference Info
									</button>
								)}{" "}
								&nbsp;
								{!this.state.editRoundInfo ? (
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.editRoundInfo()}
										disabled={this.state.editInfo}
									>
										<i className="fas"></i>Edit Round Info
									</button>
								) : (
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.setRoundInfo()}
										disabled={this.state.editInfo}
									>
										<i className="fas"></i>Set Round Info
									</button>
								)}{" "}
								&nbsp;
								<button
									type="button"
									className="btn btn-outline-primary btn-sm"
									disabled={fetchDisabled}
									onClick={() => this.fetchAuctionState()}
								>
									<i className="fas"></i>Fetch Auction State
								</button>
							</div>
							<div
								className="col-6"
								style={{
									textAlign: "right",
									alignItems: "right",
									paddingBottom: "0px",
								}}
							>
								<select
									value={this.state.filterGraphValue}
									onChange={(e) => this.onStatusDropDownChange(e)}
									style={{
										width: "150px",
										marginLeft: "5px",
										padding: "6px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
									}}
								>
									<option value="All">All Rounds</option>
									<option value={5}>Last 5 Rounds</option>
									<option value={10}>Last 10 Rounds</option>
									<option value={15}>Last 15 Rounds</option>
								</select>
								<select
									value={this.state.filteredLeaseGraphValue}
									onChange={(e) => this.onLeaseDropDownChange(e)}
									style={{
										width: "150px",
										padding: "6px",
										marginLeft: "5px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
									}}
								>
									<option value={-1}>All Lease Areas</option>
									{tracker.leases && tracker.leases.length > 0 ? (
										tracker.leases.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))
									) : (
										<></>
									)}
								</select>
							</div>
						</div>
					</div>
				</div>

				<br />

				{this.getCharts()}

				<br />
				<br />
				<br />
				<br />
				<hr />
			</div>
		);
	}
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

function WithParams(props) {
	let params = useParams();
	return <BidTracker {...props} params={params} />;
}

BidTracker.propTypes = {
	user: PropTypes.object.isRequired,
	auction: PropTypes.object.isRequired,
	tracker: PropTypes.object.isRequired,
	getTracker: PropTypes.func.isRequired,
	addRound: PropTypes.func.isRequired,
	addBid: PropTypes.func.isRequired,
	deleteRound: PropTypes.func.isRequired,
	deleteBid: PropTypes.func.isRequired,
	updatePreferences: PropTypes.func.isRequired,
	updateData: PropTypes.func.isRequired,
	updateBTTAuctionData: PropTypes.func.isRequired,
	setTrackersNotLoading: PropTypes.func.isRequired,
	setTrackersLoading: PropTypes.func.isRequired,
	resetTracker: PropTypes.func.isRequired,
	startTracker: PropTypes.func.isRequired,
	getAuction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	auction: state.auction,
	tracker: state.tracker,
});

export default connect(mapStateToProps, {
	setTrackersNotLoading,
	setTrackersLoading,
	getTracker,
	addRound,
	addBid,
	deleteBid,
	deleteRound,
	updatePreferences,
	updateData,
	updateBTTAuctionData,
	resetTracker,
	startTracker,
	getAuction,
})(WithParams);
