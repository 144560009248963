import React, { Component } from "react";
import PropTypes from "prop-types";
import { Timer } from "../../GeneralAuction/Components/Timer";
import util from "../../../../util/RENO";
import { auctionStatus } from "../../../common/constants";

class RENOInformationPanel extends Component {
	constructor(props) {
		super(props);
	}

	getStatusStyle(status) {
		if (status == auctionStatus.IN_PROGRESS) {
			return { color: "green" };
		} else if (status == auctionStatus.ENDED) {
			return { color: "red" };
		} else if (status == auctionStatus.AWAITING_START) {
			return { color: "blue" };
		} else if (status == auctionStatus.IN_A_BREAK) {
			return { color: "orange" };
		} else {
			return {};
		}
	}

	render() {
		const { auction } = this.props.auction;

		const notAwaitingStart = auction.status !== auctionStatus.AWAITING_START;

		return (
			<>
				<div className="row">
					<div className={notAwaitingStart ? "col-5" : "col"}>
						<div className="card">
							<div className="card-header" style={{ textAlign: "center", height: "2.5rem" }}>
								<h5>Auction Information</h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>
										Status:{" "}
										{auction.status ? (
											<b style={this.getStatusStyle(auction.status)}>{auction.status}</b>
										) : (
											""
										)}
									</p>
								</li>

								{notAwaitingStart ? (
									<>
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<p>
												Bid Upper Limit:{" "}
												{auction.minimumContractPrice ? (
													<b>{util.formatCurrencyWithText(auction.bidUpperLimit)}</b>
												) : (
													""
												)}
											</p>
										</li>
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<p>
												Minimum Contract Price:{" "}
												{auction.minimumContractPrice ? (
													<b>{util.formatCurrencyWithText(auction.minimumContractPrice)}</b>
												) : (
													""
												)}
											</p>
										</li>
									</>
								) : (
									<></>
								)}

								<li className="list-group-item" style={{ height: "2.5rem" }}>
									<p>Page Last Refreshed: {util.formatTime(this.props.lastRefreshTime)}</p>
								</li>
							</ul>
						</div>
					</div>

					{notAwaitingStart ? (
						<>
							<div className="col-7">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center", height: "2.5rem" }}>
										<h5>Auction Schedule</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<p>
												Start Time:{" "}
												{auction.startTime ? <b>{util.formatTime(auction.startTime)}</b> : ""}
											</p>
										</li>
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<p>
												Scheduled End Time:{" "}
												{auction.scheduledEndTime ? (
													<>{util.formatTime(auction.scheduledEndTime)}</>
												) : (
													""
												)}
											</p>
										</li>
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<p>
												Current End Time:{" "}
												{auction.status == auctionStatus.IN_A_BREAK ? (
													"Will be updated after the break."
												) : auction.endTime ? (
													<b>{util.formatTime(auction.endTime)}</b>
												) : (
													""
												)}
											</p>
										</li>
										<li className="list-group-item" style={{ height: "2.5rem" }}>
											<Timer
												endTime={auction.endTime}
												status={auction.status}
												highlight={auction.timeExtensionWindow}
											/>
										</li>
									</ul>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</>
		);
	}
}

RENOInformationPanel.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	lastRefreshTime: PropTypes.number,
};

export default RENOInformationPanel;
