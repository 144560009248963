import {
	GET_USER,
	SIGN_IN,
	GET_POINTS,
	GET_BIDS,
	SIGN_OUT,
	GET_USERS,
	USER_LOADING,
	STOP_USER_LOADING,
} from "../actions/types";

const initialState = {
	user: {},
	points: {},
	bids: {
		previous_bids: [],
		current_bids: [],
	},
	users: [],
	signedIn: false,
	user_loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_USER:
			return {
				...state,
			};
		case GET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case SIGN_IN:
			return {
				...state,
				user: action.payload,
				signedIn: true,
				user_loading: false,
			};
		case SIGN_OUT:
			return {
				...state,
				user: {},
				signedIn: false,
				user_loading: false,
			};
		case GET_POINTS:
			return {
				...state,
				points: action.payload,
			};
		case GET_BIDS:
			return {
				...state,
				bids: action.payload,
			};
		case USER_LOADING:
			return {
				...state,
				user_loading: true,
			};
		case STOP_USER_LOADING:
			return {
				...state,
				user_loading: false,
			};
		default:
			return state;
	}
}
