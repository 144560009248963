import axiosConn from "../helpers/axios";
import authHeaders from "../helpers/authHeaders";
import { UPDATE_CONFIG, ERROR, CONFIG_LOADING, STOP_CONFIG_LOADING } from "./types";
import { emitConfigUpdate } from "../helpers/emit/config";

export const getConfig = () => async (dispatch) => {
	dispatch(setConfigLoading());
	try {
		const resReady = await axiosConn.get("config/", {
			headers: authHeaders(),
		});
		const res = await resReady;

		const allowUserLogin = res.data.allow_user_login == 0 ? false : true;
		const presentationMode = res.data.presentation_mode == 1 ? true : false;
		const presentationModeAuctionId = res.data.presentation_mode_auction_id;
		const auctions = res.data.auctions;

		dispatch({
			type: UPDATE_CONFIG,
			allowUserLogin: allowUserLogin,
			presentationMode: presentationMode,
			presentationModeAuctionId: presentationModeAuctionId,
			auctions: auctions,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const updateConfig = (values) => async (dispatch) => {
	dispatch(setConfigLoading());
	try {
		const resReady = await axiosConn.post(`config/update`, values, { headers: authHeaders() });
		const res = await resReady;
		await emitConfigUpdate({});
		dispatch({
			type: UPDATE_CONFIG,
			payload: values,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const setConfigLoading = () => {
	return {
		type: CONFIG_LOADING,
	};
};

export const setConfigNotLoading = () => {
	return {
		type: STOP_CONFIG_LOADING,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err,
	};
};
