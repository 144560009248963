import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../actions/userActions";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { clearErrors } from "../../actions/errorActions";
import { getConfig, setConfigNotLoading } from "../../actions/configActions";
import bcrypt from "bcryptjs";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: "",
			error: "",
		};
	}

	componentDidMount() {
		this.getConfigValues();
	}

	async getConfigValues() {
		await this.props.getConfig();
		await this.props.setConfigNotLoading();
	}

	getError() {
		const { error } = this.props.error;
		if (this.state.error.length > 0) {
			return (
				<div style={{ textAlign: "right" }}>
					<p style={{ fontSize: "12px", color: "red" }}>{this.state.error}</p>
				</div>
			);
		}
		if (error.length > 0) {
			return (
				<div style={{ textAlign: "right" }}>
					<p style={{ fontSize: "12px", color: "red" }}>Incorrect Credentials</p>
				</div>
			);
		}
		return <br />;
	}

	usernameOnChange(event) {
		this.setState({ username: event.target.value });
	}

	passwordOnChange(event) {
		this.setState({ password: event.target.value });
	}

	async loginEvent(event) {

		event.preventDefault();
		this.props.clearErrors();
		this.setState({ ...this.state, error: "" });

		if (this.state.username.length > 0 && this.state.password.length > 0) {
			const encPassword = await bcrypt.hash(this.state.password, "$2a$10$FCCmYDDHZyvzHpwMAete2e");
			this.props.signIn(this.state.username, encPassword);
		} else {
			this.setState({ ...this.state, error: "Username and password fields cannot be empty" });
		}
	}

	render() {
		if (this.props.user.signedIn) {
			if (this.props.user.user.type === "user" && this.props.config.presentationMode) {
				return <Redirect to={`/auctions/${this.props.config.presentationModeAuctionId}`} />;
			}
			return <Redirect to="/auctions" />;
		}

		return (
			<div className="container">
				<br />

				<div className="container">
					<div className="card" style={{ width: "50%", margin: "auto" }}>
						<div className="card-header" style={{ textAlign: "center" }}>
							<h3>Sign In</h3>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item" style={{ padding: "50px" }}>
								<form>
									<div className="mb-3">
										<label className="form-label">Username</label>
										<input
											type="text"
											className="form-control"
											aria-describedby="emailHelp"
											onChange={this.usernameOnChange.bind(this)}
											autoComplete="username"
										/>
									</div>
									<div className="mb-3">
										<label className="form-label">Password</label>
										<input
											type="password"
											className="form-control"
											onChange={this.passwordOnChange.bind(this)}
											autoComplete="current-password"
										/>
									</div>

									{this.getError()}
									<button
										className="btn btn-primary"
										onClick={this.loginEvent.bind(this)}
										style={{ display: "block", marginRight: "0", marginLeft: "auto" }}
									>
										Sign In
									</button>
								</form>
							</li>
						</ul>
					</div>
				</div>

				<br />
			</div>
		);
	}
}

Login.propTypes = {
	signIn: PropTypes.func.isRequired,
	clearErrors: PropTypes.func.isRequired,
	setConfigNotLoading: PropTypes.func.isRequired,
	getConfig: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	error: state.error,
	config: state.config,
});

export default connect(mapStateToProps, { signIn, clearErrors, setConfigNotLoading, getConfig })(Login);
