import React, { Component } from "react";
import PropTypes from "prop-types";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, LabelList } from "recharts";

class UserSummaryDashboard extends Component {
	constructor(props) {
		super(props);
	}

	getUserHistoryTableCellData(his) {
		let user_status = [];
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length
				} live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length
				} frozen live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length
				} combined live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length
				} combined frozen live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length
				} exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length
				} frozen exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length
				} combined exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length
				} frozen combined exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1).length
				} (no bid) exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1).length
				} frozen (no bid) exit bid(s)`
			);

		if (user_status.length == 0) {
			return <></>;
		} else {
			return user_status.join(", ");
		}
	}

	renderUserHistoryTable() {
		const { history } = this.props.auction;
		const { users } = this.props.user;
		const rounds = this.props.rounds;

		if (!history) {
			return <></>;
		}

		return (
			<div className="table-responsive">
				<table className="table table-sm table-bordered" id="table-user-history">
					<thead style={{ textAlign: "center" }}>
						<tr>
							<th>Round</th>
							{users ? (
								users.map(({ id, username, display_name }) => (
									<th key={id} style={{ minWidth: "125px" }}>{`${display_name} (${username})`}</th>
								))
							) : (
								<></>
							)}
						</tr>
					</thead>
					<tbody>
						{rounds.map((roundNum) => (
							<tr key={roundNum}>
								<th
									className="align-middle"
									style={{
										textAlign: "center",
										verticalAlign: "center",
									}}
								>
									{roundNum}
								</th>
								{users ? (
									users.map(({ id, username }) => (
										<td key={id}>
											{this.getUserHistoryTableCellData(
												history.filter(
													(his) => his.round === roundNum && his.username == username
												)
											)}
										</td>
									))
								) : (
									<></>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	renderCustomizedLabel(props) {
		const { x, y, width, height, value } = props;
		const radius = 13;

		return (
			<g>
				<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#999999" />
				<text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
					{value}
				</text>
			</g>
		);
	}

	renderUserProgressGraph() {
		const { history } = this.props.auction;
		const rounds = this.props.rounds;

		let progressChartData = [];
		rounds.forEach((roundNum) => {
			const clb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 0).length;
			const ceb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 0).length;
			const flb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 1).length;
			let t = 14;
			let r = 0;
			if (roundNum > 1) {
				t = history.filter((his) => his.round === roundNum - 1 && his.exit == 0).length;
				r = t - (clb + ceb + flb);
			}
			progressChartData.push({
				round: `Round ${roundNum}`,
				totalUsers: clb + ceb + flb > 0 ? clb + ceb + flb : 0,
				liveUsers: clb > 0 ? clb : null,
				exitUsers: ceb > 0 ? ceb : null,
				frozenUsers: flb > 0 ? flb : null,
				remaining: r > 0 ? r : null,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							data={progressChartData}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} tickCount={4} />
							<Tooltip />
							<Bar
								type="monotone"
								dataKey="frozenUsers"
								name="Frozen Users"
								stackId="1"
								stroke="#0275d8"
								fill="#0275d8"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="liveUsers"
								name="Live Users"
								stackId="1"
								stroke="#5cb85c"
								fill="#5cb85c"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="exitUsers"
								name="Exit Users"
								stackId="1"
								stroke="#f0ad4e"
								fill="#f0ad4e"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="remaining"
								name="Remaining Bids"
								stackId="1"
								stroke="#999999"
								fill="#999999"
								fillOpacity={0.8}
							>
								<LabelList dataKey="totalUsers" content={this.renderCustomizedLabel}></LabelList>
							</Bar>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
						</BarChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Users Summary</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row">{this.renderUserProgressGraph()}</div>
				<br />
				<div className="row">{this.renderUserHistoryTable()}</div>
			</>
		);
	}
}

UserSummaryDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default UserSummaryDashboard;
