import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { getAuction, createAuction } from "../../../../actions/auctionActions";
import { useParams } from "react-router-dom";
import { auctionTypes } from "../../../common/constants";

class CreateAuction extends Component {
	constructor(props) {
		super(props);

		this.state = {
			auctionCreationType: "select",
			name: "",
			auctioneer: "",
			asq_mw: "",
			asq_gwhy: "",
			lease: [
				{
					lease_id: 1,
					name: "",
					starting_bid: "",
					area: "",
				},
			],
			round_duration: "",
			round_increments: "",
			description: "",
			starting_price: "",
		};
	}

	//getting rest of the info for pre populating the create function //

	componentDidMount() {
		if (this.props.params.auctionId !== "0") {
			this.fetchData();
		}
	}

	onChange(event) {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		});
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			auctionCreationType: event.target.value,
		});
	}

	onASQmwChange(event) {
		this.setState({
			...this.state,
			asq_mw: event.target.value,
			asq_gwhy: ((event.target.value * 8760 * 0.45) / 1000).toFixed(2),
		});
	}

	onASQgwhyChange(event) {
		this.setState({
			...this.state,
			asq_mw: ((event.target.value * 1000) / (8760 * 0.45)).toFixed(2),
			asq_gwhy: event.target.value,
		});
	}

	addLease() {
		this.setState({
			...this.state,
			lease: [
				...this.state.lease,
				{
					lease_id: this.state.lease.length + 1,
					name: "",
					starting_bid: "",
					area: "",
				},
			],
		});
	}

	removeLease() {
		this.setState({
			...this.state,
			lease: this.state.lease.filter((lease) => lease.lease_id !== this.state.lease.length),
		});
	}

	onLeaseChange(event, index) {
		//need to see why lease change is not working
		if (event.target.name === "price" && event.target.value > 150) {
			return;
		}
		let lease_copy = [...this.state.lease];
		let item = { ...lease_copy[index], [event.target.name]: event.target.value };
		lease_copy[index] = item;
		this.setState({ ...this.state, lease: lease_copy });
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);

		if (this.props.auction.auction.type === auctionTypes.SMRA) {
			//check if function is smra
			let newLease = [];
			let newinc = 1;
			this.props.auction.auction.leases.forEach((lease) => {
				newLease.push({
					lease_id: newinc,
					name: lease.name,
					area: lease.acres,
					starting_bid: lease.starting_bid,
				});
				newinc++;
			});

			this.setState({
				...this.state,
				name: this.props.auction.auction.name,
				auctioneer: this.props.auction.auction.auctioneer,
				lease: newLease,
			});
		}

		if (this.props.auction.auction.type === auctionTypes.MPSB) {
			//checks if function is mpsb
			this.setState({
				...this.state,
				name: this.props.auction.auction.name,
				auctioneer: this.props.auction.auction.auctioneer,
				asq_mw: Math.round(this.props.auction.auction.asq),
				asq_gwhy: ((this.props.auction.auction.asq * 8760 * 0.45) / 1000).toFixed(2),
			});
		}
		if (this.props.auction.auction.type === auctionTypes.ENOO) {
			//checks if function is enoo
			this.setState({
				...this.state,
				name: this.props.auction.auction.name,
				auctioneer: this.props.auction.auction.auctioneer,
				description: this.props.auction.auction.description,
				round_duration: this.props.auction.auction.round_duration,
				starting_price: this.props.auction.auction.starting_price,
			});
		}
	}

	async createSMRAEvent(event) {
		event.preventDefault();

		let leases = [];
		this.state.lease.forEach((lease) => {
			leases.push({
				name: lease.name,
				area: lease.area,
				starting_bid: lease.starting_bid,
			});
		});
		let payload = {
			name: this.state.name,
			auctioneer: this.state.auctioneer,
			type: auctionTypes.SMRA,
			leases: leases,
		};

		await this.props.createAuction(payload);

		this.props.history.push(`/auctions`);
	}

	async createMPSBEvent(event) {
		event.preventDefault();
		await this.props.createAuction({
			name: this.state.name,
			auctioneer: this.state.auctioneer,
			type: auctionTypes.MPSB,
			asq: this.state.asq_mw,
		});
		this.props.history.push(`/auctions`);
	}

	async createENOOEvent(event) {
		event.preventDefault();
		await this.props.createAuction({
			name: this.state.name,
			auctioneer: this.state.auctioneer,
			description: this.state.description,
			type: auctionTypes.ENOO,
			round_duration: this.state.round_duration,
			starting_price: this.state.starting_price,
		});

		this.props.history.push(`/auctions`);
	}

	render() {
		const { created, auction_loading } = this.props.auction;
		const { user, signedIn } = this.props.user;

		let smraEnabled = this.state.name.length > 0 && this.state.auctioneer.length > 0;

		this.state.lease.forEach((l) => {
			if (!(l.name.length > 0 && l.area.toString().length > 0 && l.starting_bid.toString().length > 0))
				smraEnabled = false;
		});

		const mpsbEnabled = this.state.name.length > 0 && this.state.auctioneer.length > 0;
		const enooEnabled = this.state.name.length > 0 && this.state.auctioneer.length > 0;

		let duplicate = false;

		if (this.props.params.auctionId !== "0") {
			duplicate = true;
		}

		if (!signedIn || user.type !== "admin" || created) {
			return <Redirect to="/auctions" />;
		}

		return (
			<div className="container">
				<h3>{duplicate ? this.props.auction.auction.name + " (copy)" : "Create New Auction"}</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li key="2" className="breadcrumb-item active" aria-current="page">
							{duplicate ? "Copying" : "Create"} New Auction
						</li>
					</ol>
				</nav>

				<hr />

				{auction_loading ? (
					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				{this.props.params.auctionId == "0" ? (
					<div>
						<div className="card">
							<div className="card-body">
								<h6>Select Auction Type</h6>

								<select
									value={this.state.auctionCreationType}
									onChange={(e) => this.onMainDropDownChange(e)}
									style={{
										width: "200px",
										marginRight: "5px",
										padding: "6px",
										borderRadius: "4px",
										fontSize: "14px",
										borderColor: "#0275d8",
										color: "#0275d8",
										position: "absolute",
										top: "13px",
										right: "5px",
									}}
								>
									<option value="select" disabled={true}>
										Select Auction Type
									</option>
									<option value="smra">SMRA</option>
									<option value="mpsb">MPSB</option>
									<option value="enoo">ENOO</option>
								</select>
							</div>
						</div>

						<br />

						{this.state.auctionCreationType === "smra" ? (
							<div className="card">
								<div className="card-body">
									<form>
										<div className="mb-3">
											<label className="form-label">Auction</label>
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auction-name"
													className="form-control"
													name="name"
													value={this.state.name}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auction-name">Auction Name</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auctioneer-name"
													className="form-control"
													name="auctioneer"
													value={this.state.auctioneer}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auctioneer-name">Auctioneer Name</label>
											</div>
										</div>

										<hr />

										{this.state.lease.map((leases) => {
											return (
												<>
													<label className="form-label">Lease {leases.lease_id}</label>

													<div className="row">
														<div className="col">
															<div className="form-floating mb-3">
																<input
																	className="form-control"
																	id="lease-name"
																	name="name"
																	value={this.state.lease[leases.lease_id - 1].name}
																	onChange={(e) =>
																		this.onLeaseChange(e, leases.lease_id - 1)
																	}
																/>
																<label htmlFor="lease_name">Name</label>
															</div>
														</div>
														<div className="col">
															<div className="form-floating mb-3">
																<input
																	className="form-control"
																	id="lease-area"
																	name="area"
																	value={this.state.lease[leases.lease_id - 1].area}
																	onChange={(e) =>
																		this.onLeaseChange(e, leases.lease_id - 1)
																	}
																/>
																<label htmlFor="lease_area">Area</label>
															</div>
														</div>
														<div className="col">
															<div className="form-floating mb-3">
																<input
																	className="form-control"
																	id="lease-starting"
																	name="starting_bid"
																	value={
																		this.state.lease[leases.lease_id - 1]
																			.starting_bid
																	}
																	onChange={(e) =>
																		this.onLeaseChange(e, leases.lease_id - 1)
																	}
																/>
																<label htmlFor="starting_bid">Starting Bid</label>
															</div>
														</div>
													</div>
												</>
											);
										})}

										<div className="row" style={{ margin: "auto" }}>
											<button
												className="btn btn-outline-success btn-sm"
												type="button"
												style={{ width: "auto", margin: "0 5px 0 0" }}
												onClick={this.addLease.bind(this)}
												disabled={this.state.lease.length >= 8}
											>
												<i className="fas fa-plus"></i>
											</button>
											<button
												className="btn btn-outline-danger btn-sm"
												type="button"
												style={{ width: "auto", margin: "0" }}
												onClick={this.removeLease.bind(this)}
												disabled={this.state.lease.length <= 1}
											>
												<i className="fas fa-minus"></i>
											</button>
										</div>

										<button
											disabled={!smraEnabled}
											className="btn btn-primary"
											onClick={this.createSMRAEvent.bind(this)}
											style={{ float: "right" }}
										>
											Create SMRA Auction
										</button>
									</form>
								</div>
							</div>
						) : (
							<></>
						)}

						{this.state.auctionCreationType === "mpsb" ? (
							<div className="card">
								<div className="card-body">
									<form>
										<div className="mb-3">
											<label className="form-label">Auction</label>
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auction-name"
													className="form-control"
													name="name"
													value={this.state.name}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auction-name">Auction Name</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auctioneer-name"
													className="form-control"
													name="auctioneer"
													value={this.state.auctioneer}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auctioneer-name">Auctioneer Name</label>
											</div>
										</div>

										<hr />

										<label className="form-label">Details</label>
										<div className="row">
											<div className="col">
												<div className="form-floating mb-3">
													<input
														className="form-control"
														id="asq_mw"
														name="asq_mw"
														value={this.state.asq_mw}
														onChange={this.onASQmwChange.bind(this)}
													/>
													<label htmlFor="asq">Auction Starting Quantity (MW)</label>
												</div>
											</div>
											<div className="col">
												<div className="form-floating mb-3">
													<input
														className="form-control"
														id="asq_gwhy"
														name="asq_gwhy"
														value={this.state.asq_gwhy}
														onChange={this.onASQgwhyChange.bind(this)}
													/>
													<label htmlFor="asq">Auction Starting Quantity (GWh/yr)</label>
												</div>
											</div>
										</div>

										<button
											disabled={!mpsbEnabled}
											className="btn btn-primary"
											onClick={this.createMPSBEvent.bind(this)}
											style={{ float: "right" }}
										>
											Create MPSB Auction
										</button>
									</form>
								</div>
							</div>
						) : (
							<></>
						)}

						{this.state.auctionCreationType === "enoo" ? (
							<div className="card">
								<div className="card-body">
									<form>
										<div className="mb-3">
											<label className="form-label">Auction</label>
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auction-name"
													className="form-control"
													name="name"
													value={this.state.name}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auction-name">Auction Name</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-floating mb-3">
												<input
													type="username"
													id="auctioneer-name"
													className="form-control"
													name="auctioneer"
													value={this.state.auctioneer}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="auctioneer-name">Auctioneer Name</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-floating mb-3">
												<input
													type="text"
													id="description"
													className="form-control"
													name="description"
													value={this.state.description}
													onChange={this.onChange.bind(this)}
												/>
												<label htmlFor="description">Auction Description</label>
											</div>
										</div>

										<hr />

										<label className="form-label">Details</label>
										<div className="row">
											<div className="col">
												<div className="form-floating mb-3">
													<input
														className="form-control"
														type="number"
														id="round_duration"
														name="round_duration"
														value={this.state.round_duration}
														onChange={this.onChange.bind(this)}
													/>
													<label htmlFor="round">Round Duration (Seconds)</label>
												</div>
											</div>

											<div className="col">
												<div className="form-floating mb-3">
													<input
														className="form-control"
														id="starting_price"
														type="number"
														name="starting_price"
														value={this.state.starting_price}
														onChange={this.onChange.bind(this)}
													/>
													<label htmlFor="starting_price">Starting Price</label>
												</div>
											</div>
										</div>

										<button
											disabled={!enooEnabled}
											className="btn btn-primary"
											onClick={this.createENOOEvent.bind(this)}
											style={{ float: "right" }}
										>
											Create ENOO Auction
										</button>
									</form>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				) : (
					<></>
				)}

				{this.props.auction.auction.type === 1 ? (
					<div className="card">
						<div className="card-body">
							<form>
								<div className="mb-3">
									<label className="form-label">Auction</label>
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auction-name"
											className="form-control"
											name="name"
											value={this.state.name}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auction-name">Auction Name</label>
									</div>
								</div>

								<div className="mb-3">
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auctioneer-name"
											className="form-control"
											name="auctioneer"
											value={this.state.auctioneer}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auctioneer-name">Auctioneer Name</label>
									</div>
								</div>

								<hr />

								{this.state.lease.map((leases) => {
									return (
										<>
											<label className="form-label">Lease {leases.lease_id}</label>

											<div className="row">
												<div className="col">
													<div className="form-floating mb-3">
														<input
															className="form-control"
															id="lease-name"
															name="name"
															value={this.state.lease[leases.lease_id - 1].name}
															onChange={(e) => this.onLeaseChange(e, leases.lease_id - 1)}
														/>
														<label htmlFor="lease_name">Name</label>
													</div>
												</div>
												<div className="col">
													<div className="form-floating mb-3">
														<input
															className="form-control"
															id="lease-area"
															name="area"
															value={this.state.lease[leases.lease_id - 1].area}
															onChange={(e) => this.onLeaseChange(e, leases.lease_id - 1)}
														/>
														<label htmlFor="lease_area">Area</label>
													</div>
												</div>
												<div className="col">
													<div className="form-floating mb-3">
														<input
															className="form-control"
															id="lease-starting"
															name="starting_bid"
															value={this.state.lease[leases.lease_id - 1].starting_bid}
															onChange={(e) => this.onLeaseChange(e, leases.lease_id - 1)}
														/>
														<label htmlFor="starting_bid">Starting Bid</label>
													</div>
												</div>
											</div>
										</>
									);
								})}

								<div className="row" style={{ margin: "auto" }}>
									<button
										className="btn btn-outline-success btn-sm"
										type="button"
										style={{ width: "auto", margin: "0 5px 0 0" }}
										onClick={this.addLease.bind(this)}
										disabled={this.state.lease.length >= 8}
									>
										<i className="fas fa-plus"></i>
									</button>
									<button
										className="btn btn-outline-danger btn-sm"
										type="button"
										style={{ width: "auto", margin: "0" }}
										onClick={this.removeLease.bind(this)}
										disabled={this.state.lease.length <= 1}
									>
										<i className="fas fa-minus"></i>
									</button>
								</div>

								<button
									disabled={!smraEnabled}
									className="btn btn-primary"
									onClick={this.createSMRAEvent.bind(this)}
									style={{ float: "right" }}
								>
									Create SMRA Auction
								</button>
							</form>
						</div>
					</div>
				) : (
					<></>
				)}

				{this.props.auction.auction.type === 2 ? (
					<div className="card">
						<div className="card-body">
							<form>
								<div className="mb-3">
									<label className="form-label">Auction</label>
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auction-name"
											className="form-control"
											name="name"
											value={this.state.name}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auction-name">Auction Name</label>
									</div>
								</div>

								<div className="mb-3">
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auctioneer-name"
											className="form-control"
											name="auctioneer"
											value={this.state.auctioneer}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auctioneer-name">Auctioneer Name</label>
									</div>
								</div>

								<hr />

								<label className="form-label">Details</label>
								<div className="row">
									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												id="asq_mw"
												name="asq_mw"
												value={this.state.asq_mw}
												onChange={this.onASQmwChange.bind(this)}
											/>
											<label htmlFor="asq">Auction Starting Quantity (MW)</label>
										</div>
									</div>
									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												id="asq_gwhy"
												name="asq_gwhy"
												value={this.state.asq_gwhy}
												onChange={this.onASQgwhyChange.bind(this)}
											/>
											<label htmlFor="asq">Auction Starting Quantity (GWh/yr)</label>
										</div>
									</div>
								</div>

								<button
									disabled={!mpsbEnabled}
									className="btn btn-primary"
									onClick={this.createMPSBEvent.bind(this)}
									style={{ float: "right" }}
								>
									Create MPSB Auction
								</button>
							</form>
						</div>
					</div>
				) : (
					<></>
				)}

				{this.props.auction.auction.type === auctionTypes.ENOO ? (
					<div className="card">
						<div className="card-body">
							<form>
								<div className="mb-3">
									<label className="form-label">Auction</label>
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auction-name"
											className="form-control"
											name="name"
											value={this.state.name}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auction-name">Auction Name</label>
									</div>
								</div>

								<div className="mb-3">
									<div className="form-floating mb-3">
										<input
											type="username"
											id="auctioneer-name"
											className="form-control"
											name="auctioneer"
											value={this.state.auctioneer}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="auctioneer-name">Auctioneer Name</label>
									</div>
								</div>

								<div className="mb-3">
									<div className="form-floating mb-3">
										<input
											type="text"
											id="description"
											className="form-control"
											name="description"
											value={this.state.description}
											onChange={this.onChange.bind(this)}
										/>
										<label htmlFor="description">Auction Description</label>
									</div>
								</div>

								<hr />

								<label className="form-label">Details</label>
								<div className="row">
									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												type="number"
												id="round_duration"
												name="round_duration"
												value={this.state.round_duration}
												onChange={this.onChange.bind(this)}
											/>
											<label htmlFor="round">Round Duration (Seconds)</label>
										</div>
									</div>

									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												id="starting_price"
												type="number"
												name="starting_price"
												value={this.state.starting_price}
												onChange={this.onChange.bind(this)}
											/>
											<label htmlFor="starting_price">Starting Price</label>
										</div>
									</div>
								</div>

								<button
									disabled={!enooEnabled}
									className="btn btn-primary"
									onClick={this.createENOOEvent.bind(this)}
									style={{ float: "right" }}
								>
									Create ENOO Auction
								</button>
							</form>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		);
	}
}

function WithParams(props) {
	let params = useParams(); // React Hooks
	return <CreateAuction {...props} params={params} />;
}

CreateAuction.propTypes = {
	createAuction: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, { getAuction, createAuction })(WithParams);
