import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import MPSBInformationPanel from "../../MPSBAuction/Components/MPSBInformationPanel";
import { MPSBAdminPanel } from "../../MPSBAuction/Components/MPSBAdminPanel";
import { MPSBResultsChart, MPSBResultsTable } from "../../MPSBAuction/Components/MPSBResults";
import { confirm } from "react-confirm-box";
import socket from "../../../../helpers/socket";
import { addNotification } from "../../../common/NotificationManager";
import {
	getAuction,
	makeMPSBBids,
	removeUserBidMPSBAuction,
	getAllMPSBBids,
	getAllMPSBBidsForUsers,
	getUserMPSBBid,
	setAuctionsLoading,
	setAuctionsNotLoading,
	getMPSBSimulationData,
} from "../../../../actions/auctionActions";
import { getAllUsers, setUserLoading, setUserNotLoading } from "../../../../actions/userActions";
import { joinAuctionRoom, emitRequestAssistance } from "../../../../helpers/emit/auction";

class MPSBAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price: 0,
			exitBid: false,
			selectedLeaseIds: [],
			submittedExitBid: false,
			requestAssistanceTimeout: 0,
			showTableData: false,
			usersBids: [{ id: 0, price: 0, quantity: 0, status: "new" }],
			graphUnit: "MW",
		};
	}

	// Run when the component is loaded
	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
		this.interval = setInterval(() => this.tick(), 1000);
	}

	// Run when the component is about to be unmounted
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	async refresh() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();
		await this.fetchData();
		await this.props.setAuctionsNotLoading();
		await this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);
		if (this.props.user.user.type === "admin") {
			await this.props.getAllMPSBBids(this.props.params.auctionId);
			await this.props.getAllUsers();
			await this.props.getMPSBSimulationData(this.props.params.auctionId);
		}
		if (this.props.user.user.type == "user") {
			await this.props.getAllMPSBBidsForUsers(this.props.params.auctionId);
		}
		await this.props.getUserMPSBBid(this.props.params.auctionId);
	}

	tick() {
		const newRequestAssistanceTimeout =
			this.state.requestAssistanceTimeout > 0 ? this.state.requestAssistanceTimeout - 1 : 0;
		this.setState({
			...this.state,
			requestAssistanceTimeout: newRequestAssistanceTimeout,
		});
	}

	async makeBid() {
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].status == "completed") {
				newUserBids.push({ price: this.state.usersBids[j].price, quantity: this.state.usersBids[j].quantity });
			}
		}

		const req_json = {
			bids: newUserBids,
		};

		const emit_json = {
			display_name: this.props.user.user.display_name,
			bids: newUserBids,
		};

		const result = await confirm("Bid Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "35vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<div>
									{this.getSubmitButtonText()}
									<div className="table-responsive">
										<table className="table table-bordered">
											<thead style={{ textAlign: "center" }}>
												<tr>
													<th style={{ width: "200px" }}>
														Offer Quantity <br />
													</th>
													<th style={{ width: "250px" }}>
														Deemed Energy Quantity <br />
													</th>
													<th style={{ width: "200px" }}>
														Deemed Offer Price <br />
													</th>
												</tr>
											</thead>
											<tbody>
												{this.state.usersBids ? (
													this.state.usersBids.map(({ id, price, quantity, status }) =>
														status === "completed" ? (
															<tr key={id}>
																<td>{priceFormat(quantity)} MW</td>
																<td>
																	{priceFormat(
																		Math.trunc((quantity * 8760 * 0.45) / 1000)
																	)}{" "}
																	GWh/yr
																</td>
																<td>{priceFormat(price)} €/MWh</td>
															</tr>
														) : (
															<></>
														)
													)
												) : (
													<></>
												)}
											</tbody>
										</table>
									</div>
								</div>
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.makeMPSBBids(this.props.params.auctionId, req_json, emit_json);
			await this.fetchData();
			return;
		}
	}

	async requestAssistance() {
		this.setState({ ...this.state, requestAssistanceTimeout: 10 });
		await emitRequestAssistance(this.props.params.auctionId, this.props.user.user);
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && this.props.user.user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getWinnerStyle(winner) {
		if (winner === "Lost") {
			return "table-danger";
		} else if (winner === "Won") {
			return "table-primary";
		} else {
			return {};
		}
	}

	editOption(id) {
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				newUserBids.push({
					id: id,
					price: this.state.usersBids[j].price,
					quantity: this.state.usersBids[j].quantity,
					status: "progress",
				});
			} else {
				newUserBids.push(this.state.usersBids[j]);
			}
		}
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	confirmOption(id) {
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				newUserBids.push({
					id: id,
					price: this.state.usersBids[j].price,
					quantity: this.state.usersBids[j].quantity,
					status: "completed",
				});
			} else {
				newUserBids.push(this.state.usersBids[j]);
			}
		}
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	addOption(id) {
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				newUserBids.push({
					id: id,
					price: this.state.usersBids[j].price,
					quantity: this.state.usersBids[j].quantity,
					status: "completed",
				});
			} else {
				newUserBids.push(this.state.usersBids[j]);
			}
		}
		newUserBids.push({ id: this.state.usersBids.length, price: 0, quantity: 0, status: "new" });
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	deleteOption(id) {
		const newUserBids = [];
		var idIndex = 0;
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				idIndex = 1;
			} else {
				newUserBids.push({
					id: j - idIndex,
					price: this.state.usersBids[j].price,
					quantity: this.state.usersBids[j].quantity,
					status: this.state.usersBids[j].status,
				});
			}
		}
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	onBidQuantityChange(event) {
		let value = parseInt(event.target.value.replace(/,/g, ""), 10);
		if (isNaN(value)) {
			value = 0;
		}
		const id = event.target.name;
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				newUserBids.push({
					id: id,
					price: this.state.usersBids[j].price,
					quantity: value,
					status: this.state.usersBids[j].status,
				});
			} else {
				newUserBids.push(this.state.usersBids[j]);
			}
		}
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	onBidPriceChange(event) {
		let value = event.target.value.replace(/^0+/, "").replace(/^-+/, "").replace("--", "");
		const id = event.target.name;
		const newUserBids = [];
		for (var j = 0; j < this.state.usersBids.length; j++) {
			if (this.state.usersBids[j].id == id) {
				newUserBids.push({
					id: id,
					price: value,
					quantity: this.state.usersBids[j].quantity,
					status: this.state.usersBids[j].status,
				});
			} else {
				newUserBids.push(this.state.usersBids[j]);
			}
		}
		this.setState({ ...this.state, usersBids: newUserBids });
	}

	async deleteUsersBids(id, user_id) {
		const { auction } = this.props.auction;
		const { users } = this.props.user;

		const result = await confirm("Delete User Bids", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "25vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>
									Are you sure you want to delete all the bids for{" "}
									<b>{users[user_id - 1].display_name}</b> (user0{user_id - 1})?
								</p>
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Delete{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.removeUserBidMPSBAuction(auction.id, user_id);
			await this.refresh();
		}
	}

	getSubmitButtonText() {
		let submitEnabled = true;

		//DISPLAYS COMBINED PRICES AND QUANTITY
		let length = 0;
		let combinedPrice = 0;
		let combinedQuantity = 0;
		this.state.usersBids.forEach((entry) => {
			if (entry.status === "completed") {
				combinedPrice = combinedPrice + (isNaN(entry.price) ? 0 : entry.price * entry.quantity);
				combinedQuantity = combinedQuantity + (isNaN(entry.quantity) ? 0 : entry.quantity);
			}
		});
		let average = combinedPrice / combinedQuantity;

		//Check price less than 150
		if (this.state.usersBids[this.state.usersBids.length - 1].price > 150) {
			submitEnabled = false;
			return (
				<div>
					<p>
						<em>Price cannot exceed €150/MWh</em>
					</p>
				</div>
			);
		}

		//Check first bid submitted
		if (this.state.usersBids.length === 1) {
			return (
				<div>
					<p>
						<em>Please Add First Bid</em>
					</p>
				</div>
			);
		}

		//CHECK THAT ALL BIDS ARE CONFIRMED
		this.state.usersBids.forEach((entry) => {
			if (entry.status == "progress") {
				submitEnabled = false;
			}
		});
		if (!submitEnabled) {
			return (
				<div>
					<p>
						<em>Please Confirm All Bids</em>
					</p>
				</div>
			);
		}

		//CHECKS THAT FINAL BID HAS BEEN CONFIRMED
		if (
			this.state.usersBids[this.state.usersBids.length - 1].price > 0 ||
			this.state.usersBids[this.state.usersBids.length - 1].quantity > 0
		) {
			submitEnabled = false;
			return (
				<div>
					<p>
						<em>Please Confirm Final Bid or Remove it</em>
					</p>
				</div>
			);
		}

		if (submitEnabled) {
			return (
				<p>
					<em>
						Submitting {this.state.usersBids.length - 1} Bid(s) with an average offer price of{" "}
						{priceFormat(average)} €/MWh
					</em>
				</p>
			);
		}
	}

	render() {
		const { auction, auction_loading, userBids } = this.props.auction;
		const { users, user, signedIn, points, bids, user_loading } = this.props.user;

		const preventMinus = (e) => {
			if (e.code === "Minus") {
				e.preventDefault();
			}
		};

		const showSubmission =
			this.props.auction.userBids.bids &&
			this.props.auction.userBids.bids.length == 0 &&
			auction.status === "In Progress";

		const enableSubmission =
			this.state.usersBids.length > 1 &&
			this.state.usersBids.filter((bid) => bid.status === "progress").length === 0 &&
			!(
				this.state.usersBids[this.state.usersBids.length - 1].price > 0 ||
				this.state.usersBids[this.state.usersBids.length - 1].quantity > 0
			);

		if (!signedIn) {
			return <Redirect to="/" />;
		}
		if (auction.hidden === 1 && user.type === "user") {
			return <Redirect to="/auctions" />;
		}
		if (auction_loading || user_loading) {
			return this.returnSpinner();
		}

		// Sockets
		socket.off("auction_refresh").on("auction_refresh", (data) => {
			this.fetchData();
			if (data.message) {
				addNotification({
					title: data.title,
					message: data.message,
					type: "info",
					duration: 7000,
				});
			}
		});
		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});
		socket.off("auction_assistance").on("auction_assistance", (msg) => {
			addNotification({
				title: "Assistance Requested",
				message: msg,
				type: "warning",
			});
		});
		socket.off("auction_reminder").on("auction_reminder", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 10000,
			});
		});
		socket.off("auction_announcement").on("auction_announcement", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "default",
				duration: 10000,
			});
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		return (
			<div className="container">
				<h3>{auction.name} Auction </h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
					<div className="col-6">
						{user.type === "user" ? (
							<button
								type="button"
								className="btn btn-primary btn-sm"
								onClick={() => this.requestAssistance()}
								disabled={this.state.requestAssistanceTimeout > 0}
								style={{ display: "block", marginRight: "0", marginLeft: "auto" }}
							>
								<i className="fas fa-question-circle"></i> Request Assistance
							</button>
						) : (
							<></>
						)}
					</div>
				</div>
				<br />

				{user.type === "admin" && <MPSBAdminPanel />}

				<MPSBInformationPanel auction={this.props.auction} user={this.props.user} config={this.props.config} />

				<br />

				{this.props.auction.auction.status === "In Progress" ||
				this.props.auction.auction.status === "Ended" ? (
					showSubmission ? (
						<div>
							<div>
								<div className="table-responsive">
									<table className="table table-bordered">
										<thead style={{ textAlign: "center" }}>
											<tr>
												<th>Deemed Offer Price (in €/MWh)</th>
												<th>Offer Quantity (in MW)</th>
												<th style={{ width: "200px" }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.usersBids ? (
												this.state.usersBids.map(({ id, price, quantity, status }) => (
													<tr key={id}>
														{status === "completed" ? (
															<td style={{ height: "65px" }}>
																{priceFormat(price)} €/MWh
															</td>
														) : (
															<td style={{ height: "65px" }}>
																<div className="input-group input-group-sm mb-3">
																	<input
																		type="number"
																		className="form-control"
																		name={id}
																		value={price}
																		onKeyPress={preventMinus}
																		onChange={this.onBidPriceChange.bind(this)}
																	/>
																</div>
															</td>
														)}

														{status === "completed" ? (
															<td style={{ height: "65px" }}>
																{quantityFormat(quantity)} MW
															</td>
														) : (
															<td style={{ height: "65px" }}>
																<div className="input-group input-group-sm mb-3">
																	<input
																		type="string"
																		className="form-control"
																		name={id}
																		value={commaFormat(quantity)}
																		onChange={this.onBidQuantityChange.bind(this)}
																	/>
																</div>
															</td>
														)}

														{status === "completed" ? (
															<td>
																<button
																	type="button"
																	className="btn btn-outline-primary btn-sm"
																	onClick={() => this.editOption(id)}
																>
																	Edit
																</button>
															</td>
														) : status === "progress" ? (
															<td>
																<button
																	type="button"
																	className="btn btn-outline-success btn-sm"
																	onClick={() => this.confirmOption(id)}
																	disabled={checkConfirmDisabled(
																		this.state.usersBids[id]
																	)}
																>
																	Confirm
																</button>

																<button
																	type="button"
																	className="btn btn-outline-danger btn-sm"
																	onClick={() => this.deleteOption(id)}
																>
																	Delete
																</button>
															</td>
														) : (
															<td>
																<button
																	type="button"
																	className="btn btn-outline-success btn-sm"
																	onClick={() => this.addOption(id)}
																	disabled={checkConfirmDisabled(
																		this.state.usersBids[id]
																	)}
																>
																	Confirm
																</button>
															</td>
														)}
													</tr>
												))
											) : (
												<></>
											)}
										</tbody>
									</table>
								</div>

								<div
									className="card"
									style={{
										textAlign: "right",
										alignItems: "right",
										paddingBottom: "0px",
									}}
								>
									<div className="card-body" style={{ paddingBottom: "14px" }}>
										{this.getSubmitButtonText()}

										<button
											type="button"
											className="btn btn-primary"
											disabled={!enableSubmission}
											onClick={() => this.makeBid()}
											name="submitBid"
										>
											Submit Bid
										</button>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div>
							<h4>Submitted Bids </h4>
							<div className="table-responsive">
								<table className="table table-bordered">
									<thead style={{ textAlign: "center" }}>
										<tr>
											<th>Deemed Offer Price (in €/MWh)</th>
											<th>Offer Quantity (in MW)</th>
											<th style={{ width: "200px" }}>Result</th>
										</tr>
									</thead>
									<tbody>
										{this.props.auction.userBids.bids ? (
											this.props.auction.userBids.bids.map(({ id, price, quantity, winner }) => (
												<tr key={id} className={this.getWinnerStyle(winnerFormat(winner))}>
													<td>{priceFormat(price)} €/MWh</td>
													<td>{quantityFormat(quantity)} MW</td>
													<td>{winnerFormat(winner)}</td>
												</tr>
											))
										) : (
											<></>
										)}
									</tbody>
								</table>
							</div>
						</div>
					)
				) : (
					<></>
				)}

				<hr />

				{user.type === "admin" ? (
					auction.status === "In Progress" ? (
						<div>
							<h4>Live Admin Results </h4>

							<div>
								<div className="table-responsive">
									<table className="table table-bordered">
										<thead style={{ textAlign: "center" }}>
											<tr>
												<th>User</th>
												<th>Deemed Offer Price (in €/MWh)</th>
												<th>Offer Quantity (in MW)</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.props.auction.allBids.bids ? (
												this.props.auction.allBids.bids.map(
													({ id, price, quantity, winner, user_id }) => (
														<tr key={id}>
															<td>
																{users[user_id - 1].display_name} (user0{user_id - 1})
															</td>
															<td>{priceFormat(price)} €/MWh</td>
															<td>{quantityFormat(quantity)} MW</td>
															<td>
																<button
																	type="button"
																	className="btn btn-outline-danger btn-sm"
																	onClick={() => this.deleteUsersBids(id, user_id)}
																>
																	Delete
																</button>
															</td>
														</tr>
													)
												)
											) : (
												<></>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					) : auction.status === "Ended" ? (
						<div>
							<h4>Final Admin Results </h4>

							{this.props.auction.allBids.bids ? (
								<>
									<MPSBResultsTable bids={this.props.auction.allBids.bids} users={users} />
									<br />
									<MPSBResultsChart
										asq={this.props.auction.auction.asq}
										bids={this.props.auction.allBids.bids}
										users={users}
										user={user}
										title={`Auction Results`}
										unit={this.state.graphUnit}
										toggleUnit={() => {
											this.setState({
												...this.state,
												graphUnit: this.state.graphUnit === "MW" ? "GWh" : "MW",
											});
										}}
									/>
								</>
							) : (
								<></>
							)}
						</div>
					) : (
						<></>
					)
				) : (
					<></>
				)}

				{user.type === "user" &&
				auction.status === "Ended" &&
				this.props.auction.allBidsUser.bids[0].winner !== null ? (
					<div>
						<h4>Final Results </h4>
						<div className="table-responsive">
							<table
								className="table table-sm table-bordered mx-auto w-auto"
								style={{ textAlign: "center" }}
							>
								<thead>
									<tr>
										<th
											style={{
												minWidth: "160px",
												margin: `auto`,
												padding: `15px`,
											}}
										></th>
										{this.props.auction.allBidsUser.bids ? (
											this.props.auction.allBidsUser.bids.map(
												({ id, price, quantity, winner, user_id }) => (
													<th
														key={id}
														style={{
															minWidth: "150px",
															margin: `auto`,
															padding: `15px`,
														}}
														className={
															user_id === 0
																? "table-secondary"
																: winner === 0
																? "table-danger"
																: "table-primary"
														}
													>
														{userNameFormatter(user_id)}
													</th>
												)
											)
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>Deemed Offer Price</th>
										{this.props.auction.allBidsUser.bids ? (
											this.props.auction.allBidsUser.bids.map(
												({ id, price, quantity, winner, user_id }) => (
													<td
														key={id}
														style={{
															minWidth: "150px",
															margin: `auto`,
															padding: `15px`,
															textAlign: "left",
														}}
														className={
															user_id === 0
																? ""
																: winner === 0
																? "table-danger"
																: "table-primary"
														}
													>
														{priceFormat(price)} €/MWh
													</td>
												)
											)
										) : (
											<></>
										)}
									</tr>

									<tr>
										<th>Offer Quantity</th>
										{this.props.auction.allBidsUser.bids ? (
											this.props.auction.allBidsUser.bids.map(
												({ id, price, quantity, winner, user_id }) => (
													<td
														key={id}
														style={{
															minWidth: "150px",
															margin: `auto`,
															padding: `15px`,
															textAlign: "left",
														}}
														className={
															user_id === 0
																? ""
																: winner === 0
																? "table-danger"
																: "table-primary"
														}
													>
														{quantityFormat(quantity)} MW
													</td>
												)
											)
										) : (
											<></>
										)}
									</tr>

									<tr>
										<th>Results</th>
										{this.props.auction.allBidsUser.bids ? (
											this.props.auction.allBidsUser.bids.map(
												({ id, price, quantity, winner, user_id }) => (
													<td
														key={id}
														style={{
															minWidth: "150px",
															margin: `auto`,
															padding: `15px`,
															textAlign: "left",
														}}
														className={
															user_id === 0
																? ""
																: winner === 0
																? "table-danger"
																: "table-primary"
														}
													>
														{winnerFormat(winner)}
													</td>
												)
											)
										) : (
											<></>
										)}
									</tr>
								</tbody>
							</table>
						</div>
						<br />
						<MPSBResultsChart
							asq={this.props.auction.auction.asq}
							bids={this.props.auction.allBidsUser.bids}
							// users={users}
							user={this.props.user.user}
							title={`Auction Results`}
							unit={this.state.graphUnit}
							toggleUnit={() => {
								this.setState({
									...this.state,
									graphUnit: this.state.graphUnit === "MW" ? "GWh" : "MW",
								});
							}}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		);
	}
}

function userNameFormatter(user_id) {
	if (user_id === 0) {
		return "Other Bidder";
	} else {
		return "Your Bid";
	}
}

function checkConfirmDisabled(bid) {
	if (bid.price == 0 || bid.quantity == 0 || bid.price > 150) {
		return true;
	} else {
		return false;
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <MPSBAuction {...props} params={params} />;
}

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat().format(num);
}

function priceFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
}

function quantityFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {}).format(num);
}

function winnerFormat(num) {
	if (num == null) {
		return "Not Announced";
	}
	if (num == 0) {
		return "Lost";
	}
	if ((num = 1)) {
		return "Won";
	}
}

MPSBAuction.propTypes = {
	getAuction: PropTypes.func.isRequired,
	makeMPSBBids: PropTypes.func.isRequired,
	removeUserBidMPSBAuction: PropTypes.func.isRequired,
	getAllMPSBBids: PropTypes.func.isRequired,
	getAllMPSBBidsForUsers: PropTypes.func.isRequired,
	getMPSBSimulationData: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	getUserMPSBBid: PropTypes.func.isRequired,
	getAllUsers: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, {
	getAllUsers,
	getAuction,
	makeMPSBBids,
	removeUserBidMPSBAuction,
	getAllMPSBBids,
	getAllMPSBBidsForUsers,
	getMPSBSimulationData,
	getUserMPSBBid,
	setAuctionsLoading,
	setAuctionsNotLoading,
	setUserLoading,
	setUserNotLoading,
})(WithParamsAndNavigate);
