import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";

import StartSMRAAuction from "../../../../components/auctions/SMRAAuction/Pages/SMRAStart";
import StartMPSBAuction from "../../../../components/auctions/MPSBAuction/Pages/MPSBStart";
import StartENOOAuction from "../../../../components/auctions/ENOOAuction/Pages/ENOOStart";
import StartRENOAuction from "../../../../components/auctions/RENOAuction/Pages/RENOStart";
import ErrorPage from "../../../../ErrorPage";
import { auctionTypes } from "../../../common/constants";

class StartAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auctionType: null,
		};
	}

	async componentDidMount() {
		const typeId = this.props.auction.auctions.filter((x) => {
			return parseInt(x.id) === parseInt(this.props.params.auctionId);
		});
		if (typeId.length > 0) {
			this.setState({
				...this.state,
				auctionType: parseInt(typeId[0].type),
			});
		} else {
			this.setState({
				...this.state,
				auctionType: null,
			});
		}
	}

	render() {
		if (this.state.auctionType === auctionTypes.SMRA) {
			return <StartSMRAAuction />;
		} else if (this.state.auctionType === auctionTypes.MPSB) {
			return <StartMPSBAuction />;
		} else if (this.state.auctionType === auctionTypes.ENOO) {
			return <StartENOOAuction />;
		} else if (this.state.auctionType === auctionTypes.RENO) {
			return <StartRENOAuction />;
		} else {
			return <ErrorPage />;
		}
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <StartAuction {...props} params={params} />;
}

StartAuction.propTypes = {};

const mapStateToProps = (state) => ({
	auction: state.auction,
});

export default connect(mapStateToProps, {})(WithParamsAndNavigate);
