import React, { Component } from "react";
import PropTypes from "prop-types";

class HistoryTables extends Component {
	constructor(props) {
		super(props);
	}

	getRoundLeaseUserStatus(round_num, lease_id) {
		const { history } = this.props.auction;

		let user_history = {};
		history
			.filter((his) => his.lease_id == lease_id && his.round == round_num)
			.forEach((his) => {
				if (user_history.hasOwnProperty(his.display_name)) {
					user_history[his.display_name].push(his);
				} else {
					user_history[his.display_name] = [his];
				}
			});

		let user_status = [];
		Object.keys(user_history).map((display_name) => {
			let status = [];
			if (user_history[display_name].filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.no_bid == 0).length > 0)
				status.push(
					`${
						user_history[display_name].filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.no_bid == 0)
							.length
					} live bid(s)`
				);
			if (user_history[display_name].filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.no_bid == 0).length > 0)
				status.push(
					`${
						user_history[display_name].filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.no_bid == 0)
							.length
					} frozen live bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0
						).length
					} exit bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0
						).length
					} frozen exit bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0
						).length
					} combined exit bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0
						).length
					} frozen combined exit bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1
						).length
					} (no bid) exit bid(s)`
				);
			if (
				user_history[display_name].filter(
					(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1
				).length > 0
			)
				status.push(
					`${
						user_history[display_name].filter(
							(uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1
						).length
					} frozen (no bid) exit bid(s)`
				);

			user_status.push(`${display_name} [${status.length > 0 ? status.join(", ") : "no bids"}]`);
		});

		return user_status.length > 0 ? user_status.join(", ") : "";
	}

	getUserHistoryTableCellData(his) {
		let user_status = [];
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length
				} live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length
				} frozen live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length
				} combined live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 0 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length
				} combined frozen live bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 0).length
				} exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 0).length
				} frozen exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 1 && uh.no_bid == 0).length
				} combined exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 1 && uh.no_bid == 0).length
				} frozen combined exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 0 && uh.combined == 0 && uh.no_bid == 1).length
				} (no bid) exit bid(s)`
			);
		if (his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1).length > 0)
			user_status.push(
				`${
					his.filter((uh) => uh.exit == 1 && uh.carried == 1 && uh.combined == 0 && uh.no_bid == 1).length
				} frozen (no bid) exit bid(s)`
			);

		if (user_status.length == 0) {
			return <></>;
		} else {
			return user_status.join(", ");
		}
	}

	renderFrozenBidsTable() {
		const { auction, results, round } = this.props.auction;
		const { user } = this.props.user;

		if (!results.results || user.type !== "admin") {
			return <></>;
		}

		return (
			<div>
				<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
					{`Round ${
						round.round_status && round.round_status == "In Progress" ? auction.round - 1 : auction.round
					} Frozen Bids`}
				</div>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="table-responsive">
							<table className="table table-sm table-bordered">
								<thead>
									<tr style={{ textAlign: "center" }}>
										<th>Lease Name</th>
										<th>Starting Bid Price</th>
										<th>Status</th>
										<th>Bid User</th>
										<th>Imputed Price</th>
										<th>Cash Bid</th>
										<th>Bid Type</th>
									</tr>
								</thead>
								<tbody>
									{results.results ? (
										results.results.map(
											({
												lease_id,
												name,
												starting_bid,
												status,
												bid_username,
												bid_display_name,
												bid_amount,
												bid_financial_exposure,
												bid_exit,
												bid_combined,
												round,
												bid_no_bid,
											}) => (
												<tr key={lease_id}>
													<td>{name}</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{currencyFormat(starting_bid)}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{status}
													</td>
													<td>
														{bid_username && bid_display_name
															? `${bid_display_name} (${bid_username})`
															: "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_amount ? currencyFormat(bid_amount) : "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_financial_exposure
															? currencyFormat(bid_financial_exposure)
															: "-"}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{bid_username ? (
															<>
																{bid_combined ? "Combined " : ""}
																{bid_no_bid ? "(No Bid) " : ""}
																{bid_exit ? "Exit Bid" : "Live Bid"}
															</>
														) : (
															"-"
														)}
													</td>
												</tr>
											)
										)
									) : (
										<></>
									)}
								</tbody>
							</table>
						</div>
					</li>
				</ul>
			</div>
		);
	}

	renderLeaseHistoryTable() {
		const { auction, round, history, prices } = this.props.auction;
		const { user } = this.props.user;

		if (!history || user.type !== "admin") {
			return <></>;
		}

		let lease_history = {};
		history.forEach((his) => {
			if (lease_history.hasOwnProperty(his.round)) {
				lease_history[his.round].push(his);
			} else {
				lease_history[his.round] = [his];
			}
		});

		let lease_his = [];
		Object.keys(lease_history).map((round_num) => {
			lease_his.push({
				round_num: round_num,
				history: lease_history[round_num],
			});
		});

		return (
			<div>
				<br />
				<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
					Lease History
				</div>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="table-responsive">
							<table
								className="table table-sm table-bordered"
								id="table-lease-history"
								style={{
									textAlign: "center",
									verticalAlign: "center",
								}}
							>
								<thead style={{ textAlign: "center" }}>
									<tr>
										<th></th>
										<th colSpan="8">Leases (Clock Price | Live Bids | Total Bids)</th>
									</tr>
									<tr>
										<th style={{ minWidth: "25px" }}>Round</th>
										{auction.leases ? (
											auction.leases.map(({ id, name }) => (
												<th
													key={id}
													style={{
														minWidth: "125px",
													}}
												>
													{name}
												</th>
											))
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{lease_his.map(({ round_num, history }) => (
										<tr key={round_num}>
											<th
												className="align-middle"
												style={{
													textAlign: "center",
													verticalAlign: "center",
												}}
											>
												{round_num}
											</th>
											{auction.leases ? (
												auction.leases.map(({ id, name, current_bid }) => (
													<td key={id}>
														{history.filter((his) => {
															return his.name == name;
														}).length > 0 ? (
															<>
																{`${currencyFormat(
																	prices.filter((price) => {
																		return (
																			price.lease_id == id &&
																			price.round == round_num
																		);
																	})[0].price
																)} | 
                                                            ${
																history.filter((his) => {
																	return his.name == name && his.exit == 0;
																}).length
															} | 
                                                            ${
																history.filter((his) => {
																	return his.name == name;
																}).length
															}`}
															</>
														) : (
															""
														)}
													</td>
												))
											) : (
												<></>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</li>
				</ul>
			</div>
		);
	}

	renderUserHistoryTable() {
		const { round, history } = this.props.auction;
		const { user, users } = this.props.user;

		if (!history || user.type !== "admin") {
			return <></>;
		}

		let user_history = {};
		history.forEach((his) => {
			if (user_history.hasOwnProperty(his.round)) {
				user_history[his.round].push(his);
			} else {
				user_history[his.round] = [his];
			}
		});

		let round_history = [];
		Object.keys(user_history).map((round_num) => {
			round_history.push({
				round_num: round_num,
				history: user_history[round_num],
			});
		});

		return (
			<div>
				<br />
				<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
					User History
				</div>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="table-responsive">
							<table className="table table-sm table-bordered" id="table-user-history">
								<thead style={{ textAlign: "center" }}>
									<tr>
										<th>Round</th>
										{users ? (
											users.map(({ id, username, display_name }) => (
												<th
													key={id}
													style={{
														minWidth: "125px",
													}}
												>{`${display_name} (${username})`}</th>
											))
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{round_history.map(({ round_num, history }) => (
										<tr key={round_num}>
											<th
												className="align-middle"
												style={{
													textAlign: "center",
													verticalAlign: "center",
												}}
											>
												{round_num}
											</th>
											{users ? (
												users.map(({ id, username, display_name }) => (
													<td key={id}>
														{this.getUserHistoryTableCellData(
															history.filter((his) => his.username == username)
														)}
													</td>
												))
											) : (
												<></>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</li>
				</ul>
			</div>
		);
	}

	renderPriceDemandHistoryTable() {
		const { auction, price_demand_history } = this.props.auction;

		let user_history = {};
		price_demand_history.forEach((his) => {
			if (user_history.hasOwnProperty(his.round)) {
				user_history[his.round].push(his);
			} else {
				user_history[his.round] = [his];
			}
		});

		let round_history = [];
		Object.keys(user_history).map((round_num) => {
			round_history.push({
				round_num: round_num,
				history: user_history[round_num],
			});
		});

		return (
			<div>
				<br />
				<div className="card-header" style={{ alignItems: "center", textAlign: "center" }}>
					Price and Demand History
				</div>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="table-responsive">
							<table
								className="table table-sm table-bordered"
								id="table-lease-history"
								style={{
									textAlign: "center",
									verticalAlign: "center",
								}}
							>
								<thead style={{ textAlign: "center" }}>
									<tr>
										<th></th>
										{auction.leases ? (
											auction.leases.map(({ id, name }) => (
												<th
													colSpan="2"
													key={id}
													style={{
														minWidth: "125px",
													}}
												>
													{name}
												</th>
											))
										) : (
											<></>
										)}
									</tr>
									<tr>
										<th style={{ minWidth: "25px" }}>Round</th>
										{auction.leases ? (
											auction.leases.map(() => (
												<>
													<th>Clock Price</th>
													<th>Demand</th>
												</>
											))
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{round_history.map(({ round_num, history }) => (
										<tr key={round_num}>
											<th
												className="align-middle"
												style={{
													textAlign: "center",
													verticalAlign: "center",
												}}
											>
												{round_num}
											</th>
											{auction.leases ? (
												auction.leases.map(({ id }) => (
													<>
														{history.filter((his) => {
															return his.lease_id == id;
														}).length > 0 ? (
															<>
																<td>
																	{`${currencyFormat(
																		history.filter((his) => {
																			return his.lease_id == id;
																		})[0].price
																	)}`}
																</td>
																<td>
																	{`${
																		history.filter((his) => {
																			return his.lease_id == id;
																		})[0].demand
																	} `}
																</td>
															</>
														) : (
															<></>
														)}
													</>
												))
											) : (
												<></>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</li>
				</ul>
			</div>
		);
	}

	render() {
		const { auction } = this.props.auction;
		const { user } = this.props.user;

		if (auction.round == 0) return <></>;

		return (
			<div>
				<br />
				<hr />
				<br />
				<div className="card" style={{ width: "90%", margin: "auto" }}>
					<div className="card-body">
						{this.renderFrozenBidsTable()}
						{this.renderLeaseHistoryTable()}
						{this.renderUserHistoryTable()}
						{this.renderPriceDemandHistoryTable()}
					</div>
				</div>
			</div>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

HistoryTables.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default HistoryTables;
