import io from "socket.io-client";
import store from "../store";
import { SOCKET_CONNECT, SOCKET_DISCONNECT } from "../actions/types";

console.log("Attempting to establish socket connection to ", process.env.REACT_APP_BACKEND_HOST);

const socket = io(process.env.REACT_APP_BACKEND_HOST, {
	cors: {
		origin: "*",
		methods: ["GET", "POST"],
	},
	autoConnect: true,
	forceNew: true,
	transports: ["polling", "websocket"],
	path: "/api/socket.io",
	extraHeaders: {
		"x-api-key": process.env.REACT_APP_BACKEND_API_KEY,
	},
});

socket.on("connect", () => {
	console.log("Successfully connected to the socket");
	store.dispatch({
		type: SOCKET_CONNECT,
	});
});

socket.on("error", () => {
	console.log("Sorry, there seems to be an issue with the connection!");
	store.dispatch({
		type: SOCKET_DISCONNECT,
	});
});

socket.on("connect_error", (err) => {
	console.log(`Socket connection error! ${err}`);
	store.dispatch({
		type: SOCKET_DISCONNECT,
	});
});

socket.on("disconnect", () => {
	console.log("Disconnected from the socket, attempting to reconnect...");
	store.dispatch({
		type: SOCKET_DISCONNECT,
	});
});

socket.connect();

export default socket;
