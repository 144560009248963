import React, { Component } from "react";
import { connect } from "react-redux";
import {
	getSimulations,
	setSimulationLoading,
	setSimulationNotLoading,
	resetSimulation,
} from "../../actions/simulationActions";
import { leaveAllRooms } from "../../helpers/emit/auction";
import { leaveASTRooms } from "../../helpers/emit/auctionSimulationTool";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { confirm } from "react-confirm-box";
import { auctionTypes } from "../common/constants";

class Simulations extends Component {
	componentDidMount() {
		this.componentRefresh();
	}

	async componentRefresh() {
		await leaveAllRooms();
		await leaveASTRooms();
		await this.props.setSimulationLoading();
		await this.props.getSimulations();
		await this.props.resetSimulation();
		await this.props.setSimulationNotLoading();
	}

	createSimulation() {
		this.props.history.push(`/simulations/create/${0}`);
	}

	goToViewSimulation(id) {
		this.props.history.push(`/simulations/${id}`);
	}

	duplicateSimulation(id) {
		this.props.history.push(`/simulations/create/${id}`);
	}

	render() {
		const { signedIn, user } = this.props.user;
		const { simulations, simulation_loading } = this.props.simulation;
		const { db_loading } = this.props.database;

		if (!signedIn) {
			return <Redirect to="/" />;
		}

		if (simulation_loading || db_loading) {
			return (
				<div className="container">
					<h3>Simulations</h3>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li key="1" className="breadcrumb-item active" aria-current="page">
								Simulations
							</li>
						</ol>
					</nav>
					<hr />

					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container">
				<h3>Simulations</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li key="2" className="breadcrumb-item active" aria-current="page">
							Simulations
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => this.componentRefresh()}
						>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
					<div
						className="col-6"
						style={{
							textAlign: "right",
							alignItems: "right",
						}}
					>
						<button
							type="button"
							className="btn btn-outline-success btn-sm"
							onClick={() => this.createSimulation()}
						>
							Create New Simulation
						</button>
					</div>
				</div>
				<br />

				<div className="container">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								{user.type === "admin" && <th>User</th>}
								<th>Type</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{simulations && simulations.length > 0 ? (
								simulations.map(({ id, name, creator, type_name, username }) => (
									<>
										<tr key={id}>
											<td>{name}</td>
											{user.type === "admin" && (
												<td>
													{creator} ({username})
												</td>
											)}
											<td>{type_name}</td>
											<td>
												<button
													type="button"
													className="btn btn-outline-primary btn-sm"
													onClick={() => this.goToViewSimulation(id)}
													style={{
														marginLeft: "5px",
													}}
													data-toggle="tooltip"
													data-placement="top"
													title="Open Simulation"
												>
													<i className="fas fa-external-link-alt"></i>
												</button>
												{/* <button
													type="button"
													className="btn btn-outline-secondary btn-sm"
													onClick={() => this.duplicateSimulation(id)}
													style={{
														marginLeft: "5px",
													}}
													data-toggle="tooltip"
													data-placement="top"
													title="Duplicate Simulation"
												>
													<i className="far fa-copy"></i>
												</button> */}
											</td>
										</tr>
									</>
								))
							) : (
								<></>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

Simulations.propTypes = {
	getSimulations: PropTypes.func.isRequired,
	setSimulationNotLoading: PropTypes.func.isRequired,
	setSimulationLoading: PropTypes.func.isRequired,
	resetSimulation: PropTypes.func.isRequired,
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	database: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	simulation: state.simulation,
	user: state.user,
	database: state.database,
});

export default connect(mapStateToProps, {
	setSimulationLoading,
	getSimulations,
	setSimulationNotLoading,
	resetSimulation,
})(Simulations);
