import { combineReducers } from "redux";
import auctionReducer from "./auctionReducer";
import simulationReducer from "./simulationReducer";
import databaseReducer from "./databaseReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import trackerReducer from "./trackerReducer";
import configReducer from "./configReducer";
import socketReducer from "./socketReducer";

export default combineReducers({
	auction: auctionReducer,
	simulation: simulationReducer,
	user: userReducer,
	database: databaseReducer,
	error: errorReducer,
	tracker: trackerReducer,
	config: configReducer,
	socket: socketReducer,
});
