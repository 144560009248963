import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { signOut } from "../../actions/userActions";
import Logo from "../../assets/PA_Consulting_Logo.png";
import ConnectionStatus from "./Navbar/ConnectionStatus";

class Navbar extends Component {
	signOut(event) {
		event.preventDefault();
		this.props.signOut();
	}

	render() {
		const { signedIn, user } = this.props.user;
		const config = this.props.config;

		return (
			<nav className="navbar sticky-top navbar-expand-md navbar-dark bg-primary">
				<div className="container-fluid">
					{/* Logo and Website Name */}
					<a className="navbar-brand" href="/">
						<img
							src={Logo}
							alt=""
							className="d-inline-block align-middle mr-2"
							style={{ width: "40px", marginRight: "15px" }}
						/>
						<span>Renewable Auctions&nbsp;&nbsp;</span>
					</a>

					{/* Nav Links */}
					{signedIn ? (
						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="navbar-nav">
								{user.type === "admin" || !config.presentationMode ? (
									<>
										<li className="nav-item active">
											<Link className="nav-link" to="/auctions">
												Auctions&nbsp;&nbsp;
											</Link>
										</li>
										<li className="nav-item active">
											<Link className="nav-link" to="/simulations">
												Simulation Tool&nbsp;&nbsp;
											</Link>
										</li>
										{/* <li className="nav-item active">
											<Link className="nav-link" to="/calculator">
												Calculator&nbsp;&nbsp;
											</Link>
										</li>
										<li className="nav-item active">
											<Link className="nav-link" to="/bidtrackers">
												Bid Trackers&nbsp;&nbsp;
											</Link>
										</li> */}
									</>
								) : (
									<></>
								)}
								{user.type === "admin" ? (
									<>
										<li className="nav-item active">
											<Link className="nav-link" to="/dashboards">
												Dashboards&nbsp;&nbsp;
											</Link>
										</li>
										<li className="nav-item active">
											<Link className="nav-link" to="/config">
												Config&nbsp;&nbsp;
											</Link>
										</li>
									</>
								) : (
									<></>
								)}
							</ul>
						</div>
					) : (
						<></>
					)}

					{/* Sign Out Button */}
					{signedIn ? (
						<div className="d-flex">
							<ul className="navbar-nav">
								<li className="nav-item" style={{ marginRight: "20px" }}>
									<ConnectionStatus />
								</li>
								<li className="nav-item" style={{ marginRight: "20px" }}>
									<a className="nav-link">
										Signed in as:{" "}
										<strong>
											{user.display_name} ({user.username})
										</strong>
									</a>
								</li>
							</ul>
							<button className="btn btn-outline-light" onClick={this.signOut.bind(this)}>
								Sign Out
							</button>
						</div>
					) : (
						<></>
					)}
				</div>
			</nav>
		);
	}
}

Navbar.propTypes = {
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, { signOut })(Navbar);
