export const RAInput = ({ label, placeholder, type, name, id, value, onChange, disabled }) => {
	return (
		<div className="form-group mb-3">
			{label && (
				<small className="form-text text-muted nowrap" style={{ whiteSpace: "nowrap" }}>
					{label}
				</small>
			)}
			<input
				className="form-control"
				type={type}
				id={id}
				name={name}
				value={value}
				placeholder={placeholder}
				onChange={(e) => onChange(e)}
				disabled={disabled}
			/>
		</div>
	);
};
