import React, { Component } from "react";
import PropTypes from "prop-types";
import { Timer } from "../../GeneralAuction/Components/Timer";

class SMRAInformationPanel extends Component {
	constructor(props) {
		super(props);
	}

	getStatusStyle(status) {
		if (status == "In Progress") {
			return { color: "green" };
		} else if (status == "Ended") {
			return { color: "red" };
		} else if (status == "Awaiting Start") {
			return { color: "blue" };
		} else {
			return {};
		}
	}

	getUserStatus() {
		const { auction, results, winning_bids } = this.props.auction;
		const { user, points, bids } = this.props.user;

		if (auction.status == "Ended") {
			if (winning_bids && winning_bids.bids) {
				const winnings = winning_bids.bids.filter((bid) => {
					return bid.username == user.username;
				});
				if (winnings.length > 0) {
					let winning_lease_names = [];
					winnings.forEach((winning) => {
						winning_lease_names.push(`${winning.name} (${currencyFormat(winning.amount)})`);
					});
					return `Has won ${winnings.length} lease(s): ${winning_lease_names.join(", ")}`;
				} else {
					return "Has not won any leases";
				}
			} else {
				return "The auction has ended. The results will be announced soon. ";
			}
		}

		// If the user has no points and no current frozen/submitted bids
		if (points.points == 0 && bids.current_bids.length == 0) {
			return "No Eligibility Remaning";
		}

		// User has no points remaning, but some frozen bids in the current round
		if (
			points.points == 0 &&
			bids.current_bids.filter((bid) => {
				return bid.carried == 1;
			}).length > 0
		) {
			return "Frozen";
		}

		let statusString = [];
		if (
			points.points >= 0 &&
			bids.current_bids.filter((bid) => {
				return bid.carried == 1;
			}).length > 0
		) {
			statusString.push(
				`${
					bids.current_bids.filter((bid) => {
						return bid.carried == 1;
					}).length
				} frozen bid(s)`
			);
		}
		if (
			points.points >= 0 &&
			bids.current_bids.filter((bid) => {
				return bid.exit == 0 && bid.carried == 0 && bid.no_bid == 0;
			}).length > 0
		) {
			statusString.push(
				`submitted ${
					bids.current_bids.filter((bid) => {
						return bid.exit == 0 && bid.carried == 0 && bid.no_bid == 0;
					}).length
				} live bid(s)`
			);
		}
		if (
			points.points >= 0 &&
			bids.current_bids.filter((bid) => {
				return bid.exit == 1 && bid.carried == 0 && bid.no_bid == 0;
			}).length > 0
		) {
			statusString.push(
				`submitted ${
					bids.current_bids.filter((bid) => {
						return bid.exit == 1 && bid.carried == 0 && bid.no_bid == 0;
					}).length
				} exit bid(s)`
			);
		}
		if (
			points.points >= 0 &&
			bids.current_bids.filter((bid) => {
				return bid.exit == 1 && bid.carried == 0 && bid.no_bid == 1;
			}).length > 0
		) {
			statusString.push(
				`submitted ${
					bids.current_bids.filter((bid) => {
						return bid.exit == 1 && bid.carried == 0 && bid.no_bid == 0;
					}).length
				} (no bid) exit bid(s)`
			);
		}

		if (statusString.length > 0) {
			return `Has ${statusString.join(", ")}`;
		} else if (points.points > 0) {
			return "Yet to submit bid";
		}

		return "NA";
	}

	render() {
		const { auction, round } = this.props.auction;
		const { points, user, bids } = this.props.user;

		// Calculate cash bid
		let prevCashBid = 0;
		let curCashBid = 0;

		if (bids) {
			if (bids.previous_bids.length > 0) {
				prevCashBid = bids.previous_bids[0].financial_exposure;
			}
			if (bids.current_bids.length > 0) {
				curCashBid = bids.current_bids[0].financial_exposure;
			}
		}

		return (
			<>
				<div className="row">
					<div className="col">
						<div className="card">
							<div className="card-header" style={{ textAlign: "center" }}>
								<h5>Auction</h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									<p>
										Status:{" "}
										{auction.status ? (
											<b style={this.getStatusStyle(auction.status)}>{auction.status}</b>
										) : (
											""
										)}
									</p>
								</li>
								<li className="list-group-item">
									<p>Auctioneer: {auction.auctioneer ? <b>{auction.auctioneer}</b> : ""}</p>
								</li>
							</ul>
						</div>
					</div>

					{auction.round == 0 ? (
						<></>
					) : (
						<>
							<div className="col">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>{round.round ? "Round " + round.round : "Round"}</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												Status:{" "}
												{round.round_status ? (
													<b style={this.getStatusStyle(round.round_status)}>
														{round.round_status}
													</b>
												) : (
													""
												)}
											</p>
										</li>
										<li className="list-group-item">
											<p>
												<Timer
													endTime={this.props.auction.round.end_time}
													status={this.props.auction.round.round_status}
													highligh={30}
												/>
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>{user.display_name ? `${user.display_name} Points` : "User Points"}</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												Eligibility Points (Round Start):{" "}
												{auction.status == "Ended" ? (
													"NA"
												) : points.starting_points ? (
													<b>{points.starting_points}</b>
												) : points.starting_points == 0 ? (
													<b>{points.starting_points}</b>
												) : (
													"NA"
												)}
											</p>
										</li>
										<li className="list-group-item">
											<p>
												Eligibility Points (Current):{" "}
												{auction.status == "Ended" ? (
													"NA"
												) : points.points ? (
													<b>{points.points}</b>
												) : points.points == 0 ? (
													<b>{points.points}</b>
												) : (
													"NA"
												)}
											</p>
										</li>
									</ul>
								</div>
							</div>
						</>
					)}
				</div>

				{auction.round == 0 ? (
					<></>
				) : (
					<>
						<br />

						<div className="row">
							<div className="col">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>Previous Round Bids</h5>
									</div>
									<ul className="list-group list-group-flush">
										{bids ? (
											bids.previous_bids.map(
												({
													id,
													amount,
													carried,
													exit,
													combined,
													lease_id,
													no_bid,
													credits_used,
												}) => (
													<li
														className="list-group-item"
														style={{
															textAlign: "center",
														}}
														key={id}
													>
														<p>
															{" "}
															{carried ? "Frozen " : ""}
															{combined ? "Combined " : ""}
															{no_bid ? "(No-bid) " : ""}
															{exit ? "Exit Bid " : "Live Bid "}({currencyFormat(amount)})
															{credits_used ? "(using Credits) " : ""}
															on{" "}
															{auction.leases &&
															auction.leases.filter((lease) => {
																return lease.id == lease_id;
															}).length > 0
																? auction.leases.filter((lease) => {
																		return lease.id == lease_id;
																  })[0].name
																: ""}
														</p>
													</li>
												)
											)
										) : (
											<></>
										)}
									</ul>
									{prevCashBid > 0 ? (
										<div
											className="card-footer text-muted"
											style={{
												textAlign: "center",
											}}
										>
											<strong>Cash Bid: {currencyFormat(prevCashBid)}</strong>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>

							<div className="col">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>{user.display_name} Status</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item" style={{ textAlign: "center" }}>
											<p>{this.getUserStatus()}</p>
										</li>
									</ul>
								</div>
							</div>

							<div className="col">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>Current Round Bids</h5>
									</div>
									<ul className="list-group list-group-flush">
										{bids ? (
											bids.current_bids.map(
												({
													id,
													amount,
													carried,
													exit,
													combined,
													lease_id,
													no_bid,
													credits_used,
												}) => (
													<li
														className="list-group-item"
														style={{
															textAlign: "center",
														}}
														key={id}
													>
														<p>
															{" "}
															{carried ? "Frozen " : ""}
															{combined ? "Combined " : ""}
															{no_bid ? "(No-bid) " : ""}
															{exit ? "Exit Bid " : "Live Bid "}({currencyFormat(amount)})
															{credits_used ? "(using Credits) " : ""}
															on{" "}
															{auction.leases &&
															auction.leases.filter((lease) => {
																return lease.id == lease_id;
															}).length > 0
																? auction.leases.filter((lease) => {
																		return lease.id == lease_id;
																  })[0].name
																: ""}
														</p>
													</li>
												)
											)
										) : (
											<></>
										)}
									</ul>
									{curCashBid > 0 ? (
										<div
											className="card-footer text-muted"
											style={{
												textAlign: "center",
											}}
										>
											<strong>Cash Bid: {currencyFormat(curCashBid)}</strong>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

SMRAInformationPanel.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default SMRAInformationPanel;
