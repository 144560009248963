import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Area,
	AreaChart,
} from "recharts";
import { COLORS } from "../../common/constants";

class LeaseDashboard extends Component {
	constructor(props) {
		super(props);
	}

	renderLeasePriceGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;
		const cur_lease_id = this.props.lease.id;

		let pricesChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = prices.filter(
					(p) => p.lease_id === lease.id && p.lease_id === cur_lease_id && p.round === roundNum
				);
				data[lease.id] = d.length > 0 ? d[0].price : null;
			});
			pricesChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Clock Prices
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={300}>
						<LineChart
							data={pricesChartData}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
							{leases_data
								.filter((ld) => ld.id == this.props.lease.id)
								.map(({ id, name, stroke }) => (
									<Line
										key={id}
										name={name}
										type="monotone"
										dataKey={id.toString()}
										stroke={stroke}
										strokeWidth={2}
										dot={{ strokeWidth: 4 }}
									/>
								))}
						</LineChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	renderDemandGraph() {
		const { auction, history } = this.props.auction;
		const rounds = this.props.rounds;
		const cur_lease_id = this.props.lease.id;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			const data = {};
			auction.leases.forEach((lease) => {
				const d = history.filter(
					(his) =>
						his.lease_id == lease.id &&
						his.lease_id === cur_lease_id &&
						his.round == roundNum &&
						his.exit == 0
				);
				data[lease.id] = lease.id === cur_lease_id ? d.length : null;
			});
			demandChartData.push({ round: `Round ${roundNum}`, ...data });
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Demand (Live Bids)
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={200}>
						<LineChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis tickCount={3} />
							<Tooltip />
							{leases_data.map(({ id, name, stroke }) => (
								<Line
									key={id}
									name={name}
									type="monotone"
									dataKey={id.toString()}
									stroke={stroke}
									strokeWidth={2}
									dot={{ strokeWidth: 4 }}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderExitBidsGraph() {
		const { auction, history } = this.props.auction;
		const cur_lease_id = this.props.lease.id;
		const rounds = this.props.rounds;

		let demandChartData = [];
		rounds.forEach((roundNum) => {
			demandChartData.push({
				round: `Round ${roundNum}`,
				exitBids: history.filter(
					(his) => his.round == roundNum && his.lease_id === cur_lease_id && his.exit == 1 && his.carried == 0
				).length,
			});
		});

		let leaseStrokes = {};
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leaseStrokes[lease.id] = COLORS[idx];
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Exit Bids
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={200}>
						<AreaChart
							data={demandChartData}
							margin={{
								top: 5,
								right: 40,
								left: 0,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} allowDecimals={false} tickCount={4} />
							<Tooltip />
							<Area
								type="monotone"
								dataKey="exitBids"
								name="Exit Bids"
								stroke={leaseStrokes[cur_lease_id]}
								fill={leaseStrokes[cur_lease_id]}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderPercentageIncreaseGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;
		const curLeaseId = this.props.lease.id;

		let pricePercentageChangeData = [];
		rounds.forEach((roundNum) => {
			if (roundNum != 1) {
				let roundData = {};
				let total = 0;
				let num = 0;

				const d = prices.filter((data) => {
					return data.lease_id == curLeaseId && data.round == roundNum;
				});
				const e = prices.filter((data) => {
					return data.lease_id == curLeaseId && data.round == roundNum - 1;
				});
				roundData[curLeaseId] = null;

				if (d.length > 0 && e.length > 0) {
					roundData[curLeaseId] =
						(d[0].price - e[0].price) / e[0].price > 0 ? (d[0].price - e[0].price) / e[0].price : null;
					if ((d[0].price - e[0].price) / e[0].price > 0) {
						total += (d[0].price - e[0].price) / e[0].price;
						num += 1;
					}
				}
				pricePercentageChangeData.push({
					round: `Round ${roundNum}`,
					...roundData,
					avg: num > 0 ? total / num : 0,
				});
			}
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Percentage Increments
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={250}>
						<LineChart
							data={pricePercentageChangeData}
							margin={{
								top: 5,
								right: 40,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(value)
								}
							/>
							<Tooltip
								allowEscapeViewBox={{ y: true }}
								formatter={(value) =>
									new Intl.NumberFormat("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(value)
								}
							/>
							<Legend />
							<Line
								name={"Average"}
								dataKey="avg"
								stroke="#999999"
								strokeWidth={1}
								strokeOpacity={0.7}
								strokeDashArray="1 1"
							/>
							{leases_data
								.filter((ld) => ld.id == this.props.lease.id)
								.map(({ id, name, stroke }) => (
									<Line
										key={id}
										name={name}
										type="monotone"
										dataKey={id.toString()}
										stroke={stroke}
										strokeWidth={2}
									/>
								))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderNominalIncreaseGraph() {
		const { auction, prices } = this.props.auction;
		const rounds = this.props.rounds;
		const curLeaseId = this.props.lease.id;

		let priceNominalChangeData = [];
		rounds.forEach((roundNum) => {
			if (roundNum != 1) {
				let roundData = {};
				let total = 0;
				let num = 0;

				const d = prices.filter((data) => {
					return data.lease_id == curLeaseId && data.round == roundNum;
				});
				const e = prices.filter((data) => {
					return data.lease_id == curLeaseId && data.round == roundNum - 1;
				});
				roundData[curLeaseId] = null;
				if (d.length > 0 && e.length > 0) {
					roundData[curLeaseId] = d[0].price - e[0].price > 0 ? d[0].price - e[0].price : null;
					if (d[0].price - e[0].price > 0) {
						total += d[0].price - e[0].price;
						num += 1;
					}
				}
				priceNominalChangeData.push({
					round: `Round ${roundNum}`,
					...roundData,
					avg: num > 0 ? total / num : 0,
				});
			}
		});

		let leases_data = [];
		let idx = 0;
		auction.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-header" style={{ textAlign: "center" }}>
					Nominal Increments
				</div>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={250}>
						<LineChart
							data={priceNominalChangeData}
							margin={{
								top: 5,
								right: 40,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis
								tickCount={4}
								tickFormatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Tooltip
								allowEscapeViewBox={{ y: true }}
								formatter={(value) =>
									new Intl.NumberFormat("en", {
										notation: "compact",
										compactDisplay: "short",
										style: "currency",
										currency: "USD",
									}).format(value)
								}
							/>
							<Legend />
							<Line
								name={"Average"}
								dataKey="avg"
								stroke="#999999"
								strokeWidth={1}
								strokeOpacity={0.7}
								strokeDashArray="1 1"
							/>
							{leases_data
								.filter((ld) => ld.id == this.props.lease.id)
								.map(({ id, name, stroke }) => (
									<Line
										key={id}
										name={name}
										type="monotone"
										dataKey={id.toString()}
										stroke={stroke}
										strokeWidth={2}
									/>
								))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	}

	renderLeaseInfoTable() {
		const { history, prices } = this.props.auction;
		const rounds = this.props.rounds;
		const cur_lease_id = this.props.lease.id;

		let tableData = [];
		rounds.forEach((roundNum) => {
			const p = prices.filter((p) => p.lease_id === cur_lease_id && p.round === roundNum);
			const price = p.length > 0 ? currencyFormat(p[0].price) : null;
			const d = history.filter(
				(his) => his.lease_id === cur_lease_id && his.round == roundNum && his.exit == 0
			).length;
			const demand = d > 0 ? d : null;
			const e = history.filter(
				(his) => his.lease_id === cur_lease_id && his.round == roundNum && his.exit == 1 && his.carried == 0
			).length;
			const exitBids = e > 0 ? e : null;
			const t = history.filter((his) => his.lease_id === cur_lease_id && his.round == roundNum).length;
			const total = t > 0 ? t : null;
			let percentageIncrements = null;
			let nominalIncrements = null;
			if (roundNum > 1) {
				const pp = prices.filter((p) => p.lease_id === cur_lease_id && p.round === roundNum - 1);
				if (pp.length > 0) {
					const percFormatter = new Intl.NumberFormat("en-US", {
						style: "percent",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
					percentageIncrements =
						(p[0].price - pp[0].price) / pp[0].price > 0
							? percFormatter.format((p[0].price - pp[0].price) / pp[0].price)
							: null;
					nominalIncrements = p[0].price - pp[0].price > 0 ? currencyFormat(p[0].price - pp[0].price) : null;
				}
			}
			tableData.push({
				roundNum,
				price,
				demand,
				exitBids,
				total,
				percentageIncrements,
				nominalIncrements,
			});
		});

		return (
			<div className="card">
				<div className="card-header" style={{ textAlign: "center" }}>
					{this.props.lease.name} Information
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<table
							className="table table-sm table-bordered"
							id="table-lease-history"
							style={{
								textAlign: "center",
								verticalAlign: "center",
							}}
						>
							<thead>
								<tr>
									<th>Round</th>
									<th>Price</th>
									<th>Demand</th>
									<th>Exit Bids</th>
									<th>Total Bids</th>
									<th>% Increments</th>
									<th>Nominal Increments</th>
								</tr>
							</thead>
							<tbody>
								{tableData.map(
									({
										roundNum,
										price,
										demand,
										exitBids,
										total,
										percentageIncrements,
										nominalIncrements,
									}) => (
										<tr key={roundNum}>
											<th>{roundNum}</th>
											<td>{price}</td>
											<td>{demand}</td>
											<td>{exitBids}</td>
											<td>{total}</td>
											<td>{percentageIncrements}</td>
											<td>{nominalIncrements}</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Lease {this.props.lease.name}</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row">{this.renderLeasePriceGraph()}</div>
				<br />
				<div className="row">
					<div className="col-6">{this.renderDemandGraph()}</div>
					<div className="col-6">{this.renderExitBidsGraph()}</div>
				</div>
				<br />
				<div className="row">
					<div className="col-6">{this.renderPercentageIncreaseGraph()}</div>
					<div className="col-6">{this.renderNominalIncreaseGraph()}</div>
				</div>
				<br />
				<hr />
				<br />
				<div className="row">{this.renderLeaseInfoTable()}</div>
				<br />
			</>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

LeaseDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
};

export default LeaseDashboard;
