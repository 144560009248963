import React, { Component } from "react";
import PropTypes from "prop-types";
import { Timer } from "../../GeneralAuction/Components/Timer";

class MPSBInformationPanel extends Component {
	constructor(props) {
		super(props);
	}

	getStatusStyle(status) {
		if (status == "In Progress") {
			return { color: "green" };
		} else if (status == "Ended") {
			return { color: "red" };
		} else if (status == "Awaiting Start") {
			return { color: "blue" };
		} else {
			return {};
		}
	}

	render() {
		const { auction, userBids } = this.props.auction;
		const { points, user, bids } = this.props.user;

		return (
			<>
				{auction.round === 0 ? (
					<></>
				) : (
					<div className="container">
						<div className="row">
							<div className="col-7">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>Auction Information</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												Auction Status:{" "}
												{auction.status && (
													<b style={this.getStatusStyle(auction.status)}>{auction.status}</b>
												)}
											</p>
										</li>
										<li className="list-group-item">
											<div className="row">
												{auction.fcr ? (
													<div className="col-4">
														<p>
															<b>FCR: </b> {auction.status && auction.fcr}
														</p>
													</div>
												) : (
													<div className="col-4">
														<p>
															<b>FCR: </b> Not Announced
														</p>
													</div>
												)}
												{auction.min_tea && auction.max_tea ? (
													<div className="col-4" style={{ textAlign: "center" }}>
														<p>
															<b>TEA: </b>
															{commaFormat(auction.min_tea)} -{" "}
															{commaFormat(auction.max_tea)} MW
															<br />
															<small className="text-muted">
																(
																{commaFormat(
																	Math.round((auction.min_tea * 8760 * 0.45) / 1000)
																)}{" "}
																-{" "}
																{commaFormat(
																	Math.round((auction.max_tea * 8760 * 0.45) / 1000)
																)}{" "}
																GWh/yr)
															</small>
														</p>
													</div>
												) : (
													<div className="col-4" style={{ textAlign: "center" }}>
														<p>
															<b>TEA: </b>
															Not Announced
															<br />
														</p>
													</div>
												)}
												{auction.min_tea && auction.max_tea && auction.fcr ? (
													<div className="col-4" style={{ textAlign: "center" }}>
														<p>
															<b>ASQ: </b>
															{commaFormat(
																Math.round(
																	Math.max(
																		Math.min(auction.min_tea / auction.fcr, 18000),
																		1000
																	)
																)
															)}{" "}
															-{" "}
															{commaFormat(
																Math.round(
																	Math.max(
																		Math.min(auction.max_tea / auction.fcr, 18000),
																		1000
																	)
																)
															)}{" "}
															MW
															<br />
															<small className="text-muted">
																(
																{commaFormat(
																	Math.round(
																		(Math.max(
																			Math.min(
																				Math.round(
																					auction.min_tea / auction.fcr
																				),
																				18000
																			),
																			1000
																		) *
																			8760 *
																			0.45) /
																			1000
																	)
																)}{" "}
																-{" "}
																{commaFormat(
																	Math.round(
																		(Math.max(
																			Math.min(
																				Math.round(
																					auction.max_tea / auction.fcr
																				),
																				18000
																			),
																			1000
																		) *
																			8760 *
																			0.45) /
																			1000
																	)
																)}{" "}
																GWh/yr)
															</small>
														</p>
													</div>
												) : (
													<div className="col-4" style={{ textAlign: "center" }}>
														<p>
															<b>ASQ: </b>
															Not Announced
															<br />
														</p>
													</div>
												)}
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="col-5">
								<div className="card">
									<div className="card-header" style={{ textAlign: "center" }}>
										<h5>User Information</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												User Status:{" "}
												{auction.status ? (
													<b>
														{userBids.bids
															? userBids.bids.length > 0
																? "Bids Submitted"
																: "Awaiting Bid Submission"
															: 0}
													</b>
												) : (
													""
												)}
											</p>
										</li>
										<li className="list-group-item">
											<p>
												<Timer
													endTime={this.props.auction.auction.end_time}
													status={this.props.auction.auction.status}
													highlight={30}
												/>
												{(auction.status !== "Awaiting Start" && !auction.fcr) ||
												(auction.min_tea && auction.max_tea) ? (
													<small style={{ color: "white" }}>|</small>
												) : (
													<></>
												)}
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

function currencyFormat(num) {
	return "€" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "€1,");
}

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat().format(num);
}

MPSBInformationPanel.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	secondsRemaining: PropTypes.number.isRequired,
};

export default MPSBInformationPanel;
