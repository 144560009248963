import React, { Component } from "react";
import socket from "../../../helpers/socket";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ConnectionStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			connected: false,
		};
	}

	componentDidMount() {
		// setInterval(() => {
		// 	this.setState({
		// 		...this.state,
		// 		connected: socket.connected,
		// 	});
		// }, 1000);
	}

	render() {
		return (
			<a className="nav-link" disabled>
				Status:{" "}
				{this.props.socket.connected == true ? (
					<>
						Connected <i className="far fa-check-circle"></i>
					</>
				) : (
					<strong>
						Not Connected <i className="fas fa-exclamation-triangle"></i>
					</strong>
				)}
			</a>
		);
	}
}

ConnectionStatus.propTypes = {
	socket: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	socket: state.socket,
});

// export default ConnectionStatus;

export default connect(mapStateToProps, {})(ConnectionStatus);
