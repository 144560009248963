import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import HistoryTables from "../../SMRAAuction/Components/HistoryTables";
import InformationPanel from "../../SMRAAuction/Components/SMRAInformationPanel";
import { SMRAAdminPanel } from "../../SMRAAuction/Components/SMRAAdminPanel";
import ResultsTable from "../../SMRAAuction/Components/ResultsTable";
import { confirm } from "react-confirm-box";
import socket from "../../../../helpers/socket";
import { addNotification } from "../../../common/NotificationManager";
import {
	getAuction,
	getRound,
	startAuction,
	makeBids,
	getResults,
	getHistory,
	getPriceDemandHistory,
	setAuctionsLoading,
	setAuctionsNotLoading,
	getPrices,
	getWinningBids,
	getBidderProgress,
	getSimulationData,
	getBidCredits,
} from "../../../../actions/auctionActions";
import { getPoints, getBids, getAllUsers, setUserLoading, setUserNotLoading } from "../../../../actions/userActions";
import { joinAuctionRoom, emitRequestAssistance } from "../../../../helpers/emit/auction";

class SMRAAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exit_price: 0,
			price: 0,
			exitBid: false,
			selectedLeaseIds: [],
			submittedExitBid: false,
			showExitInput: true,
			requestAssistanceTimeout: 0,
			showTableData: false,
			currentRoundBidSubmitted: false,
			useBidCredits: false,
			mainFilterValue: "nominalIncrements",
			incrementValues: {},
			nominalIncrementValues: {},
			priceTargetValues: {},
		};
	}

	// Run when the component is loaded
	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
		this.interval = setInterval(() => this.tick(), 1000);
	}

	// Run when the component is about to be unmounted
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	// Fetch all the data required by the auction page
	async refresh() {
		// Fetch Auction Data
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.fetchData();

		this.props.setAuctionsNotLoading();
		this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);

		if (this.props.auction.auction.status !== "Awaiting Start") {
			await this.props.getRound(this.props.params.auctionId);
			await this.props.getResults(this.props.params.auctionId);
			await this.props.getBidCredits(this.props.params.auctionId);
			await this.props.getPriceDemandHistory(this.props.params.auctionId);
			if (this.props.user.user.type === "admin") {
				await this.props.getPrices(this.props.params.auctionId);
				await this.props.getHistory(this.props.params.auctionId);
				await this.props.getBidderProgress(this.props.params.auctionId);
				await this.props.getSimulationData(this.props.params.auctionId);
			}
		}
		if (this.props.auction.auction.status === "Ended") {
			await this.props.getWinningBids(this.props.params.auctionId);
		}

		if (this.props.auction.auction.status !== "Awaiting Start") {
			await this.props.getPoints(this.props.params.auctionId);
			await this.props.getBids(this.props.params.auctionId);
		}

		if (this.props.user.user.type === "admin") {
			await this.props.getAllUsers();
		}
		this.setState({ ...this.state, showTableData: false });

		let temp = {};
		let nominalTemp = {};
		let priceTargetTemp = {};
		if (this.props.auction.round.leases && this.props.auction.round.leases.length > 0) {
			this.props.auction.round.leases.forEach((lease) => {
				temp[lease.id] = 100;
				nominalTemp[lease.id] = 10000000;
				priceTargetTemp[lease.id] = Math.round(lease.current_bid / 10000000) * 10000000 + 10000000;
			});
		}

		const { bids } = this.props.user;

		this.setState({
			...this.state,
			selectedLeaseIds: [],
			submittedExitBid: bids
				? bids.current_bids.filter((cur_bid) => cur_bid.exit === 1 && cur_bid.carried === 0).length > 0
					? true
					: false
				: false,
			incrementValues: temp,
			nominalIncrementValues: nominalTemp,
			priceTargetValues: priceTargetTemp,
			showTableData: true,
			currentRoundBidSubmitted: bids ? (bids.current_bids.length > 0 ? true : false) : false,
			useBidCredits: this.props.auction.auction.use_bid_credits === 1 ? true : false,
		});
	}

	// Decrease the clock ticks
	tick() {
		const newRequestAssistanceTimeout =
			this.state.requestAssistanceTimeout > 0 ? this.state.requestAssistanceTimeout - 1 : 0;
		this.setState({
			...this.state,
			requestAssistanceTimeout: newRequestAssistanceTimeout,
		});
	}

	// Action method used by input fields
	onExitBidChange(event) {
		let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		if (isNaN(value)) {
			value = 0;
		}
		this.setState({ ...this.state, exit_price: value });
	}

	selectedLeaseCheckBoxChange(event) {
		const leaseId = parseInt(event.target.value);
		const { round } = this.props.auction;
		const { points } = this.props.user.points;
		let updatedLeaseIds = this.state.selectedLeaseIds;

		if (updatedLeaseIds.includes(leaseId)) {
			// If the array already contains the lease id, then remove it from the array
			updatedLeaseIds = updatedLeaseIds.filter((lid) => {
				return lid !== leaseId;
			});
		} else if (updatedLeaseIds.length < points) {
			// If the array does not contain the lease id and there is still space to select more
			// then add it to the array
			updatedLeaseIds.push(leaseId);
		} else if (updatedLeaseIds.length === points) {
			// If the array has reached its limit, then remove the first element from the array and add the other
			updatedLeaseIds.shift();
			updatedLeaseIds.push(leaseId);
		}

		let updatedPrice = 0;
		updatedLeaseIds.forEach((id) => {
			updatedPrice += round.leases.filter((lease) => {
				return lease.id === id;
			})[0].current_bid;
		});

		this.setState({
			...this.state,
			selectedLeaseIds: updatedLeaseIds,
			price: updatedPrice,
		});
	}

	onIncrementValueChange(event) {
		if (this.state.mainFilterValue === "nominalIncrements") {
			let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) value = 0;
			this.setState({
				...this.state,
				nominalIncrementValues: {
					...this.state.nominalIncrementValues,
					[event.target.name]: value,
				},
			});
		}
		if (this.state.mainFilterValue === "priceTarget") {
			let value = parseInt(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
			if (isNaN(value)) value = 0;
			this.setState({
				...this.state,
				priceTargetValues: {
					...this.state.priceTargetValues,
					[event.target.name]: value,
				},
			});
		}
		if (this.state.mainFilterValue === "percentageIncrements") {
			this.setState({
				...this.state,
				incrementValues: {
					...this.state.incrementValues,
					[event.target.name]: parseInt(event.target.value),
				},
			});
		}
	}

	async makeBid() {
		const { user, bids } = this.props.user;
		const { auction, round, bid_credits } = this.props.auction;

		var lease_ids = [];
		if (!this.state.exitBid) {
			lease_ids = this.state.selectedLeaseIds;
		}

		if (this.state.exitBid && !this.state.submittedExitBid) {
			const current_lease_ids = [];
			bids.current_bids.forEach((cur_bid) => {
				current_lease_ids.push(cur_bid.lease_id);
			});

			bids.previous_bids
				.filter((prev_bid) => !current_lease_ids.includes(prev_bid.lease_id))
				.forEach((previous_bid) => {
					lease_ids.push(previous_bid.lease_id);
				});
		}

		let lease_names = [];
		auction.leases.forEach((lease) => {
			if (lease_ids.includes(lease.id)) lease_names.push(lease.name);
		});

		const user_bid_credits = bid_credits.filter((bc) => bc.user_id === user.user_id);

		let cashBid = 0;
		if (this.state.exitBid === false) {
			if (round.leases) {
				round.leases
					.filter((lease) => lease_ids.includes(lease.id))
					.forEach((lease) => {
						let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
						cashBid += lease.current_bid - (lease.current_bid * perc) / 100;
					});
			}
		}

		if (this.state.exitBid === true) {
			if (round.leases) {
				round.leases
					.filter((lease) => lease_ids.includes(lease.id))
					.forEach((lease) => {
						let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
						cashBid += this.state.price - (this.state.price * perc) / 100;
					});
			}
		}

		const req_json = {
			amount: this.state.price,
			lease_ids: lease_ids,
			exit: this.state.exitBid && !this.state.submittedExitBid,
			credits: this.state.useBidCredits ? 1 : 0,
			financial_exposure: cashBid,
		};

		const emit_json = {
			display_name: this.props.user.user.display_name,
			amount: currencyFormat(this.state.price),
			lease_ids: lease_names,
		};

		const result = await confirm("Bid Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								{this.getSubmitButtonText()}

								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.makeBids(this.props.params.auctionId, req_json, emit_json);
			await this.fetchData();
			this.setState({
				...this.state,
				selectedLeaseIds: [],
				submittedExitBid: true,
				price: this.state.price,
				exitBid: true,
			});
			this.removeExitBidValue();
			return;
		}
	}

	setExitBidValue() {
		const { bids } = this.props.user;
		const { round } = this.props.auction;

		const exitBidLeaseId = bids.previous_bids[0].lease_id;

		const prev = bids.previous_bids[0].amount;
		const cur = round.leases
			.filter((lease) => {
				return bids.previous_bids
					.filter((prev_bid) => {
						return !bids.current_bids.map((obj) => obj.lease_id).includes(prev_bid.lease_id);
					})
					.map((obj) => obj.lease_id)
					.includes(lease.id);
			})
			.map((obj) => obj.current_bid)
			.reduce((x, y) => x + y);

		if (prev < this.state.exit_price && this.state.exit_price < cur) {
			this.setState({
				...this.state,
				selectedLeaseIds: [exitBidLeaseId],
				exitBid: true,
				price: this.state.exit_price,
			});
		}

		const items = document.querySelectorAll('input[name="site_name"]');
		items.forEach(function (userItem) {
			userItem.disabled = "disabled";
		});
	}

	removeExitBidValue() {
		// Remove exit bid
		this.setState({
			...this.state,
			selectedLeaseIds: [],
			exitBid: false,
		});
		const items = document.querySelectorAll('input[name="site_name"]');
		items.forEach(function (userItem) {
			userItem.disabled = "";
		});
	}

	async requestAssistance() {
		this.setState({ ...this.state, requestAssistanceTimeout: 10 });
		await emitRequestAssistance(this.props.params.auctionId, this.props.user.user);
	}

	getLegendStyle(status) {
		if (status === "live") {
			return { color: "#69D5F7" };
		} else if (status === "exit") {
			return { color: "#F1F68E" };
		} else if (status === "won") {
			return { color: "#A5F2C6" };
		} else if (status === "lost") {
			return { color: "#F98686" };
		} else {
			return {};
		}
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && this.props.user.user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getExitBidPanel() {
		const { auction, round } = this.props.auction;
		const { points } = this.props.user;

		const showPanel = auction.round > 1 && points.points > 0 && round.round_status === "In Progress";
		const showExitInputPane = this.state.showExitInput && !this.state.submittedExitBid;

		if (!showPanel) {
			return <></>;
		}

		return (
			<div className="card">
				<div className="card-body">
					<h6>Exit Bid Controls</h6>
					{showExitInputPane ? (
						<div className="input-group">
							<span className="input-group-text">{"100 < "}</span>
							<input type="number" className="form-control" />
							<span className="input-group-text">{" < 200"}</span>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	}

	getSubmitButtonText() {
		const { round, auction } = this.props.auction;
		const { points, bids } = this.props.user;

		let submitEnabled =
			points.points > 0 &&
			(this.state.selectedLeaseIds.length > 0 || this.state.exitBid) &&
			round.round_status === "In Progress" &&
			auction.status === "In Progress";

		if (submitEnabled) {
			if (!this.state.exitBid) {
				// Live Bids
				const lease_ids = this.state.selectedLeaseIds;
				const lease_names = [];
				round.leases
					.filter((lease) => lease_ids.includes(lease.id))
					.forEach((lease) => {
						lease_names.push(lease.name);
					});
				if (lease_names) {
					return (
						<p>
							<em>
								Submit {lease_names.length > 1 ? "Combined Live Bid" : "Live Bid"}{" "}
								{this.getSubmitButtonBidCreditsText()} on <strong>{lease_names.join(", ")}</strong> for{" "}
								<strong>{currencyFormat(parseInt(this.state.price))}</strong>
							</em>
						</p>
					);
				}
			}
			if (this.state.exitBid && !this.state.submittedExitBid) {
				const lease_ids = [];
				const current_lease_ids = [];
				bids.current_bids.forEach((cur_bid) => {
					current_lease_ids.push(cur_bid.lease_id);
				});

				bids.previous_bids
					.filter((prev_bid) => !current_lease_ids.includes(prev_bid.lease_id))
					.forEach((previous_bid) => {
						lease_ids.push(previous_bid.lease_id);
					});

				const lease_names = [];
				round.leases
					.filter((lease) => lease_ids.includes(lease.id))
					.forEach((lease) => {
						lease_names.push(lease.name);
					});

				if (lease_names) {
					return (
						<>
							<p>
								<em>
									Submit {lease_names.length > 1 && "Combined"}{" "}
									<strong style={{ color: "#ff0000" }}>Exit Bid</strong> on{" "}
									<strong>{lease_names.join(", ")}</strong> for{" "}
									<strong>{currencyFormat(parseInt(this.state.price))}</strong>
								</em>
							</p>
							<p>
								<strong style={{ color: "#ff0000" }}>THIS WILL BE YOUR FINAL BID</strong>
							</p>
						</>
					);
				}
			}
		}

		return (
			<p>
				<em>Make a selection in order to submit a lease</em>
			</p>
		);
	}

	getImputedPriceText() {
		const { bids } = this.props.user;
		if (bids.current_bids.length > 0) {
			return currencyFormat(parseInt(bids.current_bids[0].amount));
		}
		return currencyFormat(this.state.price);
	}

	getSubmitButtonBidCreditsText() {
		const { bid_credits } = this.props.auction;
		const { user } = this.props.user;

		const user_bid_credits = bid_credits.filter((bc) => bc.user_id === user.user_id);

		const percentages = [];
		this.state.selectedLeaseIds.forEach((selectedLeaseId) => {
			let perc = user_bid_credits.filter((ubc) => ubc.lease_id === selectedLeaseId)[0].percentage;
			percentages.push(`${parseFloat(perc).toFixed(2)}%`);
		});

		return this.state.useBidCredits ? `(using (${percentages.join(",")}) Credits)` : "";
	}

	getCreditPercentage() {
		const { bid_credits } = this.props.auction;
		const { user, bids } = this.props.user;

		const user_bid_credits = bid_credits.filter((bc) => bc.user_id === user.user_id);

		const percentages = [];

		if (bids.current_bids.length > 0) {
			bids.current_bids.forEach((bid) => {
				let perc = user_bid_credits.filter((ubc) => ubc.lease_id === bid.lease_id)[0].percentage;
				percentages.push(`${parseFloat(perc).toFixed(2)}%`);
			});
			return percentages.join(",");
		} else {
			this.state.selectedLeaseIds.forEach((selectedLeaseId) => {
				let perc = user_bid_credits.filter((ubc) => ubc.lease_id === selectedLeaseId)[0].percentage;
				percentages.push(`${parseFloat(perc).toFixed(2)}%`);
			});

			return percentages.join(",");
		}
	}

	getCreditText() {
		const { round, bid_credits } = this.props.auction;
		const { user, bids } = this.props.user;

		//check if bid has been submitted
		if (bids.current_bids.length > 0) {
			return currencyFormat(parseInt(bids.current_bids[0].amount - bids.current_bids[0].financial_exposure));
		} else {
			const user_bid_credits = bid_credits.filter((bc) => bc.user_id === user.user_id);
			const lease_ids = this.state.selectedLeaseIds;

			let cashBid = 0;
			if (this.state.exitBid === false) {
				if (round.leases) {
					round.leases
						.filter((lease) => lease_ids.includes(lease.id))
						.forEach((lease) => {
							let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
							cashBid += (lease.current_bid * perc) / 100;
						});
				}
			}
			//exit bid is selected
			if (this.state.exitBid === true) {
				if (round.leases) {
					round.leases
						.filter((lease) => lease_ids.includes(lease.id))
						.forEach((lease) => {
							let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
							cashBid += (this.state.price * perc) / 100;
						});
				}
			}
			return currencyFormat(parseInt(cashBid));
		}
	}

	getCashBidText() {
		const { round, bid_credits } = this.props.auction;
		const { user, bids } = this.props.user;

		//check if bid has been submitted
		if (bids.current_bids.length > 0) {
			return currencyFormat(parseInt(bids.current_bids[0].financial_exposure));
		} else {
			const user_bid_credits = bid_credits.filter((bc) => bc.user_id === user.user_id);
			const lease_ids = this.state.selectedLeaseIds;

			let cashBid = 0;
			if (this.state.exitBid === false) {
				if (round.leases) {
					round.leases
						.filter((lease) => lease_ids.includes(lease.id))
						.forEach((lease) => {
							let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
							cashBid += lease.current_bid - (lease.current_bid * perc) / 100;
						});
				}
			}

			if (this.state.exitBid === true) {
				if (round.leases) {
					round.leases
						.filter((lease) => lease_ids.includes(lease.id))
						.forEach((lease) => {
							let perc = user_bid_credits.filter((ubc) => ubc.lease_id === lease.id)[0].percentage;
							cashBid += this.state.price - (this.state.price * perc) / 100;
						});
				}
			}
			return currencyFormat(parseInt(cashBid));
		}
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			mainFilterValue: event.target.value,
		});
	}

	render() {
		const { auction, round, auction_loading, bid_credits } = this.props.auction;
		const { user, signedIn, points, bids, user_loading } = this.props.user;

		let submitEnabled =
			points.points > 0 &&
			(this.state.selectedLeaseIds.length > 0 || this.state.exitBid) &&
			round.round_status === "In Progress" &&
			auction.status === "In Progress";
		const showIncrementFields =
			user.type === "admin" && auction.status === "In Progress" && round.round_status === "Ended";

		if (!signedIn) {
			return <Redirect to="/" />;
		}
		if (auction.hidden === 1 && user.type === "user") {
			return <Redirect to="/auctions" />;
		}
		if (auction_loading || user_loading) {
			return this.returnSpinner;
		}

		// Sockets
		socket.off("auction_refresh").on("auction_refresh", (data) => {
			this.fetchData();
			if (data.message) {
				addNotification({
					title: data.title,
					message: data.message,
					type: "info",
					duration: 7000,
				});
			}
		});
		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});
		socket.off("auction_assistance").on("auction_assistance", (msg) => {
			addNotification({
				title: "Assistance Requested",
				message: msg,
				type: "warning",
			});
		});
		socket.off("auction_reminder").on("auction_reminder", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 10000,
			});
		});
		socket.off("auction_announcement").on("auction_announcement", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "default",
				duration: 10000,
			});
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		return (
			<div className="container">
				<h3>{auction.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
					<div className="col-6">
						{user.type === "user" ? (
							<button
								type="button"
								className="btn btn-primary btn-sm"
								onClick={() => this.requestAssistance()}
								disabled={this.state.requestAssistanceTimeout > 0}
								style={{ display: "block", marginRight: "0", marginLeft: "auto" }}
							>
								<i className="fas fa-question-circle"></i> Request Assistance
							</button>
						) : (
							<></>
						)}
					</div>
				</div>
				<br />

				{user.type === "admin" && (
					<SMRAAdminPanel
						mainFilterValue={this.state.mainFilterValue}
						incrementValues={this.state.incrementValues}
						nominalIncrementValues={this.state.nominalIncrementValues}
						priceTargetValues={this.state.priceTargetValues}
						//refreshAction={refresh}
						fetchDataAction={this.fetchData.bind(this)}
					/>
				)}

				<InformationPanel auction={this.props.auction} user={this.props.user} config={this.props.config} />

				<hr />

				{auction.round === 0 ? (
					<>
						<table className="table">
							<thead>
								<tr>
									<th>Lease Area</th>
									{user.type === "admin" ? <th>Starting Bid</th> : <></>}
								</tr>
							</thead>
							<tbody>
								{auction.leases.map(({ id, name, starting_bid }) => (
									<tr key={id}>
										<td>{name}</td>
										{user.type === "admin" ? <td>{currencyFormat(starting_bid)}</td> : <></>}
									</tr>
								))}
							</tbody>
						</table>
					</>
				) : (
					<>
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead style={{ textAlign: "center" }}>
									<tr>
										<th>Lease Area</th>
										<th>Previous Round Price</th>
										<th>
											Previous Demand{" "}
											{round.leases && user.type === "admin"
												? `[Σ: ${round.leases.reduce(
														(total, l) => l.previous_demand + total,
														0
												  )}]`
												: ""}
										</th>
										<th>Select Live Bid</th>
										{user.type === "admin" ? (
											<th>
												Current Demand{" "}
												{round.leases
													? `[Σ: ${round.leases.reduce(
															(total, l) => l.current_demand + total,
															0
													  )}]`
													: ""}
											</th>
										) : (
											<></>
										)}
										{showIncrementFields ? (
											<th style={{ width: "200px" }}>
												<select
													value={this.state.mainFilterValue}
													onChange={(e) => this.onMainDropDownChange(e)}
													style={{
														width: "200px",
														marginLeft: "5px",
														padding: "6px",
														borderRadius: "4px",
														fontSize: "14px",
														borderColor: "#0275d8",
														color: "#0275d8",
													}}
												>
													<option value="nominalIncrements">Nominal Increments</option>
													<option value="percentageIncrements">Percentage Increments</option>
													<option value="priceTarget">Price Target</option>
												</select>

												{/* </div> */}
											</th>
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{round.leases ? (
										round.leases.map(
											({
												id,
												name,
												current_bid,
												previous_bid,
												previous_demand,
												current_demand,
											}) => (
												<tr
													key={id}
													className={
														this.state.selectedLeaseIds.includes(id) && points.points !== 0
															? "table-secondary"
															: ""
													}
												>
													<td>{name}</td>
													{this.state.showTableData ? (
														<td
															className={
																bids.previous_bids.length > 0
																	? bids.previous_bids.filter((previous_bids) => {
																			return (
																				previous_bids.lease_id === id &&
																				previous_bids.exit === 0
																			);
																	  }).length > 0
																		? "table-primary"
																		: bids.previous_bids.filter((previous_bids) => {
																				return (
																					previous_bids.lease_id === id &&
																					previous_bids.exit === 1
																				);
																		  }).length > 0
																		? "table-warning"
																		: ""
																	: ""
															}
														>
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id={`previous_round_lease_${id}`}
																	value={id}
																	checked={
																		bids.previous_bids &&
																		bids.previous_bids.filter((pb) => {
																			return pb.lease_id === id;
																		}).length > 0
																	}
																	disabled
																/>

																<label
																	className="form-check-label"
																	htmlFor={`previous_round_lease_${id}`}
																	disabled={false}
																>
																	{previous_bid ? currencyFormat(previous_bid) : ""}{" "}
																	{bids.previous_bids.filter(
																		(previous_bid) =>
																			previous_bid.lease_id === id &&
																			previous_bid.no_bid === 1
																	).length > 0
																		? "(no bid)"
																		: ""}{" "}
																	{bids.previous_bids.filter(
																		(previous_bid) =>
																			previous_bid.lease_id === id &&
																			previous_bid.carried === 1
																	).length > 0
																		? "(frozen)"
																		: ""}
																</label>
															</div>{" "}
														</td>
													) : (
														<td></td>
													)}
													<td
														style={{
															textAlign: "center",
														}}
													>
														{previous_demand}
													</td>

													{/* Current Bid Lease Selection */}
													{this.state.showTableData ? (
														<td
															className={
																bids.current_bids.length > 0
																	? bids.current_bids.filter((current_bids) => {
																			return (
																				current_bids.lease_id === id &&
																				current_bids.exit === 0
																			);
																	  }).length > 0
																		? "table-primary"
																		: bids.current_bids.filter((current_bids) => {
																				return (
																					current_bids.lease_id === id &&
																					current_bids.exit === 1
																				);
																		  }).length > 0
																		? "table-warning"
																		: ""
																	: ""
															}
														>
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id={`current_round_lease_${id}`}
																	value={id}
																	onChange={this.selectedLeaseCheckBoxChange.bind(
																		this
																	)}
																	checked={this.state.selectedLeaseIds.includes(id)}
																	disabled={
																		points.points === 0 ||
																		this.state.currentRoundBidSubmitted ||
																		this.state.exitBid ||
																		round.round_status === "Ended"
																	}
																/>
																<label
																	className="form-check-label"
																	htmlFor={`current_round_lease_${id}`}
																>
																	{currencyFormat(current_bid)}{" "}
																	{bids.current_bids.filter(
																		(current_bid) =>
																			current_bid.lease_id === id &&
																			current_bid.no_bid === 1
																	).length > 0
																		? "(no bid)"
																		: ""}{" "}
																	{bids.current_bids.filter(
																		(current_bid) =>
																			current_bid.lease_id === id &&
																			current_bid.carried === 1
																	).length > 0
																		? "(frozen)"
																		: ""}
																</label>
															</div>{" "}
														</td>
													) : (
														<td></td>
													)}

													{user.type === "admin" ? (
														<td
															style={{
																textAlign: "center",
															}}
														>
															{current_demand}
														</td>
													) : (
														<></>
													)}
													{showIncrementFields ? (
														<td
															style={{
																width: "220px",
																paddingBottom: "0px",
															}}
														>
															{this.state.mainFilterValue === "nominalIncrements" ? (
																<div className="input-group input-group-sm mb-3">
																	<span className="input-group-text">Nominal</span>
																	<input
																		type="string"
																		className="form-control"
																		value={currencyFormat(
																			this.state.nominalIncrementValues[id]
																		)}
																		name={id}
																		onChange={this.onIncrementValueChange.bind(
																			this
																		)}
																	/>
																</div>
															) : (
																<></>
															)}
															{this.state.mainFilterValue === "percentageIncrements" ? (
																<div className="input-group input-group-sm mb-3">
																	<span className="input-group-text">Percentage</span>
																	<input
																		type="number"
																		className="form-control"
																		value={this.state.incrementValues[id]}
																		name={id}
																		onChange={this.onIncrementValueChange.bind(
																			this
																		)}
																	/>
																	<span className="input-group-text">%</span>
																</div>
															) : (
																<></>
															)}
															{this.state.mainFilterValue === "priceTarget" ? (
																<div className="input-group input-group-sm mb-3">
																	<span className="input-group-text">Target</span>
																	<input
																		type="string"
																		className="form-control"
																		value={currencyFormat(
																			this.state.priceTargetValues[id]
																		)}
																		name={id}
																		onChange={this.onIncrementValueChange.bind(
																			this
																		)}
																	/>
																</div>
															) : (
																<></>
															)}
														</td>
													) : (
														<></>
													)}
												</tr>
											)
										)
									) : (
										<></>
									)}

									{round.round !== 1 &&
										points.points !== 0 &&
										round.round_status === "In Progress" &&
										!this.state.currentRoundBidSubmitted && (
											<tr>
												<td>Exit Bid</td>

												{!this.state.exitBid && !this.state.submittedExitBid ? (
													<>
														<td colSpan="3">
															<div className="input-group">
																<span className="input-group-text">
																	{bids.previous_bids && bids.previous_bids[0]
																		? currencyFormat(bids.previous_bids[0].amount)
																		: ""}
																</span>
																<input
																	className="form-control"
																	type="string"
																	id="myBid"
																	value={currencyFormat(this.state.exit_price)}
																	onChange={this.onExitBidChange.bind(this)}
																/>
																<span className="input-group-text">{` < ${
																	bids.previous_bids &&
																	round.leases &&
																	bids.previous_bids[0] &&
																	round.leases
																		.filter((lease) => {
																			return bids.previous_bids
																				.filter((prev_bid) => {
																					return !bids.current_bids
																						.map((obj) => obj.lease_id)
																						.includes(prev_bid.lease_id);
																				})
																				.map((obj) => obj.lease_id)
																				.includes(lease.id);
																		})
																		.map((obj) => obj.current_bid).length > 0
																		? currencyFormat(
																				round.leases
																					.filter((lease) => {
																						return bids.previous_bids
																							.filter((prev_bid) => {
																								return !bids.current_bids
																									.map(
																										(obj) =>
																											obj.lease_id
																									)
																									.includes(
																										prev_bid.lease_id
																									);
																							})
																							.map((obj) => obj.lease_id)
																							.includes(lease.id);
																					})
																					.map((obj) => obj.current_bid)
																					.reduce((x, y) => x + y)
																		  )
																		: ""
																}`}</span>
																<button
																	type="button"
																	className="btn btn-outline-primary btn-sm"
																	onClick={() => this.setExitBidValue()}
																>
																	Set Exit Bid Value
																</button>
															</div>
														</td>
														{user.type === "admin" && <td></td>}
													</>
												) : this.state.exitBid ? (
													<>
														<td colSpan="3">
															<div className="input-group">
																<span className="input-group-text">{`${
																	bids.previous_bids && bids.previous_bids[0]
																		? currencyFormat(bids.previous_bids[0].amount)
																		: ""
																} < `}</span>
																<input
																	className="form-control"
																	disabled
																	type="text"
																	id="myBid"
																	value={currencyFormat(Number(this.state.price))}
																/>
																<span className="input-group-text">{` < ${
																	bids.previous_bids &&
																	round.leases &&
																	bids.previous_bids[0] &&
																	round.leases
																		.filter((lease) => {
																			return bids.previous_bids
																				.filter((prev_bid) => {
																					return !bids.current_bids
																						.map((obj) => obj.lease_id)
																						.includes(prev_bid.lease_id);
																				})
																				.map((obj) => obj.lease_id)
																				.includes(lease.id);
																		})
																		.map((obj) => obj.current_bid).length > 0
																		? currencyFormat(
																				round.leases
																					.filter((lease) => {
																						return bids.previous_bids
																							.filter((prev_bid) => {
																								return !bids.current_bids
																									.map(
																										(obj) =>
																											obj.lease_id
																									)
																									.includes(
																										prev_bid.lease_id
																									);
																							})
																							.map((obj) => obj.lease_id)
																							.includes(lease.id);
																					})
																					.map((obj) => obj.current_bid)
																					.reduce((x, y) => x + y)
																		  )
																		: ""
																}`}</span>
																<button
																	type="button"
																	className="btn btn-outline-primary btn-sm"
																	onClick={() => this.removeExitBidValue()}
																>
																	Remove Exit Bid
																</button>
															</div>
														</td>
														{user.type === "admin" && <td></td>}
													</>
												) : (
													<></>
												)}
											</tr>
										)}

									{bid_credits.length > 0 && auction.use_bid_credits === 1 ? (
										<>
											<tr>
												<td>Imputed Price: </td>
												<td colSpan="2"></td>
												<td>{this.getImputedPriceText()}</td>
												{user.type === "admin" && <td></td>}
											</tr>

											<tr>
												<td>Credit ({this.getCreditPercentage()}): </td>
												<td colSpan="2"></td>
												<td>{this.getCreditText()}</td>
												{user.type === "admin" && <td></td>}
											</tr>

											<tr>
												<td>Cash Bid: </td>
												<td colSpan="2"></td>
												<td>{this.getCashBidText()}</td>
												{user.type === "admin" && <td></td>}
											</tr>
										</>
									) : (
										<></>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}

				<div
					className="card"
					style={{
						textAlign: "right",
						alignItems: "right",
						paddingBottom: "0px",
					}}
				>
					<div className="card-body" style={{ paddingBottom: "0px" }}>
						{this.getSubmitButtonText()}

						<button
							type="button"
							className="btn btn-primary"
							disabled={!submitEnabled}
							onClick={() => this.makeBid()}
							name="submitBid"
						>
							Submit Bid
						</button>

						<ul className="list-group list-group-flush" style={{ paddingBottom: "0px" }}>
							<li className="list-group-item" style={{ paddingBottom: "0px" }}>
								<p>
									<em>Live Bid: </em>
									<b style={this.getLegendStyle("live")}>&#9632; </b>
									<em>Exit Bid: </em>
									<b style={this.getLegendStyle("exit")}>&#9632; </b>
									<em>Won Bid: </em>
									<b style={this.getLegendStyle("won")}>&#9632; </b>
									<em>Lost Bid: </em>
									<b style={this.getLegendStyle("lost")}>&#9632; </b>
								</p>
							</li>
						</ul>
					</div>
				</div>

				<br />
				<hr />

				<ResultsTable auction={this.props.auction} user={this.props.user} />
				<br />
				<HistoryTables auction={this.props.auction} user={this.props.user} />

				<br />
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <SMRAAuction {...props} params={params} />;
}

function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

SMRAAuction.propTypes = {
	getAuction: PropTypes.func.isRequired,
	getRound: PropTypes.func.isRequired,
	getPoints: PropTypes.func.isRequired,
	startAuction: PropTypes.func.isRequired,
	getWinningBids: PropTypes.func.isRequired,
	makeBids: PropTypes.func.isRequired,
	getBids: PropTypes.func.isRequired,
	getResults: PropTypes.func.isRequired,
	getHistory: PropTypes.func.isRequired,
	getPriceDemandHistory: PropTypes.func.isRequired,
	getPrices: PropTypes.func.isRequired,
	getBidCredits: PropTypes.func.isRequired,
	getBidderProgress: PropTypes.func.isRequired,
	getSimulationData: PropTypes.func.isRequired,
	getAllUsers: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, {
	getSimulationData,
	setUserLoading,
	setAuctionsLoading,
	getAuction,
	getRound,
	getPoints,
	startAuction,
	getWinningBids,
	makeBids,
	getBids,
	getResults,
	getHistory,
	getPriceDemandHistory,
	getAllUsers,
	getPrices,
	getBidderProgress,
	setAuctionsNotLoading,
	setUserNotLoading,
	getBidCredits,
})(WithParamsAndNavigate);
