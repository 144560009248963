import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import {
	getSimulation,
	updateSimulation,
	runSimulation,
	getSimulationRun,
	getSimulationBids,
	getMarginalBids,
	runStatisticalAnalysis,
	setSimulationLoading,
	setSimulationNotLoading,
} from "../../actions/simulationActions";
import { setUserLoading, setUserNotLoading } from "../../actions/userActions";
import { MPSBResultsChart, MPSBResultsTable } from "../auctions/MPSBAuction/Components/MPSBResults";
import { BidPricesQuantitiesGraphs } from "../auctions/MPSBAuction/Components/MPSBGraphs";
import Plot from "react-plotly.js";
import { confirm } from "react-confirm-box";
import { COLORS } from "../common/constants";
import { joinASTRoom, emitASTSimulate, emitASTConfigUpdate } from "../../helpers/emit/auctionSimulationTool";
import { addNotification } from "../common/NotificationManager";
import socket from "../../helpers/socket";

class MPSBSimulation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			runCount: "",
			viewSimulationID: 1,
			mainFilterValue: "overview",
			secondaryFilterValue: "base",
			summaryScenario: 0,
			asq_config: 0,
			asq_mw: 0,
			asq_gwhy: 11826,
			user_bid_config: [],
			competitor_bid_config: {},
			bid_config_changed: false,
			graphUnit: "MW",
		};
	}

	componentDidMount() {
		this.joinSocketRoom();
		this.refresh();
	}

	async joinSocketRoom() {
		const data = {
			astId: this.props.params.simulationId,
			user: this.props.user.user,
		};
		await joinASTRoom(data);
	}

	async refresh() {
		this.setState({ ...this.state, viewSimulationID: 1 });

		await this.props.setSimulationLoading();
		await this.props.setUserLoading();

		await this.fetchData();

		await this.props.setSimulationNotLoading();
		await this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getSimulation(this.props.params.simulationId);
		await this.props.getSimulationBids(this.props.params.simulationId);
		await this.props.getStatisticalAnalysis(this.props.params.simulationId);
		await this.props.getMarginalBids(this.props.params.simulationId);
		// await this.props.getSimulationRun(this.props.params.simulationId, this.state.viewSimulationID);

		let competitor_bid_config = {};

		if (this.props.simulation && this.props.simulation.simulation)
			this.props.simulation.simulation.sim_config
				?.filter((bid) => !bid.owner)
				.forEach((bid) => {
					if (!competitor_bid_config[bid.username]) {
						competitor_bid_config[bid.username] = [];
					}
					competitor_bid_config[bid.username].push({
						min_price: bid.min_price,
						max_price: bid.max_price,
						min_quantity: bid.min_quantity,
						max_quantity: bid.max_quantity,
						min_splits: bid.min_splits,
						max_splits: bid.max_splits,
						id: bid.id,
					});
				});

		this.setState({
			...this.state,
			user_bid_config: this.props.simulation.simulation.sim_config?.filter((bid) => bid.owner),
			competitor_bid_config: competitor_bid_config,
			asq_mw: Math.round(this.props.simulation.simulation.asq),
			asq_gwhy: ((this.props.simulation.simulation.asq * 8760 * 0.45) / 1000).toFixed(2),
			bid_config_changed: false,
		});
	}

	async runSimulations() {
		const result = await confirm(`Confirmation`, {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>Do you want to run {commaFormat(this.state.runCount)} new simulations?</p>

								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.setSimulationLoading();
			await this.props.runSimulation(this.props.params.simulationId, { runs: this.state.runCount });
			await this.props.setSimulationNotLoading();

			await this.refresh();
			const data = {
				astId: this.props.params.simulationId,
				simNum: this.state.runCount,
			};
			await emitASTSimulate(data);
			return;
		}
	}

	async getNextSimulationRun() {
		await this.props.getSimulationRun(this.props.params.simulationId, ++this.state.viewSimulationID);
	}

	async getPrevSimulationRun() {
		await this.props.getSimulationRun(this.props.params.simulationId, --this.state.viewSimulationID);
	}

	async updateConfiguration(event) {
		event.preventDefault();

		let competitor_bid_config = [];
		for (const [competitor, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach((bid) => {
				competitor_bid_config.push({
					min_price: bid.min_price,
					max_price: bid.max_price,
					min_quantity: bid.min_quantity,
					max_quantity: bid.max_quantity,
					min_splits: bid.min_splits,
					max_splits: bid.max_splits,
					owner: 0,
					username: competitor,
					id: bid.id,
				});
			});
		}

		await this.props.updateSimulation(this.props.params.simulationId, {
			type: 2,
			bid_config: [...this.state.user_bid_config, ...competitor_bid_config],
			asq: this.state.asq_mw,
		});

		await this.props.setSimulationLoading();
		await this.props.runSimulation(this.props.params.simulationId, {
			runs: this.props.simulation.simulation.runs || 1,
		});
		await this.props.setSimulationNotLoading();

		const data = {
			astId: this.props.params.simulationId,
			simNum: this.props.simulation.simulation.runs || 1,
		};
		await emitASTConfigUpdate(data);
		await this.refresh();
	}

	onASQmwChange(event) {
		const asq_gwhy = ((event.target.value * 8760 * 0.45) / 1000).toFixed(2);
		//if (asq_gwhy >= 0 && asq_gwhy <= 18000) {
		this.setState({
			...this.state,
			asq_mw: event.target.value,
			asq_gwhy: asq_gwhy,
			bid_config_changed: true,
		});
		//}
	}

	onASQgwhyChange(event) {
		const asq_gwhy = event.target.value;
		//if (asq_gwhy >= 0 && asq_gwhy <= 18000) {
		this.setState({
			...this.state,
			asq_mw: ((event.target.value * 1000) / (8760 * 0.45)).toFixed(2),
			asq_gwhy: asq_gwhy,
			bid_config_changed: true,
		});
		//}
	}

	onUserBidConfigChange(event, index) {
		if (event.target.name === "price" && event.target.value > 150) {
			return;
		}
		let minName = `min_${event.target.name}`;
		let maxName = `max_${event.target.name}`;
		let user_bid_config_copy = [...this.state.user_bid_config];
		let item = { ...user_bid_config_copy[index], [minName]: event.target.value, [maxName]: event.target.value };
		user_bid_config_copy[index] = item;

		this.setState({
			...this.state,
			bid_config_changed: true,
			user_bid_config: user_bid_config_copy,
		});
	}

	onCompetitorBidConfigChange(event, username, bid_index) {
		let value = event.target.value;
		if ((event.target.name === "min_price" || event.target.name === "max_price") && value > 150) {
			value = 150;
		}
		if ((event.target.name === "min_splits" || event.target.name === "max_splits") && value > 3) {
			value = 3;
		}
		if ((event.target.name === "min_splits" || event.target.name === "max_splits") && value < 1) {
			value = 1;
		}

		let competitor_bids_copy = [...this.state.competitor_bid_config[username]];
		competitor_bids_copy[bid_index] = { ...competitor_bids_copy[bid_index], [event.target.name]: value };

		this.setState({
			...this.state,
			competitor_bid_config: {
				...this.state.competitor_bid_config,
				[username]: competitor_bids_copy,
			},
			bid_config_changed: true,
		});
	}

	onCountChange(event) {
		let value = event.target.value;
		if (event.target.name === "runCount" && value > 100000) {
			value = 100000;
		}
		this.setState({ ...this.state, [event.target.name]: value || null });
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			mainFilterValue: event.target.value,
		});
	}

	onSecondaryDropDownChange(event) {
		this.setState({
			...this.state,
			secondaryFilterValue: event.target.value,
		});
	}

	getDashboard() {
		if (this.state.mainFilterValue === "overview") return this.getOverview();
		if (this.state.mainFilterValue === "detailedOverview") return this.getDetailedOverview();
		if (this.state.mainFilterValue === "summaryScenarios") return this.getSummaryScenarios();
		if (this.state.mainFilterValue === "individualScenarios") return this.getIndividualScenarios();
		if (this.state.mainFilterValue === "config") return this.getConfiguration();
		return <></>;
	}

	getOverview() {
		return (
			<>
				{this.props.simulation.marginal_bids.length !== 0 && (
					<div className="mb-3">
						<div className="row" style={{ textAlign: "center" }}>
							<h4>Summary Statistics</h4>
							<hr style={{ width: "50%", margin: "auto" }} />
						</div>
						<br />
						<div className="row">
							<div className="col-12">{this.renderBidderWinRateGraph()}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-6">{this.renderDistanceToMarginalBidder()}</div>
							<div className="col-6">{this.renderQuantityClearedHistogram()}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">{this.renderCompetitorWinRateGraph()}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	getDetailedOverview() {
		return (
			<>
				{this.props.simulation.marginal_bids.length !== 0 && (
					<div className="mb-3">
						<div className="row" style={{ textAlign: "center" }}>
							<h4>Detailed Simulation Overview</h4>
							<hr style={{ width: "50%", margin: "auto" }} />
						</div>
						<br />
						<div className="row">
							<div className="col-12">{this.renderBidderWinRateGraph()}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-6">{this.renderDistanceToMarginalBidder()}</div>
							<div className="col-6">{this.renderQuantityClearedHistogram()}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">{this.renderCompetitorWinRateGraph()}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-6"> {this.renderPriceGraph()} </div>
							<div className="col-6"> {this.renderQuantityGraph()} </div>
						</div>
						<br />
						<div className="row">
							<div className="col-6"> {this.renderWinningPriceGraph()} </div>
							<div className="col-6"> {this.renderWinningQuantityGraph()} </div>
						</div>
						<br />
						<div className="row">
							<div className="col-6">{this.renderMarginalPriceGraph()}</div>
							<div className="col-6">{this.renderMarginalQuantityGraph()}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	getSummaryScenarios() {
		const { simulation, bids } = this.props.simulation;
		let filteredBids = [];
		let title = "";

		switch (this.state.secondaryFilterValue) {
			case "base":
				title = "Base Auction Results";
				filteredBids = bids?.filter((bid) => bid.run_id === 0);
				break;
			case "custom":
				title = "Custom Auction Results";
				filteredBids = bids?.filter((bid) => bid.run_id === this.state.summaryScenario + 1);
				break;
			default:
				break;
		}

		return (
			<>
				{filteredBids.length > 0 && (
					<div className="mb-3">
						<div className="row" style={{ textAlign: "center" }}>
							<h4>Auction Outcome</h4>
							<hr style={{ width: "50%", margin: "auto" }} />
						</div>
						<MPSBResultsChart
							asq={simulation.asq}
							bids={filteredBids}
							title={title}
							unit={this.state.graphUnit}
							toggleUnit={() => {
								this.setState({
									...this.state,
									graphUnit: this.state.graphUnit === "MW" ? "GWh" : "MW",
								});
							}}
						/>

						<MPSBResultsTable bids={filteredBids} />
					</div>
				)}
			</>
		);
	}

	getIndividualScenarios() {
		const { simulation, run } = this.props.simulation;
		return (
			<>
				{run.bids && (
					<div className="mb-3">
						<div className="row" style={{ textAlign: "center" }}>
							<h4>Individual Scenarios</h4>
							<hr style={{ width: "50%", margin: "auto" }} />
						</div>
						<MPSBResultsChart
							asq={simulation.asq}
							bids={run.bids}
							title={`Auction Simulation ${run.run_id} Results`}
							unit="MW"
						/>

						<MPSBResultsTable bids={run.bids} />
					</div>
				)}
			</>
		);
	}

	getCreateButtonText() {
		let asqTooHigh = false;
		let asqTooLow = false;
		if (this.state.asq_gwhy > 18000) {
			asqTooHigh = true;
		}
		if (this.state.asq_gwhy < 1000) {
			asqTooLow = true;
		}
		let submitEnabled = true;
		let splitsTooHigh = false;

		for (const [_, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach((bid) => {
				if (
					parseFloat(bid.min_price) > parseFloat(bid.max_price) ||
					parseInt(bid.min_quantity) > parseInt(bid.max_quantity) ||
					parseInt(bid.min_splits) > parseInt(bid.max_splits)
				) {
					submitEnabled = false;
				}

				if (bid.max_splits > 3) {
					splitsTooHigh = true;
				}
			});
		}

		return (
			<div>
				{submitEnabled === false ? (
					<p>
						<em>Please ensure max values are greater than min values</em>
					</p>
				) : (
					<></>
				)}
				{asqTooHigh ? (
					<p>
						<em>ASQ needs to be less than 18,000 GWh/yr</em>
					</p>
				) : (
					<></>
				)}
				{asqTooLow ? (
					<p>
						<em>ASQ needs to be greater than 1,000 GWh/yr</em>
					</p>
				) : (
					<></>
				)}
				{splitsTooHigh ? (
					<p>
						<em>Max splits cannot be greater than 3</em>
					</p>
				) : (
					<></>
				)}
			</div>
		);
	}

	getConfiguration() {
		let mpsbEnabled = this.state.bid_config_changed;

		let combinedPrice = 0;
		let combinedQuantity = 0;
		this.state.user_bid_config.forEach((entry) => {
			combinedPrice = combinedPrice + parseFloat(entry.max_price) * parseFloat(entry.max_quantity);
			combinedQuantity = combinedQuantity + parseFloat(entry.max_quantity);
		});
		let averageOfferPrice = combinedPrice / combinedQuantity;

		const preventMinus = (e) => {
			if (e.code === "Minus") {
				e.preventDefault();
			}
		};

		this.state.user_bid_config.forEach((bid) => {
			if (!bid.min_price || !bid.min_quantity) {
				mpsbEnabled = false;
			}
		});

		for (const [_, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach((bid) => {
				if (
					parseFloat(bid.min_price) > parseFloat(bid.max_price) ||
					parseInt(bid.min_quantity) > parseInt(bid.max_quantity) ||
					parseInt(bid.min_splits) > parseInt(bid.max_splits)
				) {
					mpsbEnabled = false;
				}
				if (bid.max_splits > 3) {
					mpsbEnabled = false;
				}
			});
		}

		if (this.state.asq_gwhy > 18000) {
			mpsbEnabled = false;
		}
		if (this.state.asq_gwhy < 1000) {
			mpsbEnabled = false;
		}

		return (
			<>
				{this.state.competitor_bid_config && (
					<>
						<div className="row" style={{ textAlign: "center" }}>
							<h4>Configuration</h4>
							<hr style={{ width: "50%", margin: "auto" }} />
						</div>
						<div
							className="row mb-3"
							style={{
								justifyContent: "flex-end",
								margin: "auto",
								textAlign: "right",
								alignItems: "right",
								color: "red",
								padding: "6px",
							}}
						>
							{this.getCreateButtonText()}
							<button
								disabled={!mpsbEnabled}
								className="btn btn-primary"
								style={{ width: "auto" }}
								onClick={(e) => this.updateConfiguration(e)}
							>
								Update Configuration
							</button>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<label className="form-label">Simulation Details</label>
								<br />
								<div className="row">
									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												id="asq_mw"
												name="asq_mw"
												value={this.state.asq_mw}
												onChange={this.onASQmwChange.bind(this)}
											/>
											<label htmlFor="asq">Auction Starting Quantity (MW)</label>
										</div>
									</div>
									<div className="col">
										<div className="form-floating mb-3">
											<input
												className="form-control"
												id="asq_gwhy"
												name="asq_gwhy"
												value={this.state.asq_gwhy}
												onChange={this.onASQgwhyChange.bind(this)}
											/>
											<label htmlFor="asq">Auction Starting Quantity (GWh/yr)</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<label className="form-label">
									My Bids (average offer price {priceFormat(averageOfferPrice)} €/MWh)
								</label>
								<br />
								{this.state.user_bid_config.map((bid, index) => {
									return (
										<>
											<label className="form-label">Bid {index + 1}</label>
											<div className="row">
												<div className="col">
													<div className="form-floating mb-3">
														<input
															className="form-control"
															type="number"
															id="price"
															name="price"
															value={bid.min_price}
															onChange={(e) => this.onUserBidConfigChange(e, index)}
														/>
														<label htmlFor="price">Price (€/MWh)</label>
													</div>
												</div>
												<div className="col">
													<div className="form-floating mb-3">
														<input
															className="form-control"
															type="number"
															id="quantity"
															name="quantity"
															value={bid.max_quantity}
															onChange={(e) => this.onUserBidConfigChange(e, index)}
														/>
														<label htmlFor="quantity">Quantity (MW)</label>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>
						</div>
						<BidPricesQuantitiesGraphs
							competitor_bid_config={this.state.competitor_bid_config}
							user_bid_config={this.state.user_bid_config}
						/>
						<div className="card mb-3">
							<div className="card-body">
								<h4 className="form-label">Competitor Bids</h4>
								{Object.entries(this.state.competitor_bid_config).map(([username, bids]) => {
									return (
										<>
											<div className="row">
												<div className="col">
													<h5 className="form-label">{username}</h5>
												</div>
											</div>
											{bids.map((bid, index) => {
												return (
													<div className="row">
														<h6 className="form-label">Bid {index + 1}</h6>
														<div className="col">
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Min Price (€/MWh)
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="min-price"
																		name="min_price"
																		value={bid.min_price}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Max Price (€/MWh)
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="max-price"
																		name="max_price"
																		value={bid.max_price}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="col">
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Min Quantity (MW)
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="min-quantity"
																		name="min_quantity"
																		value={bid.min_quantity}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Max Quantity (MW)
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="max-quantity"
																		name="max_quantity"
																		value={bid.max_quantity}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="col">
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Min Splits
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="min-splits"
																		name="min_splits"
																		value={bid.min_splits}
																		onKeyPress={preventMinus}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
															<div className="col">
																<div className="form-group mb-3">
																	<small className="form-text text-muted">
																		Max Splits
																	</small>
																	<input
																		className="form-control"
																		type="number"
																		id="max-splits"
																		name="max_splits"
																		value={bid.max_splits}
																		onKeyPress={preventMinus}
																		onChange={(e) =>
																			this.onCompetitorBidConfigChange(
																				e,
																				username,
																				index
																			)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</>
									);
								})}
							</div>
						</div>
					</>
				)}
			</>
		);
	}

	getSimulationConsole() {
		return (
			<>
				<div className="card" style={{ margin: "auto" }}>
					<div className="card-body" style={{ padding: "2%" }}>
						<div className="row">
							<div className="col-6">
								<div className="row">
									<select
										value={this.state.mainFilterValue}
										onChange={(e) => this.onMainDropDownChange(e)}
										style={{
											width: "200px",
											padding: "6px",
											marginLeft: "5px",
											borderRadius: "4px",
											fontSize: "14px",
											borderColor: "#0275d8",
											color: "#0275d8",
										}}
									>
										<option value="select" disabled={true}>
											Select Dashboard
										</option>
										<hr />
										<option value="overview">Summary Statistics</option>
										{/* <option value="detailedOverview">Detailed Overview</option> */}
										<hr />
										<option value="summaryScenarios">Auction Outcome</option>
										{/* <option value="individualScenarios">Individual Scenarios</option> */}
										<hr />
										<option value="config">Configuration</option>
									</select>
									{this.state.mainFilterValue === "individualScenarios" && (
										<>
											<button
												className="btn btn-outline-danger btn-sm"
												type="button"
												style={{ width: "auto", margin: "0 5px 2px 10px" }}
												onClick={() => {
													this.getPrevSimulationRun();
												}}
												disabled={this.state.viewSimulationID === 1}
											>
												<i className="fas fa-step-backward"></i>
											</button>
											<button
												className="btn btn-outline-success btn-sm"
												type="button"
												style={{ width: "auto", margin: "0 0 2px 0" }}
												onClick={() => {
													this.getNextSimulationRun();
												}}
												disabled={
													this.state.viewSimulationID ===
													this.props.simulation.simulation.runs
												}
											>
												<i className="fas fa-step-forward"></i>
											</button>
										</>
									)}
									{this.state.mainFilterValue === "summaryScenarios" && (
										<>
											<select
												value={this.state.secondaryFilterValue}
												onChange={(e) => this.onSecondaryDropDownChange(e)}
												style={{
													width: "200px",
													marginLeft: "5px",
													padding: "6px",
													borderRadius: "4px",
													fontSize: "14px",
													borderColor: "#0275d8",
													color: "#0275d8",
												}}
											>
												<option value="select" disabled={true}>
													Select Outcome
												</option>
												<hr />
												<option value="base">Base Scenario</option>
												<option value="custom">Custom Scenario</option>
												<hr />
												<option disabled={true} value="lowestMarginal">
													Lowest Marginal Price
												</option>
												<option disabled={true} value="highestMarginal">
													Highest Marginal Price
												</option>
												<hr />
												<option disabled={true} value="leastQuanity">
													Least Quantity Cleared
												</option>
												<option disabled={true} value="mostQuantity">
													Most Quantity Cleared
												</option>
											</select>
											{this.state.secondaryFilterValue === "custom" && (
												<>
													<div className="row">
														<div
															className="form-check form-switch"
															style={{ marginLeft: "5px", marginTop: "5px" }}
														>
															<input
																className="form-check-input"
																type="checkbox"
																checked={(this.state.summaryScenario & 1) === 1}
																onChange={() =>
																	this.setState((state) => {
																		return {
																			...state,
																			summaryScenario: state.summaryScenario ^ 1,
																		};
																	})
																}
															/>
															<label className="form-check-label">Min / Max Price</label>
														</div>
													</div>
													<div className="row">
														<div
															className="form-check form-switch"
															style={{ marginLeft: "5px" }}
														>
															<input
																className="form-check-input"
																type="checkbox"
																checked={(this.state.summaryScenario & 2) === 2}
																onChange={() =>
																	this.setState((state) => {
																		return {
																			...state,
																			summaryScenario: state.summaryScenario ^ 2,
																		};
																	})
																}
															/>
															<label className="form-check-label">
																Min / Max Quantity
															</label>
														</div>
													</div>
													<div className="row">
														<div
															className="form-check form-switch"
															style={{ marginLeft: "5px" }}
														>
															<input
																className="form-check-input"
																type="checkbox"
																checked={(this.state.summaryScenario & 4) === 4}
																onChange={() =>
																	this.setState((state) => {
																		return {
																			...state,
																			summaryScenario: state.summaryScenario ^ 4,
																		};
																	})
																}
															/>
															<label className="form-check-label">Min / Max Splits</label>
														</div>
													</div>
												</>
											)}
										</>
									)}
								</div>
							</div>
							<div
								className="col-6"
								style={{
									textAlign: "right",
									alignItems: "right",
								}}
							>
								<div
									className="input-group input-group-sm"
									role="group"
									style={{ justifyContent: "flex-end" }}
								>
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.runSimulations()}
										style={{ width: "170px" }}
										disabled={!this.state.runCount}
									>
										Run New Simulations
									</button>
									<input
										type="number"
										className="form-control form-control-sm"
										name="runCount"
										placeholder="New Run Count"
										value={this.state.runCount}
										onChange={this.onCountChange.bind(this)}
										style={{ maxWidth: "200px" }}
									/>
									<span className="input-group-text input-group-sm">
										Current Run Count: {commaFormat(this.props.simulation.simulation.runs) || 0}
									</span>
									<span className="input-group-text input-group-sm">
										ASQ: {Math.round(this.props.simulation.simulation.asq) || 0} MW
									</span>
								</div>
							</div>
						</div>
						{/* <div className="row" style={{ marginLeft: "2px" }}>
							<div className="col-12">
								{this.state.mainFilterValue === "overview" && (
									<p>
										The overview screen displays graphs that summarize the simulation. Bidder win
										rate shows the percentage of runs a given bidder has a successful bid. Untapped
										bid potential suggests how much you can increase your bid by and still win.
										Competitor win rates shows the percentage of competitor wins at a given price
										point.
									</p>
								)}
								{this.state.mainFilterValue === "detailedOverview" && (
									<p>
										The detailed overview page shows everything from the overview page along with
										many histograms showing the distribution of key auction parameters.
									</p>
								)}
								{this.state.mainFilterValue === "summaryScenarios" && (
									<p>
										This page displays specific simulation scenarios based on the provided
										parameters. The base case scenario uses the base price and quantity values for
										all the bidders, whereas the minimum and maximum scenarios use the values from
										either extreme of the uncertainty boundaries. 
									</p>
								)}
								{this.state.mainFilterValue === "individualScenarios" && (
									<p>
										The indivudal scenario page allows the user to view the results of each
										simulated run.
									</p>
								)}
								{this.state.mainFilterValue === "config" && (
									<p>
										The configuration page allows the user to change the input parameters for the
										simulation.
									</p>
								)}
							</div>
						</div> */}
					</div>
				</div>
				<br />
			</>
		);
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderBidderWinRateGraph() {
		let allUsernames = [];
		let traces = [];
		let cumWinRate = [];
		let traceData = [];

		let graphData = this.props.simulation.statisticalAnalysis.filter((data) => data.graph_id === 1);
		let ownerName = this.props.user.user.display_name;
		let simulatedUsers = [
			...new Set(graphData.filter((a) => a.x_value != ownerName).map((data) => data.x_value)),
		].sort();

		allUsernames = [ownerName, ...simulatedUsers];

		let traceCount = graphData.reduce((prev, curr) => (curr.trace_id > prev ? curr.trace_id : prev), 0);

		for (let trace = 0; trace < traceCount; trace++) {
			let traceData = [];
			allUsernames.forEach((username, index) => {
				let userTraceData = graphData.find((data) => data.x_value === username && data.trace_id == trace + 1);
				let userTraceWinrate = userTraceData
					? (100 * userTraceData.value) / this.props.simulation.simulation.runs
					: 0;
				traceData.push(userTraceWinrate);
				if (userTraceWinrate) {
					if (!cumWinRate[index]) {
						cumWinRate[index] = [userTraceWinrate, trace];
					} else {
						cumWinRate[index][0] += userTraceWinrate;
						cumWinRate[index][1] = trace;
					}
				}
			});
			traces.push(traceData);
		}

		traces.forEach((userWinCount, index) => {
			traceData.push({
				type: "bar",
				y: userWinCount,
				x: allUsernames,
				text: cumWinRate.map((a) => {
					return index === a[1] ? `c.${a[0].toFixed()}%` : "";
				}),
				hovertemplate: "%{y:.0f}%",
				marker: {
					opacity: 0.6,
				},
				name: `${index + 1} Winning bid${index + 1 > 1 ? "s" : ""}`,
			});
		});

		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[...traceData]}
						layout={{
							legend: {
								x: 0,
								xanchor: "left",
								yanchor: "bottom",
								y: 1,
							},
							title: "Bidder Win Rates",
							barmode: "stack",
							yaxis: {
								showticklabels: false,
							},
							colorway: COLORS,
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderDistanceToMarginalBidder() {
		let winDeltas = [];
		let winHoverText = [];
		let xValuesWin = [];

		let lossDeltas = [];
		let xValuesLoss = [];
		let lossHoverText = [];

		let graphData = this.props.simulation.statisticalAnalysis.filter(({ graph_id }) => graph_id === 2);
		let winData = graphData
			.filter(({ trace_id }) => trace_id === 1)
			.sort((a, b) => parseFloat(a.x_value.split(" -")[0]) - parseFloat(b.x_value.split(" -")[0]));
		let lossData = graphData
			.filter(({ trace_id }) => trace_id === 0)
			.sort((a, b) => parseFloat(a.x_value.split(" -")[0]) - parseFloat(b.x_value.split(" -")[0]));

		let winSum = winData.reduce((prev, curr) => prev + curr.value, 0);
		let lossSum = lossData.reduce((prev, curr) => prev + curr.value, 0);

		winData.forEach((item) => {
			winDeltas.push((100 * item.value) / (winSum + lossSum));
			winHoverText.push(item.x_value);
			xValuesWin.push(parseInt(item.x_value.split(" -")[0]));
		});

		lossData.forEach((item) => {
			lossDeltas.push((100 * item.value) / (winSum + lossSum));
			lossHoverText.push(item.x_value);
			xValuesLoss.push(parseInt(item.x_value.split(" -")[0]));
		});

		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "bar",
								x: xValuesWin,
								y: winDeltas,
								hovertext: winHoverText,
								hovertemplate: `%{hovertext}, %{y:.0f}%`,
								marker: {
									color: COLORS[2],
								},
								name: "When winning: distance to first losing bid",
							},
							{
								type: "bar",
								x: xValuesLoss,
								y: lossDeltas,
								hovertext: lossHoverText,
								hovertemplate: `%{hovertext}, %{y:.0f}%`,
								marker: {
									color: "rgb(158,202,225)",
								},
								name: "When losing: distance to last winning bid",
							},
						]}
						layout={{
							legend: {
								x: 0,
								xanchor: "left",
								yanchor: "bottom",
								y: 1,
							},
							showlegend: true,
							title: "Price Distance to Marginal Bidder ",
							bargap: 0.1,
							barmode: "stack",
							xaxis: { title: "Suggested price change (€/MWh)" },
							yaxis: { ticksuffix: "%" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderQuantityClearedHistogram() {
		let quantitiesCleared = [];
		let hoverText = [];
		let xValues = [];

		let graphData = this.props.simulation.statisticalAnalysis.filter(({ graph_id }) => graph_id === 3);
		let totalQuantityCleared = graphData.reduce((prev, curr) => prev + curr.value, 0);
		graphData.forEach((item) => {
			quantitiesCleared.push((100 * item.value) / totalQuantityCleared);
			hoverText.push(item.x_value);
			xValues.push(parseInt(item.x_value.split(" -")[0]));
		});

		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "bar",
								y: quantitiesCleared,
								x: xValues,
								hovertext: hoverText,
								hovertemplate: `%{hovertext}, %{y:.0f}%`,
								marker: {
									color: "rgb(158,202,225)",
								},
								name: "",
							},
						]}
						layout={{
							title: "Total Quantity Cleared",
							bargap: 0.1,
							xaxis: { title: "Quantity Cleared (MW)" },
							yaxis: { ticksuffix: "%" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderCompetitorWinRateGraph() {
		let winnerTraceData = [];
		let loserTraceData = [];
		let hoverText = [];
		let xValues = [];

		let graphData = this.props.simulation.statisticalAnalysis
			.filter((item) => item.graph_id === 4)
			.sort((a, b) => parseFloat(a.x_value.split(" -")[0]) - parseFloat(b.x_value.split(" -")[0]));

		let winData = graphData.filter((item) => item.trace_id === 1);
		let lossData = graphData.filter((item) => item.trace_id === 0);

		winData.forEach(({ x_value, value }) => {
			let matchingLoss = lossData.find((item) => item.x_value === x_value);
			let winCount = value;
			let lossCount = matchingLoss ? matchingLoss.value : 0;

			let winrate = (100 * winCount) / (winCount + lossCount);

			winnerTraceData.push(winrate);
			xValues.push(parseFloat(x_value.split(" -")[0]));
			hoverText.push(x_value);
		});

		return (
			<>
				<div className="card" style={{ margin: "auto" }}>
					<div className="card-body">
						<Plot
							data={[
								{
									type: "bar",
									y: winnerTraceData,
									x: xValues,
									marker: {
										color: "rgb(158,202,225)",
									},
									line: {
										color: "rgb(0,0,0)",
										width: 0.1,
									},
									name: "Win percentage",
									text: winnerTraceData.map((val) => `${val.toFixed(0)}%`),
									hovertext: hoverText,
									hovertemplate: `%{hovertext}, %{y:.0f}%`,
								},
							]}
							layout={{
								title: "Competitor Win Rates at Price Points",
								bargap: 0.2,
								barmode: "stack",
								showlegend: false,
								xaxis: {
									title: "Offer Price (€/MWh)",
								},
								yaxis: {
									ticksuffix: "%",
									showticklabels: false,
								},
							}}
							style={{ width: "100%", height: "100%" }}
							useResizeHandler
						/>
					</div>
				</div>
			</>
		);
	}

	renderMarginalPriceGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.marginal_bids.map((a) => a.price),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Marginal Price Distribution",
							bargap: 0.05,
							xaxis: { title: "Price (€ / MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderMarginalQuantityGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.marginal_bids
									.filter((bid) => bid.owner !== 1)
									.map((a) => a.quantity),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Marginal Quantity Distribution",
							bargap: 0.05,
							xaxis: { title: "Quantity (MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderPriceGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.bids.filter((bid) => bid.owner !== 1).map((a) => a.price),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Price Distribution",
							bargap: 0.05,
							xaxis: { title: "Price (€ / MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderQuantityGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.bids.filter((bid) => bid.owner !== 1).map((a) => a.quantity),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Quantity Distribution",
							bargap: 0.05,
							xaxis: { title: "Quantity (MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderWinningPriceGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.bids
									.filter((a) => a.winner === 1 && a.owner !== 1)
									.map((b) => b.price),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Winning Price Distribution",
							bargap: 0.05,
							xaxis: { title: "Price (€ / MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	renderWinningQuantityGraph() {
		return (
			<div className="card" style={{ margin: "auto" }}>
				<div className="card-body">
					<Plot
						data={[
							{
								type: "histogram",
								x: this.props.simulation.bids
									.filter((a) => a.winner === 1 && a.owner !== 1)
									.map((b) => b.quantity),
								marker: {
									color: "rgb(158,202,225)",
								},
							},
						]}
						layout={{
							title: "Winning Quantity Distribution",
							bargap: 0.05,
							xaxis: { title: "Quantity (MWh)" },
							yaxis: { title: "Frequency" },
						}}
						style={{ width: "100%", height: "100%" }}
						useResizeHandler
					/>
				</div>
			</div>
		);
	}

	render() {
		const { simulation, simulation_loading } = this.props.simulation;
		const { user, signedIn, user_loading } = this.props.user;

		if (!signedIn) {
			return <Redirect to="/" />;
		}

		if (simulation_loading || user_loading) {
			return this.returnSpinner();
		}

		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});

		socket.off("ast_refresh").on("ast_refresh", (data) => {
			this.refresh();
			if (data.message) {
				addNotification({
					title: data.title,
					message: data.message,
					type: "info",
					duration: 7000,
				});
			}
		});

		return (
			<div className="container">
				<h3>{simulation.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && user.type === "user" ? (
								<>Simulations</>
							) : (
								<Link to="/simulations">Simulations</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{simulation.name}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
				</div>
				<br />

				{this.getSimulationConsole()}

				<hr />
				<br />

				{this.getDashboard()}
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <MPSBSimulation {...props} params={params} />;
}

function IQR(arr) {
	var sorted = arr.slice(0).sort(function (a, b) {
		return a - b;
	});
	var q1 = sorted[Math.floor(sorted.length / 4)];
	var q3 = sorted[Math.floor((sorted.length * 3) / 4)];
	return q3 - q1;
}

function binWidth(arr) {
	return 2 * IQR(arr) * Math.pow(arr.length, -1 / 3);
}

function numBins(arr, defaultBins = null) {
	var h = binWidth(arr),
		ulim = Math.max.apply(Math, arr),
		llim = Math.min.apply(Math, arr);
	if (h <= (ulim - llim) / arr.length) {
		return defaultBins || 10; // Fix num bins if binWidth yields too small a value.
	}
	return Math.ceil((ulim - llim) / h);
}

function priceFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
}

function commaFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(num);
}

MPSBSimulation.propTypes = {
	getSimulation: PropTypes.func.isRequired,
	setSimulationLoading: PropTypes.func.isRequired,
	setSimulationNotLoading: PropTypes.func.isRequired,
	updateSimulation: PropTypes.func.isRequired,
	runSimulation: PropTypes.func.isRequired,
	getSimulationRun: PropTypes.func.isRequired,
	getSimulationBids: PropTypes.func.isRequired,
	getMarginalBids: PropTypes.func.isRequired,
	getStatisticalAnalysis: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	simulation: state.simulation,
	user: state.user,
	config: state.config,
});

export default connect(mapStateToProps, {
	getSimulation,
	updateSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
	runSimulation,
	getSimulationRun,
	getSimulationBids,
	getMarginalBids,
	getStatisticalAnalysis: runStatisticalAnalysis,
	setUserLoading,
	setUserNotLoading,
})(WithParamsAndNavigate);
