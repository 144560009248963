import React, { Component } from "react";
import PropTypes from "prop-types";
import util from "../../../../util/RENO";

class AuctionRulesCard extends Component {
	constructor(props) {
		super(props);
	}

	getTimeExtensionRule() {
		const { auction } = this.props.auction;

		return (
			<>
				Bids in the last <strong>{util.formatDurationWithText(auction.timeExtensionWindow)}</strong> will extend
				the auction by <strong>{util.formatDurationWithText(auction.timeExtensionDuration)}</strong>.
				<ul>
					<li>
						During this extension window, the <em>Time Remaining</em> (under "Auction Schedule" section)
						will be highlighted in red.
					</li>
					{auction.onlyLeadingBidsCanExtend == 1 ? (
						<li>
							Only bids lower than the strike price will cause an extension during the time extension
							window
						</li>
					) : (
						<li>
							All bids placed during this extension window (including those above the strike price) will
							cause an extension
						</li>
					)}
				</ul>
			</>
		);
	}

	getViewStrikePriceRule() {
		const { auction } = this.props.auction;

		if (auction.showRanksToBidders == 1)
			return (
				<>
					The <em>Overall Lowest Bid</em>, i.e., <strong>Strike Price</strong> (and the relative rank) will
					not be visible until you have placed a bid.
				</>
			);

		return (
			<>
				The <em>Overall Lowest Bid</em>, i.e., <strong>Strike Price</strong> will not be visible until you have
				placed a bid.
			</>
		);
	}

	getViewStrikePriceRule() {
		const { auction } = this.props.auction;

		if (auction.showRanksToBidders == 1)
			return <>Your bid's rank relative to the strike bid will be visible in the bidding table.</>;

		return (
			<>
				Your bid's rank relative to the strike bid will <strong>not be visible</strong> during the auction.
			</>
		);
	}

	getNewBidPriceRule() {
		const { auction, userBids } = this.props.auction;

		var upperLimit = util.formatCurrencyWithText(auction.bidUpperLimit);

		if (userBids.length > 0) {
			const lowestBid = userBids.sort((a, b) => a.amount - b.amount)[0];

			// upperLimit = `NOK ${(auction.minimumDecrementToStrikePrice == 1 && auction.strikeBid !== null
			// 	? Math.round(
			// 			(auction.strikeBid.amount - auction.minimumDecrement < auction.minimumContractPrice
			// 				? auction.minimumContractPrice
			// 				: auction.strikeBid.amount - auction.minimumDecrement) * 100000
			// 	  ) / 100000
			// 	: Math.round(
			// 			(lowestBid.amount - auction.minimumDecrement < auction.minimumContractPrice
			// 				? auction.minimumContractPrice
			// 				: lowestBid.amount - auction.minimumDecrement) * 100000
			// 	  ) / 100000
			// ).toFixed(5)} øre/kWh`;

			upperLimit = util.formatCurrencyWithText(
				auction.minimumDecrementToStrikePrice == 1 && auction.strikeBid !== null
					? auction.strikeBid.amount - auction.minimumDecrement < auction.minimumContractPrice
						? auction.minimumContractPrice
						: auction.strikeBid.amount - auction.minimumDecrement
					: lowestBid.amount - auction.minimumDecrement < auction.minimumContractPrice
					? auction.minimumContractPrice
					: lowestBid.amount - auction.minimumDecrement
			);
		}

		// const lowerLimit = `NOK ${(Math.round(auction.minimumContractPrice * 100000) / 100000).toFixed(5)} øre/kWh`;
		const lowerLimit = util.formatCurrencyWithText(auction.minimumContractPrice);

		const ruleText =
			auction.minimumDecrementToStrikePrice == 1
				? "The upper limit to the bid price is calculated by applying the minimum decrement rule to the strike price (if applicable)"
				: "The upper limit to the bid price is calculated by applying the minimum decrement rule to your previous bid (if applicable)";

		return (
			<>
				The <strong>new bid price</strong> must be <strong>greater than (or equal to) {lowerLimit}</strong>
				{upperLimit ? " and " : ""}
				<strong>{upperLimit ? `less than (or equal to) ${upperLimit}` : ""}</strong>. This is in order to meet
				the minimum contract price and minimum decrement criteria.
				<ul>
					<li>{ruleText}</li>
				</ul>
			</>
		);
	}

	getSameBidAmountRule() {
		const { auction } = this.props.auction;
		const mcp = `NOK ${(Math.round(auction.minimumContractPrice * 1000) / 1000).toFixed(3)} øre/kWh`;

		const ruleText =
			auction.tiesOnlyForLeadingBids == 0
				? "Ties are checked against all (leading and non-leading bids) submitted bids."
				: "Ties are checked only against users' leading bids.";

		return (
			<>
				The auction platform will <strong>not accept ties during the auction</strong>, unless the minimum
				contract price of {mcp} is met. If the minimum contract price is met, multiple bidders will be able to
				bid the final lowest acceptable bid of {mcp}.
				<ul>
					<li>{ruleText}</li>
				</ul>
			</>
		);
	}

	getBreaksRule() {
		const { auction } = this.props.auction;

		return (
			<>
				During the auction, the auctioneer can make <strong>breaks</strong> when required.
				<ul>
					<li>The duration of these breaks is up to the auctioneer's discretion.</li>
					<li>
						Once a break has ended, there will be{" "}
						<strong>{util.formatDurationWithText(auction.postBreakDuration)}</strong> of open bidding,
						regardless of the remaining time before the break.
					</li>
					<li>Bids cannot be made during a break.</li>
				</ul>
			</>
		);
	}

	getWinnerDeterminationRule() {
		const { auction } = this.props.auction;
		const mcp = `NOK ${(Math.round(auction.minimumContractPrice * 1000) / 1000).toFixed(3)} øre/kWh`;

		return (
			<>
				The winner for the auction will be determined based on the following:
				<ul>
					<li>
						If a unique lowest bid has been submitted, then the applicant who has submitted this bid will
						win
					</li>
					<li>
						If more than equal lowest bids (equal to the minimum contract price of {mcp}) have been
						submitted, then one of them will be picked at random to be deemed the winning bid.
					</li>
				</ul>
			</>
		);
	}

	render() {
		const { auction } = this.props.auction;

		return (
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header" style={{ textAlign: "left", height: "2.5rem" }}>
							<h5>Auction Rules</h5>
						</div>
						<div className="card-body" style={{ paddingBottom: "0", margin: "auto" }}>
							<ul>
								<li>{this.getTimeExtensionRule()}</li>
								<li>{this.getViewStrikePriceRule()}</li>
								<li>{this.getNewBidPriceRule()}</li>
								<li>{this.getSameBidAmountRule()}</li>
								<li>{this.getBreaksRule()}</li>
								<li>{this.getWinnerDeterminationRule()}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AuctionRulesCard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default AuctionRulesCard;
