import React, { Component } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import { auctionStatus } from "../../common/constants";
import util from "../../../util/RENO";

class UserDashboard extends Component {
	constructor(props) {
		super(props);

		this.viewStrikePriceIcon = {
			width: 500,
			height: 600,
			path: "M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z",
		};

		this.state = {
			viewStrikePrice: false,
		};
	}

	onViewStrikePriceChange() {
		this.setState({
			...this.state,
			viewStrikePrice: !this.state.viewStrikePrice,
		});
	}

	renderStatistics() {
		const { auction, history, user } = this.props;
		const userBids = this.props.history.filter((bid) => bid.userId == this.props.user.id);

		const didUserWin = userBids.filter((bid) => bid.winner === 1).length > 0;

		const highestBidPrice = Math.max.apply(
			Math,
			history.map((bid) => bid.amount)
		);
		const userHighestBidPrice = Math.max.apply(
			Math,
			userBids.map((bid) => bid.amount)
		);
		const lowestBidPrice = Math.min.apply(
			Math,
			history.map((bid) => bid.amount)
		);
		const userLowestBidPrice = Math.min.apply(
			Math,
			userBids.map((bid) => bid.amount)
		);
		const averageBidPrice =
			history.reduce((total, next) => total + next.amount, 0) / (history.length > 0 ? history.length : 1);
		const userAverageBidPrice =
			userBids.reduce((total, next) => total + next.amount, 0) / (userBids.length > 0 ? userBids.length : 1);

		const decrementUserBids = userBids.filter((bid) => bid.decrement > 0);
		const averageDecrement =
			decrementUserBids.reduce((total, next) => total + next.decrement, 0) /
			(decrementUserBids.length > 0 ? decrementUserBids.length : 1);

		return (
			<div className="row">
				<div className="col-4">
					<div className="card">
						<ul className="list-group list-group-flush">
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								{auction.status === auctionStatus.ENDED ? (
									didUserWin ? (
										<p style={{ color: "green" }}>
											{user.display_name} <strong>won</strong>.
										</p>
									) : (
										<p style={{ color: "red" }}>
											{user.display_name} <strong>did not win</strong>.
										</p>
									)
								) : (
									<p>
										<em>Winners will be determined once the auction ends</em>
									</p>
								)}
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Number of Bids: <strong>{userBids.length}</strong> / {history.length}
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Minimum Contract Price:{" "}
									<strong>{util.formatCurrencyWithText(auction.minimumContractPrice)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Minimum Decrement:{" "}
									<strong>{util.formatCurrencyWithText(auction.minimumDecrement)}</strong>
								</p>
							</li>
						</ul>
					</div>
				</div>

				<div className="col-4">
					<div className="card">
						<ul className="list-group list-group-flush">
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Highest Bid Price:{" "}
									<strong>{util.formatCurrencyWithText(userHighestBidPrice)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Lowest Bid Price: <strong>{util.formatCurrencyWithText(userLowestBidPrice)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Average Bid Price:{" "}
									<strong>{util.formatCurrencyWithText(userAverageBidPrice)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Average Decrement: <strong>{util.formatCurrencyWithText(averageDecrement)}</strong>
								</p>
							</li>
						</ul>
					</div>
				</div>

				<div className="col-4">
					<div className="card">
						<ul className="list-group list-group-flush">
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Start Time: <strong>{util.formatTime(auction.startTime)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									End Time: <strong>{util.formatTime(auction.endTime)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Scheduled End Time: <strong>{util.formatTime(auction.scheduledEndTime)}</strong>
								</p>
							</li>
							<li className="list-group-item" style={{ height: "2.5rem" }}>
								<p>
									Auction Duration:{" "}
									<strong>{util.formatDuration(auction.endTime - auction.startTime)}</strong>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}

	getTicks(minX, maxX, tickNum) {
		var step = parseInt((maxX - minX) / tickNum);

		var ticks = [];
		for (var i = 0; i < tickNum; i++) {
			ticks.push(minX + step * i);
		}
		ticks.push(maxX);

		return ticks;
	}

	renderAuctionGraph() {
		const auction = this.props.auction;
		const history = this.props.history;
		const user = this.props.user;
		const userBids = this.props.history
			.filter((bid) => bid.userId == this.props.user.id)
			.sort((a, b) => a.time - b.time);

		var chartData = [];

		const maxBid = history.length > 0 ? Math.max(...history.map((h) => h.amount)) : 0;
		const yUpper = maxBid * 1.1;
		const yLower = 0;
		const xLower = auction.startTime;
		const xUpper = auction.endTime;

		// Add time extension window to the chart
		if (auction.timeExtensionWindows && auction.timeExtensionWindows.length > 0) {
			var tewInLegend = false;
			var tewNum = 1;
			auction.timeExtensionWindows
				.sort((a, b) => a.startTime - b.startTime)
				.forEach((tew) => {
					chartData.push({
						name: "TEW",
						x: [tew.startTime, tew.endTime],
						y: [yUpper, yUpper],
						fill: "tozeroy",
						type: "scatter",
						mode: "none",
						showlegend: tewInLegend ? false : true,
						fillcolor: "rgba(217,217,217,0.3)",
						hovertemplate: "skip",
					});

					chartData.push({
						x: [tew.startTime, tew.startTime],
						y: [yLower, yUpper],
						mode: "lines",
						name: "TEW",
						customdata: [
							{
								startTime: util.formatTime(tew.startTime),
								endTime: util.formatTime(tew.endTime),
								number: tewNum,
							},
							{
								startTime: util.formatTime(tew.startTime),
								endTime: util.formatTime(tew.endTime),
								number: tewNum,
							},
						],
						showlegend: false,
						line: {
							color: "rgba(217,217,217,0.9)",
							dash: "dot",
							width: 2,
						},
						hovertemplate:
							"<b>Time Extension Window #%{customdata.number}</b><br>" +
							"Start Time: %{customdata.startTime} <br>" +
							"End Time: %{customdata.endTime} <br>" +
							"<extra></extra>",
					});

					tewInLegend = true;
					tewNum += 1;
				});
		}

		// Add breaks to the chart
		if (auction.breaks && auction.breaks.length > 0) {
			var breakInLegend = false;
			var breakNum = 1;
			auction.breaks
				.sort((a, b) => a.startTime - b.startTime)
				.forEach((auctionBreak) => {
					chartData.push({
						name: "Break",
						x: [auctionBreak.startTime, auctionBreak.endTime],
						y: [yUpper, yUpper],
						fill: "tozeroy",
						type: "scatter",
						mode: "none",
						showlegend: breakInLegend ? false : true,
						fillcolor: "rgba(241,173,0,0.2)",
						hovertemplate: "skip",
					});

					chartData.push({
						x: [auctionBreak.startTime, auctionBreak.startTime],
						y: [yLower, yUpper],
						mode: "lines",
						name: "TEW",
						customdata: [
							{
								startTime: util.formatTime(auctionBreak.startTime),
								endTime: util.formatTime(auctionBreak.endTime),
								duration: util.formatDurationWithText(auctionBreak.endTime - auctionBreak.startTime),
								number: breakNum,
							},
							{
								startTime: util.formatTime(auctionBreak.startTime),
								endTime: util.formatTime(auctionBreak.endTime),
								duration: util.formatDurationWithText(auctionBreak.endTime - auctionBreak.startTime),
								number: breakNum,
							},
						],
						showlegend: false,
						line: {
							color: "rgba(241,173,0,0.9)",
							dash: "dot",
							width: 2,
						},
						hovertemplate:
							"<b>Break #%{customdata.number}</b><br>" +
							"Start Time: %{customdata.startTime} <br>" +
							"End Time: %{customdata.endTime} <br>" +
							"Duration: %{customdata.duration} <br>" +
							"<extra></extra>",
					});

					breakInLegend = true;
					breakNum += 1;
				});
		}

		// Add strike price to the chart
		if (this.state.viewStrikePrice) {
			var xStrike = [];
			var yStrike = [];
			var strikePrice = null;
			history
				.sort((a, b) => a.time - b.time)
				.forEach((bid) => {
					if (strikePrice == null || bid.amount < strikePrice) {
						strikePrice = bid.amount;
						xStrike.push(bid.time);
						yStrike.push(bid.amount);
					}
				});
			xStrike.push(xUpper);
			yStrike.push(strikePrice);

			chartData.push({
				x: xStrike,
				y: yStrike,
				fill: "tozeroy",
				type: "scatter",
				mode: "line",
				name: "Strike Price",
				fillcolor: "rgba(0,0,0,0.2)",
				line: {
					shape: "hv",
					color: "black",
					width: 3,
				},
			});
		}

		// Add the MCP line to the chart
		chartData.push({
			x: [xLower, xUpper],
			y: [auction.minimumContractPrice, auction.minimumContractPrice],
			mode: "lines",
			name: "MCP",
			marker: {
				color: "red",
			},
			line: {
				dash: "dot",
				width: 2,
			},
		});

		var xScatter = [];
		var yScatter = [];
		var tScatter = [];
		var customData = [];

		var bidNumber = 1;
		for (var i = 0; i < userBids.length; i++) {
			var bid = userBids[i];

			if (i < userBids.length - 1) {
				chartData.push({
					x: [bid.time, userBids[i + 1].time],
					y: [bid.amount, bid.amount],
					mode: "lines",
					name: user.display_name,
					showlegend: false,
					line: {
						color: this.props.colors[user.id],
						width: 2,
					},
					hoverinfo: "skip",
				});

				chartData.push({
					x: [userBids[i + 1].time, userBids[i + 1].time],
					y: [bid.amount, userBids[i + 1].amount],
					mode: "lines",
					name: user.display_name,
					showlegend: false,
					line: {
						color: this.props.colors[user.id],
						dash: "dot",
						width: 2,
					},
					hoverinfo: "skip",
				});
			} else if (i == userBids.length - 1) {
				chartData.push({
					x: [bid.time, xUpper],
					y: [bid.amount, bid.amount],
					mode: "lines",
					name: user.display_name,
					showlegend: false,
					line: {
						color: this.props.colors[user.id],
						width: 2,
					},
					hoverinfo: "skip",
				});
			}

			xScatter.push(bid.time);
			yScatter.push(bid.amount);
			tScatter.push(`${user.display_name}'s Bid #${bidNumber}`);
			customData.push({
				decrement: util.formatCurrencyWithText(bid.decrement),
				winner: bid.winner == 1 ? "Yes" : "No",
				increasedDuration: bid.increasedDuration == 1 ? "Yes" : "No",
				time: util.formatTime(bid.time),
				amount: util.formatCurrencyWithText(bid.amount),
				timeSinceStart: util.formatDuration(bid.time - auction.startTime),
				rankOnSubmission: bid.rankOnSubmission,
			});
			bidNumber += 1;
		}

		chartData.push({
			x: xScatter,
			y: yScatter,
			customdata: customData,
			mode: "markers",
			name: user.display_name,
			marker: {
				color: this.props.colors[user.id],
				size: 10,
			},
			text: tScatter,
			hovertemplate:
				"<b>%{text}</b><br>" +
				"Bid Amount: %{customdata.amount}<br>" +
				"Bid Time: %{customdata.time}<br>" +
				"Bid Decrement: %{customdata.decrement}<br>" +
				"Rank on Submission: %{customdata.rankOnSubmission}<br>" +
				"Time Since Start: %{customdata.timeSinceStart}<br>" +
				"Increased Duration?: %{customdata.increasedDuration}<br>" +
				"Winning Bid?: %{customdata.winner}" +
				"<extra></extra>",
		});

		const ticks = this.getTicks(xLower, xUpper, 5);
		const formattedTicks = ticks.map((tick) => util.formatTime(tick));

		// Setup the layout for the chart
		const layout = {
			title: `${user.display_name}'s Bids`,
			autosize: true,
			yaxis: {
				range: [yLower, yUpper],
				title: "NOK øre/kWh",
				showgrid: false,
				zeroline: true,
				showline: true,
			},
			xaxis: {
				range: [xLower, xUpper],
				title: "Time",
				showgrid: false,
				zeroline: true,
				showline: true,
				tickvals: ticks,
				ticktext: formattedTicks,
			},
			hoverlabel: { bgcolor: "#FFF" },
		};

		return (
			<div className="card" style={{ width: "100%", height: "70vh", margin: "auto", padding: "0" }}>
				<div className="card-body">
					<Plot
						data={chartData}
						style={{ width: "100%", height: "100%" }}
						layout={layout}
						config={{
							responsive: true,
							modeBarButtonsToRemove: [
								"lasso2d",
								"select2d",
								"zoomIn",
								"zoomOut",
								"zoom",
								"pan",
								"autoscale",
							],
							displaylogo: false,
							displayModeBar: true,
							modeBarButtonsToAdd: [
								{
									name: `View the strike price`,
									icon: this.viewStrikePriceIcon,
									click: this.onViewStrikePriceChange.bind(this),
								},
							],
						}}
					/>
				</div>
			</div>
		);
	}

	renderBidPricesGraph() {
		const user = this.props.user;
		const userBids = this.props.history
			.filter((bid) => bid.userId == this.props.user.id)
			.sort((a, b) => a.time - b.time);

		if (userBids.length > 0) {
			var chartData = [];

			var pricesX = [];
			var pricesY = [];
			var pricesCustom = [];

			var decrementX = [];
			var decrementY = [];
			var decrementCustom = [];

			var yUpper = 0;

			var bidNumber = 1;
			userBids.forEach((bid) => {
				pricesX.push(`Bid #${bidNumber}`);
				pricesY.push(bid.amount);
				pricesCustom.push({
					bidder: user.display_name,
					number: bidNumber,
					price: util.formatCurrencyWithText(bid.amount),
					decrement: bid.decrement ? util.formatCurrencyWithText(bid.decrement) : "NA",
					time: util.formatTime(bid.time),
				});

				decrementX.push(`Bid #${bidNumber}`);
				decrementY.push(bid.decrement);
				decrementCustom.push({
					bidder: user.display_name,
					number: bidNumber,
					price: util.formatCurrencyWithText(bid.amount),
					decrement: bid.decrement ? util.formatCurrencyWithText(bid.decrement) : "NA",
					time: util.formatTime(bid.time),
				});

				yUpper = bid.amount > yUpper ? bid.amount : yUpper;
				bidNumber += 1;
			});

			chartData.push({
				name: "Bid Price",
				x: pricesX,
				y: pricesY,
				customdata: pricesCustom,
				type: "bar",
				hovertemplate:
					"<b>%{customdata.bidder}'s Bid #%{customdata.number}</b><br>" +
					"Bid Price: <b>%{customdata.price}</b><br>" +
					"Bid Decrement: <b>%{customdata.decrement}</b><br>" +
					"Time: %{customdata.time}<br>" +
					"<extra></extra>",
			});

			chartData.push({
				name: "Bid Decrement",
				x: decrementX,
				y: decrementY,
				customdata: decrementCustom,
				type: "bar",
				hovertemplate:
					"<b>%{customdata.bidder}'s Bid #%{customdata.number}</b><br>" +
					"Bid Price: <b>%{customdata.price}</b><br>" +
					"Bid Decrement: <b>%{customdata.decrement}</b><br>" +
					"Time: %{customdata.time}<br>" +
					"<extra></extra>",
			});

			return (
				<Plot
					data={chartData}
					style={{ width: "95%", height: "100%" }}
					layout={{
						title: "Bid Prices and Decrements",
						autosize: true,
						barmode: "group",
						showlegend: true,
						yaxis: {
							range: [0, yUpper + 1.05],
							title: "NOK øre/kWh",
							showgrid: true,
							zeroline: true,
							showline: true,
							uirevision: "time",
						},

						xaxis: {
							zeroline: true,
							showline: true,
							uirevision: "time",
						},
						hoverlabel: { bgcolor: "#FFF" },
					}}
					config={{
						responsive: true,
						modeBarButtonsToRemove: [
							"lasso2d",
							"select2d",
							"zoomIn",
							"zoomOut",
							"zoom",
							"pan",
							"autoscale",
						],
						displaylogo: false,
						displayModeBar: true,
					}}
				/>
			);
		}
		return <p>No bids have been placed yet</p>;
	}

	renderBidsTable() {
		const auction = this.props.auction;
		const history = this.props.history;
		const user = this.props.user;

		const sortedUserBids = history.filter((bid) => bid.userId === user.id).sort((a, b) => b.time - a.time);

		return (
			<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
				<thead>
					<th style={{ width: "15%" }}>Bid Time</th>
					<th style={{ width: "25%" }}>Bid Amount</th>
					<th style={{ width: "25%" }}>Bid Decrement</th>
					{/* <th style={{ width: "10%" }}>Rank</th> */}
					<th style={{ width: "15%" }}>
						Rank <em style={{ color: "gray" }}>(on submission)</em>
					</th>
					<th style={{ width: "10%" }}>Time Since Start</th>
					<th style={{ width: "10%" }}>Extended?</th>
				</thead>
				<tbody>
					{sortedUserBids.map(
						({
							id,
							amount,
							decrement,
							canUndo,
							winner,
							increasedDuration,
							time,
							rank,
							rankOnSubmission,
						}) => (
							<tr key={id} className={winner ? "table-success" : canUndo ? "table-active" : ""}>
								<td>{util.formatTime(time)}</td>
								<td>{util.formatCurrencyWithText(amount)}</td>
								<td>{util.formatCurrencyWithText(decrement)}</td>
								<td>
									{rank ? <strong>{rank}</strong> : "-"}{" "}
									<em style={{ color: "gray" }}>
										{rankOnSubmission ? `(${rankOnSubmission})` : "(-)"}
									</em>
								</td>
								<td>{util.formatDuration(time - auction.startTime)}</td>
								<td>{increasedDuration ? "Yes" : "-"}</td>
							</tr>
						)
					)}
				</tbody>
			</table>
		);
	}

	getNoBidsCard() {
		const { display_name } = this.props.user;

		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>{display_name}'s Auction Summary</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row" style={{ textAlign: "center" }}>
					<p>{display_name} did not make any bids during the auction</p>
				</div>
				<br />
			</>
		);
	}

	render() {
		const userBids = this.props.history.filter((bid) => bid.userId == this.props.user.id);

		if (userBids.length == 0) {
			return this.getNoBidsCard();
		}

		const { display_name } = this.props.user;

		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>{display_name}'s Auction Summary</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderStatistics()}
				</div>
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderAuctionGraph()}
				</div>
				<br />
				{/* <div className="row" style={{ alignItems: "center" }}>
					<div className="card" style={{ width: "100%", margin: "auto", padding: "0" }}>
						<div className="card-body">{this.renderBidPricesGraph()}</div>
					</div>
				</div> */}
				<br />
				<div className="row" style={{ alignItems: "center" }}>
					{this.renderBidsTable()}
				</div>
				<br />
			</>
		);
	}
}

UserDashboard.propTypes = {
	history: PropTypes.object.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	colors: PropTypes.object.isRequired,
};

export default UserDashboard;
