import authHeaders from "../helpers/authHeaders";
import axiosConn from "../helpers/axios";
import {
	GET_AUCTIONS,
	GET_AUCTION,
	CREATE_AUCTION,
	UPDATE_AUCTION,
	GET_RESULT,
	AUCTION_LOADING,
	GET_PRICES,
	GET_MPSB_ALL_BIDS,
	GET_MPSB_USER_BIDS,
	GET_MPSB_ALL_BIDS_FOR_USERS,
	GET_ROUND,
	START_AUCTION,
	END_ROUND,
	END_AUCTION,
	USER_BIDS_REMOVAL_MPSB,
	NEXT_ROUND,
	MAKE_BID,
	ERROR,
	GET_HISTORY,
	GET_PRICE_DEMAND_HISTORY,
	STOP_AUCTION_LOADING,
	RESET_AUCTION,
	DETERMINE_WINNERS,
	GET_WINNING_BIDS,
	GET_BIDDER_PROGRESS,
	SIMULATE_ROUND,
	GET_SIMULATION_DATA,
	GET_STATE,
	RESTORE_STATE,
	GET_BID_CREDITS,
	UNDO_USER_ROUND_BIDS,
	SIMULATE_MPSB_BIDS,
	GET_RENO_USER_BIDS,
	AUCTION_NO_CHANGE,
} from "./types";

import {
	emitEndRound,
	emitNextRound,
	emitDetermineWinners,
	emitMakeBids,
	emitMakeProxyBid,
	emitSimulateAuction,
	emitDeleteBids,
	emitStartAuction,
	emitEndAuction,
} from "../helpers/emit/auction";
import { auctionTypes } from "../components/common/constants";

export const getAuctions = () => async (dispatch) => {
	try {
		const resReady = await axiosConn.get("/auction", {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_AUCTIONS, payload: res.data.auctions });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getAuction = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_AUCTION, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const createAuction = (auctionData) => async (dispatch) => {
	dispatch(setAuctionsLoading());
	try {
		const resReady = await axiosConn.post("/auction/create", auctionData, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: CREATE_AUCTION });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const startAuction = (id, payload, type) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/${id}/start`, payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		// await emitStartAuction({ auction_id: id, type: type });
		dispatch({
			type: START_AUCTION,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const updateAuction = (id, payload) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/${id}/update`, payload, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: UPDATE_AUCTION,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const endRound = (id, round) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/round/${id}/end`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitEndRound({ auction_id: id, round: round });
		dispatch({
			type: END_ROUND,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const endAuction = (id, type) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/${id}/end`, {}, { headers: authHeaders() });
		const res = await resReady;
		// await emitEndAuction({ auction_id: id });
		dispatch({
			type: END_AUCTION,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const removeUserBidMPSBAuction = (id, userId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/${userId}/undo`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitDeleteBids({ auctionId: id, userId: userId });
		dispatch({
			type: USER_BIDS_REMOVAL_MPSB,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const nextRound = (id, req_json, emit_json, type) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/round/${id}/next`, req_json, { headers: authHeaders() });
		const res = await resReady;
		await emitNextRound({ auction_id: id, ...emit_json, type: type });
		dispatch({
			type: NEXT_ROUND,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const determineWinners = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/${id}/winner-determination/`, {}, { headers: authHeaders() });
		const res = await resReady;
		// await emitDetermineWinners({ auction_id: id });
		dispatch({
			type: DETERMINE_WINNERS,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const startAuctionBreak = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/breaks/${id}/start`, {}, { headers: authHeaders() });
		const res = await resReady;
		dispatch({
			type: AUCTION_NO_CHANGE,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const endAuctionBreak = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/breaks/${id}/end`, {}, { headers: authHeaders() });
		const res = await resReady;
		dispatch({
			type: AUCTION_NO_CHANGE,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const triggerRefresh = (auctionId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/${auctionId}/trigger-refresh`, {}, { headers: authHeaders() });
		const res = await resReady;
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const requestAssistance = (auctionId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(
			`/auction/${auctionId}/request-assisstance`,
			{},
			{ headers: authHeaders() }
		);
		const res = await resReady;
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeAnnouncement = (auctionId, announcementText) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(
			`/auction/${auctionId}/make-announcement`,
			{ text: announcementText },
			{ headers: authHeaders() }
		);
		const res = await resReady;
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const randomizeWinners = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(
			`/auction/${id}/winner-determination/randomize`,
			{},
			{ headers: authHeaders() }
		);
		const res = await resReady;
		// await emitDetermineWinners({ auction_id: id });
		dispatch({
			type: DETERMINE_WINNERS,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getWinningBids = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/${id}/winning-bids`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_WINNING_BIDS,
			payload: res.data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getRound = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/round/${id}/status`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_ROUND, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getResults = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/${id}/results`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_RESULT, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getHistory = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${id}/all`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_HISTORY, payload: res.data.history });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getPriceDemandHistory = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/${id}/price-demand-history`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_PRICE_DEMAND_HISTORY, payload: res.data.price_demand_history });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getPrices = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/${id}/prices`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_PRICES, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getAllMPSBBids = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${id}/all`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_MPSB_ALL_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getAllMPSBBidsForUsers = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${id}/all`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_MPSB_ALL_BIDS_FOR_USERS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getUserMPSBBid = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${id}/user`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_MPSB_USER_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getUserRENOBids = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/bid/${id}/user`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({ type: GET_RENO_USER_BIDS, payload: res.data });
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeBids = (id, bid_json, emit_json) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/make`, bid_json, {
			headers: authHeaders(),
		});
		const res = await resReady;
		await emitMakeBids({ auction_id: id, ...emit_json, type: "SMRA" });
		dispatch({
			type: MAKE_BID,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeMPSBBids = (id, bid_json, emit_json) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/make`, bid_json, {
			headers: authHeaders(),
		});
		const res = await resReady;
		await emitMakeBids({ auction_id: id, ...emit_json, type: "MPSB" });
		dispatch({
			type: MAKE_BID,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeENOOBids = (id, bid_json, emit_json) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/make`, bid_json, {
			headers: authHeaders(),
		});
		const res = await resReady;
		await emitMakeBids({ auction_id: id, ...emit_json, type: "ENOO" });
		dispatch({
			type: MAKE_BID,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeENOOProxyBid = (id, bid_json, emit_json) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/make`, bid_json, {
			headers: authHeaders(),
		});
		const res = await resReady;
		await emitMakeProxyBid({ auction_id: id, ...emit_json, type: "ENOO" });
		dispatch({
			type: MAKE_BID,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const makeRENOBid = (id, data) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`/auction/bid/${id}/make`, data, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: MAKE_BID,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const simulateMpsbBids = (id, user_ids) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`auction/bid-bots/${id}/run`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitSimulateAuction({ auction_id: id, user_ids });
		dispatch({
			type: SIMULATE_MPSB_BIDS,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getSimulationData = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`auction/bid-bots/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_SIMULATION_DATA,
			payload: res.data.simulation_data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getMPSBSimulationData = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`auction/bid-bots/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_SIMULATION_DATA,
			payload: res.data.simulation_data,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const simulateRound = (id, user_ids) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`auction/bid-bots/${id}/run`, {}, { headers: authHeaders() });
		const res = await resReady;
		await emitSimulateAuction({ auction_id: id, user_ids });
		dispatch({
			type: SIMULATE_ROUND,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getBidderProgress = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`auction/bid/${id}/progress`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_BIDDER_PROGRESS,
			payload: res.data.bidder_progress,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getBidCredits = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`auction/${id}/bid-credits`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_BID_CREDITS,
			payload: res.data.bid_credits,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const getSaveState = (id) => async (dispatch) => {
	try {
		const resReady = await axiosConn.get(`/auction/state/${id}`, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: GET_STATE,
			payload: res.data,
		});
		return {
			payload: res.data,
		};
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const restoreState = (id, json) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(`auction/state/${id}/restore`, json, {
			headers: authHeaders(),
		});
		const res = await resReady;
		dispatch({
			type: RESTORE_STATE,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const postUndoUserRoundBids = (auctionId, userId) => async (dispatch) => {
	try {
		const resReady = await axiosConn.post(
			`auction/bid/${auctionId}/${userId}/undo`,
			{},
			{ headers: authHeaders() }
		);
		const res = await resReady;
		dispatch({
			type: UNDO_USER_ROUND_BIDS,
		});
	} catch (err) {
		dispatch(errorHandler(err));
	}
};

export const resetAuction = () => {
	return {
		type: RESET_AUCTION,
	};
};

export const setAuctionsLoading = () => {
	return {
		type: AUCTION_LOADING,
	};
};

export const setAuctionsNotLoading = () => {
	return {
		type: STOP_AUCTION_LOADING,
	};
};

export const errorHandler = (err) => {
	return {
		type: ERROR,
		payload: err,
	};
};
