import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Timer } from "../../GeneralAuction/Components/Timer";
import { confirm } from "react-confirm-box";
import { useParams } from "react-router-dom";
import {
	setAuctionsLoading,
	getAuction,
	setAuctionsNotLoading,
	getHistory,
	getRound,
	makeENOOBids,
} from "../../../../actions/auctionActions";
import { ENOOAdminPanel } from "../Components/ENOOAdminPanel";
import { RAInput } from "../../../common/RAInput/RAInput";
import { addNotification } from "../../../common/NotificationManager";
import socket from "../../../../helpers/socket";
import { joinAuctionRoom } from "../../../../helpers/emit/auction";

class ENOOAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price: 0,
			increment: 0,
			round_duration: 0,
			user_increment: 1.0, //for users making their own bids
			user_bid_price: 0.0,
		};
	}

	componentDidMount() {
		this.componentRefresh();
		this.joinSocketRoom(this.props.params.auctionId, this.props.user.user);
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getHistory(this.props.params.auctionId);
		await this.props.getRound(this.props.params.auctionId);

		const { auction, round } = this.props.auction;
		const newBidPrice = round.price + 1.0;
		const newIncrement = 1.0;

		this.setState({
			...this.state,
			increment: auction.increment,
			round_duration: auction.round_duration,
			user_increment: newIncrement,
			user_bid_price: newBidPrice,
		});
	}

	async componentRefresh() {
		await this.props.setAuctionsLoading();
		await this.fetchData();
		await this.props.setAuctionsNotLoading();
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && this.props.user.user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async makeBid() {
		// Check if the timer for the round has elapsed
		const { round } = this.props.auction;
		const roundHasEnded = round.end_time - Math.floor(new Date().getTime() / 1000.0) <= 0;

		if (roundHasEnded) {
			addNotification({
				title: "Cannot submit bid!",
				message: "The bid cannot be submitted as the time for the round has elapsed",
				type: "danger",
				duration: 10000,
			});
			await this.fetchData();
			return;
		}

		const req_json = {
			price: this.props.auction.round.price + parseInt(this.state.user_increment),
		};

		const emit_json = {
			display_name: this.props.user.user.display_name,
			amount: this.props.auction.round.price + parseInt(this.state.user_increment),
		};

		const result = await confirm("Bid Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "35vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<div>{this.getSubmitButtonText()}</div>
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.makeENOOBids(this.props.auction.auction.id, req_json, emit_json);
			await this.fetchData();
			await this.componentRefresh();
			return;
		}
	}

	onIncrementChange(event) {
		const { round } = this.props.auction;
		let newIncrement = parseFloat(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newPrice = round.price + newIncrement;

		if (newIncrement <= 0) {
			newIncrement = 1;
			newPrice = round.price + 1;
		}

		this.setState({ ...this.state, user_increment: newIncrement, user_bid_price: newPrice });
	}

	onPriceChange(event) {
		const { round } = this.props.auction;
		let newPrice = parseFloat(event.target.value.replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10);
		let newIncrement = newPrice - round.price;

		if (newPrice <= round.price) {
			newIncrement = 1;
			newPrice = round.price + 1;
		}

		this.setState({ ...this.state, user_increment: newIncrement, user_bid_price: newPrice });
	}

	getSubmitButtonText() {
		let submitEnabled = true;

		if (this.state.user_increment <= 0) {
			submitEnabled = false;
			return (
				<div>
					<p>
						<em>Your bid price cannot be 0 </em>
					</p>
				</div>
			);
		}

		if (submitEnabled) {
			return (
				<p>
					<em>
						Submitting the bid with the price of{" "}
						{"$" + (this.props.auction.round.price + parseInt(this.state.user_increment)).toLocaleString()}
					</em>
				</p>
			);
		}
	}

	getStatusStyle(status) {
		if (status == "In Progress") {
			return { color: "green" };
		} else if (status == "Ended") {
			return { color: "red" };
		} else if (status == "Awaiting Start") {
			return { color: "blue" };
		} else {
			return {};
		}
	}

	render() {
		const { auction, auction_loading, round, history } = this.props.auction;
		const { user } = this.props.user;

		const userIncrement = this.state.user_increment;
		const userBidPrice = this.state.user_bid_price;

		const enableSubmission =
			parseFloat(userIncrement.toString().replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10) > 0 &&
			parseFloat(userBidPrice.toString().replace(/,/g, ``).replace(`$`, ``).replace(`$`, ``), 10) > round.price &&
			auction.status == "In Progress" &&
			round.end_time - Math.floor(new Date().getTime() / 1000.0) > 0;

		if (auction_loading) {
			return this.returnSpinner;
		}

		// Sockets
		socket.off("auction_refresh").on("auction_refresh", (data) => {
			this.componentRefresh();
			if (data.message) {
				addNotification({
					title: data.title,
					message: data.message,
					type: "info",
					duration: 7000,
				});
			}
		});
		socket.off("join_auction").on("join_auction", (msg) => {
			addNotification({
				title: "User Joined",
				message: msg,
				duration: 3000,
			});
		});
		socket.off("auction_assistance").on("auction_assistance", (msg) => {
			addNotification({
				title: "Assistance Requested",
				message: msg,
				type: "warning",
			});
		});
		socket.off("auction_reminder").on("auction_reminder", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 10000,
			});
		});
		socket.off("auction_announcement").on("auction_announcement", (data) => {
			addNotification({
				title: data.title,
				message: data.message,
				type: "default",
				duration: 10000,
			});
		});
		socket.off("config_update").on("config_update", (data) => {
			this.componentRefresh();
		});

		return (
			<div className="container">
				<h3>{auction.name} Auction </h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/auctions">Auctions</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name}
							{auction.status === "In Progress" ? (
								<span className="badge bg-success" style={{ marginLeft: "10px" }}>
									In Progress
								</span>
							) : auction.status === "Ended" ? (
								<span className="badge bg-danger" style={{ marginLeft: "10px" }}>
									Ended
								</span>
							) : (
								<></>
							)}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button
							type="button"
							className="btn btn-outline-primary btn-sm"
							onClick={() => this.componentRefresh()}
						>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
				</div>
				<br />
				{user.type === "admin" && (
					<>
						<ENOOAdminPanel refresh={() => this.fetchData()} />
						<hr />
					</>
				)}

				<div className="row" style={{ alignItems: "center" }}>
					<div
						className="card"
						style={{ textAlign: "center", width: "78%", alignItems: "center", margin: "auto" }}
					>
						<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
							{this.props.auction.auction.description}
						</div>
					</div>
				</div>
				<br />

				<div className="container">
					<div className="row">
						<div className="col-6">
							<div className="card">
								<div className="card-header" style={{ textAlign: "center" }}>
									{user?.display_name == history?.at(-1)?.display_name ? (
										<h5> You are the highest bidder </h5>
									) : (
										<h5> Highest Bidder</h5>
									)}
								</div>

								{history && history.length > 0 ? (
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												Price: <b> {currencyFormat(history.at(-1)?.price)} </b>{" "}
											</p>
										</li>
										<li className="list-group-item">
											<p>
												Bidder: <b> {history.at(-1)?.display_name} </b>
											</p>
										</li>
										<li className="list-group-item">
											<p>
												Time:{" "}
												<b>
													{" "}
													{round.status &&
														new Date(history.at(-1)?.time * 1000).toLocaleTimeString(
															"en-US",
															{
																timeZone: "America/Los_Angeles",
															}
														)}{" "}
												</b>
											</p>
										</li>
									</ul>
								) : (
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											{" "}
											<p> No bids have been placed yet </p>{" "}
										</li>
									</ul>
								)}
							</div>
						</div>

						<div className="col-6">
							<div className="card">
								<div className="card-header" style={{ textAlign: "center" }}>
									{auction.status === "Ended" ? <h5> Results</h5> : <h5> Place a Bid</h5>}
								</div>

								{auction.status === "In Progress" ? (
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												<Timer endTime={round.end_time} status={round.status} highlight={30} />{" "}
											</p>
										</li>
										<li className="list-group-item">
											<table>
												<tbody>
													<tr>
														<td style={{ paddingTop: "1em" }}>
															{round.price ? (
																<RAInput
																	id="inputEnooIncrements"
																	name="inputEnooIncrements"
																	value={"$" + round.price.toLocaleString()}
																	onChange={() => {}}
																	label=""
																/> //this shows up only if the round has a price (ie if auction started)
															) : (
																<div>
																	<RAInput
																		id="inputEnooIncrements"
																		name="inputEnooIncrements"
																		value={"$ round price "}
																		onChange={() => {}}
																		label=""
																	/>
																</div>
															)}
														</td>
														<td>
															<input
																className="form-control"
																type="text"
																value={"+"}
																disabled
																style={{
																	width: "2.5em",
																	height: "2.5em",
																	textAlign: "center",
																}}
															></input>
														</td>
														<td style={{ paddingTop: "1em" }}>
															<RAInput
																id="inputEnooIncrements"
																name="inputEnooIncrements"
																value={currencyFormat(this.state.user_increment)}
																onChange={this.onIncrementChange.bind(this)}
																label=""
																placeholder={"$ Add price"}
															/>
														</td>
														<td>
															<input
																className="form-control"
																type="text"
																value={"="}
																disabled
																style={{
																	width: "2.5em",
																	height: "2.5em",
																	textAlign: "center",
																}}
															></input>
														</td>
														<td style={{ paddingTop: "1em" }}>
															<RAInput
																id="inputEnooIncrements"
																name="inputEnooIncrements"
																placeholder={"$ final bid price"}
																value={currencyFormat(this.state.user_bid_price)}
																onChange={this.onPriceChange.bind(this)}
																label=""
															/>
														</td>{" "}
														<td>
															<button
																type="button"
																className="btn btn-primary"
																disabled={!enableSubmission}
																onClick={() => this.makeBid()}
																name="placeBid"
																style={{ float: "right", marginLeft: "1em" }}
															>
																Place Bid
															</button>
														</td>
													</tr>
													<tr>
														<td colSpan="4"> {this.getSubmitButtonText()} </td>
													</tr>
												</tbody>
											</table>
										</li>
									</ul>
								) : (
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<p>
												<b>
													{" "}
													The auction has{" "}
													{auction.status === "Awaiting Start"
														? "not started yet"
														: "ended"}.{" "}
												</b>{" "}
											</p>
										</li>
										<li className="list-group-item">
											<p>
												{history &&
												history.length > 0 &&
												auction.status === "Ended" &&
												history.at(-1).winner &&
												"table-success"
													? `Winner: ${history.at(-1).display_name}`
													: auction.status === "Ended"
													? "Winners will be announced soon."
													: ""}
											</p>

											<p>
												{auction.status === "Awaiting Start"
													? "Please wait for the auction to start to place a bid."
													: ""}{" "}
											</p>
										</li>
									</ul>
								)}
							</div>
						</div>
					</div>
				</div>

				<br />

				<div className="table-responseive-sm">
					<table className="table table-sm table-bordered">
						<thead
							style={{
								alignItems: "center",
								textAlign: "center",
							}}
						>
							<tr>
								<th colSpan={4}>Bid History</th>
							</tr>
							<tr>
								<th width="10%">Bid</th>
								<th width="30%">User Display Name</th>
								<th width="20%">Price</th>
								<th>Time</th>
							</tr>
						</thead>
						<tbody
							style={{
								alignItems: "center",
								textAlign: "center",
							}}
						>
							{this.props.auction.history &&
								this.props.auction.history.map((bid, index) => {
									return (
										<tr className={history.at(-index - 1).winner && "table-success"}>
											<td> {history.at(-index - 1).id}</td>
											<td> {history.at(-index - 1).display_name}</td>
											<td> {currencyFormat(history.at(-index - 1).price)}</td>
											<td>
												{" "}
												{new Date(history.at(-index - 1).time * 1000).toLocaleTimeString(
													"en-US",
													{
														timeZone: "America/Los_Angeles",
													}
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>

				<br />

				<br />
			</div>
		);
	}
}

export function currencyFormat(num) {
	if (isNaN(num)) {
		return "$0";
	}
	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

function WithParams(props) {
	let params = useParams();
	return <ENOOAuction {...props} params={params} />;
}

ENOOAuction.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	getAuction: PropTypes.func.isRequired,
	getHistory: PropTypes.func.isRequired,
	getRound: PropTypes.func.isRequired,
	makeENOOBids: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, {
	setAuctionsLoading,
	getAuction,
	setAuctionsNotLoading,
	getHistory,
	getRound,
	makeENOOBids,
})(WithParams);
