import React, { Component } from "react";
import PropTypes from "prop-types";
import { confirm } from "react-confirm-box";

class CommandCentre extends Component {
	constructor(props) {
		super(props);
	}

	async handleRefresh() {
		await this.props.refresh();
	}

	getUserRoundBids(round_history, username) {
		return round_history.filter((rh) => rh.username == username);
	}

	getUndoUserRoundBidsDisabled(round_history, username) {
		const { round } = this.props.auction;
		return (
			this.getUserRoundBids(round_history, username).filter((urb) => {
				return urb.carried == 0;
			}).length == 0 && round.status !== "In Progress"
		);
	}

	getUserBidInformation(round_history, username) {
		const { history } = this.props.auction;

		let return_data = [];

		this.getUserRoundBids(round_history, username).forEach((uh) => {
			let bid_data = "";
			if (uh.carried == 1) bid_data += "Frozen ";
			if (uh.combined == 1) bid_data += "Combined ";
			if (uh.exit == 0) {
				bid_data += "Live Bid on ";
			} else {
				bid_data += "Exit Bid on ";
			}

			bid_data += `${uh.name} for ${currencyFormat(uh.amount)}`;
			return_data.push(bid_data);
		});

		return return_data.join(", ");
	}

	async undoUserRoundBids(id, username, display_name) {
		const { auction } = this.props.auction;

		const result = await confirm(`Undo ${display_name} (${username})'s Round ${auction.round} Bids`, {
			render: (message, onConfirm, onCancel) => {
				return (
					<div
						className="card"
						style={{
							width: "25vw",
							margin: "auto",
							alignItems: "center",
						}}
					>
						<div
							className="card-body"
							style={{
								alignItems: "center",
								textAlign: "center",
							}}
						>
							<h6 style={{ padding: "10px" }}> {message} </h6>
							<hr />
							<p>Would you like to start the auction with the following information?</p>

							<div>
								<button
									type="button"
									className="btn btn-outline-success"
									onClick={onConfirm}
									style={{ margin: "10px" }}
								>
									{" "}
									Yes{" "}
								</button>
								<button
									type="button"
									className="btn btn-outline-danger"
									onClick={onCancel}
									style={{ margin: "10px" }}
								>
									No
								</button>
							</div>
						</div>
					</div>
				);
			},
		});

		if (result) {
			await this.props.postUndoUserRoundBids(auction.id, id);
			await this.handleRefresh();
		}
	}

	render() {
		const { auction, history } = this.props.auction;
		const { user, users } = this.props.user;

		let round_history = [];
		if (history && auction && auction.round) {
			round_history = history.filter((h) => {
				return h.round == auction.round;
			});
		}

		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Command Centre</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />

				<div className="card" style={{ width: "90%", margin: "auto" }}>
					<div className="card-body">
						<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
							<thead>
								<tr>
									<th>User</th>
									<th>Round {auction.round} Bid(s)</th>
									<th>Simulate</th>
									<th>Undo Round {auction.round} Bid(s)</th>
								</tr>
							</thead>
							<tbody>
								{users.length !== 0 ? (
									users.map(({ id, username, display_name }) => (
										<tr key={id}>
											<td>
												{display_name} ({username})
											</td>
											<td>{this.getUserBidInformation(round_history, username)}</td>
											<td></td>
											<td>
												<button
													type="button"
													className="btn btn-outline-danger btn-sm"
													disabled={this.getUndoUserRoundBidsDisabled(
														round_history,
														username
													)}
													onClick={() => this.undoUserRoundBids(id, username, display_name)}
												>
													Undo Bid(s)
												</button>
											</td>
										</tr>
									))
								) : (
									<></>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}
}

function currencyFormat(num) {
	if (isNaN(num)) return "$0";

	return Intl.NumberFormat("en", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	}).format(num);
}

CommandCentre.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	postUndoUserRoundBids: PropTypes.func.isRequired,
	refresh: PropTypes.func.isRequired,
};

export default CommandCentre;
