import React, { Component } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import { COLORS } from "../../../common/constants";
import util from "../../../../util/RENO";

class BidConfigGraph extends Component {
	constructor(props) {
		super(props);
		this.orderOfBidders = ["Bidder 01", "Bidder 02", "Bidder 03", "Bidder 04"];
	}

	getGraph() {
		const { userMinBidAmount, outOfRangeFloor, outOfRangeCeiling, ownerUsername, competitorBidConfigs } =
			this.props;

		let chartData = [];
		let userChartData = [];
		let priceAnnotations = [];

		console.log(competitorBidConfigs);

		let annotationIndex = 0;
		// Add user data to the chart
		chartData.push({
			type: "box",
			y: [userMinBidAmount],
			x: ownerUsername,
			name: ownerUsername,
			hoverinfo: "none",
			marker: {
				color: "gray",
			},
			line: {
				width: 5,
			},
		});

		if (userMinBidAmount) {
			priceAnnotations.push({
				y: userMinBidAmount,
				x: annotationIndex,
				xref: "x",
				yref: "y",
				text: userMinBidAmount.toFixed(3),
				ax: 0,
				ay: 10,
			});
		}

		annotationIndex++;

		this.orderOfBidders.forEach((username) => {
			if (username in competitorBidConfigs) {
				let bid = competitorBidConfigs[username];

				chartData.push({
					type: "box",
					x: [username],
					q1: [bid.minAmount],
					q3: [bid.maxAmount],
					median: [parseFloat(((bid.maxAmount + bid.minAmount) / 2).toFixed(3))],
					name: username,
					showwhiskers: bid.aboveRangeBidProbability > 0 || bid.belowRangeBidProbability > 0,
					upperfence: [bid.aboveRangeBidProbability > 0 ? outOfRangeCeiling : null],
					lowerfence: [bid.belowRangeBidProbability > 0 ? outOfRangeFloor : null],
					hoverinfo: "none",
				});

				priceAnnotations.push({
					y: bid.maxAmount,
					x: annotationIndex,
					xref: "x",
					yref: "y",
					text: bid.maxAmount.toFixed(3),
					ax: 0,
					ay: -10,
				});

				if (bid.maxAmount !== bid.minAmount) {
					let avgAmount = parseFloat(((bid.maxAmount + bid.minAmount) / 2).toFixed(3));
					priceAnnotations.push({
						y: bid.minAmount.toFixed(3),
						x: annotationIndex,
						xref: "x",
						yref: "y",
						text: bid.minAmount.toFixed(3),
						ax: 0,
						ay: 10,
					});
					priceAnnotations.push({
						y: avgAmount,
						x: annotationIndex + 0.35,
						xref: "x",
						yref: "y",
						text: avgAmount,
						ax: 0,
						ay: 0,
					});
				}

				annotationIndex++;
			}
		});

		if (userMinBidAmount !== null) {
			userChartData.push({
				type: "line",
				xref: "paper",
				x0: 0.025,
				y0: userMinBidAmount,
				x1: 1,
				y1: userMinBidAmount,
				line: {
					color: "grey",
					width: 2,
					dash: "dash",
				},
				opacity: 0.8,
			});
		}

		if (outOfRangeFloor !== null) {
			userChartData.push({
				type: "line",
				xref: "paper",
				x0: 0.025,
				y0: outOfRangeFloor,
				x1: 1,
				y1: outOfRangeFloor,
				line: {
					color: "gray",
					width: 1,
					dash: "dot",
				},
				opacity: 0.4,
			});
		}

		if (outOfRangeCeiling !== null) {
			userChartData.push({
				type: "line",
				xref: "paper",
				x0: 0.025,
				y0: outOfRangeCeiling,
				x1: 1,
				y1: outOfRangeCeiling,
				line: {
					color: "gray",
					width: 1,
					dash: "dot",
				},
				opacity: 0.4,
			});
		}

		return (
			<Plot
				data={chartData}
				layout={{
					yaxis: {
						showticklabels: false,
						showgrid: false,
						fixedrange: true,
					},
					xaxis: { fixedrange: true },
					title: "Simulation Bid Price Ranges (in NOK øre/kWh)",
					showlegend: false,
					annotations: priceAnnotations,
					shapes: userChartData,
				}}
				style={{ width: "100%", height: "100%" }}
				useResizeHandler
				config={{
					responsive: true,
					modeBarButtonsToRemove: ["lasso2d", "select2d", "zoomIn", "zoomOut", "zoom", "pan", "autoscale"],
					displaylogo: false,
					displayModeBar: true,
				}}
			/>
		);
	}

	render() {
		return (
			<div className="card mb-3">
				<div className="row mb-3" style={{ height: "50vh" }}>
					<div className="col-12">{this.getGraph()}</div>
				</div>
			</div>
		);
	}
}

BidConfigGraph.propTypes = {
	ownerUsername: PropTypes.string,
	userMinBidAmount: PropTypes.number,
	outOfRangeFloor: PropTypes.number,
	outOfRangeCeiling: PropTypes.number,
	competitorBidConfig: PropTypes.object.isRequired,
};

export default BidConfigGraph;
