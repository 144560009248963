import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import {
	getSimulation,
	createSimulation,
	runSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
} from "../../actions/simulationActions";
import { BidPricesQuantitiesGraphs } from "../auctions/MPSBAuction/Components/MPSBGraphs";
import { RAInput } from "../common/RAInput/RAInput";

class CreateSimulation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			simulationType: "mpsb",
			name: "",
			asq_mw: 3000,
			asq_gwhy: 11826,
			initialRuns: 10000,
			user_bid_config: [
				{
					bid_id: 1,
					price: "",
					quantity: "",
				},
			],
			competitor_bid_config: {},
			competitor_bid_config_preset: "select",
		};
	}

	componentDidMount() {
		if (this.props.params.simulationId !== "0") {
			this.fetchData();
		} else {
			let competitor_bid_config = {
				["Corio"]: [
					{
						min_price: 75.9,
						max_price: 110.5,
						min_quantity: 414,
						max_quantity: 450,
						min_splits: 1,
						max_splits: 1,
					},
				],
				["ESB, JERA"]: [
					{
						min_price: 83.4,
						max_price: 123.6,
						min_quantity: 375,
						max_quantity: 380,
						min_splits: 1,
						max_splits: 1,
					},
				],
				["Statkraft, CIP"]: [
					{
						min_price: 76.1,
						max_price: 110.9,
						min_quantity: 414,
						max_quantity: 504,
						min_splits: 1,
						max_splits: 2,
					},
				],
				["RWE, Saorgus Energy"]: [
					{
						min_price: 76.2,
						max_price: 111.6,
						min_quantity: 810,
						max_quantity: 882,
						min_splits: 1,
						max_splits: 2,
					},
				],
				["EDF, Fred Olsen"]: [
					{
						min_price: 69.4,
						max_price: 101.2,
						min_quantity: 1440,
						max_quantity: 1449,
						min_splits: 1,
						max_splits: 3,
					},
				],
				["SSE"]: [
					{
						min_price: 75.9,
						max_price: 99.7,
						min_quantity: 798,
						max_quantity: 810,
						min_splits: 1,
						max_splits: 2,
					},
				],
			};

			delete competitor_bid_config[this.props.user.user.display_name];

			this.setState({ ...this.state, competitor_bid_config: competitor_bid_config });
		}
	}

	async fetchData() {
		await this.props.getSimulation(this.props.params.simulationId);

		let competitor_bid_config = {};

		this.props.simulation.simulation.sim_config
			?.filter((bid) => !bid.owner)
			.forEach((bid) => {
				if (!competitor_bid_config[bid.username]) {
					competitor_bid_config[bid.username] = [];
				}
				competitor_bid_config[bid.username].push({
					min_price: bid.min_price,
					max_price: bid.max_price,
					min_quantity: bid.min_quantity,
					max_quantity: bid.max_quantity,
					min_splits: bid.min_splits,
					max_splits: bid.max_splits,
					id: bid.id,
				});
			});

		let newUserConfig = [];
		let newinc = 1;
		this.props.simulation.simulation.sim_config
			?.filter((bid) => bid.owner)
			.forEach((user) => {
				newUserConfig.push({
					bid_id: newinc,
					price: user.min_price,
					quantity: user.min_quantity,
				});
				newinc++;
			});

		this.setState({
			...this.state,
			asq_mw: Math.round(this.props.simulation.simulation.asq),
			asq_gwhy: ((this.props.simulation.simulation.asq * 8760 * 0.45) / 1000).toFixed(2),
			initialRuns: this.props.simulation.simulation.runs,
			//competitor_bid_config: newCompConfig,
			competitor_bid_config: competitor_bid_config,
			user_bid_config: newUserConfig,
		});
	}

	async createMPSBSimulationEvent(event) {
		event.preventDefault();

		let user_bid_config = [];
		this.state.user_bid_config.forEach((bid) => {
			user_bid_config.push({
				min_price: bid.price,
				max_price: bid.price,
				min_quantity: bid.quantity,
				max_quantity: bid.quantity,
				min_splits: 1,
				max_splits: 1,
				owner: 1,
				username: this.props.user.user.display_name,
			});
		});

		let competitor_bid_config = [];
		for (const [competitor, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach((bid) => {
				competitor_bid_config.push({
					min_price: bid.min_price,
					max_price: bid.max_price,
					min_quantity: bid.min_quantity,
					max_quantity: bid.max_quantity,
					min_splits: bid.min_splits,
					max_splits: bid.max_splits,
					owner: 0,
					username: competitor,
				});
			});
		}

		let total_bid_config = [...competitor_bid_config, ...user_bid_config];

		await this.props.createSimulation({
			name: this.state.name,
			creator: this.props.user.user.display_name,
			type: 2,
			user_id: this.props.user.user.user_id,
			asq: parseFloat(this.state.asq_mw),
			user_bid_config: total_bid_config,
		});
		await this.props.setSimulationLoading();
		await this.props.runSimulation(this.props.simulation.simulationId, { runs: this.state.initialRuns });
		await this.props.setSimulationNotLoading();
		this.props.history.push(`/simulations/${this.props.simulation.simulationId}`);
	}

	onChange(event) {
		let value = event.target.value;
		if (event.target.name === "initialRuns" && value > 100000) {
			value = 100000;
		}
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	onASQmwChange(event) {
		const asq_gwhy = ((event.target.value * 8760 * 0.45) / 1000).toFixed(2);
		//if (asq_gwhy >= 0 && asq_gwhy <= 18000) {
		this.setState({
			...this.state,
			asq_mw: event.target.value,
			asq_gwhy: asq_gwhy,
		});
		//}
	}

	onASQgwhyChange(event) {
		const asq_gwhy = event.target.value;
		//if (asq_gwhy >= 0 && asq_gwhy <= 18000) {
		this.setState({
			...this.state,
			asq_mw: ((event.target.value * 1000) / (8760 * 0.45)).toFixed(2),
			asq_gwhy: asq_gwhy,
		});
		//}
	}

	onMainDropDownChange(event) {
		this.setState({
			...this.state,
			simulationType: event.target.value,
		});
	}

	onCompetitorBidPresetChange(event) {
		let empty = {
			["Corio"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
			["SSE"]: [
				{
					min_price: "",
					max_price: "",
					min_quantity: "",
					max_quantity: "",
					min_splits: 1,
					max_splits: 1,
				},
			],
		};

		let randomSplits = {
			["Corio"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 450,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 83,
					max_price: 107,
					min_quantity: 375,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 810,
					max_quantity: 882,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 69,
					max_price: 86,
					min_quantity: 1440,
					max_quantity: 1449,
					min_splits: 1,
					max_splits: 3,
				},
			],
			["SSE"]: [
				{
					min_price: 76,
					max_price: 92,
					min_quantity: 798,
					max_quantity: 810,
					min_splits: 1,
					max_splits: 2,
				},
			],
		};
		let individualSplits = {
			["Corio"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 450,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 83,
					max_price: 107,
					min_quantity: 375,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 504,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 76,
					max_price: 95,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 69,
					max_price: 86,
					min_quantity: 468,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 69,
					max_price: 86,
					min_quantity: 468,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 69,
					max_price: 86,
					min_quantity: 468,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["SSE"]: [
				{
					min_price: 76,
					max_price: 92,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 76,
					max_price: 92,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
			],
		};

		let randomSplitsPlusK2 = {
			["Corio"]: [
				{
					min_price: 55,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 460,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 57,
					max_price: 107,
					min_quantity: 375,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 54,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 54,
					max_price: 95,
					min_quantity: 810,
					max_quantity: 882,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 53,
					max_price: 86,
					min_quantity: 1206,
					max_quantity: 1449,
					min_splits: 1,
					max_splits: 3,
				},
			],
			["SSE"]: [
				{
					min_price: 52,
					max_price: 92,
					min_quantity: 798,
					max_quantity: 810,
					min_splits: 1,
					max_splits: 2,
				},
			],
		};
		let individualSplitsPlusK2 = {
			["Corio"]: [
				{
					min_price: 55,
					max_price: 95,
					min_quantity: 414,
					max_quantity: 460,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 57,
					max_price: 107,
					min_quantity: 375,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 54,
					max_price: 95,
					min_quantity: 504,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 54,
					max_price: 95,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 54,
					max_price: 95,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 53,
					max_price: 86,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 53,
					max_price: 86,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 53,
					max_price: 86,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["SSE"]: [
				{
					min_price: 52,
					max_price: 92,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 52,
					max_price: 92,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
			],
		};

		let randomSplitsK2 = {
			["Corio"]: [
				{
					min_price: 55,
					max_price: 74,
					min_quantity: 414,
					max_quantity: 460,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 57,
					max_price: 75,
					min_quantity: 378,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 54,
					max_price: 74,
					min_quantity: 414,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 54,
					max_price: 74,
					min_quantity: 810,
					max_quantity: 882,
					min_splits: 1,
					max_splits: 2,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 53,
					max_price: 72,
					min_quantity: 1206,
					max_quantity: 1449,
					min_splits: 1,
					max_splits: 3,
				},
			],
			["SSE"]: [
				{
					min_price: 52,
					max_price: 72,
					min_quantity: 798,
					max_quantity: 810,
					min_splits: 1,
					max_splits: 2,
				},
			],
		};
		let individualSplitsK2 = {
			["Corio"]: [
				{
					min_price: 55,
					max_price: 74,
					min_quantity: 414,
					max_quantity: 460,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["ESB, JERA"]: [
				{
					min_price: 57,
					max_price: 75,
					min_quantity: 378,
					max_quantity: 380,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["Statkraft, CIP"]: [
				{
					min_price: 54,
					max_price: 74,
					min_quantity: 504,
					max_quantity: 504,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["RWE, Saorgus Energy"]: [
				{
					min_price: 54,
					max_price: 74,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 54,
					max_price: 74,
					min_quantity: 396,
					max_quantity: 441,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["EDF, Fred Olsen"]: [
				{
					min_price: 53,
					max_price: 72,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 53,
					max_price: 72,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 53,
					max_price: 72,
					min_quantity: 396,
					max_quantity: 483,
					min_splits: 1,
					max_splits: 1,
				},
			],
			["SSE"]: [
				{
					min_price: 52,
					max_price: 72,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
				{
					min_price: 52,
					max_price: 72,
					min_quantity: 396,
					max_quantity: 414,
					min_splits: 1,
					max_splits: 1,
				},
			],
		};

		let value = {};

		switch (event.target.value) {
			case "empty":
				value = empty;
				break;
			case "random":
				value = randomSplits;
				break;
			case "individual":
				value = individualSplits;
				break;
			case "randomPlusK2":
				value = randomSplitsPlusK2;
				break;
			case "individualPlusK2":
				value = individualSplitsPlusK2;
				break;
			case "randomK2":
				value = randomSplitsK2;
				break;
			case "individualK2":
				value = individualSplitsK2;
				break;
			default:
				value = this.state.competitor_bid_config;
				break;
		}

		delete value[this.props.user.user.display_name];

		this.setState({
			...this.state,
			competitor_bid_config_preset: event.target.value,
			competitor_bid_config: value,
		});
	}

	onUserBidConfigChange(event, index) {
		if (event.target.name === "price" && event.target.value > 150) {
			return;
		}
		let user_bid_config_copy = [...this.state.user_bid_config];
		let item = { ...user_bid_config_copy[index], [event.target.name]: event.target.value };
		user_bid_config_copy[index] = item;
		this.setState({ ...this.state, user_bid_config: user_bid_config_copy });
	}

	onCompetitorBidConfigChange(event, username, bid_index) {
		let value = event.target.value;
		if ((event.target.name === "min_price" || event.target.name === "max_price") && value > 150) {
			value = 150;
		}
		if ((event.target.name === "min_splits" || event.target.name === "max_splits") && value > 3) {
			value = 3;
		}
		if ((event.target.name === "min_splits" || event.target.name === "max_splits") && value < 0) {
			value = 0;
		}

		let competitor_bids_copy = [...this.state.competitor_bid_config[username]];
		competitor_bids_copy[bid_index] = { ...competitor_bids_copy[bid_index], [event.target.name]: value };

		this.setState({
			...this.state,
			competitor_bid_config: {
				...this.state.competitor_bid_config,
				[username]: competitor_bids_copy,
			},
		});
	}

	addUserBid() {
		this.setState({
			...this.state,
			user_bid_config: [
				...this.state.user_bid_config,
				{
					bid_id: this.state.user_bid_config.length + 1,
					price: "",
					quantity: "",
				},
			],
		});
	}

	removeUserBid() {
		this.setState({
			...this.state,
			user_bid_config: this.state.user_bid_config.filter(
				(bid) => bid.bid_id !== this.state.user_bid_config.length
			),
		});
	}

	addCompetitor() {
		this.setState({
			...this.state,
			competitor_bid_config: {
				...this.state.competitor_bid_config,
				[`Competitor ${Object.keys(this.state.competitor_bid_config).length + 1}`]: [
					{
						min_price: "",
						max_price: "",
						min_quantity: "",
						max_quantity: "",
						min_splits: "",
						max_splits: "",
					},
				],
			},
		});
	}

	removeCompetitor() {
		let competitor_bid_config_copy = { ...this.state.competitor_bid_config };
		let lastElement = Object.keys(competitor_bid_config_copy).at(-1);
		delete competitor_bid_config_copy[lastElement];

		this.setState({
			...this.state,
			competitor_bid_config: competitor_bid_config_copy,
		});
	}

	addCompetitorBid(username) {
		let competitor_bids_copy = [...this.state.competitor_bid_config[username]];
		if (competitor_bids_copy.length >= 4) {
			return;
		}
		competitor_bids_copy.push(competitor_bids_copy.at(-1));
		this.setState({
			...this.state,
			competitor_bid_config: {
				...this.state.competitor_bid_config,
				[username]: competitor_bids_copy,
			},
		});
	}

	removeCompetitorBid(username) {
		let competitor_bids_copy = [...this.state.competitor_bid_config[username]];
		if (competitor_bids_copy.length <= 1) {
			return;
		}
		competitor_bids_copy.pop();
		this.setState({
			...this.state,
			competitor_bid_config: {
				...this.state.competitor_bid_config,
				[username]: competitor_bids_copy,
			},
		});
	}

	getCreateButtonText() {
		let asqTooHigh = false;
		let asqTooLow = false;
		if (this.state.asq_gwhy > 18000) {
			asqTooHigh = true;
		}
		if (this.state.asq_gwhy < 1000) {
			asqTooLow = true;
		}
		let submitEnabled = true;
		let splitsTooHigh = false;
		let splitsTooLow = false;
		let emptyInput = false;

		for (const [_, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach(({ min_price, max_price, min_quantity, max_quantity, min_splits, max_splits }) => {
				if (
					parseFloat(min_price) > parseFloat(max_price) ||
					parseInt(min_quantity) > parseInt(max_quantity) ||
					parseInt(min_splits) > parseInt(max_splits)
				) {
					submitEnabled = false;
				}

				if (max_splits > 3) {
					splitsTooHigh = true;
				}
				if (min_splits < 1) {
					splitsTooLow = true;
				}

				if (!min_price || !max_price || !min_splits || !max_splits || !min_quantity || !max_quantity) {
					emptyInput = true;
				}
			});
		}

		return (
			<div>
				{submitEnabled === false ? (
					<p>
						<em>Please ensure max values are greater than min values</em>
					</p>
				) : (
					<></>
				)}
				{asqTooHigh ? (
					<p>
						<em>ASQ needs to be less than 18,000 GWh/yr</em>
					</p>
				) : (
					<></>
				)}
				{asqTooLow ? (
					<p>
						<em>ASQ needs to be greater than 1,000 GWh/yr</em>
					</p>
				) : (
					<></>
				)}
				{splitsTooHigh ? (
					<p>
						<em>Max splits cannot be greater than 3</em>
					</p>
				) : (
					<></>
				)}
				{splitsTooLow ? (
					<p>
						<em>Min splits cannot be less than 1</em>
					</p>
				) : (
					<></>
				)}
				{emptyInput ? (
					<p>
						<em>Make sure all input boxes have a value</em>
					</p>
				) : (
					<></>
				)}
			</div>
		);
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { created, simulation_loading } = this.props.simulation;
		const { user, signedIn } = this.props.user;

		let combinedPrice = 0;
		let combinedQuantity = 0;
		this.state.user_bid_config.forEach((entry) => {
			combinedPrice = combinedPrice + parseFloat(entry.price) * parseFloat(entry.quantity);
			combinedQuantity = combinedQuantity + parseFloat(entry.quantity);
		});
		let averageOfferPrice = combinedPrice / combinedQuantity;

		const preventMinus = (e) => {
			if (e.code === "Minus") {
				e.preventDefault();
			}
		};

		let duplicate = false;

		if (this.props.params.simulationId !== "0") {
			duplicate = true;
		}

		let mpsbEnabled = this.state.name.length > 0;

		this.state.user_bid_config.forEach((bid) => {
			if (!bid.price || !bid.quantity) {
				mpsbEnabled = false;
			}
		});

		for (const [_, bids] of Object.entries(this.state.competitor_bid_config)) {
			bids.forEach((bid) => {
				if (
					parseFloat(bid.min_price) > parseFloat(bid.max_price) ||
					parseInt(bid.min_quantity) > parseInt(bid.max_quantity) ||
					parseInt(bid.min_splits) > parseInt(bid.max_splits)
				) {
					mpsbEnabled = false;
				}
				if (bid.max_splits > 3) {
					mpsbEnabled = false;
				}
			});
		}

		if (this.state.asq_gwhy > 18000) {
			mpsbEnabled = false;
		}
		if (this.state.asq_gwhy < 1000) {
			mpsbEnabled = false;
		}

		if (!signedIn) {
			return <Redirect to="/simulations" />;
		}

		if (simulation_loading) {
			return this.returnSpinner();
		}

		return (
			<div className="container">
				<h3>
					{duplicate ? "Copying" : "Create"} New Simulation{" "}
					{duplicate ? "from " + this.props.simulation.simulation.name : ""}
				</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li key="1" className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li key="2" className="breadcrumb-item active" aria-current="page">
							{duplicate ? "Copying" : "Create"} New Simulation
						</li>
					</ol>
				</nav>
				<hr />
				{simulation_loading ? (
					<div
						style={{
							display: "block",
							position: "fixed",
							zIndex: 100,
							top: "40%",
							right: "50%",
						}}
					>
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				{/* <div className="card">
					<div className="card-body">
						<h6>Simulation Type</h6>
						<select
							value={this.state.simulationType}
							onChange={(e) => this.onMainDropDownChange(e)}
							style={{
								width: "200px",
								marginRight: "5px",
								padding: "6px",
								borderRadius: "4px",
								fontSize: "14px",
								borderColor: "#0275d8",
								color: "#0275d8",
								position: "absolute",
								top: "13px",
								right: "5px",
							}}
						>
							<option value="select" disabled={true}>
								Select Simulation Type
							</option>
							<option value="mpsb">MPSB</option>
						</select>
					</div>
				</div>

				<br /> */}

				{this.state.simulationType === "mpsb" && (
					<>
						<div className="card mb-3">
							<div className="card-body">
								<div className="mb-3">
									<label className="form-label">Simulation Details</label>
									<div className="form-floating mb-3">
										<RAInput
											id="simulation-name"
											name="name"
											value={this.state.name}
											onChange={(e) => this.onChange(e)}
											label="Simulation Name"
										/>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-floating mb-3">
											<RAInput
												id="asq_mw"
												name="asq_mw"
												value={this.state.asq_mw}
												onChange={(e) => this.onASQmwChange(e)}
												label="Auction Starting Quantity (MW)"
											/>
										</div>
									</div>
									<div className="col">
										<div className="form-floating mb-3">
											<RAInput
												id="asq_gwhy"
												name="asq_gwhy"
												value={this.state.asq_gwhy}
												onChange={(e) => this.onASQgwhyChange(e)}
												label="Auction Starting Quantity (GWh/yr)"
											/>
										</div>
									</div>
								</div>

								<div className="mb-3">
									<div className="form-floating mb-3">
										<RAInput
											id="initialRuns"
											name="initialRuns"
											value={this.state.initialRuns}
											onChange={(e) => this.onChange(e)}
											label="Initial Runs"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<label className="form-label">
									My Bids (average offer price {priceFormat(averageOfferPrice)} €/MWh)
								</label>
								<br />
								{this.state.user_bid_config.map((bids) => {
									return (
										<>
											<label className="form-label">Bid {bids.bid_id}</label>
											<div className="row">
												<div className="col">
													<RAInput
														type="number"
														id="price"
														name="price"
														value={this.state.user_bid_config[bids.bid_id - 1].price}
														onChange={(e) => this.onUserBidConfigChange(e, bids.bid_id - 1)}
														label="Price (€/MWh)"
													/>
												</div>
												<div className="col">
													<RAInput
														type="number"
														id="quantity"
														name="quantity"
														value={this.state.user_bid_config[bids.bid_id - 1].quantity}
														onChange={(e) => this.onUserBidConfigChange(e, bids.bid_id - 1)}
														label="Quantity (MW)"
													/>
												</div>
											</div>
										</>
									);
								})}

								<div className="row" style={{ margin: "auto" }}>
									<button
										className="btn btn-outline-success btn-sm"
										type="button"
										style={{ width: "auto", margin: "0 5px 0 0" }}
										onClick={this.addUserBid.bind(this)}
										disabled={this.state.user_bid_config.length >= 8}
									>
										<i className="fas fa-plus"></i>
									</button>
									<button
										className="btn btn-outline-danger btn-sm"
										type="button"
										style={{ width: "auto", margin: "0" }}
										onClick={this.removeUserBid.bind(this)}
										disabled={this.state.user_bid_config.length <= 1}
									>
										<i className="fas fa-minus"></i>
									</button>
								</div>
							</div>
						</div>
						<BidPricesQuantitiesGraphs
							competitor_bid_config={this.state.competitor_bid_config}
							user_bid_config={this.state.user_bid_config}
						/>
						<div className="card mb-3">
							<div className="card-body">
								<div className="row">
									<div className="col">
										<h4 className="form-label">Competitor Bids</h4>
									</div>
									<div
										className="col"
										style={{
											justifyContent: "flex-end",
											margin: "auto",
											textAlign: "right",
											alignItems: "right",
										}}
									>
										<select
											value={this.state.competitor_bid_config_preset}
											onChange={(e) => this.onCompetitorBidPresetChange(e)}
											style={{
												width: "200px",
												marginRight: "5px",
												padding: "6px",
												borderRadius: "4px",
												fontSize: "14px",
												borderColor: "#0275d8",
												color: "#0275d8",
												position: "absolute",
												top: "13px",
												right: "5px",
											}}
										>
											<option value="select" disabled={true}>
												Preset Values
											</option>
											<option value="empty">Empty</option>
											<hr />
											<option value="random">Random Splits (Slide 22)</option>
											<option value="individual">Individual Splits (Slide 22)</option>
											<hr />
											<option value="randomPlusK2">Random Splits (Slide 22+K2 Range)</option>
											<option value="individualPlusK2">
												Individual Splits (Slide 22+K2 Range)
											</option>
											<hr />
											<option value="randomK2">Random Splits (K2 Range)</option>
											<option value="individualK2">Individual Splits (K2 Range)</option>
										</select>
									</div>
								</div>
								{Object.entries(this.state.competitor_bid_config).map(([username, bids]) => {
									return (
										<>
											<div className="row">
												<div className="col">
													<h5 className="form-label">{username}</h5>
												</div>
												<div
													className="col"
													style={{
														justifyContent: "flex-end",
														margin: "auto",
														textAlign: "right",
														alignItems: "right",
													}}
												>
													<button
														className="btn btn-outline-success btn-sm"
														type="button"
														style={{ width: "auto", margin: "0 5px 5px 10px" }}
														onClick={() => this.addCompetitorBid(username)}
														disabled={
															this.state.competitor_bid_config[username].length >= 4
														}
													>
														<i className="fas fa-plus"></i>
													</button>
													<button
														className="btn btn-outline-danger btn-sm"
														type="button"
														style={{ width: "auto", margin: "0 0 5px 0" }}
														onClick={() => this.removeCompetitorBid(username)}
														disabled={
															this.state.competitor_bid_config[username].length <= 1
														}
													>
														<i className="fas fa-minus"></i>
													</button>
												</div>
											</div>
											{bids.map((bid, index) => {
												return (
													<div className="row">
														<h6 className="form-label">Bid {index + 1}</h6>
														<div className="col">
															<div className="col">
																<RAInput
																	label="Min Price (€/MWh)"
																	value={bid.min_price}
																	type="number"
																	id="min-price"
																	name="min_price"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
															<div className="col">
																<RAInput
																	label="Max Price (€/MWh)"
																	value={bid.max_price}
																	type="number"
																	id="max-price"
																	name="max_price"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
														</div>
														<div className="col">
															<div className="col">
																<RAInput
																	label="Min Quantity (MW)"
																	value={bid.min_quantity}
																	type="number"
																	id="min_quantity"
																	name="min_quantity"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
															<div className="col">
																<RAInput
																	label="Max Quantity (MW)"
																	value={bid.max_quantity}
																	type="number"
																	id="max_quantity"
																	name="max_quantity"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
														</div>
														<div className="col">
															<div className="col">
																<RAInput
																	label="Min Splits"
																	value={bid.min_splits}
																	type="number"
																	id="min_splits"
																	name="min_splits"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
															<div className="col">
																<RAInput
																	label="Max Splits"
																	value={bid.max_splits}
																	type="number"
																	id="max_splits"
																	name="max_splits"
																	onChange={(e) =>
																		this.onCompetitorBidConfigChange(
																			e,
																			username,
																			index
																		)
																	}
																/>
															</div>
														</div>
													</div>
												);
											})}
											<hr />
										</>
									);
								})}

								<div className="row" style={{ margin: "auto" }}>
									<button
										className="btn btn-outline-success btn-sm"
										type="button"
										style={{ width: "auto", margin: "0 5px 0 0" }}
										onClick={this.addCompetitor.bind(this)}
										disabled={Object.keys(this.state.competitor_bid_config).length >= 8}
									>
										<i className="fas fa-plus"></i>
									</button>
									<button
										className="btn btn-outline-danger btn-sm"
										type="button"
										style={{ width: "auto", margin: "0" }}
										onClick={this.removeCompetitor.bind(this)}
										disabled={Object.keys(this.state.competitor_bid_config).length <= 1}
									>
										<i className="fas fa-minus"></i>
									</button>
								</div>

								<div
									className="row"
									style={{
										justifyContent: "flex-end",
										margin: "auto",
										textAlign: "right",
										alignItems: "right",
										color: "red",
									}}
								>
									{this.getCreateButtonText()}
									<button
										disabled={!mpsbEnabled}
										className="btn btn-primary"
										style={{ width: "auto" }}
										onClick={this.createMPSBSimulationEvent.bind(this)}
									>
										Create Simulation
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <CreateSimulation {...props} params={params} />;
}

function priceFormat(num) {
	if (isNaN(num)) {
		return "0";
	}
	return Intl.NumberFormat("en", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
}

CreateSimulation.propTypes = {
	getSimulation: PropTypes.func.isRequired,
	createSimulation: PropTypes.func.isRequired,
	runSimulation: PropTypes.func.isRequired,
	setSimulationLoading: PropTypes.func.isRequired,
	setSimulationNotLoading: PropTypes.func.isRequired,
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	simulation: state.simulation,
	user: state.user,
});

export default connect(mapStateToProps, {
	getSimulation,
	createSimulation,
	runSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
})(WithParamsAndNavigate);
