import { confirm } from "react-confirm-box";

const RAButton = (props) => {
	const { label, style, color, disabled, onClick, confirmProps } = props;

	const confirmation = async () => {
		const result = await confirm(confirmProps.title, {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								<p>{confirmProps.body}</p>
								<br />
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										{" "}
										Yes{" "}
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			onClick();
		}
	};

	return (
		<button
			type="button"
			style={style}
			className={"btn btn-sm " + (color ? color : "btn-outline-primary")}
			disabled={disabled}
			onClick={confirmProps ? confirmation : onClick}
		>
			{label}
		</button>
	);
};

export default RAButton;
