import { DATABASE_RESET, DATABASE_LOADING } from "../actions/types";

const initialState = {
	resetToggle: false,
	db_loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case DATABASE_RESET:
			return {
				...state,
				resetToggle: !state.resetToggle,
				db_loading: false,
			};
		case DATABASE_LOADING:
			return {
				...state,
				db_loading: true,
			};
		default:
			return state;
	}
}
