import React, { Component } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { auctionStatus } from "../../../common/constants";
import { confirm } from "react-confirm-box";
import util from "../../../../util/RENO";
import {
	determineWinners,
	endAuction,
	randomizeWinners,
	updateAuction,
	triggerRefresh,
	makeAnnouncement,
	startAuctionBreak,
	endAuctionBreak,
} from "../../../../actions/auctionActions";

class AdminPanel extends Component {
	constructor(props) {
		super(props);

		const { auction } = this.props.auction;

		const tewMins = auction.timeExtensionWindow ? parseInt(auction.timeExtensionWindow / 60) : 0;
		const tewSecs = auction.timeExtensionWindow ? parseInt(auction.timeExtensionWindow % 60) : 0;
		const tedMins = auction.timeExtensionDuration ? parseInt(auction.timeExtensionDuration / 60) : 0;
		const tedSecs = auction.timeExtensionDuration ? parseInt(auction.timeExtensionDuration % 60) : 0;
		const pbdMins = auction.postBreakDuration ? parseInt(auction.postBreakDuration / 60) : 0;
		const pbdSecs = auction.postBreakDuration ? parseInt(auction.postBreakDuration % 60) : 0;

		this.state = {
			showAdminPanel: false,
			editingConfig: false,
			announcementText: "",
			minimumContractPrice: auction.minimumContractPrice,
			minimumDecrement: auction.minimumDecrement,
			bidUpperLimit: auction.bidUpperLimit,
			timeExtensionWindow: auction.timeExtensionWindow,
			timeExtensionWindowMinutes: tewMins,
			timeExtensionWindowSeconds: tewSecs,
			timeExtensionDuration: auction.timeExtensionDuration,
			timeExtensionDurationMinutes: tedMins,
			timeExtensionDurationSeconds: tedSecs,
			postBreakDuration: auction.postBreakDuration,
			postBreakDurationMinutes: pbdMins,
			postBreakDurationSeconds: pbdSecs,
			minimumDecrementToStrikePrice: auction.minimumDecrementToStrikePrice,
			tiesOnlyForLeadingBids: auction.tiesOnlyForLeadingBids,
			onlyLeadingBidsCanExtend: auction.onlyLeadingBidsCanExtend,
			showRanksToBidders: auction.showRanksToBidders,
			reduceByDuration: 0,
			reduceByDurationMinutes: 0,
			reduceByDurationSeconds: 0,
			estimatedNewEndTime: auction.endTime,
		};
	}

	async refresh() {
		await this.props.refresh();

		this.setState({
			...this.state,
			editingConfig: false,
			announcementText: "",
		});
	}

	async startAuctionAction() {
		this.props.history.push(`/auctions/start/${this.props.auction.auction.id}`);
	}

	async endAuctionAction() {
		const { auction } = this.props.auction;

		if (auction.status !== auctionStatus.IN_PROGRESS) return;

		const result = await confirm("End Auction Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Are you sure you would like to end the auction?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.endAuction(this.props.params.auctionId);
			await this.refresh();
			return;
		}
	}

	async determineWinnersAction() {
		const { auction } = this.props.auction;

		// Double check if this action should be allowed
		if (auction.status !== auctionStatus.ENDED || auction.winningBid !== null) return;

		const result = await confirm("Determine Winners Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Are you sure you would like determine winners for the auction?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.determineWinners(this.props.params.auctionId);
			await this.refresh();
			return;
		}
	}

	async startBreakAction() {
		const { auction } = this.props.auction;

		// Double check if this action should be allowed
		if (auction.status !== auctionStatus.IN_PROGRESS) return;

		const result = await confirm("Start Break Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Are you sure you would like to start a break for the auction?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.startAuctionBreak(this.props.params.auctionId);
			await this.refresh();
			return;
		}
	}

	async endBreakAction() {
		const { auction } = this.props.auction;

		// Double check if this action should be allowed
		if (auction.status !== auctionStatus.IN_A_BREAK) return;

		const result = await confirm("Start Break Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Are you sure you would like to end the break?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.endAuctionBreak(this.props.params.auctionId);
			await this.refresh();
			return;
		}
	}

	async randomizeWinnersAction() {
		const { auction } = this.props.auction;

		// Double check if this action should be allowed
		if (auction.winningBid === null) return;

		const result = await confirm("Randomize Winners Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Are you sure you would like randomize winners for the auction?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.randomizeWinners(this.props.params.auctionId);
			await this.refresh();
			return;
		}
	}

	async updateAuctionConfigAction() {
		const { auction } = this.props.auction;

		const minimumContractPriceText =
			this.state.minimumContractPrice != auction.minimumContractPrice
				? util.formatCurrencyWithText(this.state.minimumContractPrice)
				: "-";
		const minimumDecrementText =
			this.state.minimumDecrement != auction.minimumDecrement
				? util.formatCurrencyWithText(this.state.minimumDecrement)
				: "-";
		const bidUpperLimitText =
			this.state.bidUpperLimit != auction.bidUpperLimit
				? util.formatCurrencyWithText(this.state.bidUpperLimit)
				: "-";
		const timeExtensionWindowText =
			this.state.timeExtensionWindow != auction.timeExtensionWindow
				? util.formatDurationWithText(this.state.timeExtensionWindow)
				: "-";
		const timeExtensionDurationText =
			this.state.timeExtensionDuration != auction.timeExtensionDuration
				? util.formatDurationWithText(this.state.timeExtensionDuration)
				: "-";
		const postBreakDurationText =
			this.state.postBreakDuration != auction.postBreakDuration
				? util.formatDurationWithText(this.state.postBreakDuration)
				: "-";
		const reduceByDurationText =
			this.state.reduceByDuration != 0 ? util.formatDurationWithText(this.state.reduceByDuration) : "-";
		const estimatedNewEndTimeText =
			this.state.reduceByDuration != 0 ? util.formatTime(this.state.estimatedNewEndTime) : "-";
		const minimumDecrementToStrikePriceCurrentText =
			auction.minimumDecrementToStrikePrice == 0
				? "Apply minimum decrement rule to users' leading bid"
				: "Apply minimum decrement rule to the strike bid";
		const minimumDecrementToStrikePriceText =
			this.state.minimumDecrementToStrikePrice != auction.minimumDecrementToStrikePrice
				? this.state.minimumDecrementToStrikePrice == 0
					? "Apply minimum decrement rule to users' leading bid"
					: "Apply minimum decrement rule to the strike bid"
				: "-";
		const tiesOnlyForLeadingBidsCurrentText =
			auction.tiesOnlyForLeadingBids == 0
				? "Apply bid price tie rule to all bids"
				: "Apply bid price tie rule only to leading bids";
		const tiesOnlyForLeadingBidsText =
			this.state.tiesOnlyForLeadingBids != auction.tiesOnlyForLeadingBids
				? this.state.tiesOnlyForLeadingBids == 0
					? "Apply bid price tie rule to all bids"
					: "Apply bid price tie rule only to leading bids"
				: "-";
		const showRanksToBiddersCurrentText =
			auction.showRanksToBidders == 0 ? "Do not show ranks to bidders" : "Show ranks to bidders";
		const showRanksToBiddersText =
			this.state.showRanksToBidders != auction.showRanksToBidders
				? this.state.showRanksToBidders == 0
					? "Do not show ranks to bidders"
					: "Show ranks to bidders"
				: "-";
		const onlyLeadingBidsCanExtendCurrentText =
			auction.onlyLeadingBidsCanExtend == 0
				? "All bids placed in the TEW can extend"
				: "Only leading bids placed in the TEW can extend";
		const onlyLeadingBidsCanExtendText =
			this.state.onlyLeadingBidsCanExtend != auction.onlyLeadingBidsCanExtend
				? this.state.onlyLeadingBidsCanExtend == 0
					? "All bids placed in the TEW can extend"
					: "Only leading bids placed in the TEW can extend"
				: "-";

		const result = await confirm("Auction Configuration Update Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "40vw",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Would you like to update the following auction configuration parameters?
								<table className="table table-bordered table-sm">
									<thead>
										<tr>
											<th>Parameter</th>
											<th>Current Value</th>
											<th>New Value</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Reduce Auction Duration By:</td>
											<td>-</td>
											<td>{reduceByDurationText}</td>
										</tr>
										<tr>
											<td>Estimated (New) End Time Based:</td>
											<td>-</td>
											<td>{estimatedNewEndTimeText}</td>
										</tr>
										<tr>
											<td>Minimum Contract Price</td>
											<td>{util.formatCurrencyWithText(auction.minimumContractPrice)}</td>
											<td>{minimumContractPriceText}</td>
										</tr>
										<tr>
											<td>Minimum Decrement</td>
											<td>{util.formatCurrencyWithText(auction.minimumDecrement)}</td>
											<td>{minimumDecrementText}</td>
										</tr>
										<tr>
											<td>Bid Upper Limit</td>
											<td>{util.formatCurrencyWithText(auction.bidUpperLimit)}</td>
											<td>{bidUpperLimitText}</td>
										</tr>
										<tr>
											<td>Time Extension Window</td>
											<td>{util.formatDuration(auction.timeExtensionWindow)}</td>
											<td>{timeExtensionWindowText}</td>
										</tr>
										<tr>
											<td>Time Extension Duration</td>
											<td>{util.formatDuration(auction.timeExtensionDuration)}</td>
											<td>{timeExtensionDurationText}</td>
										</tr>
										<tr>
											<td>Post Break Duration</td>
											<td>{util.formatDuration(auction.postBreakDuration)}</td>
											<td>{postBreakDurationText}</td>
										</tr>
										<tr>
											<td>Minimum Decrement Rule</td>
											<td>{minimumDecrementToStrikePriceCurrentText}</td>
											<td>{minimumDecrementToStrikePriceText}</td>
										</tr>
										<tr>
											<td>Bid Price Ties Rule</td>
											<td>{tiesOnlyForLeadingBidsCurrentText}</td>
											<td>{tiesOnlyForLeadingBidsText}</td>
										</tr>
										<tr>
											<td>Show Ranks Rule</td>
											<td>{showRanksToBiddersCurrentText}</td>
											<td>{showRanksToBiddersText}</td>
										</tr>
										<tr>
											<td>Bid Extensions Rule</td>
											<td>{onlyLeadingBidsCanExtendCurrentText}</td>
											<td>{onlyLeadingBidsCanExtendText}</td>
										</tr>
									</tbody>
								</table>
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			const data = {
				minimumContractPrice: this.state.minimumContractPrice,
				minimumDecrement: this.state.minimumDecrement,
				bidUpperLimit: this.state.bidUpperLimit,
				timeExtensionWindow: this.state.timeExtensionWindow,
				timeExtensionDuration: this.state.timeExtensionDuration,
				postBreakDuration: this.state.postBreakDuration,
				minimumDecrementToStrikePrice: this.state.minimumDecrementToStrikePrice,
				tiesOnlyForLeadingBids: this.state.tiesOnlyForLeadingBids,
				showRanksToBidders: this.state.showRanksToBidders,
				onlyLeadingBidsCanExtend: this.state.onlyLeadingBidsCanExtend,
				reduceByDuration: this.state.reduceByDuration,
			};

			await this.props.updateAuction(this.props.params.auctionId, data);
			await this.refresh();
			return;
		}
	}

	async triggerRefreshAction() {
		await this.props.triggerRefresh(this.props.params.auctionId);
	}

	async makeAnnouncementAction() {
		await this.props.makeAnnouncement(this.props.params.auctionId, this.state.announcementText);
	}

	onToggleAdminPanel() {
		this.setState({ ...this.state, showAdminPanel: !this.state.showAdminPanel });
	}

	onToggleEditingConfig() {
		const { auction } = this.props.auction;

		const tewMins = auction.timeExtensionWindow ? parseInt(auction.timeExtensionWindow / 60) : 0;
		const tewSecs = auction.timeExtensionWindow ? parseInt(auction.timeExtensionWindow % 60) : 0;
		const tedMins = auction.timeExtensionDuration ? parseInt(auction.timeExtensionDuration / 60) : 0;
		const tedSecs = auction.timeExtensionDuration ? parseInt(auction.timeExtensionDuration % 60) : 0;

		this.setState({
			...this.state,
			editingConfig: !this.state.editingConfig,
			minimumContractPrice: auction.minimumContractPrice,
			minimumDecrement: auction.minimumDecrement,
			timeExtensionWindow: auction.timeExtensionWindow,
			timeExtensionWindowMinutes: tewMins,
			timeExtensionWindowSeconds: tewSecs,
			timeExtensionDuration: auction.timeExtensionDuration,
			timeExtensionDurationMinutes: tedMins,
			timeExtensionDurationSeconds: tedSecs,
			minimumDecrementToStrikePrice: auction.minimumDecrementToStrikePrice,
			tiesOnlyForLeadingBids: auction.tiesOnlyForLeadingBids,
			reduceByDuration: 0,
			reduceByDurationMinutes: 0,
			reduceByDurationSeconds: 0,
			estimatedNewEndTime: auction.endTime,
		});
	}

	onMinimumDecrementToStrikePriceChange() {
		this.setState({
			...this.state,
			minimumDecrementToStrikePrice: this.state.minimumDecrementToStrikePrice === 0 ? 1 : 0,
		});
	}

	onTiesOnlyForLeadingBidsChange() {
		this.setState({
			...this.state,
			tiesOnlyForLeadingBids: this.state.tiesOnlyForLeadingBids === 0 ? 1 : 0,
		});
	}

	onOnlyLeadingBidsCanExtendChange() {
		this.setState({
			...this.state,
			onlyLeadingBidsCanExtend: this.state.onlyLeadingBidsCanExtend === 0 ? 1 : 0,
		});
	}

	onShowRanksToBiddersChange() {
		this.setState({
			...this.state,
			showRanksToBidders: this.state.showRanksToBidders === 0 ? 1 : 0,
		});
	}

	onAnnouncementChange(event) {
		this.setState({ announcementText: event.target.value });
	}

	onMinimumContractPriceChange(event) {
		const value = parseFloat(event.target.value);
		this.setState({ ...this.state, minimumContractPrice: value < 0 ? 0 : value });
	}

	onMinimumDecrementChange(event) {
		const value = parseFloat(event.target.value);
		this.setState({ ...this.state, minimumDecrement: value < 0 ? 0 : value });
	}

	onBidUpperLimitChange(event) {
		const value = parseFloat(event.target.value);
		this.setState({ ...this.state, bidUpperLimit: value < 0 ? 0 : value });
	}

	onTimeChange(event) {
		const { auction } = this.props.auction;

		if (event.target && event.target.title && event.target.value) {
			const newValue = event.target.value < 0 ? 0 : parseInt(event.target.value);
			if (event.target.title === "window-min")
				this.setState({
					...this.state,
					timeExtensionWindowMinutes: newValue,
					timeExtensionWindow: newValue * 60 + this.state.timeExtensionWindowSeconds,
				});
			else if (event.target.title === "window-sec")
				this.setState({
					...this.state,
					timeExtensionWindowSeconds: newValue,
					timeExtensionWindow: this.state.timeExtensionWindowMinutes * 60 + newValue,
				});
			else if (event.target.title === "duration-min")
				this.setState({
					...this.state,
					timeExtensionDurationMinutes: newValue,
					timeExtensionDuration: newValue * 60 + this.state.timeExtensionDurationSeconds,
				});
			else if (event.target.title === "duration-sec")
				this.setState({
					...this.state,
					timeExtensionDurationSeconds: newValue,
					timeExtensionDuration: this.state.timeExtensionDurationMinutes * 60 + newValue,
				});
			else if (event.target.title === "postbreak-min")
				this.setState({
					...this.state,
					postBreakDurationMinutes: newValue,
					postBreakDuration: newValue * 60 + this.state.postBreakDurationSeconds,
				});
			else if (event.target.title === "postbreak-sec")
				this.setState({
					...this.state,
					postBreakDurationSeconds: newValue,
					postBreakDuration: this.state.postBreakDurationMinutes * 60 + newValue,
				});
			if (event.target.title === "reduce-min")
				this.setState({
					...this.state,
					reduceByDurationMinutes: newValue,
					reduceByDuration: newValue * 60 + this.state.reduceByDurationSeconds,
					estimatedNewEndTime: auction.endTime - (newValue * 60 + this.state.reduceByDurationSeconds),
				});
			else if (event.target.title === "reduce-sec")
				this.setState({
					...this.state,
					reduceByDurationSeconds: newValue,
					reduceByDuration: this.state.reduceByDurationMinutes * 60 + newValue,
					estimatedNewEndTime: auction.endTime - (this.state.reduceByDurationMinutes * 60 + newValue),
				});
		}
	}

	render() {
		const { auction } = this.props.auction;

		const startAuctionEnabled = auction.status === auctionStatus.AWAITING_START;
		const endAuctionEnabled = auction.status === auctionStatus.IN_PROGRESS;
		const determineWinnersEnabled = auction.status === auctionStatus.ENDED && auction.winningBid === null;
		const randomizeWinnersEnabled = auction.winningBid !== null;
		const makeAnnouncementEnabled = this.state.announcementText.length > 0;
		const startBreakEnabled = auction.status === auctionStatus.IN_PROGRESS;
		const endBreakEnabled = auction.status === auctionStatus.IN_A_BREAK;

		const updateAuctionConfigEnabled =
			this.state.minimumContractPrice != auction.minimumContractPrice ||
			this.state.minimumDecrement != auction.minimumDecrement ||
			this.state.bidUpperLimit != auction.bidUpperLimit ||
			this.state.timeExtensionDuration !== auction.timeExtensionDuration ||
			this.state.postBreakDuration !== auction.postBreakDuration ||
			this.state.timeExtensionWindow !== auction.timeExtensionWindow ||
			this.state.minimumDecrementToStrikePrice !== auction.minimumDecrementToStrikePrice ||
			this.state.tiesOnlyForLeadingBids !== auction.tiesOnlyForLeadingBids ||
			this.state.showRanksToBidders !== auction.showRanksToBidders ||
			this.state.onlyLeadingBidsCanExtend !== auction.onlyLeadingBidsCanExtend ||
			this.state.reduceByDuration !== 0;

		return (
			<div>
				<div
					className="card border-secondary"
					style={{
						width: "80%",
						margin: "auto",
					}}
				>
					<div className="card-header" style={{ textAlign: "left", height: "2.5rem" }}>
						<div className="row" style={{ paddingLeft: "1rem" }}>
							<div
								className="form-check form-switch"
								style={{ minHeight: "inherit", position: "flex", marginRight: "auto" }}
							>
								<input
									className="form-check-input"
									type="checkbox"
									checked={this.state.showAdminPanel}
									onChange={() => this.onToggleAdminPanel()}
								/>
								<label className="form-check-label">Admin Panel</label>
							</div>
						</div>
					</div>

					{this.state.showAdminPanel ? (
						<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
							<div className="row">
								<div
									className="input-group flex-nowrap input-group-sm"
									role="group"
									style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}
								>
									<button
										type="button"
										disabled={!startAuctionEnabled}
										className="btn btn-outline-success btn-sm"
										onClick={() => this.startAuctionAction()}
										style={{ width: "25%" }}
									>
										Start Auction
									</button>
									<button
										type="button"
										disabled={!endAuctionEnabled}
										className="btn btn-outline-danger  btn-sm"
										onClick={() => this.endAuctionAction()}
										style={{ width: "25%" }}
									>
										End Auction
									</button>
									<button
										type="button"
										disabled={!determineWinnersEnabled}
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.determineWinnersAction()}
										style={{ width: "25%" }}
									>
										Determine Winners
									</button>
									<button
										type="button"
										disabled={!randomizeWinnersEnabled}
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.randomizeWinnersAction()}
										style={{ width: "25%" }}
									>
										Randomize Winners
									</button>
								</div>
							</div>

							<hr />

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Minimum Contract Price: </span>
										<span className="input-group-text">NOK</span>
										<input
											type="number"
											className="form-control"
											disabled={!this.state.editingConfig}
											value={
												this.state.editingConfig
													? this.state.minimumContractPrice
													: util.formatCurrency(this.state.minimumContractPrice)
											}
											onChange={(e) => this.onMinimumContractPriceChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">øre/kWh</span>
									</div>
								</div>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Time Ext Window:</span>
										<input
											type="number"
											className="form-control"
											title="window-min"
											disabled={!this.state.editingConfig}
											value={this.state.timeExtensionWindowMinutes}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">min(s)</span>
										<input
											type="number"
											className="form-control"
											title="window-sec"
											disabled={!this.state.editingConfig}
											value={this.state.timeExtensionWindowSeconds}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">sec(s)</span>
									</div>
								</div>
								<div className="col-2">
									<div className="input-group input-group-sm">
										<div className="input-group-text" style={{ width: "100%" }}>
											<div
												className="form-check form-switch"
												style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
											>
												<input
													className="form-check-input"
													type="checkbox"
													checked={this.state.editingConfig}
													onChange={() => this.onToggleEditingConfig()}
												/>
												<label className="form-check-label">Editing Config</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Minimum Decrement:</span>
										<span className="input-group-text">NOK</span>
										<input
											type="number"
											className="form-control"
											disabled={!this.state.editingConfig}
											value={
												this.state.editingConfig
													? this.state.minimumDecrement
													: util.formatCurrency(this.state.minimumDecrement)
											}
											onChange={(e) => this.onMinimumDecrementChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">øre/kWh</span>
									</div>
								</div>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Time Ext Duration:</span>
										<input
											type="number"
											className="form-control"
											title="duration-min"
											disabled={!this.state.editingConfig}
											value={this.state.timeExtensionDurationMinutes}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">min(s)</span>
										<input
											type="number"
											className="form-control"
											title="duration-sec"
											disabled={!this.state.editingConfig}
											value={this.state.timeExtensionDurationSeconds}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">sec(s)</span>
									</div>
								</div>
							</div>

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Bid Upper Limit:</span>
										<span className="input-group-text">NOK</span>
										<input
											type="number"
											className="form-control"
											disabled={!this.state.editingConfig}
											value={
												this.state.editingConfig
													? this.state.bidUpperLimit
													: util.formatCurrency(this.state.bidUpperLimit)
											}
											onChange={(e) => this.onBidUpperLimitChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">øre/kWh</span>
									</div>
								</div>
								<div className="col-5">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Post Break Duration:</span>
										<input
											type="number"
											className="form-control"
											title="postbreak-min"
											disabled={!this.state.editingConfig}
											value={this.state.postBreakDurationMinutes}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">min(s)</span>
										<input
											type="number"
											className="form-control"
											title="postbreak-sec"
											disabled={!this.state.editingConfig}
											value={this.state.postBreakDurationSeconds}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">sec(s)</span>
									</div>
								</div>
							</div>

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div className="col-5">
									<div className="input-group input-group-sm">
										<div className="input-group-text" style={{ width: "100%" }}>
											<div
												className="form-check form-switch"
												style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
											>
												<input
													className="form-check-input"
													type="checkbox"
													checked={this.state.minimumDecrementToStrikePrice}
													disabled={!this.state.editingConfig}
													onChange={(e) => this.onMinimumDecrementToStrikePriceChange(e)}
												/>
												<label className="form-check-label">
													Apply Minimum Decrement to Strike Bid
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="col-5">
									<div className="input-group input-group-sm">
										<div className="input-group-text" style={{ width: "100%" }}>
											<div
												className="form-check form-switch"
												style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
											>
												<input
													className="form-check-input"
													type="checkbox"
													checked={this.state.tiesOnlyForLeadingBids}
													disabled={!this.state.editingConfig}
													onChange={(e) => this.onTiesOnlyForLeadingBidsChange(e)}
												/>
												<label className="form-check-label">
													Ties only for Leading Bid Prices
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div className="col-5">
									<div className="input-group input-group-sm">
										<div className="input-group-text" style={{ width: "100%" }}>
											<div
												className="form-check form-switch"
												style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
											>
												<input
													className="form-check-input"
													type="checkbox"
													checked={this.state.showRanksToBidders}
													disabled={!this.state.editingConfig}
													onChange={(e) => this.onShowRanksToBiddersChange(e)}
												/>
												<label className="form-check-label">Show Ranks to Bidders</label>
											</div>
										</div>
									</div>
								</div>

								<div className="col-5">
									<div className="input-group input-group-sm">
										<div className="input-group-text" style={{ width: "100%" }}>
											<div
												className="form-check form-switch"
												style={{ minHeight: "inherit", marginBottom: "0rem", width: "100%" }}
											>
												<input
													className="form-check-input"
													type="checkbox"
													checked={this.state.onlyLeadingBidsCanExtend}
													disabled={!this.state.editingConfig}
													onChange={(e) => this.onOnlyLeadingBidsCanExtendChange(e)}
												/>
												<label className="form-check-label">Only Leading Bids can Extend</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-10">
									<div className="input-group input-group-sm has-validation">
										<span className="input-group-text">Reduce Auction Duration By: </span>
										<input
											type="number"
											className="form-control"
											title="reduce-min"
											disabled={!this.state.editingConfig}
											value={this.state.reduceByDurationMinutes}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">min(s)</span>
										<input
											type="number"
											className="form-control"
											title="reduce-sec"
											disabled={!this.state.editingConfig}
											value={this.state.reduceByDurationSeconds}
											onChange={(e) => this.onTimeChange(e)}
											style={{ textAlign: "center" }}
										/>
										<span className="input-group-text">sec(s)</span>
										<span className="input-group-text">
											(New) Estimated End Time:{" "}
											{this.state.estimatedNewEndTime
												? util.formatTime(this.state.estimatedNewEndTime)
												: "NA"}
										</span>
									</div>
								</div>
								<div className="col-2">
									<button
										className="btn btn-outline-primary btn-sm"
										type="button"
										disabled={!updateAuctionConfigEnabled}
										onClick={() => this.updateAuctionConfigAction()}
										style={{ width: "100%" }}
									>
										Update Config
									</button>
								</div>
							</div>

							<hr />

							<div className="row" style={{ paddingBottom: "5px" }}>
								<div
									className="input-group flex-nowrap input-group-sm"
									role="group"
									style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}
								>
									<button
										type="button"
										disabled={!startBreakEnabled}
										className="btn btn-outline-success btn-sm"
										onClick={() => this.startBreakAction()}
										style={{ width: "25%" }}
									>
										Start <strong>Break</strong>
									</button>
									<button
										type="button"
										disabled={!endBreakEnabled}
										className="btn btn-outline-danger  btn-sm"
										onClick={() => this.endBreakAction()}
										style={{ width: "25%" }}
									>
										End <strong>Break</strong>
									</button>
									<button
										type="button"
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.triggerRefreshAction()}
										style={{ width: "25%" }}
									>
										Trigger Refresh
									</button>
									<button
										type="button"
										disabled={!makeAnnouncementEnabled}
										className="btn btn-outline-primary btn-sm"
										onClick={() => this.makeAnnouncementAction()}
										style={{ width: "25%" }}
									>
										Make Announcement
									</button>
								</div>
							</div>

							<div className="row">
								<div className="btn-group" role="group">
									<input
										type="string"
										className="form-control form-control-sm"
										name="announcement"
										value={this.state.announcementText}
										onChange={(e) => this.onAnnouncementChange(e)}
										placeholder="Announcement..."
										style={{ width: "100%" }}
									/>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
				<br />
			</div>
		);
	}
}

function WithParamsAndHistory(props) {
	let params = useParams(); // React Hooks
	let history = useHistory();
	return <AdminPanel {...props} params={params} history={history} />;
}

AdminPanel.propTypes = {
	refresh: PropTypes.func.isRequired,
	endAuction: PropTypes.func.isRequired,
	determineWinners: PropTypes.func.isRequired,
	randomizeWinners: PropTypes.func.isRequired,
	triggerRefresh: PropTypes.func.isRequired,
	makeAnnouncement: PropTypes.func.isRequired,
	updateAuction: PropTypes.func.isRequired,
	startAuctionBreak: PropTypes.func.isRequired,
	endAuctionBreak: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
});

export default connect(mapStateToProps, {
	endAuction,
	determineWinners,
	randomizeWinners,
	updateAuction,
	triggerRefresh,
	makeAnnouncement,
	startAuctionBreak,
	endAuctionBreak,
})(WithParamsAndHistory);
