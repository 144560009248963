import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { confirm } from "react-confirm-box";
import InformationPanel from "../Components/RENOInformationPanel";
import {
	getAuction,
	getUserRENOBids,
	makeRENOBid,
	setAuctionsLoading,
	setAuctionsNotLoading,
	requestAssistance,
	getHistory,
} from "../../../../actions/auctionActions";
import { getAllUsers, setUserLoading, setUserNotLoading } from "../../../../actions/userActions";
import UserBidHistoryTable from "../Components/UserBidHistoryTable";
import AuctionRulesCard from "../Components/AuctionRulesCard";
import { auctionStatus, eventTypes, userType } from "../../../common/constants";
import AdminPanel from "../Components/AdminPanel";
import { joinAuctionRoom } from "../../../../helpers/emit/auction";
import { addNotification } from "../../../common/NotificationManager";
import socket from "../../../../helpers/socket";
import util from "../../../../util/RENO";
import { rejoinedSocketRoom } from "../../../../actions/socketActions";
import AdminBidHistoryTable from "../Components/AdminBidHistoryTable";

class RENOAuction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newBidPrice: 0.0,
			isNewBidPriceValid: false,
			lastRefreshTime: null,
			previousExitBidPrice: null,
			newBidUpperLimit: null,
			newBidLowerLimit: null,
			timeUp: false,
			socketConnected: true,
		};
	}

	componentDidMount() {
		this.refresh();
	}

	componentDidUpdate() {
		if (this.props.socket) {
			if (this.props.socket.needToRejoinRoom) {
				this.joinSocketRoom();
				this.props.rejoinedSocketRoom();
			}
		}
	}

	async refresh() {
		await this.props.setAuctionsLoading();
		await this.props.setUserLoading();

		await this.fetchData();
		await this.joinSocketRoom();

		this.props.setAuctionsNotLoading();
		this.props.setUserNotLoading();
	}

	async fetchData() {
		await this.props.getAuction(this.props.params.auctionId);
		await this.props.getUserRENOBids(this.props.params.auctionId);

		if (this.props.user.user && this.props.user.user.type === userType.ADMIN) {
			await this.props.getHistory(this.props.params.auctionId);
			await this.props.getAllUsers();
		}

		const { auction, userBids } = this.props.auction;

		var upperLimit = auction.bidUpperLimit;
		var previousExitBidPrice = null;

		if (userBids && userBids.length > 0) {
			const lowestBid = userBids.sort((a, b) => a.amount - b.amount)[0];

			previousExitBidPrice = lowestBid.amount;

			// upperLimit =
			// 	auction.minimumDecrementToStrikePrice == 1 && auction.strikeBid !== null
			// 		? Math.round(
			// 				(auction.strikeBid.amount - auction.minimumDecrement < auction.minimumContractPrice
			// 					? auction.minimumContractPrice
			// 					: auction.strikeBid.amount - auction.minimumDecrement) * 100000
			// 		  ) / 100000
			// 		: Math.round(
			// 				(lowestBid.amount - auction.minimumDecrement < auction.minimumContractPrice
			// 					? auction.minimumContractPrice
			// 					: lowestBid.amount - auction.minimumDecrement) * 100000
			// 		  ) / 100000;

			upperLimit = parseFloat(
				util.formatCurrency(
					auction.minimumDecrementToStrikePrice == 1 && auction.strikeBid !== null
						? auction.strikeBid.amount - auction.minimumDecrement < auction.minimumContractPrice
							? auction.minimumContractPrice
							: auction.strikeBid.amount - auction.minimumDecrement
						: lowestBid.amount - auction.minimumDecrement < auction.minimumContractPrice
						? auction.minimumContractPrice
						: lowestBid.amount - auction.minimumDecrement
				)
			);
		}

		// const lowerLimit = Math.round(auction.minimumContractPrice * 100000) / 100000;
		const lowerLimit = parseFloat(util.formatCurrency(auction.minimumContractPrice));

		const currentTime = Date.now() / 1000;

		this.setState({
			...this.state,
			newBidLowerLimit: lowerLimit,
			newBidUpperLimit: upperLimit,
			previousExitBidPrice: previousExitBidPrice,
			lastRefreshTime: currentTime,
			timeUp: currentTime > auction.endTime,
		});
	}

	async joinSocketRoom() {
		await joinAuctionRoom(this.props.params.auctionId, this.props.user.user);
	}

	onNewBidPriceChange(event) {
		let value = parseFloat(event.target.value);
		// if (isNaN(value)) value = 0;

		const isInvalidBidPrice =
			isNaN(value) ||
			(this.state.newBidLowerLimit && value < this.state.newBidLowerLimit) ||
			(this.state.newBidUpperLimit && value > this.state.newBidUpperLimit);

		this.setState({ ...this.state, newBidPrice: value, isNewBidPriceValid: !isInvalidBidPrice });
	}

	async requestAssistanceAction() {
		await this.props.requestAssistance(this.props.params.auctionId);
	}

	async makeBid() {
		const data = {
			amount: this.state.newBidPrice,
		};

		const result = await confirm("Bid Confirmation", {
			render: (message, onConfirm, onCancel) => {
				return (
					<>
						<div
							className="card"
							style={{
								width: "15vw",
								margin: "auto",
								alignItems: "center",
							}}
						>
							<div
								className="card-body"
								style={{
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<h6 style={{ padding: "10px" }}> {message} </h6>
								<hr />
								Would you like to submit a bid of <strong>NOK {this.state.newBidPrice} øre/kWh</strong>?
								<div>
									<button
										type="button"
										className="btn btn-outline-success"
										onClick={onConfirm}
										style={{ margin: "10px" }}
									>
										Yes
									</button>
									<button
										type="button"
										className="btn btn-outline-danger"
										onClick={onCancel}
										style={{ margin: "10px" }}
									>
										No
									</button>
								</div>
							</div>
						</div>
					</>
				);
			},
			closeOnOverlayClick: true,
		});

		if (result) {
			await this.props.makeRENOBid(this.props.params.auctionId, data);
			await this.fetchData();

			const { auction } = this.props.auction;

			var newBidPriceValue = 0;

			if (auction.minimumDecrementToStrikePrice == 1 && auction.strikeBid !== null) {
				newBidPriceValue = auction.strikeBid.amount - auction.minimumDecrement;
			} else if (this.state.previousExitBidPrice != null) {
				newBidPriceValue = this.state.previousExitBidPrice - auction.minimumDecrement;
			}

			if (newBidPriceValue < 0) newBidPriceValue = 0;

			const isInvalidBidPrice =
				(this.state.newBidLowerLimit && newBidPriceValue < this.state.newBidLowerLimit) ||
				(this.state.newBidUpperLimit && newBidPriceValue > this.state.newBidUpperLimit);

			this.setState({ ...this.state, newBidPrice: newBidPriceValue, isNewBidPriceValid: !isInvalidBidPrice });
			return;
		}
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && this.props.user.user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border  text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { auction, auction_loading } = this.props.auction;
		const { user, user_loading } = this.props.user;

		const notAwaitingStart = auction.status !== auctionStatus.AWAITING_START;
		const auctionHasEnded = auction.status === auctionStatus.ENDED;
		const winnerAnnounced = auction.winningBid != null;
		const showRank = auction.showRanksToBidders == 1;

		if (auction_loading || user_loading) {
			return this.returnSpinner;
		}

		// Sockets
		socket.off(eventTypes.AUCTION_INFORMATION).on(eventTypes.AUCTION_INFORMATION, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "info",
				duration: 10000,
			});
		});
		socket.off(eventTypes.AUCTION_SUCCESS).on(eventTypes.AUCTION_SUCCESS, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "success",
				duration: 10000,
			});
		});
		socket.off(eventTypes.AUCTION_ERROR).on(eventTypes.AUCTION_ERROR, (data) => {
			this.fetchData();
			addNotification({
				title: data.title,
				message: data.message,
				type: "danger",
				duration: 20000,
			});
		});
		socket.off(eventTypes.AUCTION_WARNING).on(eventTypes.AUCTION_WARNING, (data) => {
			if (data.title === "BREAK HAS ENDED") {
				this.refresh();
			} else {
				this.fetchData();
			}

			addNotification({
				title: data.title,
				message: data.message,
				type: "warning",
				showIcon: true,
				duration: 30000,
			});
		});
		socket.off(eventTypes.AUCTION_REFRESH).on(eventTypes.AUCTION_REFRESH, (data) => {
			this.fetchData();
		});
		socket.off("config_update").on("config_update", (data) => {
			console.log("Config was just updated");
			this.fetchData();
		});

		const invalidNewBidPriceErrorMessage = this.state.newBidUpperLimit
			? `Must be between NOK ${this.state.newBidLowerLimit} øre/kWh and NOK ${this.state.newBidUpperLimit} øre/kWh`
			: `Must be greater than NOK ${this.state.newBidUpperLimit} øre/kWh`;

		return (
			<div className="container">
				<h3>{auction.name}</h3>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							{this.props.config.presentationMode && user.type === "user" ? (
								<>Auctions</>
							) : (
								<Link to="/auctions">Auctions</Link>
							)}
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{auction.name}
						</li>
					</ol>
				</nav>
				<hr />
				<div className="row">
					<div className="col-6">
						<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.refresh()}>
							<i className="fas fa-sync"></i> Data Reload
						</button>
					</div>
					<div className="col-6">
						{user.type === "user" ? (
							<button
								type="number"
								className="btn btn-primary btn-sm"
								onClick={() => this.requestAssistanceAction()}
								style={{ display: "block", marginRight: "0", marginLeft: "auto" }}
							>
								<i className="fas fa-question-circle"></i> Request Assistance
							</button>
						) : (
							<></>
						)}
					</div>
				</div>
				<br />

				{user.type === userType.ADMIN ? <AdminPanel refresh={() => this.fetchData()} /> : <></>}

				<InformationPanel
					auction={this.props.auction}
					user={this.props.user}
					lastRefreshTime={this.state.lastRefreshTime}
				/>

				{winnerAnnounced || auctionHasEnded ? (
					<div>
						<br />
						<div
							className="card border-secondary"
							style={{
								width: "80%",
								margin: "auto",
							}}
						>
							<div className="card-header" style={{ textAlign: "center", height: "2.5rem" }}>
								<div className="row" style={{ paddingLeft: "1rem" }}>
									<h5>Auction Results</h5>
								</div>
							</div>
							<div className="card-body" style={{ alignItems: "center", textAlign: "center" }}>
								<div className="row">
									{winnerAnnounced ? (
										<p>
											<strong>{auction.winningBid.display_name}</strong> has won with a price of{" "}
											<strong>{util.formatCurrencyWithText(auction.winningBid.amount)}</strong>
										</p>
									) : (
										<p>The auction results will be announced soon</p>
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				<br />
				<hr />
				<br />

				{notAwaitingStart ? (
					<>
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead style={{ textAlign: "center" }}>
									<tr>
										<td className="text-center" style={{ width: "15%" }}>
											Item
										</td>
										<td style={{ width: "5%" }}>Qty</td>
										<td style={{ width: "5%" }}>UoM</td>
										<td style={{ width: "10%" }}>Previous Exit Bid Price</td>
										<td style={{ width: "10%" }}>Minimum Decrement</td>
										<td style={{ width: "30%" }}>New Bid Price</td>
										{showRank ? <td style={{ width: "10%" }}>Your Rank</td> : <></>}
										<td style={{ width: "10%" }}>Overall Lowest Bid</td>
									</tr>
								</thead>
								<tbody style={{ textAlign: "center" }}>
									<tr>
										<td>CfD - Price per KWh</td>
										<td>1</td>
										<td>øre/k</td>
										<td>
											{this.state.previousExitBidPrice
												? util.formatCurrency(this.state.previousExitBidPrice)
												: "-"}
										</td>
										<td>{util.formatCurrency(auction.minimumDecrement)}</td>
										<td>
											<div className="input-group input-group-sm has-validation">
												<span className="input-group-text" id="inputGroupPrepend3">
													NOK
												</span>
												<input
													type="number"
													className={
														auctionHasEnded || this.state.timeUp
															? "form-control"
															: this.state.isNewBidPriceValid
															? "form-control is-valid"
															: "form-control is-invalid"
													}
													id="validationServerUsername"
													aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback"
													value={this.state.newBidPrice}
													onChange={this.onNewBidPriceChange.bind(this)}
													disabled={auctionHasEnded || this.state.timeUp}
													required
												/>
												<span className="input-group-text" id="inputGroupPrepend3">
													øre/kWh
												</span>

												{this.state.isNewBidPriceValid || auctionHasEnded ? (
													<></>
												) : (
													<div
														id="validationServerUsernameFeedback"
														className="invalid-feedback"
													>
														{invalidNewBidPriceErrorMessage}
													</div>
												)}
											</div>
										</td>
										{showRank ? (
											<td>
												{auction.strikeBid && auction.strikeBid.rank
													? auction.strikeBid.rank
													: " - "}
											</td>
										) : (
											<></>
										)}
										<td>
											{auction.strikeBid && auction.strikeBid.amount
												? util.formatCurrency(auction.strikeBid.amount)
												: " - "}
										</td>
									</tr>
								</tbody>
								<tfoot style={{ alignItems: "center", textAlign: "center" }}>
									<tr>
										<td colSpan={5}></td>
										<td colSpan={1}>
											<button
												type="button"
												className="btn btn-primary btn-sm"
												disabled={
													!this.state.isNewBidPriceValid ||
													auctionHasEnded ||
													this.state.timeUp
												}
												onClick={() => this.makeBid()}
											>
												Preview My Bid
											</button>
										</td>
										<td colSpan={2}>
											<button
												type="button"
												className="btn btn-outline-primary btn-sm"
												onClick={() => this.refresh()}
											>
												<i className="fas fa-sync"></i> Refresh
											</button>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>

						<br />

						<AuctionRulesCard auction={this.props.auction} user={this.props.user} />

						<br />
						<hr />
						<br />

						{user.type === userType.ADMIN ? (
							<AdminBidHistoryTable auction={this.props.auction} />
						) : (
							<UserBidHistoryTable auction={this.props.auction} user={this.props.user} />
						)}

						<br />
					</>
				) : (
					<></>
				)}
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <RENOAuction {...props} params={params} />;
}

RENOAuction.propTypes = {
	getAuction: PropTypes.func.isRequired,
	getUserRENOBids: PropTypes.func.isRequired,
	makeRENOBid: PropTypes.func.isRequired,
	getHistory: PropTypes.func.isRequired,
	getAllUsers: PropTypes.func.isRequired,
	setAuctionsNotLoading: PropTypes.func.isRequired,
	setAuctionsLoading: PropTypes.func.isRequired,
	setUserNotLoading: PropTypes.func.isRequired,
	setUserLoading: PropTypes.func.isRequired,
	requestAssistance: PropTypes.func.isRequired,
	rejoinedSocketRoom: PropTypes.func.isRequired,
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auction: state.auction,
	user: state.user,
	config: state.config,
	socket: state.socket,
});

export default connect(mapStateToProps, {
	getAuction,
	getUserRENOBids,
	makeRENOBid,
	setAuctionsLoading,
	setAuctionsNotLoading,
	setUserLoading,
	setUserNotLoading,
	requestAssistance,
	rejoinedSocketRoom,
	getHistory,
	getAllUsers,
})(WithParamsAndNavigate);
