import React, { PureComponent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { COLORS } from "../../common/constants";

export default class PriceLineChart extends PureComponent {
	render() {
		let leases_data = [];
		let idx = 0;
		this.props.leases
			.sort((a, b) => a.id - b.id)
			.forEach((lease) => {
				leases_data.push({
					id: lease.id,
					name: lease.name,
					stroke: COLORS[idx],
				});
				idx += 1;
			});

		return (
			<ResponsiveContainer width="100%" height={this.props.height}>
				<LineChart
					width={400}
					height={this.props.height}
					data={this.props.data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="round" />
					<YAxis
						tickCount={this.props.tickCount}
						tickFormatter={(value) =>
							new Intl.NumberFormat("en", {
								notation: "compact",
								compactDisplay: "short",
								style: "currency",
								currency: "USD",
							}).format(value)
						}
					/>
					<Tooltip
						formatter={(value) =>
							new Intl.NumberFormat("en", {
								style: "currency",
								currency: "USD",
							}).format(value)
						}
					/>
					<Legend />
					{leases_data.map(({ id, name, stroke }) => (
						<Line
							key={id}
							name={name}
							type="monotone"
							dataKey={id.toString()}
							stroke={stroke}
							strokeWidth={2}
							dot={{ strokeWidth: this.props.dotWidth }}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		);
	}
}
