import React, { Component } from "react";
import PropTypes from "prop-types";

class CreditsDashboard extends Component {
	constructor(props) {
		super(props);
	}

	getPercentageText(lease_id, user_id) {
		const { bid_credits } = this.props.auction;

		if (bid_credits.filter((bc) => bc.lease_id == lease_id && bc.user_id == user_id).length > 0) {
			const bid_credit = bid_credits.filter((bc) => bc.lease_id == lease_id && bc.user_id == user_id)[0];
			return `${parseFloat(bid_credit.percentage).toFixed(2)}%`;
		}

		return `${parseFloat(0).toFixed(2)}%`;
	}

	render() {
		const { users } = this.props.user;
		const { auction, bid_credits } = this.props.auction;

		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Bid Credits</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />

				<div className="card" style={{ width: "90%", margin: "auto" }}>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-sm table-bordered">
								<thead>
									<tr>
										<th
											style={{
												minWidth: "160px",
												margin: `auto`,
												padding: `15px`,
											}}
										></th>
										{users ? (
											users.map(({ id, username, display_name }) => (
												<th
													key={id}
													style={{
														minWidth: "100px",
														margin: `auto`,
													}}
												>
													{`${display_name} (${username})`}
												</th>
											))
										) : (
											<></>
										)}
									</tr>
								</thead>
								<tbody>
									{auction.leases ? (
										auction.leases.map((lease) => (
											<tr key={lease.id}>
												<th>{lease.name}</th>

												{users ? (
													users.map(({ id }) => (
														<td
															key={id}
															style={{
																minWidth: "100px",
																margin: `auto`,
															}}
														>
															{this.getPercentageText(lease.id, id)}
														</td>
													))
												) : (
													<></>
												)}
											</tr>
										))
									) : (
										<></>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
	}
}

CreditsDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default CreditsDashboard;
