import { Store } from "react-notifications-component";

export const addNotification = (notification) => {
	const title = notification.title ? notification.title : null;
	const message = notification.message ? notification.message : null;
	const type = notification.type ? notification.type : "success";
	const duration = notification.duration ? notification.duration : 0;

	Store.addNotification({
		title: title,
		message: message,
		type: type,
		insert: "top",
		container: "top-right",
		dismiss: {
			duration: duration,
			onScreen: true,
			pauseOnHover: true,
		},
	});
};
