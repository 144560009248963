import React, { Component } from "react";
import PropTypes from "prop-types";
import util from "../../../../util/RENO";

class AdminBidHistoryTable extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { history, auction } = this.props.auction;

		const sortedBids = history.sort((a, b) => b.time - a.time);

		return (
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header" style={{ textAlign: "center", height: "2.5rem" }}>
							<h5>Bidding History</h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								{sortedBids.length == 0 ? (
									<p>No bids have been submitted yet</p>
								) : (
									<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
										<thead>
											<th style={{ width: "15%" }}>Bid Time</th>
											<th style={{ width: "25%" }}>Bidder</th>
											<th style={{ width: "15%" }}>Bid Amount</th>
											<th style={{ width: "15%" }}>Bid Decrement</th>
											<th style={{ width: "12%" }}>
												Rank <em style={{ color: "gray" }}>(on submission)</em>
											</th>
											<th style={{ width: "8%" }}>Time Since Start</th>
											<th style={{ width: "5%" }}>Extended?</th>
										</thead>
										<tbody>
											{sortedBids.map(
												({
													id,
													amount,
													decrement,
													canUndo,
													display_name,
													username,
													winner,
													increasedDuration,
													time,
													rank,
													rankOnSubmission,
												}) => (
													<tr
														key={id}
														className={
															winner ? "table-success" : canUndo ? "table-active" : ""
														}
													>
														<td>{util.formatTime(time)}</td>
														<td>{`${display_name} (${username})`}</td>
														<td>{util.formatCurrencyWithText(amount)}</td>
														<td>{util.formatCurrencyWithText(decrement)}</td>
														<td>
															{rank ? <strong>{rank}</strong> : "-"}{" "}
															<em style={{ color: "gray" }}>
																{rankOnSubmission ? `(${rankOnSubmission})` : "(-)"}
															</em>
														</td>
														<td>{util.formatDuration(time - auction.startTime)}</td>
														<td>{increasedDuration ? "Yes" : "-"}</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								)}
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

AdminBidHistoryTable.propTypes = {
	auction: PropTypes.object.isRequired,
};

export default AdminBidHistoryTable;
