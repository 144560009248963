import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { Provider } from "react-redux";
import store from "./store";

import history from "./history";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Login from "./components/users/Login";
import Landing from "./components/common/Landing";
import Config from "./components/config/Config";
import ErrorPage from "./ErrorPage";

//Auction----------------------------------------------------------------------------
import Auctions from "./components/auctions/GeneralAuction/Pages/Auctions";
import CreateAuction from "./components/auctions/GeneralAuction/Pages/Create";
import StartAuction from "./components/auctions/GeneralAuction//Pages/StartAuction";
import Auction from "./components/auctions/GeneralAuction/Pages/Auction";

import MPSBAuction from "./components/auctions/MPSBAuction/Pages/MPSBAuction";
import SMRAAuction from "./components/auctions/SMRAAuction/Pages/SMRAAuction";

//Bid Trackers----------------------------------------------------------------------------
import BidTracker from "./components/trackers/BidTracker";
import BidTrackers from "./components/trackers/BidTrackers";
import StartTracker from "./components/trackers/StartTracker";

//Dashboards----------------------------------------------------------------------------
import Dashboards from "./components/dashboards/Dashboards";
import Dashboard from "./components/dashboards/Dashboard";

//Simulations----------------------------------------------------------------------------
import Simulations from "./components/simulation/Simulations";
import CreateSimulation from "./components/simulation/Create";
import MPSBSimulation from "./components/simulation/MPSBSimulation";
import CreateRENOSimulation from "./components/simulation/RENO/Create";
import RENOSimulation from "./components/simulation/RENO";

import Calculator from "./components/calculator/Calculator";

class App extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<ReactNotifications />
				<BrowserRouter history={history}>
					<Navbar />
					<br />
					<Switch>
						<Route exact path="/auctions/" component={Auctions} />
						<Route exact path="/auctions/create/:auctionId" component={CreateAuction} />
						<Route exact path="/auctions/start/:auctionId" component={StartAuction} />
						<Route exact path="/auction/:auctionId" component={Auction} />

						<Route exact path="/simulations/" component={Simulations} />
						<Route exact path="/simulations/create/:simulationId" component={CreateRENOSimulation} />
						<Route exact path="/simulations/:simulationId" component={RENOSimulation} />

						<Route exact path="/bidtrackers/" component={BidTrackers} />
						<Route exact path="/bidtrackers/:bidTrackerId" component={BidTracker} />
						<Route exact path="/bidtrackers/:bidTrackerId/start" component={StartTracker} />

						<Route exact path="/dashboards/" component={Dashboards} />
						<Route exact path="/dashboard/:auctionId" component={Dashboard} />

						<Route exact path="/login" component={Login} />
						<Route exact path="/config" component={Config} />
						<Route exact path="/" component={Landing} />

						<Route exact path="/error" component={ErrorPage} />

						<Route exact path="/calculator" component={Calculator} />
					</Switch>
					<Footer />
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
