import React, { Component } from "react";
import PropTypes from "prop-types";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, LabelList } from "recharts";

class BidsSummaryDashboard extends Component {
	constructor(props) {
		super(props);
	}

	renderCustomizedLabel(props) {
		const { x, y, width, height, value } = props;
		const radius = 13;

		return (
			<g>
				<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#999999" />
				<text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
					{value}
				</text>
			</g>
		);
	}

	renderBidderProgressGraph() {
		const { history } = this.props.auction;
		const rounds = this.props.rounds;

		let progressChartData = [];
		rounds.forEach((roundNum) => {
			const clb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 0).length;
			const ceb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 0).length;
			const flb = history.filter((his) => his.round === roundNum && his.exit == 0 && his.carried == 1).length;
			const feb = history.filter((his) => his.round === roundNum && his.exit == 1 && his.carried == 1).length;
			let t = 14;
			let r = 0;
			if (roundNum > 1) {
				t = history.filter((his) => his.round === roundNum - 1 && his.exit == 0).length;
				r = t - (clb + ceb + flb + feb);
			}
			progressChartData.push({
				round: `Round ${roundNum}`,
				totalBids: clb + ceb + flb + feb > 0 ? clb + ceb + flb + feb : 0,
				liveBids: clb > 0 ? clb : null,
				exitBids: ceb > 0 ? ceb : null,
				frozenLiveBids: flb > 0 ? flb : null,
				frozenExitBids: feb > 0 ? feb : null,
				remaining: r > 0 ? r : null,
			});
		});

		return (
			<div className="card" style={{ width: "95%", margin: "auto" }}>
				<div className="card-body">
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							data={progressChartData}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="round" />
							<YAxis domain={[0, "dataMax+1"]} tickCount={4} />
							<Tooltip />
							<Bar
								type="monotone"
								dataKey="frozenLiveBids"
								name="Frozen Live Bids"
								stackId="1"
								stroke="#0275d8"
								fill="#0275d8"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="frozenExitBids"
								name="Frozen Exit Bids"
								stackId="1"
								stroke="#5bc0de"
								fill="#5bc0de"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="liveBids"
								name="Live Bids"
								stackId="1"
								stroke="#5cb85c"
								fill="#5cb85c"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="exitBids"
								name="Exit Bids"
								stackId="1"
								stroke="#f0ad4e"
								fill="#f0ad4e"
								fillOpacity={0.8}
							/>
							<Bar
								type="monotone"
								dataKey="remaining"
								name="Remaining Bids"
								stackId="1"
								stroke="#999999"
								fill="#999999"
								fillOpacity={0.8}
							>
								<LabelList dataKey="totalBids" content={this.renderCustomizedLabel}></LabelList>
							</Bar>
							<Legend wrapperStyle={{ position: "relative" }} verticalAlign="bottom" />
						</BarChart>
					</ResponsiveContainer>
					<br />
				</div>
			</div>
		);
	}

	renderBidHistoryTable() {
		const { history } = this.props.auction;
		const rounds = this.props.rounds;

		if (!history) {
			return <></>;
		}

		const roundsHistory = history.filter((his) => rounds.includes(his.round));

		return (
			<div className="table-responsive">
				<table className="table table-sm table-bordered" id="table-bid-history">
					<thead>
						<tr style={{ textAlign: "center" }}>
							<th>Round</th>
							<th>User</th>
							<th>Lease Name</th>
							<th>Imputed Price</th>
							<th>Cash Bid</th>
							<th>Bid Type</th>
							<th>Bid Carried?</th>
						</tr>
					</thead>
					<tbody>
						{roundsHistory ? (
							roundsHistory.map(
								({
									id,
									round,
									amount,
									financial_exposure,
									exit,
									combined,
									carried,
									username,
									display_name,
									name,
									no_bid,
								}) => (
									<tr key={id}>
										<th style={{ textAlign: "center" }}>{round}</th>
										<td>{`${display_name} (${username})`}</td>
										<td>{name}</td>
										<td style={{ textAlign: "center" }}>{currencyFormat(amount)}</td>
										<td style={{ textAlign: "center" }}>
											{financial_exposure ? currencyFormat(financial_exposure) : "-"}
										</td>
										<td style={{ textAlign: "center" }}>
											{exit
												? combined
													? "Combined Exit Bid"
													: no_bid
													? "(No Bid) Exit Bid"
													: "Exit Bid"
												: "Live Bid"}
										</td>
										<td style={{ textAlign: "center" }}>{carried ? "Yes" : ""}</td>
									</tr>
								)
							)
						) : (
							<></>
						)}
					</tbody>
				</table>
			</div>
		);
	}

	render() {
		const { auction, history } = this.props.auction;

		if (auction.round == 0) return <></>;

		const roundsHistory = history.filter((his) => this.props.rounds.includes(his.round));

		return (
			<>
				<div className="row" style={{ textAlign: "center" }}>
					<h4>Bids Summary ({roundsHistory.length})</h4>
					<hr style={{ width: "50%", margin: "auto" }} />
				</div>
				<br />
				<div className="row">{this.renderBidderProgressGraph()}</div>
				<br />
				<div className="row">{this.renderBidHistoryTable()}</div>
			</>
		);
	}
}

function currencyFormat(num) {
	return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

BidsSummaryDashboard.propTypes = {
	auction: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default BidsSummaryDashboard;
