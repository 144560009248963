import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import {
	getSimulation,
	createSimulation,
	runSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
} from "../../../actions/simulationActions";
import { RAInput } from "../../common/RAInput/RAInput";
import { auctionTypes } from "../../common/constants";
import BidConfigGraph from "./Components/BidConfigGraph";
import util from "../../../util/RENO";

class CreateRENOSimulation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			bidUpperLimit: 999,
			minimumContractPrice: 5,
			minimumBidDecrement: 0.25,
			userMinBidAmount: null,
			userMaxBidAmount: null,
			outOfRangeFloor: 50,
			outOfRangeCeiling: 150,
			competitorBidConfigs: {},
			asq_mw: 3000,
			asq_gwhy: 11826,
			initialRuns: 1000000,
		};

		this.orderOfBidders = ["Bidder 01", "Bidder 02", "Bidder 03", "Bidder 04"];
	}

	componentDidMount() {
		if (this.props.params.simulationId !== "0") {
			this.fetchData();
		} else {
			var newCompetitorBidConfigs = {
				["Bidder 01"]: {
					minAmount: 70,
					maxAmount: 85,
					minDecrement: 0.25,
					maxDecrement: 0.25,
					naiveBidProbability: 0,
					aboveRangeBidProbability: 0,
					belowRangeBidProbability: 0,
				},
				["Bidder 02"]: {
					minAmount: 75,
					maxAmount: 105,
					minDecrement: 0.25,
					maxDecrement: 0.25,
					naiveBidProbability: 0,
					aboveRangeBidProbability: 0,
					belowRangeBidProbability: 0,
				},
				["Bidder 03"]: {
					minAmount: 85,
					maxAmount: 120,
					minDecrement: 0.25,
					maxDecrement: 0.25,
					naiveBidProbability: 0,
					aboveRangeBidProbability: 0,
					belowRangeBidProbability: 0,
				},
				["Bidder 04"]: {
					minAmount: 95,
					maxAmount: 130,
					minDecrement: 0.25,
					maxDecrement: 0.25,
					naiveBidProbability: 0,
					aboveRangeBidProbability: 0,
					belowRangeBidProbability: 0,
				},
			};

			delete newCompetitorBidConfigs[this.props.user.user.display_name];

			this.setState({
				...this.state,
				competitorBidConfigs: newCompetitorBidConfigs,
			});
		}
	}

	async fetchData() {
		// TODO: Implement this for duplication
	}

	async createRENOSimulationEvent(event) {
		event.preventDefault();

		let payloadBidConfigs = [
			{
				username: this.props.user.user.display_name,
				owner: 1,
				minAmount: this.state.userMinBidAmount,
				maxAmount: this.state.userMinBidAmount,
				minDecrement: this.state.minimumBidDecrement,
				maxDecrement: this.state.minimumBidDecrement,
				naiveBidProbability: 0,
				aboveRangeBidProbability: 0,
				belowRangeBidProbability: 0,
				outOfRangeCeiling: this.state.outOfRangeCeiling,
				outOfRangeFloor: this.state.outOfRangeFloor,
			},
		];

		for (const [competitor, bidConfig] of Object.entries(this.state.competitorBidConfigs)) {
			payloadBidConfigs.push({
				username: competitor,
				owner: 0,
				minAmount: bidConfig.minAmount,
				maxAmount: bidConfig.maxAmount,
				minDecrement: bidConfig.minDecrement,
				maxDecrement: bidConfig.maxDecrement,
				naiveBidProbability: bidConfig.naiveBidProbability,
				aboveRangeBidProbability: bidConfig.aboveRangeBidProbability,
				belowRangeBidProbability: bidConfig.belowRangeBidProbability,
				outOfRangeCeiling: this.state.outOfRangeCeiling,
				outOfRangeFloor: this.state.outOfRangeFloor,
			});
		}

		const payload = {
			name: this.state.name,
			creator: this.props.user.user.display_name,
			type: auctionTypes.RENO,
			user_id: this.props.user.user.user_id,
			minimumDecrement: this.state.minimumBidDecrement,
			minimumContractPrice: this.state.minimumContractPrice,
			bidUpperLimit: this.state.bidUpperLimit,
			bidConfigs: payloadBidConfigs,
		};

		await this.props.createSimulation(payload);
		await this.props.setSimulationLoading();
		await this.props.runSimulation(this.props.simulation.simulationId, { runs: this.state.initialRuns });
		await this.props.setSimulationNotLoading();
		this.props.history.push(`/simulations/${this.props.simulation.simulationId}`);
	}

	onChange(event) {
		let value = event.target.value;
		if (event.target.name === "initialRuns") {
			if (value > 2000000) value = 2000000;
			value = parseInt(value);
		} else if (event.target.name === "bidUpperLimit") {
			if (value < 0 || isNaN(value)) value = 0;
			value = parseFloat(value);
		} else if (event.target.name === "minimumContractPrice") {
			if (value < 0 || isNaN(value)) value = 0;
			value = parseFloat(value);
		} else if (event.target.name === "userMinBidAmount") {
			if (value < 0 || isNaN(value)) value = 0;
			value = parseFloat(value);
		} else if (event.target.name === "userMaxBidAmount") {
			if (value < 0 || isNaN(value)) value = 0;
			value = parseFloat(value);
		}
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	onCompetitorBidConfigChange(event, username) {
		let value = event.target.value;

		let competitorBidConfigsCopy = this.state.competitorBidConfigs[username];
		competitorBidConfigsCopy = { ...competitorBidConfigsCopy, [event.target.name]: parseFloat(value) };

		this.setState({
			...this.state,
			competitorBidConfigs: {
				...this.state.competitorBidConfigs,
				[username]: competitorBidConfigsCopy,
			},
		});
	}

	getCreateButtonText() {
		let nameIsEmpty = this.state.name.length === 0;
		let bidUpperLimitIsInvalid = !this.state.bidUpperLimit || this.state.bidUpperLimit < 0;
		let minimumContractPriceIsInvalid = !this.state.minimumContractPrice || this.state.minimumContractPrice < 0;
		let minimumBidDecrementIsInvalid = !this.state.minimumBidDecrement || this.state.minimumBidDecrement < 0;
		let minimumBidPriceIsInvalid =
			!this.state.userMinBidAmount ||
			this.state.userMinBidAmount < 0 ||
			this.state.userMinBidAmount > this.state.bidUpperLimit ||
			this.state.userMinBidAmount < this.state.minimumContractPrice;
		// let maximumBidPriceIsInvalid =
		// 	!this.state.userMaxBidAmount ||
		// 	this.state.userMaxBidAmount < 0 ||
		// 	this.state.userMaxBidAmount > this.state.bidUpperLimit ||
		// 	this.state.userMaxBidAmount < this.state.minimumContractPrice;
		let initialRunsIsInvalid = !this.state.initialRuns || this.state.initialRuns < 0;
		let minIsHigherThanMax = false;
		let emptyInput = false;
		let valuesLowerThanLowerLimit = false;
		let valuesGreaterThanUpperLimit = false;

		for (const [_, bid] of Object.entries(this.state.competitorBidConfigs)) {
			if (!bid.minAmount || !bid.maxAmount) emptyInput = true;
			if (bid.minAmount > bid.maxAmount) minIsHigherThanMax = true;
			if (bid.minAmount < this.state.minimumContractPrice || bid.maxAmount < this.state.minimumContractPrice)
				valuesLowerThanLowerLimit = true;
			if (bid.minAmount > this.state.bidUpperLimit || bid.maxAmount > this.state.bidUpperLimit)
				valuesGreaterThanUpperLimit = true;
		}

		return (
			<div>
				<em>
					{nameIsEmpty ? <p>Please set a name for this simulation.</p> : <></>}
					{bidUpperLimitIsInvalid ? <p>Please make sure that the "Bid Upper Limit" is valid</p> : <></>}
					{minimumContractPriceIsInvalid ? (
						<p>Please make sure that the "Minimum Contract Price" is valid</p>
					) : (
						<></>
					)}
					{minimumBidDecrementIsInvalid ? (
						<p>Please make sure that the "Minimum Bid Decrement" is valid </p>
					) : (
						<></>
					)}
					{minimumBidPriceIsInvalid ? <p>Please make sure that "Your Bid Envelope" is valid</p> : <></>}
					{/* {maximumBidPriceIsInvalid ? <p>Please make sure that "Your Maximum Bid Price" is valid</p> : <></>} */}
					{initialRunsIsInvalid ? <p>Please make sure that the "Initial Runs" number is valid</p> : <></>}
					{minIsHigherThanMax ? (
						<p>Please ensure "Maximum Bid Prices" are greater than corresponding "Minimum Bid Prices".</p>
					) : (
						<></>
					)}
					{emptyInput ? <p>Make sure all input boxes have a valid value greater than 0.</p> : <></>}
					{valuesGreaterThanUpperLimit ? (
						<p>
							Make sure that all the Competitor bid prices are less than (or equal to) the "Bid Upper
							Limit".
						</p>
					) : (
						<></>
					)}
					{valuesLowerThanLowerLimit ? (
						<p>
							Make sure that all the Competitor bid prices are greater than (or equal to) the "Minimum
							Contract Price".
						</p>
					) : (
						<></>
					)}
				</em>
			</div>
		);
	}

	returnSpinner() {
		return (
			<div className="container">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page"></li>
					</ol>
				</nav>
				<hr />

				<div
					style={{
						display: "block",
						position: "fixed",
						zIndex: 100,
						top: "40%",
						right: "50%",
					}}
				>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { simulation_loading } = this.props.simulation;
		const { user, signedIn } = this.props.user;

		let duplicate = false;

		if (this.props.params.simulationId !== "0") {
			duplicate = true;
		}

		let createSimulationEnabled = false;

		let nameIsEmpty = this.state.name.length === 0;
		let bidUpperLimitIsInvalid = !this.state.bidUpperLimit || this.state.bidUpperLimit < 0;
		let minimumContractPriceIsInvalid = !this.state.minimumContractPrice || this.state.minimumContractPrice < 0;
		let minimumBidDecrementIsInvalid = !this.state.minimumBidDecrement || this.state.minimumBidDecrement < 0;
		let minimumBidPriceIsInvalid =
			!this.state.userMinBidAmount ||
			this.state.userMinBidAmount < 0 ||
			this.state.userMinBidAmount > this.state.bidUpperLimit ||
			this.state.userMinBidAmount < this.state.minimumContractPrice;
		// let maximumBidPriceIsInvalid =
		// 	!this.state.userMaxBidAmount ||
		// 	this.state.userMaxBidAmount < 0 ||
		// 	this.state.userMaxBidAmount > this.state.bidUpperLimit ||
		// 	this.state.userMaxBidAmount < this.state.minimumContractPrice;
		let initialRunsIsInvalid = !this.state.initialRuns || this.state.initialRuns < 0;
		let minIsHigherThanMax = false;
		let emptyInput = false;
		let valuesLowerThanLowerLimit = false;
		let valuesGreaterThanUpperLimit = false;

		for (const [_, bid] of Object.entries(this.state.competitorBidConfigs)) {
			if (!bid.minAmount || !bid.maxAmount) emptyInput = true;
			if (bid.minAmount > bid.maxAmount) minIsHigherThanMax = true;
			if (bid.minAmount < this.state.minimumContractPrice || bid.maxAmount < this.state.minimumContractPrice)
				valuesLowerThanLowerLimit = true;
			if (bid.minAmount > this.state.bidUpperLimit || bid.maxAmount > this.state.bidUpperLimit)
				valuesGreaterThanUpperLimit = true;
		}

		createSimulationEnabled =
			!nameIsEmpty &&
			!bidUpperLimitIsInvalid &&
			!minimumContractPriceIsInvalid &&
			!minimumBidDecrementIsInvalid &&
			!minimumBidPriceIsInvalid &&
			// !maximumBidPriceIsInvalid &&
			!initialRunsIsInvalid &&
			!minIsHigherThanMax &&
			!emptyInput &&
			!valuesLowerThanLowerLimit &&
			!valuesGreaterThanUpperLimit;

		const sortedCompetitorConfigs = [];
		this.orderOfBidders.forEach((username) => {
			if (username in this.state.competitorBidConfigs)
				sortedCompetitorConfigs.push({ username, ...this.state.competitorBidConfigs[username] });
		});

		if (!signedIn) {
			return <Redirect to="/simulations" />;
		}

		if (simulation_loading) {
			return this.returnSpinner();
		}

		return (
			<div className="container">
				<h3>
					{duplicate ? "Copying" : "Create"} New Simulation{" "}
					{duplicate ? "from " + this.props.simulation.simulation.name : ""}
				</h3>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li key="1" className="breadcrumb-item">
							<Link to="/simulations">Simulations</Link>
						</li>
						<li key="2" className="breadcrumb-item active" aria-current="page">
							{duplicate ? "Copying" : "Create"} New Simulation
						</li>
					</ol>
				</nav>
				<hr />

				{/* SIMULATION DETAILS */}
				<div className="card mb-3">
					<div className="card-body">
						<div className="mb-3">
							<h4 className="form-label">Simulation Details</h4>
							<div className="form-floating mb-3">
								<RAInput
									id="simulation-name"
									name="name"
									value={this.state.name}
									onChange={(e) => this.onChange(e)}
									label="Simulation Name"
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-4">
								<div className="form-floating mb-3">
									<RAInput
										id="bidUpperLimit"
										name="bidUpperLimit"
										value={this.state.bidUpperLimit}
										onChange={(e) => this.onChange(e)}
										label="Bid Upper Limit (in NOK øre/kWh)"
									/>
								</div>
							</div>
							<div className="col-4">
								<div className="form-floating mb-3">
									<RAInput
										id="minimumContractPrice"
										name="minimumContractPrice"
										value={this.state.minimumContractPrice}
										onChange={(e) => this.onChange(e)}
										label="Minimum Contract Price (in NOK øre/kWh)"
									/>
								</div>
							</div>
							<div className="col-4">
								<div className="form-floating mb-3">
									<RAInput
										id="minimumBidDecrement"
										name="minimumBidDecrement"
										value={this.state.minimumBidDecrement}
										onChange={(e) => this.onChange(e)}
										label="Minimum Bid Decrement (in NOK øre/kWh)"
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<div className="form-floating mb-3">
									<RAInput
										id="outOfRangeFloor"
										type="number"
										name="outOfRangeFloor"
										value={this.state.outOfRangeFloor}
										onChange={(e) => this.onChange(e)}
										label="Out of Range Bid Price Floor (in NOK øre/kWh)"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-floating mb-3">
									<RAInput
										id="outOfRangeCeiling"
										type="number"
										name="outOfRangeCeiling"
										value={this.state.outOfRangeCeiling}
										onChange={(e) => this.onChange(e)}
										label="Out of Range Bid Price Ceiling (in NOK øre/kWh)"
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<div className="form-floating mb-3">
									<RAInput
										id="userMinBidAmount"
										type="number"
										name="userMinBidAmount"
										value={this.state.userMinBidAmount}
										onChange={(e) => this.onChange(e)}
										label="Your Bid Envelope (in NOK øre/kWh)"
									/>
								</div>
							</div>
							{/* <div className="col-4">
								<div className="form-floating mb-3">
									<RAInput
										id="userMaxBidAmount"
										name="userMaxBidAmount"
										value={this.state.userMaxBidAmount}
										onChange={(e) => this.onChange(e)}
										label="Your Maximum Bid Price (in NOK øre/kWh)"
									/>
								</div>
							</div> */}
							<div className="col-6">
								<div className="form-floating mb-3">
									<RAInput
										id="initialRuns"
										name="initialRuns"
										value={this.state.initialRuns}
										onChange={(e) => this.onChange(e)}
										label="Initial Runs"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<BidConfigGraph
					ownerUsername={user.display_name}
					competitorBidConfigs={this.state.competitorBidConfigs}
					userMinBidAmount={this.state.userMinBidAmount}
					outOfRangeFloor={this.state.outOfRangeFloor}
					outOfRangeCeiling={this.state.outOfRangeCeiling}
				/>

				{/* COMPETITOR EVALUATION RANGES */}
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col">
								<h4 className="form-label">Competitor Evaluation Ranges</h4>
							</div>
						</div>

						<br />

						<table className="table table-sm table-bordered" style={{ textAlign: "center" }}>
							<thead>
								<th style={{ width: "15%" }}>Competitor Name</th>
								<th style={{ width: "10%" }}>Minimum Bid Price</th>
								<th style={{ width: "10%" }}>Maximum Bid Price</th>
								<th style={{ width: "10%" }}>Minimum Decrement</th>
								<th style={{ width: "10%" }}>Maximum Decrement</th>
								<th style={{ width: "15%" }}>Naïve Bid Probability</th>
								<th style={{ width: "15%" }}>Below Range Bid Probability</th>
								<th style={{ width: "15%" }}>Above Range Bid Probability</th>
							</thead>

							<tbody>
								{sortedCompetitorConfigs.map((bidConfig) => {
									return (
										<>
											<tr key={bidConfig.username}>
												<td className="align-middle">{bidConfig.username}</td>
												<td>
													<div className="input-group input-group-sm has-validation">
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															NOK
														</span> */}
														<input
															id="min-amount"
															name="minAmount"
															type="number"
															className="form-control"
															value={bidConfig.minAmount}
															onChange={(e) =>
																this.onCompetitorBidConfigChange(e, bidConfig.username)
															}
															style={{ textAlign: "center" }}
														/>
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															øre/kWh
														</span> */}
													</div>
												</td>
												<td>
													<div className="input-group input-group-sm has-validation">
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															NOK
														</span> */}
														<input
															id="max-amount"
															name="maxAmount"
															type="number"
															className="form-control"
															value={bidConfig.maxAmount}
															onChange={(e) =>
																this.onCompetitorBidConfigChange(e, bidConfig.username)
															}
															style={{ textAlign: "center" }}
														/>
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															øre/kWh
														</span> */}
													</div>
												</td>
												<td>
													<div className="input-group input-group-sm has-validation">
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															NOK
														</span> */}
														<input
															id="min-decrement"
															name="minDecrement"
															type="number"
															className="form-control"
															value={bidConfig.minDecrement}
															onChange={(e) =>
																this.onCompetitorBidConfigChange(e, bidConfig.username)
															}
															style={{ textAlign: "center" }}
														/>
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															øre/kWh
														</span> */}
													</div>
												</td>
												<td>
													<div className="input-group input-group-sm has-validation">
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															NOK
														</span> */}
														<input
															id="max-decrement"
															name="maxDecrement"
															type="number"
															className="form-control"
															value={bidConfig.maxDecrement}
															onChange={(e) =>
																this.onCompetitorBidConfigChange(e, bidConfig.username)
															}
															style={{ textAlign: "center" }}
														/>
														{/* <span className="input-group-text" style={{ fontSize: "12px" }}>
															øre/kWh
														</span> */}
													</div>
												</td>
												<td className="align-middle">
													<div className="row">
														<div className="col-3">
															<label
																for={`${bidConfig.username}-range`}
																class="form-label"
																style={{ fontSize: "13px" }}
															>
																{`${util.getPercentage(
																	bidConfig.naiveBidProbability,
																	1,
																	0
																)}%`}
															</label>
														</div>
														<div className="col-9">
															<input
																name="naiveBidProbability"
																type="range"
																class="form-range"
																min={0}
																max={1}
																step={0.05}
																id={`${bidConfig.username}-range`}
																value={bidConfig.naiveBidProbability}
																onChange={(e) =>
																	this.onCompetitorBidConfigChange(
																		e,
																		bidConfig.username
																	)
																}
															/>
														</div>
													</div>
												</td>
												<td className="align-middle">
													<div className="row">
														<div className="col-3">
															<label
																for={`${bidConfig.username}-range`}
																class="form-label"
																style={{ fontSize: "13px" }}
															>
																{`${util.getPercentage(
																	bidConfig.belowRangeBidProbability,
																	1,
																	0
																)}%`}
															</label>
														</div>
														<div className="col-9">
															<input
																name="belowRangeBidProbability"
																type="range"
																class="form-range"
																min={0}
																max={0.3}
																step={0.05}
																id={`${bidConfig.username}-range`}
																value={bidConfig.belowRangeBidProbability}
																onChange={(e) =>
																	this.onCompetitorBidConfigChange(
																		e,
																		bidConfig.username
																	)
																}
															/>
														</div>
													</div>
												</td>
												<td className="align-middle">
													<div className="row">
														<div className="col-3">
															<label
																for={`${bidConfig.username}-range`}
																class="form-label"
																style={{ fontSize: "13px" }}
															>
																{`${util.getPercentage(
																	bidConfig.aboveRangeBidProbability,
																	1,
																	0
																)}%`}
															</label>
														</div>
														<div className="col-9">
															<input
																name="aboveRangeBidProbability"
																type="range"
																class="form-range"
																min={0}
																max={0.3}
																step={0.05}
																id={`${bidConfig.username}-range`}
																value={bidConfig.aboveRangeBidProbability}
																onChange={(e) =>
																	this.onCompetitorBidConfigChange(
																		e,
																		bidConfig.username
																	)
																}
															/>
														</div>
													</div>
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>

				{/* CREATE SIMULATION BUTTON */}
				<div className="card mb-3">
					<div className="card-body">
						<div
							className="row"
							style={{
								justifyContent: "flex-end",
								margin: "auto",
								textAlign: "right",
								alignItems: "right",
								color: "red",
							}}
						>
							<br />
							{this.getCreateButtonText()}
							<button
								disabled={!createSimulationEnabled}
								className="btn btn-primary"
								style={{ width: "auto" }}
								onClick={this.createRENOSimulationEvent.bind(this)}
							>
								Create Simulation
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function WithParamsAndNavigate(props) {
	let params = useParams(); // React Hooks
	return <CreateRENOSimulation {...props} params={params} />;
}

CreateRENOSimulation.propTypes = {
	getSimulation: PropTypes.func.isRequired,
	createSimulation: PropTypes.func.isRequired,
	runSimulation: PropTypes.func.isRequired,
	setSimulationLoading: PropTypes.func.isRequired,
	setSimulationNotLoading: PropTypes.func.isRequired,
	simulation: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	simulation: state.simulation,
	user: state.user,
});

export default connect(mapStateToProps, {
	getSimulation,
	createSimulation,
	runSimulation,
	setSimulationLoading,
	setSimulationNotLoading,
})(WithParamsAndNavigate);
