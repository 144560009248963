import React from "react";
import { useState } from "react";

function MPSBAuctionCalculator() {
	const [op, setOP] = useState("");
	const [ecf, setECF] = useState("");
	const [oq, setOQ] = useState("");
	const [tea, setTEA] = useState(""); //for last calculation
	const [fcr, setFCR] = useState(""); //for last calculation

	const resultCalc1 = op * ecf;
	const resultCalc2 = (oq * 8760 * 0.45) / 1000;
	const resultCalc3 = Math.max(Math.min(tea / fcr, 18000), 1000);

	return (
		<div>
			<div className="card">
				<div className="card-body">
					<h5 className="card-title">Calculation Deemed Offer Price</h5>
					<div className="row">
						<div className="col">
							<input
								className="form-control-sm"
								type="text"
								onChange={(e) => setOP(e.target.value)}
								placeholder="OP"
								value={op}
								id="num1"
							/>{" "}
							x
							<input
								className="form-control-sm"
								type="text"
								onChange={(e) => setECF(e.target.value)}
								placeholder="ECF"
								value={ecf}
								style={{ marginLeft: 5 }}
								id="num2"
							/>{" "}
							=
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								placeholder="DOP"
								value={resultCalc1}
								id="result"
							/>
						</div>
					</div>
					<div className="card-body">
						<div className="class-subtitle mb-2 text-muted">Values meaning</div>
						<p className="card-text">
							OP = Offer Price <br />
							ECF = Evaluation Correction Factor, set at 1.00 <br />
						</p>
					</div>
				</div>
			</div>

			<br />

			<div className="card">
				<div className="card-body">
					<h5 className="card-title">Calculation Deemed Energy Quantity</h5>
					<div clasName="row">
						<div className="col">
							<input
								className="form-control-sm"
								type="text"
								onChange={(e) => setOQ(e.target.value)}
								placeholder="OQ"
								value={oq}
								id="num1"
							/>{" "}
							x
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								value="8760 x 45% / 1000"
								id="num2"
								disabled
							/>{" "}
							=
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								id="result"
								value={resultCalc2}
								placeholder="DEQ"
							/>
						</div>
					</div>
					<div className="card-body">
						<p className="card-text">
							OQ= Offer Quantity <br />
							RCF = Renewable Capacity Factor, set at 45%
						</p>
					</div>
				</div>
			</div>
			<br />
			<div className="card">
				<div className="card-body">
					<h5 className="card-title">Calculation Auction Starting Quantity</h5>
					<div className="row">
						<div className="col">
							<input className="form-control-sm" type="text" value="Max ( Min ( " size="10" disabled />
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								onChange={(e) => setTEA(e.target.value)}
								value={tea}
								placeholder="TEA"
								id="num1"
							/>{" "}
							/
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								onChange={(e) => setFCR(e.target.value)}
								value={fcr}
								placeholder="FCR"
								id="num2"
							/>{" "}
							<input
								className="form-control-sm"
								type="text"
								value=" , RMax ), RMin )"
								size="14"
								disabled
							/>{" "}
							=
							<input
								className="form-control-sm"
								type="text"
								style={{ marginLeft: 5 }}
								value={resultCalc3}
								id="result"
								placeholder="ASQ"
							/>
						</div>
					</div>
					<div className="card-body">
						<p className="card-text">
							TEA = Total Energy Available (sum of Offer DEQs) <br />
							FCR = Final Competition Ratio, set by the CRU, was 1.37 in RESS2 and 1.87 for community
							projects <br />
							Rmax = Representative Maximum Quantity (18,000 GWh) <br />
							Rmin = Representative Minimum Quantity (1,000 GWh) <br />
							<span className="class-subtitle mb-2 text-muted">
								{" "}
								*Note: The ASQ will be between 1,000 GWh and 18,000 GWh{" "}
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MPSBAuctionCalculator;
