import React, { PureComponent } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

export default class ExitBidAreaChart extends PureComponent {
	render() {
		return (
			<ResponsiveContainer width="100%" height={150}>
				<AreaChart
					width={200}
					height={150}
					data={this.props.data}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis allowDecimals={false} tickCount={3} />
					<Tooltip />
					<Area type="monotone" dataKey="exit_bids" name="Exit Bids" stroke="#f0ad4e" fill="#f0ad4e" />
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}
