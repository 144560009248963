import { ERROR, CLEAR_ERROR, AUCTION_LOADING } from "../actions/types";

const initialState = {
	error: "",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ERROR:
			return {
				...state,
				error: action.payload,
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: "",
			};
		default:
			return state;
	}
}
