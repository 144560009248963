import React from "react";
import { useState } from "react";
import MPSBAuctionCalculator from "./MPSBAuctionCalculator";

function Calculator() {
	const [selectText, setSelectText] = useState("select");

	return (
		<div className="container" style={{ marginBottom: 30 }}>
			<h3>Calculator</h3>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item active" aria-current="page">
						Calculator
					</li>
				</ol>
			</nav>
			<hr />
			<div className="card" style={{ marginBottom: 20 }}>
				<div className="card-body">
					<h6> Select auction type</h6>
					<select
						value={selectText}
						onChange={(e) => setSelectText(e.target.value)}
						style={{
							width: "200px",
							marginRight: "5px",
							padding: "6px",
							borderRadius: "4px",
							fontSize: "14px",
							borderColor: "#0275d8",
							color: "#0275d8",
							position: "absolute",
							top: "13px",
							right: "5px",
						}}
					>
						<option value="select" disabled={true}>
							Select Simulation Type
						</option>
						<option value="mpsb">MPSB</option>
						<option value="smra">SMRA</option>
					</select>
				</div>
			</div>
			{selectText === "mpsb" && <MPSBAuctionCalculator />}
		</div>
	);
}

export default Calculator;
