import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export const Timer = (props) => {
	let { endTime, status, highlight } = props;
	const params = useParams();
	const auction = useSelector((state) => state.auction);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const [secondsRemaining, setSecondsRemaining] = useState(
		endTime - Math.floor(new Date().getTime() / 1000.0) < 0
			? 0
			: endTime - Math.floor(new Date().getTime() / 1000.0)
	);

	useEffect(() => {
		if (secondsRemaining > 0) {
			const intervalId = setInterval(() => {
				setSecondsRemaining((secondsRemaining) => endTime - Math.floor(new Date().getTime() / 1000.0));
			}, 1000);
			return () => {
				clearInterval(intervalId);
			};
		}
	}, [secondsRemaining]);

	const getTime = () => {
		let style = {};
		if (secondsRemaining <= highlight) {
			style = { color: "red" };
		}
		if (status === "In a Break") {
			style = { color: "orange" };
		}
		return <strong style={style}>{formatTime()}</strong>;
	};

	const formatTime = () => {
		if (status === "In Progress") {
			if (secondsRemaining > 0) {
				let hours = Math.floor(secondsRemaining / 3600);
				let minutes = Math.floor(secondsRemaining / 60) % 60;
				let seconds = secondsRemaining % 60;
				return [hours, minutes, seconds]
					.map((v) => ("" + v).padStart(2, "0"))
					.filter((v, i) => v !== "00" || i > 0)
					.join(":");
			}
			if (secondsRemaining === 0) {
				return "Time Up";
			}
		}
		if (status === "Awaiting Start") {
			return "Not Started";
		}
		if (status === "Ended") {
			return "Time Up";
		}

		if (status === "In a Break") {
			return "In a Break";
		}
	};

	return <div>Time Remaining: {getTime()}</div>;
};
