import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";

class ErrorPage extends Component {
	redirect() {
		this.props.history.push(`/auctions/`);
	}

	render() {
		return (
			<div className="d-flex align-items-center justify-content-center vh-100">
				<div className="text-center">
					<h1 className="display-1 fw-bold">404</h1>
					<p className="fs-3">
						{" "}
						<span className="text-danger">Oops!</span> Page not found.
					</p>
					<p className="lead">The page you’re looking for doesn’t exist.</p>
					<button type="button" className="btn btn-outline-primary" onClick={() => this.redirect()}>
						Go Home
					</button>
				</div>
			</div>
		);
	}
}

export default ErrorPage;
